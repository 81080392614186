import {
    observable,
    computed,
    action,
    makeObservable,
    reaction,
    runInAction,
    toJS,
} from 'mobx'
import React from 'react'
import _ from 'lodash'
import {
    addDays,
    addMonths,
    addWeeks,
    differenceInBusinessDays,
    endOfDay,
    endOfISOWeek,
    endOfMonth,
    format,
    parse,
    startOfDay,
    startOfISOWeek,
    startOfMonth,
    subMonths,
    subWeeks,
} from 'date-fns'
import TableStore from '../../Components/TableStore'
import StaffCollection from '../../State/Collections/StaffCollection'
import ResourceScheduleReportCollection from '../../State/Collections/ResourceScheduleReportCollection'
import LayoutStore from '../../State/LayoutStore'
import SessionStore from '../../State/SessionStore'
import TimeEntryAggregateCollection from '../../State/Aggregates/TimeEntryAggregateCollection'
import { getResourceValuesInDateRange } from '../../Utils/getValuesInDateRange'
import { editAllocationsInDateRange } from '../../Utils/allocationHelpers'
import download from 'downloadjs'
import Papa from 'papaparse'
import bind from 'bind-decorator'
import fetchData from '../../Queries/fetchData'
import { tuple } from 'immutable-tuple'
import { qf } from '../../Queries/queryFormatter'
import FetchStore from '../../Queries/FetchStore'
import DailyAllocationCollection from '../../State/Collections/DailyAllocationCollection'
import { capitalCase } from 'change-case'
import cuid from 'cuid'
import { makeRequest } from '../../Queries/makeRequest'
import { queryClient, router } from '../../App'
import MonthlyResourceCellCollection from '../../State/Collections/MonthlyResourceCellCollection'
import ResourceRowCollection from '../../State/Collections/ResourceRowCollection'
import { ResourceScheduleReportAllocationColumns } from '../../reports/ResourceSchedule/ResourceScheduleReportAllocationColumns'
import { computedFn } from 'mobx-utils'
import OrganisationHolidayCollection from '../../State/Collections/OrganisationHolidayCollection'
import { keys } from 'localforage'
import PhaseCollection from '../../State/Collections/PhaseCollection'

const undefinedRole = { id: null }

export const groupProp = {
    project: 'projectId',
    phase: 'phaseId',
    role: 'staffRoleId',
    staff: 'staffId',
    status: 'status',
    owner: 'ownerId',
}

export const groupName = {
    project: (row) => row.project.title,
    phase: (row) => row.phase.title,
    role: (row) => row?.name || '(No Role)',
    staff: (row) => row.fullName || '(No Staff)',
    status: (row) => capitalCase(row),
}

const dateFuncs = {
    month: {
        add: addMonths,
        sub: subMonths,
        start: startOfMonth,
        end: endOfMonth,
        formatString: 'yyyy-MM',
        displayFormatString: 'MMM yy',
    },
    week: {
        add: addWeeks,
        sub: subWeeks,
        start: startOfISOWeek,
        end: endOfISOWeek,
        formatString: 'RRRR-II',
        displayFormatString: 'dd MMM yy',
    },
}

class ResourceScheduleStore {
    @observable startDate = startOfMonth(subMonths(new Date(), 3))
    @observable endDate = endOfMonth(addMonths(new Date(), 8))
    @observable selectedObject = null
    @observable selectedPeriod = null
    @observable selectedProjectId = null
    @observable selectedPhaseId = null
    @observable selectedStaffId = null
    @observable selectedRoleId = null
    @observable selectedStaffIds = []
    @observable selectedPhaseIds = []
    @observable isPrinting = false
    @observable expandedAllocationRows = new Set()
    @observable editedHours = {
        month: {},
        week: {},
    }
    @observable queryData = []
    @observable _periodType = 'month'
    @observable searchParams = {}
    @observable expandAll = false
    @observable exportGroup = 'staff'

    constructor() {
        makeObservable(this)
        this.resourceTableStore = new TableStore()
    }

    @action.bound
    init(queryData) {
        this._periodType = this.report.filters.period || 'month'
        this.queryData = queryData
        this.getLeafResourceRows(null).forEach((r) => {
            this.editedHours[this.periodType][r.fullPath] = []
        })
        this.startDate = this.startOfPeriod(this.subPeriods(new Date(), 3))
        this.endDate = this.endOfPeriod(this.addPeriods(new Date(), 8))
        this.selectedObject = null
        this.selectedPeriod = null
    }

    @action
    reset() {
        LayoutStore.toggleSidebar(false)
        this.startDate = this.startOfPeriod(this.subPeriods(new Date(), 3))
        this.endDate = this.endOfPeriod(this.addPeriods(new Date(), 8))
        this.selectedObject = null
        this.selectedPeriod = null
        this.expandedAllocationRows = new Set()
        this.queryData = []
    }
    @action.bound
    setSearchParams(params) {
        this.searchParams = params
    }

    @action.bound
    setExportGroup(group) {
        this.exportGroup = group
    }

    @computed
    get periodType() {
        return this.report?.filters?.period || this._periodType
    }

    setPeriodType = (type) => {
        this._periodType = type
        this.report.updateFilters({ period: type })
        this.reset()
    }

    addPeriods = (...params) => {
        return dateFuncs[this.periodType].add(...params)
    }
    subPeriods = (...params) => {
        return dateFuncs[this.periodType].sub(...params)
    }
    startOfPeriod = (...params) => {
        return dateFuncs[this.periodType].start(...params)
    }
    endOfPeriod = (...params) => {
        return dateFuncs[this.periodType].end(...params)
    }
    periodFormat = () => {
        return dateFuncs[this.periodType].formatString
    }

    periodDisplayFormat = () => {
        return dateFuncs[this.periodType].displayFormatString
    }

    @computed
    get staff() {
        const reportFilters = this.report.filters
        return StaffCollection.staff.filter((s) => {
            return (
                (!reportFilters.roles.length ||
                    reportFilters.roles.includes(s.roleId)) &&
                (!reportFilters.staff.length ||
                    reportFilters.staff.includes(s.id)) &&
                (reportFilters.staff.length ||
                    reportFilters.roles.length ||
                    !s.deletedAt)
            )
        })
    }

    @action.bound
    setExpandAll(expandAll) {
        this.expandAll = expandAll
    }

    @bind
    makeQueryKey(base) {
        return base + this.report.queryKey
    }

    @computed
    get fetchedProjectIds() {
        return [
            ...new Set(
                this.getAllLeafResourceRows().map(
                    (r) =>
                        r.fullPath.split(',')[
                            this.report.filters.groups.findIndex(
                                (g) => g === 'project'
                            )
                        ]
                )
            ),
        ].filter((v) => v)
    }

    getResourceRows = computedFn((keyString = '') => {
        const keys = keyString ? keyString.split(',').filter((v) => v) : []
        let rows = this.queryData
        keys.forEach((k) => {
            rows = (
                rows.find((r) => r?.key === k)?.children || [
                    rows.find((r) => r?.key === k),
                ]
            ).filter((v) => v)
        })
        return rows || []
    })

    getAllLeafResourceRows = computedFn(() => {
        let rows = this.queryData
        while (rows[0]?.children?.length) {
            rows = rows.flatMap((r) => r.children)
        }
        return rows
    })

    getRowStaffIds = (row) => {
        let rows = [row]
        while (rows[0]?.children?.length) {
            rows = rows.flatMap((r) => r.children)
        }
        return [...new Set(rows.filter(Boolean).map((r) => r.staffId))]
    }

    getRowPhaseIds = (row) => {
        let rows = [row]
        while (rows[0]?.children?.length) {
            rows = rows.flatMap((r) => r.children)
        }
        return [...new Set(rows.filter(Boolean).map((r) => r.phaseId))]
    }

    getLeafResourceRows = computedFn((keyString = '') => {
        return []
        const rows = this.getAllLeafResourceRows()
        const keys = keyString?.split(',').filter((v) => v) || []
        return rows.filter((r) => keys.every((k) => r.fullPath.includes(k)))
    })

    getEditedHoursData = computedFn((keyString = '') => {
        const rows = this.getLeafResourceRows(keyString)
        return rows.flatMap(
            (r) => this.editedHours[this.periodType][r.fullPath] || []
        )
    })

    @bind
    setEditedHoursData(keyString = '', period, hours, afterEdit) {
        const rows = this.getLeafResourceRows(keyString)
        const existingHours = this.getHoursInPeriod(keyString, period)
        const ratio = existingHours && hours / existingHours
        rows.forEach((r) => {
            const rExistingHours = this.getHoursInPeriod(r.fullPath, period)
            const likelihood = this.getLikelihood(r.fullPath)
            const newHours =
                (ratio ? rExistingHours * ratio : hours / rows.length) /
                likelihood
            this.editedHours[this.periodType][r.fullPath] ??= []
            this.editedHours[r.fullPath] = this.editedHours[this.periodType][
                r.fullPath
            ].filter((h) => h[this.periodType] !== period)
            this.editedHours[this.periodType][r.fullPath].push({
                fullPath: r.fullPath,
                [this.periodType]: period,
                hours: newHours,
            })
            r.data = r.data.filter(
                (d) =>
                    !(d[this.periodType] === period && d.type === 'allocation')
            )

            if (afterEdit && this.selectedProjectId) {
                afterEdit(
                    period,
                    r.fullPath.split(','),
                    newHours,
                    this.report.filters.groups
                )
            }
        })
    }

    @bind
    async saveChanges() {
        return makeRequest({
            baseURL: process.env.REACT_APP_NODE_SERVER_URL,
            path: '/resource-schedule/save',
            method: 'POST',
            data: {
                organisationId: SessionStore.organisationId,
                groups: this.report.filters.groups,
                editedAllocations: this.getEditedHoursData(null),
            },
        })
    }

    getRowTimeData = computedFn((keyString = '') => {
        const rows = this.getLeafResourceRows(keyString)
        return rows.flatMap((r) => r.data || [])
    })

    @computed
    get dataPeriods() {
        return [...this.getRowTimeData(null), ...this.getEditedHoursData(null)]
            .map((d) => d[this.periodType])
            .filter((v) => v)
    }

    getTotalBudget = computedFn((keyString = '') => {
        const budgets = this.getRowTimeData(keyString).filter(
            (b) => b.type === 'budget'
        )
        return _.sum(
            budgets.map((b) => {
                const useLikelihood = ['prospective', 'onHold'].includes(
                    b.status
                )
                const likelihood = useLikelihood ? (b.likelihood ?? 1) : 1
                return b.hours * likelihood
            })
        )
    })

    getLikelihood = computedFn((keyString = '') => {
        const budgets = this.getRowTimeData(keyString).filter(
            (b) => b.type === 'budget' || b.type === 'allocation'
        )
        if (!budgets.length) return 1
        return _.mean(
            budgets.map((b) => {
                const useLikelihood = ['prospective', 'onHold'].includes(
                    b.status
                )
                return useLikelihood ? (b.likelihood ?? 1) : 1
            })
        )
    })

    getAvailabilityInPeriod = computedFn((keyString = '', period) => {
        const keys = keyString.split(',')
        const staffId =
            keys[this.report.filters.groups.findIndex((g) => g === 'staff')]
        const roleId =
            keys[this.report.filters.groups.findIndex((g) => g === 'role')]
        const staff = StaffCollection.activeStaff.filter((st) => {
            return (
                (!staffId || st.id === staffId) &&
                (!roleId ||
                    st.roleId === roleId ||
                    (roleId === 'null' && !st.roleId))
            )
        })
        const periodDate = this.startOfPeriod(
            parse(period, this.periodFormat(), new Date())
        )
        const start = this.startOfPeriod(periodDate)
        const end = this.endOfPeriod(periodDate)
        const dayAfterEnd = this.startOfPeriod(this.addPeriods(end, 1))
        const holidayDays = _.sum(
            OrganisationHolidayCollection.organisationHolidays
                .filter((h) => h.startDate <= end && h.endDate >= start)
                .map((h) => {
                    const startDate = Math.max(h.startDate, start)
                    const endDate = addDays(
                        startOfDay(Math.min(h.endDate, end)),
                        1
                    )
                    return differenceInBusinessDays(endDate, startDate)
                })
        )
        return (
            _.sum(
                staff.map(
                    (s) => s.getAvailabilityInDateRange([start, end]) / 60 / 5
                )
            ) *
            (differenceInBusinessDays(dayAfterEnd, start) - holidayDays)
        )
    })

    getEditedHoursDataInPeriod = computedFn((keyString = '', period) => {
        return this.getEditedHoursData(keyString).filter(
            (d) => d[this.periodType] === period
        )
    })

    getTimeDataInPeriod = computedFn((keyString = '', period) => {
        return this.getRowTimeData(keyString).filter((d) => {
            return d[this.periodType] === period
        })
    })

    getAllocationTimeDataInPeriod = computedFn((keyString = '', period) => {
        return this.getTimeDataInPeriod(keyString, period).filter((d) => {
            return d.type === 'allocation'
        })
    })

    getTimeEntryTimeDataInPeriod = computedFn((keyString = '', period) => {
        return this.getTimeDataInPeriod(keyString, period).filter((d) => {
            return d.type === 'timeEntry'
        })
    })

    getProspectiveTimeDataInPeriod = computedFn((keyString = '', period) => {
        return this.getAllocationTimeDataInPeriod(keyString, period).filter(
            (d) => {
                return d.status === 'prospective'
            }
        )
    })

    getProspectiveHoursInPeriod = computedFn((keyString = '', period) => {
        const thisPeriod = format(new Date(), 'yyyy-MM')
        if (period < thisPeriod) {
            return 0
        }
        return _.sum(
            this.getProspectiveTimeDataInPeriod(keyString, period).map((b) => {
                const useLikelihood = ['prospective', 'onHold'].includes(
                    b.status
                )
                const likelihood = useLikelihood ? (b.likelihood ?? 1) : 1
                return b.hours * likelihood
            })
        )
    })

    getEditedHoursInPeriod = computedFn((keyString = '', period) => {
        return _.sum(
            this.getEditedHoursDataInPeriod(keyString, period).map((b) => {
                const useLikelihood = ['prospective', 'onHold'].includes(
                    b.status
                )
                const likelihood = useLikelihood ? (b.likelihood ?? 1) : 1
                return b.hours * likelihood
            })
        )
    })

    getAllocationHoursInPeriod = computedFn((keyString = '', period) => {
        return _.sum(
            this.getAllocationTimeDataInPeriod(keyString, period).map((b) => {
                const useLikelihood = ['prospective', 'onHold'].includes(
                    b.status
                )
                const likelihood = useLikelihood ? (b.likelihood ?? 1) : 1
                return b.hours * likelihood
            })
        )
    })

    getTimeEntryHoursInPeriod = computedFn((keyString = '', period) => {
        return _.sum(
            this.getTimeEntryTimeDataInPeriod(keyString, period).map(
                (d) => d.hours
            )
        )
    })

    getHoursInPeriod = computedFn((keyString = '', period) => {
        const rows = this.getLeafResourceRows(keyString)
        if (rows.length > 1) {
            return _.sum(
                rows.map((r) => this.getHoursInPeriod(r.fullPath, period))
            )
        }
        const hoursData = this.report.filters.hoursData
        const thisPeriod = format(new Date(), this.periodFormat())
        const isPastPeriod = period < thisPeriod
        const isFuturePeriod = period > thisPeriod
        const isCurrentPeriod = period === thisPeriod
        if (hoursData === 'remainingProjected') {
            return (
                this.getAllocationHoursInPeriod(keyString, period) +
                this.getEditedHoursInPeriod(keyString, period) -
                this.getTimeEntryHoursInPeriod(keyString, period)
            )
        }
        if (hoursData === 'remainingProjectedCapped') {
            return Math.max(
                this.getAllocationHoursInPeriod(keyString, period) +
                    this.getEditedHoursInPeriod(keyString, period) -
                    this.getTimeEntryHoursInPeriod(keyString, period),
                0
            )
        }
        // if (hoursData === 'actualsVsProjected') {
        //     return {
        //         numerator: this.getTimeEntryHoursInPeriod(keyString, period),
        //         denominator:
        //             this.getAllocationHoursInPeriod(keyString, period) +
        //             this.getEditedHoursInPeriod(keyString, period),
        //     }
        // }
        if (hoursData === 'projected') {
            return (
                this.getAllocationHoursInPeriod(keyString, period) +
                this.getEditedHoursInPeriod(keyString, period)
            )
        }
        if (hoursData === 'actuals') {
            return this.getTimeEntryHoursInPeriod(keyString, period)
        }
        if (isPastPeriod) {
            return this.getTimeEntryHoursInPeriod(keyString, period)
        }
        if (isFuturePeriod) {
            return (
                this.getAllocationHoursInPeriod(keyString, period) +
                this.getEditedHoursInPeriod(keyString, period)
            )
        }
        if (isCurrentPeriod) {
            return Math.max(
                this.getAllocationHoursInPeriod(keyString, period) +
                    this.getEditedHoursInPeriod(keyString, period),
                this.getTimeEntryHoursInPeriod(keyString, period)
            )
        }
    })

    getHoursToDateInPeriod = computedFn((keyString = '', period) => {
        const periods = this.dataPeriods.filter((m) => m <= period)
        return _.sum(periods.map((m) => this.getHoursInPeriod(keyString, m)))
    })

    getTotalHours = computedFn((keyString = '') => {
        return _.sum(
            this.dataPeriods.map((m) => this.getHoursInPeriod(keyString, m))
        )
    })

    @computed
    get report() {
        return (
            ResourceScheduleReportCollection.resourceSchedulesById[
                this.searchParams?.report
            ] ||
            SessionStore?.organisation?.defaultResourceScheduleReport ||
            {}
        )
    }
    @action.bound
    updateFilters(filterData) {
        this.report.updateFilters(filterData)
    }

    @action.bound
    selectCell(selectedPeriod, keys, selectedObject) {
        this.selectedPeriod = selectedPeriod
        this.selectedObject = selectedObject
        this.selectedProjectId = selectedObject?.projectId
        this.selectedPhaseId = selectedObject?.phaseId
        this.selectedStaffId = selectedObject?.staffId
        this.selectedRoleId = selectedObject?.staffRoleId
        this.selectedStaffIds = this.getRowStaffIds(selectedObject)
        this.selectedPhaseIds = this.getRowPhaseIds(selectedObject)
        LayoutStore.showSidebar = !!(selectedPeriod && selectedObject)
    }

    @action.bound
    shiftSelectedPeriod(amount) {
        this.selectedPeriod = this.addPeriods(this.selectedPeriod, amount)
        if (
            this.selectedPeriod < this.startDate ||
            this.selectedPeriod > this.endDate
        ) {
            this.shiftDates(amount)
        }
    }

    @action.bound
    shiftDates(amount) {
        this.startDate = this.addPeriods(this.startDate, amount)
        this.endDate = this.addPeriods(this.endDate, amount)
        let newDates = []
        if (amount > 0) {
            newDates = [
                this.startOfPeriod(this.endDate),
                this.endOfPeriod(this.endDate),
            ]
        } else {
            newDates = [
                this.startOfPeriod(this.startDate),
                this.endOfPeriod(this.startDate),
            ]
        }
        this.updateRows()
    }

    getRowAvailabilityInPeriod(row, period) {
        const emptyRow = !row?.key
        const staff = !emptyRow
            ? row?.staffIds?.length
                ? row.staffIds.map((stId) => StaffCollection.staffById[stId])
                : row.roleIds.flatMap(
                      (rId) => StaffCollection.staffByRoleId[rId]
                  )
            : StaffCollection.staffs
        const periodDate = this.startOfPeriod(
            parse(period, this.periodFormat(), new Date())
        )
        const start = this.startOfPeriod(periodDate)
        const end = this.endOfPeriod(periodDate)
        const dayAfterEnd = this.startOfPeriod(this.addPeriods(end, 1))
        const holidayDays = _.sum(
            OrganisationHolidayCollection.organisationHolidays
                .filter((h) => h.startDate <= end && h.endDate >= start)
                .map((h) => {
                    const startDate = Math.max(h.startDate, start)
                    const endDate = addDays(
                        startOfDay(Math.min(h.endDate, end)),
                        1
                    )
                    return differenceInBusinessDays(endDate, startDate)
                })
        )
        return (
            _.sum(
                staff
                    .filter(Boolean)
                    .map(
                        (s) =>
                            s.getAvailabilityInDateRange([start, end]) / 60 / 5
                    )
            ) *
            (differenceInBusinessDays(dayAfterEnd, start) - holidayDays)
        )
    }

    getRowBudget(row) {
        const emptyRow = !row?.key
        if (emptyRow)
            return _.sum(this.queryData.map((r) => this.getRowBudget(r)))
        return row?.budget || 0
    }

    getRowTotalHours(row, hoursData = this.report.filters.hoursData) {
        const emptyRow = !row?.key
        if (emptyRow)
            return _.sum(
                this.queryData.map((r) => this.getRowTotalHours(r, hoursData))
            )
        if (hoursData == 'actuals') {
            return row?.grandTotals?.actuals || 0
        } else if (hoursData == 'projected') {
            return row?.grandTotals?.projected || 0
        } else {
            return row?.grandTotals?.actualsProjected || 0
        }
    }

    getRowHoursToDateInPeriod(
        row,
        period,
        hoursData = this.report.filters.hoursData
    ) {
        const emptyRow = !row?.key
        if (emptyRow)
            return _.sum(
                this.queryData.map((r) =>
                    this.getRowHoursToDateInPeriod(r, period, hoursData)
                )
            )
        if (hoursData == 'actuals') {
            return row?.totalHours?.actuals?.[period] || 0
        } else if (hoursData == 'projected') {
            return row?.totalHours.projected?.[period] || 0
        } else {
            return row?.totalHours?.actualsProjected?.[period] || 0
        }
    }

    getRowHoursInPeriod(
        row,
        period,
        hoursData = this.report.filters.hoursData
    ) {
        const emptyRow = !row?.key
        if (emptyRow)
            return _.sum(
                this.queryData.map((r) =>
                    this.getRowHoursInPeriod(r, period, hoursData)
                )
            )
        return (
            this.getRowActiveHoursInPeriod(row, period, hoursData) +
            this.getRowProspectiveHoursInPeriod(row, period, hoursData)
        )
    }

    getRowActiveHoursInPeriod(
        row,
        period,
        hoursData = this.report.filters.hoursData
    ) {
        const emptyRow = !row?.key
        if (emptyRow)
            return _.sum(
                this.queryData.map((r) =>
                    this.getRowActiveHoursInPeriod(r, period, hoursData)
                )
            )
        const currentPeriod = format(new Date(), this.periodFormat())
        if (
            hoursData === 'actuals' ||
            (['actualsProjected', 'actualsVsProjected'].includes(hoursData) &&
                period < currentPeriod)
        ) {
            return row?.recordedHours?.active?.[period] || 0
        } else if (
            hoursData === 'projected' ||
            (['actualsProjected', 'actualsVsProjected'].includes(hoursData) &&
                period > currentPeriod)
        ) {
            return row?.allocatedHours?.active?.[period] || 0
        } else if (
            ['actualsProjected', 'actualsVsProjected'].includes(hoursData)
        ) {
            return Math.max(
                row?.allocatedHours?.active?.[period] || 0,
                row?.recordedHours?.active?.[period] || 0
            )
        } else if (hoursData === 'remainingProjected') {
            return (
                (row?.allocatedHours?.active?.[period] || 0) -
                (row?.recordedHours?.active?.[period] || 0)
            )
        } else if (hoursData === 'remainingProjectedCapped') {
            return Math.max(
                (row?.allocatedHours?.active?.[period] || 0) -
                    (row?.recordedHours?.active?.[period] || 0),
                0
            )
        } else {
            return 0
        }
    }

    getRowProspectiveHoursInPeriod(
        row,
        period,
        hoursData = this.report.filters.hoursData
    ) {
        const emptyRow = !row?.key
        if (emptyRow)
            return _.sum(
                this.queryData.map((r) =>
                    this.getRowProspectiveHoursInPeriod(r, period, hoursData)
                )
            )
        const currentPeriod = format(new Date(), this.periodFormat())
        if (
            hoursData === 'actuals' ||
            (['actualsProjected', 'actualsVsProjected'].includes(hoursData) &&
                period < currentPeriod)
        ) {
            return 0
        } else {
            return row?.allocatedHours?.prospective?.[period] || 0
        }
    }

    @action.bound
    setHours(row, period, value, updateProjectForecasts) {
        const oldValue =
            (row?.allocatedHours?.active?.[period] || 0) +
            (row?.allocatedHours?.prospective?.[period] || 0)
        const ratio = value / oldValue
        let diffActive = 0
        let diffProspective = 0
        const children = row.children.filter(
            (c) =>
                c.statuses.length > 1 ||
                (c.statuses[0] && c.statuses[0] !== 'archived')
        )
        if (children?.length && oldValue) {
            children.forEach((r) => {
                this.setHours(
                    r,
                    period,
                    (r?.allocatedHours?.active?.[period] +
                        r?.allocatedHours?.prospective?.[period]) *
                        ratio,
                    updateProjectForecasts
                )
            })
        } else if (children?.length) {
            children.forEach((r) => {
                this.setHours(
                    r,
                    period,
                    value / children.length,
                    updateProjectForecasts
                )
            })
        } else if (oldValue) {
            diffActive =
                row?.allocatedHours?.active?.[period] * ratio -
                row?.allocatedHours?.active?.[period]
            diffProspective =
                row?.allocatedHours?.prospective?.[period] * ratio -
                row?.allocatedHours?.prospective?.[period]
            row.allocatedHours.active[period] =
                row?.allocatedHours?.active?.[period] * ratio
            row.allocatedHours.prospective[period] =
                row?.allocatedHours?.prospective?.[period] * ratio
        } else {
            row.allocatedHours.active[period] = value
            diffActive = value
        }
        if (!children?.length) {
            Object.keys(row.totalHours.actualsProjected).forEach((k) => {
                if (k >= period) {
                    row.totalHours.projected[k] += diffActive + diffProspective
                    row.totalHours.actualsProjected[k] +=
                        diffActive + diffProspective
                }
            })
            row.grandTotals.projected += diffActive + diffProspective
            row.grandTotals.actualsProjected += diffActive + diffProspective
            const keys = row.key.split('||')
            const parentKeys = keys.slice(0, keys.length - 1)
            const addDiff = (rows, keys, level) => {
                const keysAtLevel = keys.slice(0, level + 1)
                const key = keysAtLevel.join('||')
                const row = rows.find((r) => r.key === key)
                row.allocatedHours.active[period] += diffActive
                row.allocatedHours.prospective[period] += diffProspective
                Object.keys(row.totalHours.actualsProjected).forEach((k) => {
                    if (k >= period) {
                        row.totalHours.projected[k] +=
                            diffActive + diffProspective
                        row.totalHours.actualsProjected[k] +=
                            diffActive + diffProspective
                    }
                })
                row.grandTotals.projected += diffActive + diffProspective
                row.grandTotals.actualsProjected += diffActive + diffProspective
                if (keys.length > level + 1) {
                    addDiff(row.children, keys, level + 1)
                }
            }
            addDiff(this.queryData, parentKeys, 0)
            const projectId =
                row.projectId ||
                (row.projectIds.length === 1
                    ? row.projectIds[0]
                    : this.selectedProjectId)
            const phaseId = row.phaseId || row?.phaseIds?.[0] || null
            const roleId =
                row.roleId || (row.roleIds.length === 1 ? row.roleIds[0] : null)
            const staffId =
                row.staffId ||
                (row.staffIds.length === 1 ? row.staffIds[0] : null)
            if (updateProjectForecasts)
                updateProjectForecasts(
                    period,
                    value,
                    projectId,
                    phaseId,
                    roleId,
                    staffId
                )
        }
    }

    @action.bound
    downloadCSV() {
        const table = new TableStore()
        let rows = this.queryData
        const exportLevel = this.report.filters.groups.findIndex(
            (g) => g === this.exportGroup
        )
        let rowLevel = 0
        while (rowLevel < exportLevel) {
            rows = rows.flatMap((r) => r.children)
            rowLevel++
        }
        const rowColumns = ResourceScheduleReportAllocationColumns(this)
        const exportGroups = this.report.filters.groups.slice(
            0,
            exportLevel + 1
        )
        let columns = [
            ...exportGroups.map((g) => ({
                id: g,
                label: capitalCase(g),
                type: 'text',
                value: (row) => row[g],
            })),
        ]
        columns.push(
            ...[...Array(12)].map((v, i) => {
                return rowColumns.csvHours(
                    this.addPeriods(this.startDate, i),
                    0,
                    {}
                )
            })
        )
        table.update({
            columns,
            rows,
        })
        download(
            Papa.unparse([...table.getCsvData()]),
            `${this.report.name}.csv`,
            'text/csv'
        )
    }

    @action.bound
    addStaffs(
        staffs,
        project,
        phase,
        dateRange,
        allocationValue,
        allocationType,
        updateProjectForecasts
    ) {
        staffs.forEach((staff) => {
            this.addStaff(
                staff,
                project,
                phase,
                dateRange,
                allocationValue,
                allocationType,
                updateProjectForecasts
            )
        })
    }

    @action.bound
    addStaff(
        staff,
        project,
        phase,
        dateRange,
        allocationValue,
        allocationType,
        updateProjectForecasts
    ) {
        const groupKeys = {
            project: project?.id,
            phase: phase?.id,
            role: staff?.roleId,
            staff: staff?.id,
            status: phase.status,
            owner: project?.ownerId,
        }
        const groupTitles = {
            project: project?.title,
            phase: phase?.title,
            role: staff?.role?.name || '(No Role)',
            staff: staff?.fullName || '(No Staff)',
            status: capitalCase(phase.status),
            owner: project?.owner?.fullName || '(No Owner)',
        }
        const groups = this.report.filters.groups
        let parentRow
        let groupRows = this.queryData

        const createRow = (group, groupKey, parentRow, groupRows) => {
            const newRow = {
                ...parentRow,
                key: parentRow
                    ? `${parentRow.key}||${groupKey}`
                    : `${groupKey}`,
                children: [],
                allocatedHours: { active: {}, prospective: {} },
                recordedHours: { active: {} },
                totalHours: { projected: {}, actualsProjected: {} },
                grandTotals: { projected: 0, actualsProjected: 0 },
            }
            newRow.roleIds ??= [staff.roleId]
            newRow.staffIds ??= [staff.id]
            newRow.projectIds ??= [project.id]
            newRow.phaseIds ??= [phase.id]
            newRow.statuses ??= [phase.status]

            if (group === 'project') {
                newRow.projectIds = [groupKey]
                newRow.projectId = groupKey
                newRow.phaseIds = newRow.phaseIds.filter(
                    (p) => PhaseCollection.phasesById[p]?.projectId === groupKey
                )
            } else if (group === 'phase') {
                newRow.phaseIds = [groupKey]
                newRow.phaseId = groupKey
            } else if (group === 'role') {
                newRow.roleIds = [groupKey]
                newRow.roleId = groupKey
                newRow.staffIds = newRow.staffIds.filter((s) => s === staff.id)
            } else if (group === 'staff') {
                newRow.staffIds = [groupKey]
                newRow.staffId = groupKey
            } else if (group === 'status') {
                newRow.statuses = [groupKey]
                newRow.status = groupKey
            } else if (group === 'owner') {
                newRow.ownerIds = [groupKey]
                newRow.ownerId = groupKey
                newRow.projectIds = newRow.projectIds.filter(
                    (p) =>
                        ProjectCollection.projectsById[p]?.ownerId === groupKey
                )
                newRow.phaseIds = newRow.phaseIds.filter(
                    (p) =>
                        PhaseCollection.phasesById[p]?.project?.ownerId ===
                        groupKey
                )
            }
            return newRow
        }
        let lastRow
        groups.forEach((g) => {
            let row = groupRows.find((r) => r[groupProp[g]] === groupKeys[g])
            if (!row) {
                // Create a new row
                row = createRow(g, groupKeys[g], parentRow, groupRows)
                row.title = groupTitles[g]
                if (parentRow) {
                    parentRow.children.push(row)
                    //I don't know why the reference is lost here
                    row = parentRow.children[parentRow.children.length - 1]
                } else {
                    groupRows.push(row)
                    //I don't know why the reference is lost here
                    row = groupRows[groupRows.length - 1]
                }
            }
            if (!row.staffIds.includes(staff.id || 'null')) {
                row.staffIds.push(staff.id || 'null')
            }
            if (!row.projectIds.includes(project.id || 'null')) {
                row.projectIds.push(project.id || 'null')
            }
            if (!row.phaseIds.includes(phase.id || 'null')) {
                row.phaseIds.push(phase.id || 'null')
            }
            if (!row.roleIds.includes(staff.roleId || 'null')) {
                row.roleIds.push(staff.roleId || 'null')
            }
            // Update row ids
            if (g === 'staff') {
                row.staffId = staff.id || 'null'
                row.staff = groupTitles[g]
            }
            if (g === 'project') {
                row.projectId = project.id || 'null'
                row.project = groupTitles[g]
            }
            if (g === 'phase') {
                row.phaseId = phase.id || 'null'
                row.phase = groupTitles[g]
            }
            if (g === 'role') {
                row.roleId = staff.roleId || 'null'
                row.role = groupTitles[g]
            }
            parentRow = row
            groupRows = row.children
            lastRow = row
        })
        this.selectCell(this.selectedPeriod, null, this.selectedObject)
        this.addAllocationInDateRange(
            lastRow,
            staff,
            project,
            phase,
            dateRange,
            allocationValue,
            allocationType,
            updateProjectForecasts
        )
        // this.queryData = _.cloneDeep(this.queryData)
    }

    @action.bound
    addAllocationInDateRange(
        row,
        staff,
        project,
        phase,
        dateRange,
        allocationValue,
        allocationType,
        updateProjectForecasts
    ) {
        const periods = this.getPeriodsInDateRange(dateRange)
        const businessDays = this.getBusinessDaysInDateRange(dateRange)
        const value =
            allocationType === 'hours'
                ? allocationValue
                : (staff.getAvailabilityInDateRange(dateRange) / 60 / 5) *
                  allocationValue *
                  businessDays
        const valuePerBusinessDay = value / businessDays
        periods.forEach((period) => {
            const businessDaysInPeriod = this.getBusinessDaysInDateRange([
                Math.max(
                    this.startOfPeriod(
                        parse(period, this.periodFormat(), new Date())
                    ),
                    dateRange[0]
                ),
                Math.min(
                    this.endOfPeriod(
                        parse(period, this.periodFormat(), new Date())
                    ),
                    dateRange[1]
                ),
            ])
            this.setHours(
                row,
                period,
                allocationType === 'hours'
                    ? value
                    : businessDaysInPeriod * valuePerBusinessDay,
                updateProjectForecasts
            )
        })
    }

    @bind
    getBusinessDaysInDateRange(dateRange) {
        const startDate = startOfDay(dateRange[0])
        const endDate = addDays(startOfDay(dateRange[1]), 1)
        const businessDays = differenceInBusinessDays(endDate, startDate)
        return businessDays
    }

    @bind
    getPeriodsInDateRange(dateRange) {
        const startDate = startOfDay(dateRange[0])
        const endDate = addDays(startOfDay(dateRange[1]), 1)
        const periods = []
        let currentDate = startDate
        while (currentDate <= endDate) {
            periods.push(format(currentDate, this.periodFormat()))
            currentDate = this.addPeriods(currentDate, 1)
        }
        return periods
    }

    @bind
    resourceRowQueryOld({
        level = 0,
        projectId,
        phaseId,
        staffRoleId,
        staffId,
        status,
        dateRange = [this.startDate, this.endDate],
        groups = this.report.filters.groups,
    } = {}) {
        const groupsAtLevel = groups.slice(0, level + 1)
        const groupsBeforeLevel = groups.slice(0, level)
        const group = groups[level]
        let id = 'resourceRow'
        if (groupsBeforeLevel.includes('status')) id += String(status)
        if (groupsBeforeLevel.includes('project')) id += String(projectId)
        if (groupsBeforeLevel.includes('phase')) id += String(phaseId)
        if (groupsBeforeLevel.includes('role')) id += String(staffRoleId)
        if (groupsBeforeLevel.includes('staff')) id += String(staffId)
        id += String(groups)
        id += format(dateRange[0], 'yyyy-MM')
        id += format(dateRange[1], 'yyyy-MM')
        id = this.makeQueryKey(id)
        return {
            id,
            collection: 'resourceRows',
            data: {
                groupBy: groups.slice(0, level + 1),
                dateRange: [qf(dateRange[0]), qf(dateRange[1])],
                period: 'monthly',
                reportFilters: {
                    ...this.report.filters,
                    projectId,
                    phaseId,
                    staffRoleId,
                    staffId,
                    status,
                },
            },
        }
    }

    @bind
    getResourceRowsOld({
        level = 0,
        projectId,
        phaseId,
        staffRoleId,
        staffId,
        status,
        parent,
        groups = this.report.filters.groups,
        dateRange = [this.startDate, this.endDate],
        filterDateRange = true,
    } = {}) {
        let id = 'resourceRow'
        const groupsBeforeLevel = groups.slice(0, level)
        if (groupsBeforeLevel.includes('status')) id += String(status)
        if (groupsBeforeLevel.includes('project')) id += String(projectId)
        if (groupsBeforeLevel.includes('phase')) id += String(phaseId)
        if (groupsBeforeLevel.includes('role')) id += String(staffRoleId)
        if (groupsBeforeLevel.includes('staff')) id += String(staffId)
        id += String(groups)
        id += format(dateRange[0], 'yyyy-MM')
        id += format(dateRange[1], 'yyyy-MM')
        id = this.makeQueryKey(id)
        const rows = FetchStore.getResponse(id)?.resourceRows?.rows || []
        rows.forEach((r) => {
            r.setStore(this)
            parent && r.setParent(parent)
        })
        parent && parent.setChildren(rows)
        return [...rows].filter((r) =>
            runInAction(() => {
                return (
                    !filterDateRange ||
                    this.report.filters.hoursData.includes('remaining') ||
                    r.getHoursToDateInMonth(format(dateRange[1], 'yyyy-MM')) -
                        r.getHoursToDateInMonth(
                            format(subMonths(dateRange[0], 1), 'yyyy-MM')
                        ) >
                        0
                )
            })
        )
    }

    @action.bound updateRows = _.debounce(() => {
        makeRequest({
            id: `resourceTable` + this.report.queryKey,
            baseURL: process.env.REACT_APP_NODE_SERVER_URL,
            path: `/resource-schedule/table`,
            method: 'POST',
            data: {
                organisationId: SessionStore.organisationId,
                userId: SessionStore.user?.id,
                filters: this.report.filters,
                level: 0,
                parentData: {},
                dateRange: [
                    format(this.startDate, 'yyyy-MM-dd'),
                    format(this.endDate, 'yyyy-MM-dd'),
                ],
                periodType: this.periodType,
            },
        }).then((r) => {
            this.updateQueryData(r.data)
        })
    }, 500)

    @action.bound
    updateQueryData(data) {
        let storedRows = this.queryData
        let newRows = data

        // Function to insert or update rows recursively
        const insertOrUpdateRows = (storedRows, newRows) => {
            newRows.forEach((newRow) => {
                let existingRow = storedRows.find(
                    (storedRow) => storedRow.key === newRow.key
                )
                if (!existingRow) {
                    // If the row does not exist, add it to storedRows
                    storedRows.push(newRow)
                } else {
                    Object.entries(newRow).forEach(([key, value]) => {
                        if (key !== 'children') {
                            existingRow[key] = value
                        }
                    })
                    // If it exists, update existing children with new ones recursively
                    if (newRow.children && newRow.children.length > 0) {
                        if (!existingRow.children) {
                            existingRow.children = [] // Initialize children array if it doesn't exist
                        }
                        insertOrUpdateRows(
                            existingRow.children,
                            newRow.children
                        )
                    }
                }
            })
        }

        // Start the recursive update process from the root
        requestIdleCallback(() => {
            insertOrUpdateRows(storedRows, newRows)
            this.queryData = storedRows
        })
    }
}

export default new ResourceScheduleStore()
