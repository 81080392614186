import classNames from 'classnames'
import { Link } from '@tanstack/react-router'
import { Input } from '@/version2/components/ui/input'
import { TextArea } from '@/version2/components/ui/textarea'
import {
    useContact,
    useInvoices,
    useProjects,
    ContactDetail,
    updateContact,
} from './contact-store'
import { canEditContacts } from '../../../State/Permissions/HasPermissions'
import SessionStore from '../../../State/SessionStore'

export const ContactForm = () => {
    const contact = useContact()
    const projects = useProjects()
    const invoices = useInvoices()

    const onChange = (type: keyof ContactDetail, value: any) => {
        updateContact(value, type)
    }

    return (
        <div className="flex w-full">
            <div
                className="flex-0-0-auto flex items-center justify-center mx-auto"
                style={{ padding: '0.5em', width: '100%' }}
            >
                <div className="form-horizontal">
                    <ContactField
                        contact={contact}
                        prop={'firstName'}
                        label={'First name'}
                        onChange={onChange}
                    />
                    <ContactField
                        contact={contact}
                        prop={'lastName'}
                        label={'Last name'}
                        onChange={onChange}
                    />
                    <ContactField
                        contact={contact}
                        prop={'organisationName'}
                        label={'Organisation name'}
                        onChange={onChange}
                    />
                    <ContactField
                        contact={contact}
                        prop={'phone'}
                        label={'Phone'}
                        onChange={onChange}
                    />
                    <ContactField
                        contact={contact}
                        prop={'email'}
                        label={'Email'}
                        onChange={onChange}
                    />
                    <ContactField
                        contact={contact}
                        prop={'address'}
                        label={'Address'}
                        onChange={onChange}
                    />
                    <ContactField
                        contact={contact}
                        prop={'notes'}
                        label={'Notes'}
                        onChange={onChange}
                    />
                </div>
                {projects?.length ? (
                    <div style={{ margin: '1.5em' }}>
                        <h4>Projects</h4>
                        {projects
                            .sort((a, b) => b?.tile - a?.title)
                            .map((p) => (
                                <span className="mr-2 text-nowrap inline-block">
                                    <Link to={`/projects/${p?.id}`}>
                                        {p?.title}
                                    </Link>
                                    {',     '}
                                </span>
                            ))}
                    </div>
                ) : null}
                {invoices?.length ? (
                    <div style={{ margin: '1.5em', marginTop: '2em' }}>
                        <h4>Invoices</h4>
                        {invoices
                            .sort((a, b) => b?.ref - a?.ref)
                            .map((i) => (
                                <span className="mr-2 text-nowrap inline-block">
                                    <Link to={`/invoices/${i?.id}`}>
                                        {i?.ref}
                                    </Link>
                                    {',    '}
                                </span>
                            ))}
                    </div>
                ) : null}
            </div>
        </div>
    )
}

const ContactField = ({
    contact,
    label,
    prop,
    onChange,
}: {
    contact: ContactDetail
    label: string
    prop: keyof ContactDetail
    onChange: (type: keyof ContactDetail, value: any) => void
}) => {
    const editable = canEditContacts(SessionStore.user)
    return (
        <div className="form-group mb-5">
            <div className="col-md-3">
                <label>{label}:</label>
            </div>
            <div className={classNames('col-md-8', `contact-form__${prop}`)}>
                {prop === 'notes' || prop === 'address' ? (
                    <TextArea
                        cols={60}
                        rows={4}
                        value={contact[prop]}
                        onChange={(newValue) => {
                            onChange(prop, newValue.target.value)
                        }}
                        disabled={!editable}
                        className="input-base"
                    />
                ) : (
                    <Input
                        type="text"
                        value={contact[prop] || ''}
                        onChange={(newValue) => {
                            console.log('newValue', newValue.target.value)
                            onChange(prop, newValue.target.value)
                        }}
                        disabled={!editable}
                        className="input-base"
                    />
                )}
            </div>
        </div>
    )
}
