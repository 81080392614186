import { type ColumnDef } from '@tanstack/react-table'
import Formatter from '@/Components/Formatter'
import SessionStore from '@/State/SessionStore'
import {
    canViewProjectInvoices,
    canViewProjectFees,
    canViewProjectExpenseBudgets,
    canViewPrimaryContact,
    canViewRemote,
    canViewFlexi,
    canViewStaffPayRate,
    canViewStaffCostRate,
    canViewStaffChargeOutRate,
    canViewCostCentres,
} from '@/State/Permissions/HasPermissions'
import _ from 'lodash'
import { parse } from 'date-fns'
import { createGenerateColumnDef } from '@/version2/components/data-table/helpers/generate-column-def'
import { renderCell } from '@/version2/components/data-table/helpers/render-cell'
export function getColumns(
    onHideColumn: (column: string) => void
): ColumnDef<any>[] {
    const generateColumnDef = createGenerateColumnDef({
        onHideColumn,
    })
    return [
        generateColumnDef({
            key: 'project',
            type: 'project',
            title: 'Project',
        }),
        generateColumnDef({
            key: 'projectPhase',
            type: 'text',
            title: 'Phase',
        }),
        generateColumnDef({
            key: 'projectStatus',
            type: 'status',
            title: 'Project Status',
        }),
        generateColumnDef({
            key: 'phaseStatus',
            type: 'status',
            title: 'Phase Status',
        }),
        generateColumnDef({
            key: 'staffMember',
            type: 'staffMember',
            title: 'Staff',
        }),
        generateColumnDef({
            key: 'task',
            type: 'text',
            title: 'Task',
        }),
        generateColumnDef({
            key: 'date',
            type: 'date',
            title: 'Date',
        }),
        generateColumnDef({
            key: 'numMinutes',
            type: 'number',
            title: 'Hours',
            aggregationFn: 'sum',
        }),
        generateColumnDef({
            key: 'billableHours',
            type: 'number',
            title: 'Billable Hours',
            aggregationFn: 'sum',
        }),
        generateColumnDef({
            key: 'nonBillableHours',
            type: 'number',
            title: 'Non-Billable Hours',
            aggregationFn: 'sum',
        }),
        generateColumnDef({
            key: 'percentBillable',
            type: 'percent',
            title: 'Percent Billable',
            aggregationFn: 'mean',
        }),
        generateColumnDef({
            key: 'notes',
            type: 'text',
            title: 'Notes',
            size: 240,
        }),
        generateColumnDef({
            key: 'costCentre',
            type: 'costCentre',
            title: 'Cost Centre',
            size: 120,
        }),
        generateColumnDef({
            key: 'staffCostCentre',
            type: 'costCentre',
            title: 'Staff Cost Centre',
            size: 120,
        }),
        generateColumnDef({
            key: 'projectOwner',
            type: 'staffMember',
            title: 'Project Owner',
        }),
        generateColumnDef({
            key: 'projectContact',
            type: 'contact',
            title: 'Project Client',
        }),
        generateColumnDef({
            key: 'projectInvoiceContact',
            type: 'contact',
            title: 'Project Primary Contact',
            permissions: (row) => canViewPrimaryContact(),
        }),
        generateColumnDef({
            key: 'projectCode',
            type: 'text',
            title: 'Project Code',
        }),
        generateColumnDef({
            key: 'projectPhaseCode',
            type: 'text',
            title: 'Phase Code',
        }),
        generateColumnDef({
            key: 'staffMemberFirstName',
            type: 'text',
            title: 'Staff First Name',
        }),
        generateColumnDef({
            key: 'staffMemberLastName',
            type: 'text',
            title: 'Staff Last Name',
        }),
        generateColumnDef({
            key: 'staffRole',
            type: 'role',
            title: 'Staff Role',
        }),
        generateColumnDef({
            key: 'monthIndex',
            type: 'text',
            title: 'Month',
            cell: ({ getValue, row, column }) => {
                const month = getValue()
                const formattedMonth =
                    month &&
                    Formatter.month(parse(month, 'yyyy-MM', new Date()))
                return renderCell({ value: formattedMonth, row, column })
            },
        }),
        generateColumnDef({
            key: 'isBillable',
            type: 'boolean',
            title: 'Is Billable',
        }),
        generateColumnDef({
            key: 'isVariation',
            type: 'boolean',
            title: 'Is Variation',
        }),
        generateColumnDef({
            key: 'isOvertime',
            type: 'boolean',
            title: 'Is Overtime',
        }),
        generateColumnDef({
            key: 'isLocked',
            type: 'boolean',
            title: 'Is Locked',
        }),
        generateColumnDef({
            key: 'beenInvoiced',
            type: 'boolean',
            title: 'Has Been Invoiced',
        }),
        generateColumnDef({
            key: 'flexi',
            type: 'boolean',
            title: 'Flexi',
            permissions: (row) => canViewFlexi(),
        }),
        generateColumnDef({
            key: 'fee',
            type: 'currency',
            title: 'Fee',
            permissions: (r) =>
                canViewProjectFees(SessionStore.user, r?.project),
            aggregationFn: 'sum',
        }),
        generateColumnDef({
            key: 'remote',
            type: 'boolean',
            title: 'Remote',
            permissions: (r) => canViewRemote(SessionStore.user, r?.project),
            aggregationFn: 'sum',
        }),
        generateColumnDef({
            key: 'revenue',
            type: 'currency',
            title: 'Revenue',
            permissions: (r) =>
                canViewProjectFees(SessionStore.user, r?.project) &&
                canViewProjectInvoices(SessionStore.user, r?.project),
            aggregationFn: 'sum',
        }),
        generateColumnDef({
            key: 'remainingFee',
            type: 'currency',
            title: 'Remaining Fee',
            permissions: (r) =>
                canViewProjectFees(SessionStore.user, r?.project) &&
                canViewProjectInvoices(SessionStore.user, r?.project),
            aggregationFn: 'sum',
        }),
        generateColumnDef({
            key: 'budget',
            type: 'currency',
            title: 'Expense Budget',
            permissions: (r) =>
                canViewProjectExpenseBudgets(SessionStore.user, r?.project),
            aggregationFn: 'sum',
        }),
        generateColumnDef({
            key: 'hoursBudget',
            type: 'number',
            title: 'Hours Budget',
            aggregationFn: 'sum',
        }),
        generateColumnDef({
            key: 'staffHoursBudget',
            type: 'number',
            title: 'Staff Hours Budget',
            aggregationFn: 'sum',
        }),
        generateColumnDef({
            key: 'actualVsStaffHoursBudget',
            type: 'progressBar',
            title: 'Actual vs Staff Hours Budget',
            aggregationFn: 'sum',
        }),
        generateColumnDef({
            key: 'startMinutes',
            type: 'time',
            title: 'Start Time',
        }),
        generateColumnDef({
            key: 'endMinutes',
            type: 'time',
            title: 'End Time',
        }),
        generateColumnDef({
            key: 'labourExpense',
            type: 'currency',
            title: 'Labour Expense',
            permissions: (r) => canViewStaffPayRate(SessionStore.user),
            aggregationFn: 'sum',
        }),
        generateColumnDef({
            key: 'cost',
            type: 'currency',
            title: 'Expense (cost)',
            permissions: (r) => canViewStaffCostRate(SessionStore.user),
            aggregationFn: 'sum',
        }),
        generateColumnDef({
            key: 'chargeOut',
            type: 'currency',
            title: 'Charge Out',
            permissions: (r) => canViewStaffChargeOutRate(SessionStore.user),
            aggregationFn: 'sum',
        }),
        generateColumnDef({
            key: 'chargeOutRate',
            type: 'currency',
            title: 'Charge Out Rate',
            permissions: (r) => canViewStaffChargeOutRate(SessionStore.user),
            aggregationFn: 'sum',
        }),
        generateColumnDef({
            key: 'hasNotes',
            type: 'boolean',
            title: 'Has Notes',
        }),
    ]
}
