import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Table from '../../Components/Table'
import SessionStore from '../../State/SessionStore'
import RenderOnQueries from '../Layout/RenderOnQueries'
import TimesheetReportStore from './TimesheetReportStore'
import LoadingSpinner from '../../Components/LoadingSpinner'
import _ from 'lodash'
import { queryClient } from '../../App'
import UpdateReportModal from '../../Components/UpdateReportModal'
import { format } from 'date-fns'
import { useMediaQuery } from '@react-hook/media-query'
import {
    canViewProjectStaffBudgets,
    getQueryPermissionFilters,
    hasPermission,
} from '../../State/Permissions/HasPermissions'
import { dateStringLookup } from '../../Components/Filters'
import { useQuery } from '@tanstack/react-query'

let renders = 0

export default observer((props) => {
    useEffect(() => {
        TimesheetReportStore.setSearchParams({ ...props })
    }, [props.report])
    const report = TimesheetReportStore.report
    if (!report) return null
    const permissionFilters = []
    if (!canViewProjectStaffBudgets(SessionStore.user)) {
        permissionFilters.push({
            column: 'staffMember',
            operator: 'in',
            value: [SessionStore.user?.id],
        })
    }
    return (
        <RenderOnQueries
            queryIds={[
                {
                    id: report.queryKey,
                    path:
                        process.env.REACT_APP_NODE_SERVER_URL + '/time-report',
                    method: 'POST',
                    staleTime: 0,
                    cacheTime: 0,
                    data: {
                        organisationId: SessionStore.organisationId,
                        userId: SessionStore.user?.id,
                        columns: report.columns.filter(
                            (c) =>
                                !report.columnOptionsById[c]?.permissions ||
                                report.columnOptionsById[c].permissions()
                        ),
                        filters: report.filters.filter(Boolean).map((f) => {
                            if (
                                report.columnOptionsById[f.column]?.type ===
                                'date'
                            ) {
                                let value =
                                    dateStringLookup[f.value]?.() || f.value
                                if (Array.isArray(value)) {
                                    value = value.map((d) =>
                                        d && d.getTime()
                                            ? format(d, 'yyyy-MM-dd')
                                            : null
                                    )
                                } else {
                                    value =
                                        value && value.getTime()
                                            ? format(value, 'yyyy-MM-dd')
                                            : null
                                }
                                return {
                                    ...f,
                                    value,
                                }
                            } else if (
                                report.columnOptionsById[f.column]?.type ===
                                'time'
                            ) {
                                let value = f.value
                                if (Array.isArray(value)) {
                                    value = value.map((d) => {
                                        d = new Date(d)
                                        return d && d.getTime()
                                            ? format(d, 'yyyy-MM-dd HH:mm')
                                            : null
                                    })
                                } else {
                                    value = new Date(value)
                                    value =
                                        value && value.getTime()
                                            ? format(value, 'yyyy-MM-dd HH:mm')
                                            : null
                                }
                                return {
                                    ...f,
                                    value,
                                }
                            } else {
                                return f
                            }
                        }),
                        groupBy: report.groupBy,
                        sortBy: report.sortBy,
                        dateRange: report.dateRange.map((d) =>
                            isFinite(d) ? format(d, 'yyyy-MM-dd') : null
                        ),
                    },
                },
            ]}
            loading={<LoadingSpinner />}
        >
            <TimeTable key={report.queryKey} />
        </RenderOnQueries>
    )
})

const TimeTable = observer(({ level = 0, parent = null }) => {
    const store = TimesheetReportStore
    const report = store.report
    const query = useQuery([report.queryKey])
    const queryData = query.data.data
    useEffect(() => {
        store.init(queryData)
    }, [report.queryKey, queryData])
    if (store.batchSaving) return <LoadingSpinner />
    return (
        <>
            {!level ? <UpdateReportModal report={report} /> : null}
            <TimeTableTable
                level={level}
                parent={parent}
                key={report.queryKey}
            />
        </>
    )
})

const TimeTableTable = observer(({ level = 0, parent = null }) => {
    const store = TimesheetReportStore
    const { report, groupBy, sortBy } = store
    const isPrinting = useMediaQuery('print')
    const bgColor = 256 - level * 5
    const bgShadow = bgColor - 10
    const tableColumns = useMemo(
        () => report?.tableColumns.filter((c) => !store.groupBy.includes(c.id)),
        [report, store.groupBy]
    )

    const getChildComponent = useCallback(
        (r) => (
            <TimeTableTable
                parent={r}
                level={level + 1}
                key={`${report.queryKey}-${level}-${r.key}`}
            />
        ),
        [level, store.groupBy, report]
    )
    return (
        <Table
            expandAll={store.expandAll && store.expandToLevel > level}
            key={`${report.queryKey}-${level}-${parent?.key}`}
            tableStore={!level ? store.tableStore : null}
            columns={[
                {
                    value: (row) => {
                        return store.isRowSelected(row)
                    },
                    type: 'boolean',
                    width: 3,
                    id: 'checked',
                    label: '',
                    editable: (r) => true,
                    print: false,
                    onChange: (r, stores) => (v) => {
                        if (_.isEmpty(r)) {
                            if (v) {
                                store.selectAllRows()
                            } else {
                                store.deselectAllRows()
                            }
                            return
                        } else {
                            if (v) {
                                store.selectRow(r)
                            } else {
                                store.deselectRow(r)
                            }
                        }
                    },
                },
                {
                    value: (row) => row.label,
                    ...report?.columnOptionsById[groupBy[level]],
                    width: level < groupBy.length && !isPrinting ? 30 : 33,
                    id: 'label',
                    label: 'Label',
                    style: () => ({
                        textAlign: 'left',
                        justifyContent: 'flex-start',
                        paddingLeft: 1 + level * 1 + 'rem',
                        fontWeight: 800 - level * 100,
                    }),
                },
                ...tableColumns,
            ]}
            rows={parent?.children || store.queryData || []}
            sortBy={
                !level ? [...sortBy, ['date', 'desc']] : store.tableStore.sortBy
            }
            showTotals={!level}
            showHeader={!level}
            getChildComponent={
                level < store.groupBy.length ? getChildComponent : null
            }
            style={
                level
                    ? {
                          // borderBottom: 'none',
                          boxShadow: 'none',
                          background: `linear-gradient(180deg, rgb(${bgShadow},${bgShadow},${bgShadow}) 1px, rgb(${bgColor},${bgColor},${bgColor}) 8px)`,
                          fontSize: '1.2rem',
                      }
                    : null
            }
        />
    )
})
