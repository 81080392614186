import * as React from 'react'
import { Cross2Icon } from '@radix-ui/react-icons'
import { Row, type Table } from '@tanstack/react-table'
import { Separator } from '@/version2/components/ui/separator'
import { Portal } from '@/version2/components/ui/portal'
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
    TooltipProvider,
} from '@/version2/components/ui/tooltip'
import { Button } from '@/version2/components/ui/button'
import { EditTimeEntryModal } from '../timesheet-page/edit-time-entry-modal'
import { DataTableFilterField } from '@/version2/types'
import { getCacheItemById } from '@/version2/cache'
import { trpc } from '@/system/trpc'
import { useQueryClient } from '@tanstack/react-query'
import { setReportData } from './time-report-store'

interface TimeReportFloatingBarProps {
    table: Table<any>
    filterFields?: DataTableFilterField<TData>[]
}

const getConsistentValue = (arr: any[], key: string): string | null => {
    const uniqueValues = new Set(arr.map((obj) => obj.original[key]))
    return uniqueValues.size === 1 ? [...uniqueValues][0] || null : null
}

export function TimeReportTableFloatingBar({
    table,
}: TimeReportFloatingBarProps) {
    const queryClient = useQueryClient()

    const rows = React.useMemo(() => {
        const rows = table.getFilteredRowModel().rows
        const selectedRows: Row<any>[] = []

        const getSelectedLeafRows = (row: Row<any>) => {
            // If this is a leaf row and it's selected, add it
            if (!row.subRows.length && row.getIsSelected()) {
                selectedRows.push(row)
                return
            }

            // If this is a parent row, check its subrows
            row.subRows.forEach((subRow) => {
                getSelectedLeafRows(subRow)
            })
        }

        // Process all root rows
        rows.forEach((row) => {
            // If root row is selected with no children, add it
            if (row.getIsSelected() && !row.subRows.length) {
                selectedRows.push(row)
            }
            // If it has children, process them
            else if (row.subRows.length) {
                getSelectedLeafRows(row)
            }
        })

        return selectedRows
    }, [table.getFilteredRowModel().rows, table.getState().rowSelection])

    const getAllSelectedRows = (row: Row<any>) => {
        if (!row.subRows?.length) return [row]
        return row.subRows.flatMap((c) => getAllSelectedRows(c))
    }
    const selectedRows = React.useMemo<Row<any>[]>(() => {
        return rows.flatMap((row) => {
            return getAllSelectedRows(row)
        })
    }, [rows])
    // Clear selection on Escape key press
    React.useEffect(() => {
        function handleKeyDown(event: KeyboardEvent) {
            if (event.key === 'Escape') {
                table.toggleAllRowsSelected(false)
            }
        }

        window.addEventListener('keydown', handleKeyDown)
        return () => window.removeEventListener('keydown', handleKeyDown)
    }, [table])
    const [isOpenDialog, setIsOpenDialog] = React.useState(false)
    const [isEditingReport, setIsEditingReport] = React.useState(false)
    const [updateError, setUpdateError] = React.useState(false)

    return (
        <>
            <Portal>
                <TooltipProvider>
                    <div className="absolute inset-x-0 bottom-6 z-50 mx-auto w-fit px-2.5">
                        <div className="w-full overflow-x-auto">
                            <div className="mx-auto flex w-fit items-center gap-2 rounded-md border bg-background p-2 text-foreground shadow">
                                <div className="flex h-7 items-center rounded-md border border-dashed pl-2.5 pr-1">
                                    <span className="whitespace-nowrap text-xs">
                                        {rows.length} selected
                                    </span>
                                    <Separator
                                        orientation="vertical"
                                        className="ml-2 mr-1"
                                    />
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                variant="ghost"
                                                size="icon"
                                                className="size-5 hover:border"
                                                onClick={() =>
                                                    table.toggleAllRowsSelected(
                                                        false
                                                    )
                                                }
                                            >
                                                <Cross2Icon
                                                    className="size-3.5 shrink-0"
                                                    aria-hidden="true"
                                                />
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent className="flex items-center border bg-accent px-2 py-1 font-semibold text-foreground dark:bg-zinc-900">
                                            <p className="mr-2">
                                                Clear selection
                                            </p>
                                        </TooltipContent>
                                    </Tooltip>
                                </div>
                                <Separator
                                    orientation="vertical"
                                    className="hidden h-5 sm:block"
                                />
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Button
                                            aria-label="Toggle columns"
                                            className="flex h-7 items-center border"
                                            variant="secondary"
                                            size="sm"
                                            onClick={() => {
                                                setIsOpenDialog(true)
                                            }}
                                        >
                                            <span className="whitespace-nowrap text-xs">
                                                Edit
                                            </span>
                                        </Button>
                                    </TooltipTrigger>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </TooltipProvider>
            </Portal>
            <EditTimeEntryModal
                table={table}
                isOpenDialog={isOpenDialog}
                setIsOpenDialog={setIsOpenDialog}
                timeEntries={selectedRows}
                onSave={async (data) => {
                    try {
                        setIsEditingReport(true)
                        await trpc.timeReport.updateTimeEntry.mutate(data)
                        setIsOpenDialog(false)
                        table.resetRowSelection(false)
                        await queryClient.invalidateQueries({
                            queryKey: ['timeReportCache'],
                        })
                        await queryClient.refetchQueries({
                            queryKey: ['timeReportCache'],
                        })
                        const queryData = await queryClient.fetchQuery({
                            queryKey: ['timeReportCache'],
                        })
                        setReportData(queryData)
                    } catch (err) {
                        setIsEditingReport(false)
                        setUpdateError(true)
                    }
                }}
                isEditingReport={isEditingReport}
                updateError={updateError}
                showAllFlags={true}
                placeholders={{
                    project: 'Leave Project Unchanged',
                    phase: 'Leave Phase Unchanged',
                    task: 'Leave Task Unchanged',
                }}
                selectDefaults={false}
                defaults={{
                    project: getCacheItemById(
                        'projects',
                        getConsistentValue(selectedRows, 'projectId')
                    ),
                    phase: getCacheItemById(
                        'phases',
                        getConsistentValue(selectedRows, 'phaseId')
                    ),
                    task: getCacheItemById(
                        'tasks',
                        getConsistentValue(selectedRows, 'taskId')
                    ),
                    isBillable: getConsistentValue(selectedRows, 'isBillable'),
                    isVariation: getConsistentValue(
                        selectedRows,
                        'isVariation'
                    ),
                    isOvertime: getConsistentValue(selectedRows, 'isOvertime'),
                    beenInvoiced: getConsistentValue(
                        selectedRows,
                        'beenInvoiced'
                    ),
                    isLocked: getConsistentValue(selectedRows, 'isLocked'),
                }}
            />
        </>
    )
}
