import { Checkbox } from '@/version2/components/ui/checkbox'
import { useState } from 'react'
import { Row, Table } from '@tanstack/react-table'
import {
    PhaseSelector,
    ProjectSelector,
    TaskSelector,
} from '@/version2/components/selector'
import { useCache, getFilteredCacheItems } from '@/version2/cache'
import {
    canEditStaffTime,
    canEnterTimeAgainstPhase,
    canEnterTimeAgainstProject,
    canLogTimeAgainstNoPhase,
    canLogTimeAgainstTasks,
    canUseTimeEntryFlag,
} from '@/State/Permissions/HasPermissions'
import SessionStore from '@/State/SessionStore'
import { Modal } from '@/version2/components/modal'
import { queryClient } from '@/App'
import { trpc } from '@/system/trpc'

interface DataEditTimeEntryModal {
    table: Table<any>
    isOpenDialog: boolean
    setIsOpenDialog: (isOpenDialog: boolean) => void
    timeEntries: Row<any>[]
    defaults?: any
    dates?: Date[]
    staff?: any
    store?: any
    heading?: any
    onSave?: (data) => void
    showAllFlags?: boolean
    placeholders?: Record<string, string>
    selectDefaults: boolean
    isEditingReport: boolean
    updateError: boolean
}

export function EditTimeEntryModal({
    table,
    isOpenDialog,
    setIsOpenDialog,
    timeEntries,
    dates,
    staff,
    store,
    heading,
    onSave,
    showAllFlags,
    placeholders,
    selectDefaults = true,
    defaults,
    isEditingReport,
    updateError,
}: DataEditTimeEntryModal) {
    const prefetchPhases = (projectId: string) => {
        queryClient.prefetchQuery({
            queryKey: ['project-phases', projectId],
            queryFn: () => trpc.global.getPhaseTasks.query({ projectId }),
            staleTime: 1000 * 60 * 5,
        })
    }

    const cache = useCache()

    const [project, setProject] = useState(
        defaults ? defaults.project : timeEntries[0]?.original?.project
    )
    const [phase, setPhase] = useState(
        defaults ? defaults.phase : timeEntries?.[0]?.original?.phase
    )
    const [task, setTask] = useState(
        defaults ? defaults.task : timeEntries?.[0]?.original?.task
    )
    const [phaseOptions, setPhaseOptions] = useState(
        defaults.project
            ? getFilteredCacheItems(
                  'phases',
                  (item) => item.projectId === defaults.project.id
              )
            : []
    )
    const [taskOptions, setTaskOptions] = useState(
        defaults.phase
            ? getFilteredCacheItems(
                  'tasks',
                  (item) => item.phaseId === defaults.phase.id
              )
            : []
    )
    const [isBillable, setIsBillable] = useState(
        defaults
            ? defaults.isBillable
            : (timeEntries?.[0]?.original?.isBillable ??
                  timeEntries?.[0]?.original?.task?.isBillable ??
                  timeEntries?.[0]?.original?.project?.costCentre?.isBillable ??
                  true)
    )

    const [isVariation, setIsVariation] = useState(
        defaults
            ? defaults.isVariation
            : (timeEntries?.[0]?.original?.isVariation ??
                  timeEntries?.[0]?.original?.task?.isVariation ??
                  false)
    )
    const [isOvertime, setIsOvertime] = useState(
        defaults
            ? defaults.isOvertime
            : (timeEntries?.[0]?.original?.isOvertime ?? false)
    )
    const [isRemote, setIsRemote] = useState(
        defaults
            ? defaults.isRemote
            : (timeEntries?.[0]?.original?.isRemote ?? false)
    )
    const [isFlexi, setIsFlexi] = useState(
        defaults
            ? defaults.isFlexi
            : (timeEntries?.[0]?.original?.isFlexi ?? false)
    )
    const [beenInvoiced, setBeenInvoiced] = useState(
        defaults
            ? defaults.beenInvoiced
            : (timeEntries?.[0]?.original?.beenInvoiced ?? false)
    )
    const [isLocked, setIsLocked] = useState(
        defaults
            ? defaults.isLocked
            : (timeEntries?.[0]?.original?.isLocked ?? false)
    )

    const getSelectedData = () => {
        return {
            data: Object.fromEntries(
                [
                    project ? ['costCentreId', project.costCentreId] : null,
                    project ? ['projectId', project.id] : null,
                    phase ? ['phaseId', phase.id] : null,
                    task ? ['taskId', task.id] : null,
                    isBillable != null ? ['isBillable', isBillable] : null,
                    isVariation != null ? ['isVariation', isVariation] : null,
                    isOvertime != null ? ['isOvertime', isOvertime] : null,
                    isRemote != null ? ['isRemote', isRemote] : null,
                    isFlexi != null ? ['isFlexi', isFlexi] : null,
                    beenInvoiced != null
                        ? ['beenInvoiced', beenInvoiced]
                        : null,
                    isLocked != null ? ['isLocked', isLocked] : null,
                ].filter((v) => v)
            ),
            ids: timeEntries
                .map((timeEntry) => timeEntry?.original?.timeEntryId)
                .filter((v) => v),
        }
    }

    return (
        <>
            <Modal
                isOpenDialog={isOpenDialog}
                setIsOpenDialog={setIsOpenDialog}
                heading={
                    heading ||
                    (timeEntries
                        ? 'Edit Timesheet Entries'
                        : 'New Timesheet Entry')
                }
                onSave={() => {
                    if (onSave) {
                        return onSave(getSelectedData())
                    }
                    if (timeEntries) {
                    } else {
                    }
                }}
                styleSaveButton={{
                    border: updateError ? '1px #ef4444 solid' : '',
                }}
                saveLabel={
                    isEditingReport
                        ? 'Loading...'
                        : updateError
                          ? 'Try again'
                          : timeEntries
                            ? 'Edit Time Entries'
                            : 'Add Time Entries'
                }
                canSave={() =>
                    showAllFlags ||
                    (project && (canLogTimeAgainstNoPhase() || phase))
                }
                isLoading={isEditingReport}
            >
                <div className="pb-2">
                    <div className="flex items-center justify-between mb-2">
                        <label className="font-medium mr-4">Project</label>
                        <ProjectSelector
                            value={project}
                            options={cache.projects.filter((p) =>
                                canEnterTimeAgainstProject(SessionStore.user, p)
                            )}
                            onChange={(pr) => {
                                const phaseData = queryClient.getQueryData([
                                    'project-phases',
                                    pr?.id,
                                ])
                                const phase = phaseData?.phases?.[0] || null
                                const task =
                                    phaseData?.tasks?.find(
                                        (t) =>
                                            t.phaseId === phase?.id &&
                                            t.isDefault
                                    ) || null
                                setProject(pr)
                                setPhase(phase)
                                setTask(task)
                                if (selectDefaults) {
                                    setIsBillable(pr?.costCentre?.isBillable)
                                    setIsVariation(false)
                                    setIsOvertime(false)
                                }
                            }}
                            placeholder={'Leave Project Unchanged'}
                            clearable={true}
                            itemProps={{
                                onMouseEnter: (pr) => prefetchPhases(pr.id),
                                onFocus: (pr) => prefetchPhases(pr.id),
                            }}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-between mb-2">
                        <label className="font-medium mr-4">Phase</label>
                        <PhaseSelector
                            projectId={project?.id}
                            value={phase}
                            onChange={(ph) => {
                                const phaseData = queryClient.getQueryData([
                                    'project-phases',
                                    project?.id,
                                ])
                                const phase =
                                    phaseData?.phases?.find(
                                        (p) => p.id === ph?.id
                                    ) || null
                                const task =
                                    phaseData?.tasks?.find(
                                        (t) =>
                                            t.phaseId === phase?.id &&
                                            t.isDefault
                                    ) ||
                                    phaseData?.tasks?.find(
                                        (t) => t.phaseId === phase?.id
                                    ) ||
                                    null
                                setPhase(phase)
                                if (task) {
                                    setTask(task)
                                    if (selectDefaults) {
                                        setIsBillable(task?.isBillable)
                                        setIsVariation(task?.isVariation)
                                        setIsOvertime(false)
                                    }
                                }
                            }}
                            placeholder="Leave Phase Unchanged"
                            clearable={true}
                        />
                    </div>
                    {canLogTimeAgainstTasks() ? (
                        <div className="flex flex-row items-center justify-between mb-2">
                            <label className="font-medium mr-4">Task</label>
                            <TaskSelector
                                value={task}
                                projectId={project?.id}
                                phaseId={phase?.id}
                                onChange={(task) => {
                                    setTask(task)
                                    if (selectDefaults) {
                                        setIsBillable(task?.isBillable)
                                        setIsVariation(task?.isVariation)
                                        setIsOvertime(false)
                                    }
                                }}
                                placeholder="Leave Task Unchanged"
                                clearable={true}
                            />
                        </div>
                    ) : null}
                </div>
                <div className="border-b border-b-[#ccc] border-t border-t-[#ccc] pb-2 pt-2 flex justify-around">
                    {showAllFlags || canUseTimeEntryFlag('billable') ? (
                        <div className="flex items-center">
                            <Checkbox
                                style={{ borderColor: '#000', marginRight: 5 }}
                                id="billable-checkbox"
                                onClick={() =>
                                    setIsBillable((prevState) => !prevState)
                                }
                                checked={
                                    isBillable === null
                                        ? 'indeterminate'
                                        : isBillable
                                }
                                value={
                                    isBillable == null
                                        ? 'indeterminate'
                                        : isBillable
                                }
                            />
                            <label
                                htmlFor="billable-checkbox"
                                className="font-medium cursor-pointer"
                            >
                                Billable
                            </label>
                        </div>
                    ) : null}
                    {showAllFlags || canUseTimeEntryFlag('variation') ? (
                        <div className="flex items-center">
                            <Checkbox
                                style={{ borderColor: '#000', marginRight: 5 }}
                                id="variation-checkbox"
                                onClick={() =>
                                    setIsVariation((prevState) => !prevState)
                                }
                                checked={
                                    isVariation === null
                                        ? 'indeterminate'
                                        : isVariation
                                }
                                value={
                                    isVariation == null
                                        ? 'indeterminate'
                                        : isVariation
                                }
                            />
                            <label
                                htmlFor="variation-checkbox"
                                className="font-medium cursor-pointer"
                            >
                                Variation
                            </label>
                        </div>
                    ) : null}
                    {showAllFlags || canUseTimeEntryFlag('overtime') ? (
                        <div className="flex items-center">
                            <Checkbox
                                style={{ borderColor: '#000', marginRight: 5 }}
                                id="overtime-checkbox"
                                onClick={() =>
                                    setIsOvertime((prevState) => !prevState)
                                }
                                checked={
                                    isOvertime === null
                                        ? 'indeterminate'
                                        : isOvertime
                                }
                                value={
                                    isOvertime == null
                                        ? 'indeterminate'
                                        : isOvertime
                                }
                            />
                            <label
                                htmlFor="overtime-checkbox"
                                className="font-medium cursor-pointer"
                            >
                                Overtime
                            </label>
                        </div>
                    ) : null}
                    {canUseTimeEntryFlag('remote') ? (
                        <div className="flex items-center">
                            <Checkbox
                                style={{ borderColor: '#000', marginRight: 5 }}
                                id="remote-checkbox"
                                className="remote-checkbox"
                                checked={
                                    isRemote === null
                                        ? 'indeterminate'
                                        : isRemote
                                }
                                value={
                                    isRemote == null
                                        ? 'indeterminate'
                                        : isRemote
                                }
                                onClick={() =>
                                    setIsRemote((prevState) => !prevState)
                                }
                            />
                            <label
                                htmlFor="remote-checkbox"
                                className="font-medium cursor-pointer"
                            >
                                Remote
                            </label>
                        </div>
                    ) : null}
                    {canUseTimeEntryFlag('flex') ? (
                        <div className="flex items-center">
                            <Checkbox
                                style={{ borderColor: '#000', marginRight: 5 }}
                                id="is-flexi-checkbox"
                                className="is-flexi-checkbox"
                                checked={
                                    isFlexi === null ? 'indeterminate' : isFlexi
                                }
                                value={
                                    isFlexi == null ? 'indeterminate' : isFlexi
                                }
                                onClick={() =>
                                    setIsFlexi((prevState) => !prevState)
                                }
                            />
                            <label
                                htmlFor="is-flexi-checkbox"
                                className="font-medium cursor-pointer"
                            >
                                Flexi
                            </label>
                        </div>
                    ) : null}
                    {showAllFlags && canEditStaffTime(SessionStore.user) ? (
                        <>
                            <div className="flex items-center">
                                <Checkbox
                                    aria-label="Select all"
                                    style={{
                                        borderColor: '#000',
                                        marginRight: 5,
                                    }}
                                    id="invoiced-checkbox"
                                    onClick={() =>
                                        setBeenInvoiced(
                                            (prevState) => !prevState
                                        )
                                    }
                                    checked={
                                        beenInvoiced === null
                                            ? 'indeterminate'
                                            : beenInvoiced
                                    }
                                    value={
                                        beenInvoiced == null
                                            ? 'indeterminate'
                                            : beenInvoiced
                                    }
                                />
                                <label
                                    htmlFor="invoiced-checkbox"
                                    className="font-medium cursor-pointer"
                                >
                                    Invoiced
                                </label>
                            </div>
                            <div className="flex items-center">
                                <Checkbox
                                    aria-label="Select all"
                                    style={{
                                        borderColor: '#000',
                                        marginRight: 5,
                                    }}
                                    id="locked-checkbox"
                                    onClick={() =>
                                        setIsLocked((prevState) => !prevState)
                                    }
                                    checked={
                                        isLocked === null
                                            ? 'indeterminate'
                                            : isLocked
                                    }
                                    value={
                                        isLocked == null
                                            ? 'indeterminate'
                                            : isLocked
                                    }
                                />
                                <label
                                    htmlFor="locked-checkbox"
                                    className="font-medium cursor-pointer"
                                >
                                    Locked
                                </label>
                            </div>
                        </>
                    ) : null}
                </div>
            </Modal>
        </>
    )
}
