import * as React from 'react'
import { ColumnDef, Row, Table } from '@tanstack/react-table'
import { Check, ChevronRight } from 'lucide-react'
import { cn } from '@/lib/utils'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@2/components/ui/popover'
import {
    Command,
    CommandGroup,
    CommandItem,
    CommandList,
    CommandSeparator,
} from '@2/components/ui/command'

interface CreateExpansionColumnOptions<TData> {
    groupByColumns?: string[]
    columns?: ColumnDef<TData, any>[]
}

export const createExpansionColumn = <TData,>({
    groupByColumns,
    columns,
}: CreateExpansionColumnOptions<TData>) => {
    const [open, setOpen] = React.useState(false)
    const [rowExpand, setRowExpand] = React.useState('')

    const toggleRowsAtDepth = (
        table: Table<TData>,
        targetDepth: number,
        isExpand: boolean
    ) => {
        const allRows = table
            .getFilteredRowModel()
            .rows.flatMap((row) => [row, ...row.getLeafRows()])
        allRows.forEach((row) => {
            if (row.depth < targetDepth) {
                row.toggleExpanded(isExpand)
            }
            if (isExpand && row.depth >= targetDepth) {
                row.toggleExpanded(!isExpand)
            }
        })
    }

    return {
        id: 'expand',
        accessorKey: 'expand',
        header: ({ table }: { table: Table<TData> }) => {
            // If no grouping columns or exactly one grouping column, show simple toggle
            if (!groupByColumns?.length || groupByColumns.length <= 2) {
                return (
                    <div
                        className="cursor-pointer"
                        onClick={() => table.toggleAllRowsExpanded()}
                    >
                        <ChevronRight
                            size={18}
                            className={`transition-transform duration-300 ${
                                table.getIsAllRowsExpanded() ? 'rotate-90' : ''
                            }`}
                        />
                    </div>
                )
            }

            // For multiple grouping columns, show the dropdown menu
            return (
                <Popover open={open} onOpenChange={setOpen}>
                    <PopoverTrigger asChild>
                        <ChevronRight
                            size={18}
                            className={`transition-transform duration-300 ${
                                open ? 'rotate-90' : ''
                            } cursor-pointer`}
                        />
                    </PopoverTrigger>
                    <PopoverContent className="w-[20rem] p-0" align="start">
                        <Command>
                            <CommandList>
                                <CommandGroup>
                                    {groupByColumns.map((columnId, index) => {
                                        const column = columns?.find(
                                            (col) =>
                                                col.accessorKey === columnId
                                        )
                                        return (
                                            <CommandItem
                                                key={columnId}
                                                className="capitalize"
                                                value={columnId}
                                                onSelect={(currentValue) => {
                                                    setRowExpand(
                                                        currentValue ===
                                                            rowExpand
                                                            ? ''
                                                            : currentValue
                                                    )
                                                    toggleRowsAtDepth(
                                                        table,
                                                        index,
                                                        !(
                                                            currentValue ===
                                                            rowExpand
                                                        )
                                                    )
                                                    setOpen(false)
                                                }}
                                            >
                                                <Check
                                                    className={cn(
                                                        'mr-2 h-4 w-4',
                                                        rowExpand === columnId
                                                            ? 'opacity-100'
                                                            : 'opacity-0'
                                                    )}
                                                />
                                                {column?.meta?.title ||
                                                    columnId}
                                            </CommandItem>
                                        )
                                    })}
                                </CommandGroup>
                                <CommandSeparator />
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            )
        },
        cell: ({ row }: { row: Row<TData> }) => {
            return row.getCanExpand() ? (
                <div
                    className="cursor-pointer"
                    onClick={() => row.toggleExpanded()}
                >
                    <ChevronRight
                        size={18}
                        className={`transition-transform duration-300 ${
                            row.getIsExpanded() ? 'rotate-90' : ''
                        }`}
                    />
                </div>
            ) : null
        },
        size: 40,
        minSize: 34,
    }
}
