export const permissionStructure = [
    {
        label: 'Forecasts',
        permissions: [
            {
                label: 'Can View Revenue Forecast',
                type: 'switch',
                key: 'canViewRevenueForecast',
            },
            {
                label: 'Can View Staff Expenses',
                type: 'switch',
                key: 'canViewStaffExpenses',
                display: (permissions) => permissions.canViewRevenueForecast,
            },
            {
                label: 'Can View Operational Expenses',
                type: 'switch',
                key: 'canViewOperationalExpenses',
                display: (permissions) => permissions.canViewRevenueForecast,
            },
            {
                label: 'Can View Resource Schedule',
                type: 'switch',
                key: 'canViewResourceSchedule',
            },
            {
                label: 'Can View All Resourcing',
                type: 'switch',
                key: 'canViewAllResourcing',
                display: (permissions) => permissions.canViewResourceSchedule,
            },
            {
                label: 'Can View Weekly Planner',
                type: 'switch',
                key: 'canViewWeeklyPlanner',
            },
        ],
    },
    {
        label: 'Projects',
        permissions: [
            {
                label: 'Project Permissions',
                type: 'radio',
                options: ['hidden', 'view', 'edit', 'admin'],
                key: 'projectPermissions',
            },
            {
                label: 'Fees',
                type: 'radio',
                options: ['hidden', 'view', 'edit'],
                key: 'projectFees',
                display: (permissions) =>
                    permissions.projectPermissions &&
                    permissions.projectPermissions !== 'hidden',
            },
            {
                label: 'Expense Budgets',
                type: 'radio',
                options: ['hidden', 'view', 'edit'],
                key: 'projectExpenseBudgets',
                display: (permissions) =>
                    permissions.projectPermissions &&
                    permissions.projectPermissions !== 'hidden',
            },
            {
                label: 'Hours Budgets',
                type: 'radio',
                options: ['view', 'edit'],
                key: 'projectHoursBudgets',
                display: (permissions) =>
                    permissions.projectPermissions &&
                    permissions.projectPermissions !== 'hidden',
            },
            {
                label: 'Staff Budgets',
                type: 'radio',
                options: ['view', 'admin'],
                key: 'projectStaffBudgets',
                display: (permissions) =>
                    permissions.projectPermissions &&
                    permissions.projectPermissions !== 'hidden',
            },
            {
                label: 'Project Dates',
                type: 'radio',
                options: ['view', 'edit'],
                key: 'projectDates',
                display: (permissions) =>
                    permissions.projectPermissions &&
                    permissions.projectPermissions !== 'hidden',
            },
            {
                label: 'Phases & Tasks',
                type: 'radio',
                options: ['view', 'admin'],
                key: 'projectPhasesTasks',
                display: (permissions) =>
                    permissions.projectPermissions &&
                    permissions.projectPermissions !== 'hidden',
            },
            {
                label: 'Project Expenses',
                type: 'radio',
                options: ['hidden', 'view', 'admin'],
                key: 'projectExpenses',
                display: (permissions) =>
                    permissions.projectPermissions &&
                    permissions.projectPermissions !== 'hidden',
            },
            {
                label: 'Forecasted Revenue',
                type: 'radio',
                options: ['hidden', 'view', 'edit'],
                key: 'projectForecastedRevenue',
                display: (permissions) =>
                    permissions.projectPermissions &&
                    permissions.projectPermissions !== 'hidden',
            },
            {
                label: 'Forecasted Hours',
                type: 'radio',
                options: ['view', 'edit'],
                key: 'projectForecastedHours',
                display: (permissions) =>
                    permissions.projectPermissions &&
                    permissions.projectPermissions !== 'hidden',
            },
            {
                label: 'Invoices',
                type: 'radio',
                options: ['hidden', 'view', 'admin'],
                key: 'projectInvoices',
                display: (permissions) =>
                    permissions.projectPermissions &&
                    permissions.projectPermissions !== 'hidden',
            },
            {
                label: 'Project Rates',
                type: 'radio',
                options: ['hidden', 'view', 'admin'],
                key: 'projectRates',
                display: (permissions) =>
                    permissions.projectPermissions &&
                    permissions.projectPermissions !== 'hidden',
            },
            {
                label: 'Project Notes',
                type: 'radio',
                options: ['hidden', 'view', 'admin'],
                key: 'projectNotes',
                display: (permissions) =>
                    permissions.projectPermissions &&
                    permissions.projectPermissions !== 'hidden',
            },
            {
                label: 'Can Record Time',
                type: 'switch',
                key: 'projectTimesheets',
            },
        ],
    },
    {
        label: 'Staff & Roles',
        permissions: [
            {
                label: 'Staff',
                type: 'radio',
                options: ['hidden', 'view', 'edit', 'admin'],
                key: 'staffPermissions',
            },
            {
                label: 'Roles',
                type: 'radio',
                options: ['hidden', 'view', 'edit', 'admin'],
                key: 'rolePermissions',
            },
            {
                label: 'Can Edit Timesheets',
                type: 'switch',
                key: 'editStaffTime',
                display: (permissions) =>
                    (permissions.staffPermissions &&
                        permissions.staffPermissions !== 'hidden') ||
                    (permissions.rolePermissions &&
                        permissions.rolePermissions !== 'hidden'),
            },
            {
                label: 'Pay Rate',
                type: 'radio',
                options: ['hidden', 'view', 'admin'],
                key: 'payRate',
                display: (permissions) =>
                    (permissions.staffPermissions &&
                        permissions.staffPermissions !== 'hidden') ||
                    (permissions.rolePermissions &&
                        permissions.rolePermissions !== 'hidden'),
            },
            {
                label: 'Overtime Rate',
                type: 'radio',
                options: ['hidden', 'view', 'admin'],
                key: 'overtimeRate',
                display: (permissions) =>
                    (permissions.staffPermissions &&
                        permissions.staffPermissions !== 'hidden') ||
                    (permissions.rolePermissions &&
                        permissions.rolePermissions !== 'hidden'),
            },
            {
                label: 'Cost Rate',
                type: 'radio',
                options: ['hidden', 'view', 'admin'],
                key: 'costRate',
                display: (permissions) =>
                    (permissions.staffPermissions &&
                        permissions.staffPermissions !== 'hidden') ||
                    (permissions.rolePermissions &&
                        permissions.rolePermissions !== 'hidden'),
            },
            {
                label: 'Charge Out Rate',
                type: 'radio',
                options: ['hidden', 'view', 'admin'],
                key: 'chargeOutRate',
                display: (permissions) =>
                    (permissions.staffPermissions &&
                        permissions.staffPermissions !== 'hidden') ||
                    (permissions.rolePermissions &&
                        permissions.rolePermissions !== 'hidden'),
            },
        ],
    },
    {
        label: 'Organisation',
        permissions: [
            {
                label: 'Contacts',
                type: 'radio',
                options: ['view', 'admin'],
                key: 'contacts',
            },
            {
                label: 'Cost Centres',
                type: 'radio',
                options: ['view', 'admin'],
                key: 'costCentres',
            },
            {
                label: 'Operational Expenses',
                type: 'radio',
                options: ['hidden', 'view', 'admin'],
                key: 'operationalExpenses',
            },
            {
                label: 'Holidays',
                type: 'radio',
                options: ['hidden', 'view', 'admin'],
                key: 'holidays',
            },
            {
                label: 'Invoice Settings',
                type: 'radio',
                options: ['hidden', 'edit'],
                key: 'invoiceSettings',
            },
            {
                label: 'Organisation Settings',
                type: 'radio',
                options: ['hidden', 'edit'],
                key: 'organisationSettings',
            },
            {
                label: 'Billing',
                type: 'radio',
                options: ['hidden', 'edit'],
                key: 'billing',
            },
        ],
    },
]

export const permissionLevels = ['hidden', 'view', 'edit', 'admin']
