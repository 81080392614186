import { createFileRoute, notFound } from '@tanstack/react-router'
import RolePageHeader from '../../Pages/RolePage/RolePageHeader'
import RolePage from '../../Pages/RolePage/RolePage'
import PageBody from '../../Pages/Layout/PageBody'
import { idf } from '../../Queries/queryFormatter'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import fetchData from '../../Queries/fetchData'
import {
    canViewRoute,
    migratedToV2,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import PermissionDenied from '../../Pages/PermissionDenied'
import DataStore from '../../State/DataStore'
import { router } from '../../App'
import RoleCollection from '../../State/Collections/RoleCollection'
import { setLayoutDefaults } from '@2/layout/layout-store'
import { getCacheItemById } from '@2/cache'
import { CenterPadPageBody } from '@2/layout/page-body'

export const Route = createFileRoute('/_private/staff-roles/$id')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'staffRole', params))
            throw new Error('Permission Denied')
        const role = getCacheItemById('roles', params.id)
        setLayoutDefaults({
            pageTitle: role?.name || 'Staff Role',
            subMenu: 'resources',
        })
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    loader: async ({ params }) => await loader(params.id),
    pendingComponent: LoadingSpinner,
    component: Wrapper,
    onLeave: (match) => {
        const { id } = match.params
        const role = RoleCollection.rolesById[id]
        if (
            !role?.createdAt &&
            match.pathname !== router.latestLocation.pathname
        ) {
            DataStore.destroyNewItems('roleId', id)
        }
    },
})

const loader = async (id) => {
    const collections = [
        {
            collection: 'roles',
            fields: [
                'name',
                'avgPayRate',
                'avgOvertimeRate',
                'avgCostRate',
                'avgChargeOutRate',
            ],
            filters: [idf(id)],
            chain: [
                {
                    collection: 'roleRates',
                    join: {
                        roles: 'id',
                        roleRates: 'roleId',
                    },
                    fields: [
                        'roleId',
                        'date',
                        'payRate',
                        'chargeOutRate',
                        'costRate',
                        'overtimeRate',
                    ],
                },
                {
                    collection: 'staff',
                    join: {
                        roles: 'id',
                        staff: 'roleId',
                    },
                    fields: [
                        'roleId',
                        'inheritPayRate',
                        'inheritOvertimeRate',
                        'inheritCostRate',
                        'inheritChargeOutRate',
                    ],
                    chain: [
                        {
                            collection: 'staffRates',
                            join: {
                                staff: 'id',
                                staffRates: 'staffId',
                            },
                            fields: [
                                'staffId',
                                'date',
                                'payRate',
                                'chargeOutRate',
                                'costRate',
                                'weeklyAvailability',
                                'overtimeRate',
                            ],
                        },
                    ],
                },
            ],
        },
    ].filter(Boolean)

    const data = await Promise.all(collections.map(fetchData))
    const role = RoleCollection.rolesById[id]
    if (!role) {
        throw notFound()
    }
    return data
}

function Wrapper() {
    const { id } = Route.useParams()
    if (migratedToV2()) {
        return (
            <CenterPadPageBody>
                <RolePage id={id} />
            </CenterPadPageBody>
        )
    }
    return (
        <>
            <RolePageHeader id={id} />
            <PageBody>
                <RolePage id={id} />
            </PageBody>
        </>
    )
}
