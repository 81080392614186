import { type ColumnDef } from '@tanstack/react-table'
import Formatter from '@/Components/Formatter'
import SessionStore from '@/State/SessionStore'
import {
    canViewProjectInvoices,
    canViewProjectStaffCost,
    canViewProjectFees,
    canViewProjectExpenseBudgets,
    canViewCostCentres,
    canViewContacts,
    canViewPrimaryContact,
    canViewProjectNotes,
    canViewStaffAllocations,
    canViewProjectExpenses,
    canViewProjectStaffPay,
    canViewProjectStaffChargeOut,
    canViewRevenueTargets,
} from '@/State/Permissions/HasPermissions'
import { getCacheItemById } from '@/version2/cache'
import { createGenerateColumnDef } from '@/version2/components/data-table/helpers/generate-column-def'

export function getColumns(
    onHideColumn: (column: string) => void
): ColumnDef<any>[] {
    const generateColumnDef = createGenerateColumnDef({
        onHideColumn,
    })

    const standardSize = 100
    const wideSize = 240

    return [
        generateColumnDef({
            key: 'actualVsBudgetedHours',
            type: 'progressBar',
            title: 'Actual / Budgeted Hours',
            size: wideSize,
            aggregationFn: 'progressBar',
        }),
        generateColumnDef({
            key: 'actualVsBudgetedExpenses',
            type: 'progressBar',
            title: 'Actual (Cost) / Budgeted Expenses',
            size: wideSize,
            aggregationFn: 'progressBar',
            permissions: (row) =>
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenseBudgets(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'revenueVsFee',
            type: 'progressBar',
            title: 'Revenue / Fee',
            size: wideSize,
            aggregationFn: 'progressBar',
            formatter: (v) => Formatter.currency(v, { decimals: 0 }),
            permissions: (row) =>
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'profit',
            type: 'currency',
            title: 'Profit (cost + project expenses)',
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'profitMargin',
            type: 'percent',
            title: 'Profit margin (cost + project expenses)',
            aggregationFn: 'mean',
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'project',
            type: 'project',
            title: 'Project',
        }),
        generateColumnDef({
            key: 'startDate',
            type: 'date',
            title: 'Start Date',
            aggregationFn: 'min',
        }),
        generateColumnDef({
            key: 'endDate',
            type: 'date',
            title: 'End Date',
            aggregationFn: 'max',
        }),
        generateColumnDef({
            key: 'fee',
            type: 'currency',
            title: 'Fee',
            size: standardSize,
            permissions: (row) =>
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'expenseBudget',
            type: 'currency',
            title: 'Expense Budget',
            permissions: (row) =>
                canViewProjectExpenseBudgets(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'costCentre',
            type: 'costCentre',
            title: 'Cost centre',
            permissions: (row) => canViewCostCentres(SessionStore.user),
        }),
        generateColumnDef({
            key: 'contact',
            type: 'contact',
            title: 'Contact',
            permissions: (row) => canViewContacts(SessionStore.user),
        }),
        generateColumnDef({
            key: 'invoiceContact',
            type: 'contact',
            title: 'Primary Contact',
            permissions: (row) =>
                canViewContacts(SessionStore.user) && canViewPrimaryContact(),
        }),
        generateColumnDef({
            key: 'owner',
            type: 'staffMember',
            title: 'Project Owner',
        }),
        generateColumnDef({
            key: 'status',
            type: 'status',
            title: 'Status',
        }),
        generateColumnDef({
            key: 'remainingFee',
            type: 'currency',
            title: 'Remaining fee',
            permissions: (row) =>
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'remainingExpenseProjectBudget',
            type: 'currency',
            title: 'Remaining Expense Budget',
            size: standardSize,
            permissions: (row) =>
                canViewProjectExpenseBudgets(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'latestEvent',
            type: 'date',
            title: 'Latest Event',
            size: standardSize,
        }),
        generateColumnDef({
            key: 'latestNote',
            type: 'text',
            title: 'Latest Note',
            size: 350,
            permissions: (row) =>
                canViewProjectNotes(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'currentPhases',
            type: 'text',
            title: 'Current Phases',
            size: standardSize,
        }),
        generateColumnDef({
            key: 'activePhases',
            type: 'text',
            title: 'Active Phases',
            size: standardSize,
        }),
        generateColumnDef({
            key: 'prospectivePhases',
            type: 'text',
            title: 'Prospective Phases',
            size: standardSize,
        }),
        generateColumnDef({
            key: 'staffMembers',
            type: 'staffs',
            title: 'Staff members assigned',
            size: standardSize,
        }),
        generateColumnDef({
            key: 'progress',
            type: 'percent',
            title: 'Progress',
            size: standardSize,
            aggregationFn: 'mean',
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'revenue',
            type: 'currency',
            title: 'Revenue',
            size: standardSize,
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'revenueWithVariations',
            type: 'currency',
            title: 'Revenue (Variations)',
            size: standardSize,
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'revenueWithVariationsReimbursements',
            type: 'currency',
            title: 'Revenue (Variations + Reimbursements)',
            size: standardSize,
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'recordedHours',
            type: 'number',
            title: 'Recorded Hours',
            size: standardSize,
        }),
        generateColumnDef({
            key: 'recordedHoursVariation',
            type: 'number',
            title: 'Recorded Hours (Variation)',
            size: standardSize,
        }),
        generateColumnDef({
            key: 'hoursBudgeted',
            type: 'number',
            title: 'Hours budgeted',
            size: standardSize,
        }),
        generateColumnDef({
            key: 'hoursAllocated',
            type: 'number',
            title: 'Hours allocated',
            size: standardSize,
            permissions: (row) =>
                canViewStaffAllocations(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'expenses',
            type: 'currency',
            title: 'Expenses (cost)',
            size: standardSize,
            permissions: (row) =>
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'projectExpenses',
            type: 'currency',
            title: 'Project Expenses',
            size: standardSize,
            permissions: (row) =>
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'billableProjectExpenses',
            type: 'currency',
            title: 'Billable Project Expenses',
            size: standardSize,
            permissions: (row) =>
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'allProjectExpenses',
            type: 'currency',
            title: 'All Project Expenses',
            size: standardSize,
            permissions: (row) =>
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'expensesVariation',
            type: 'currency',
            title: 'Expenses (cost) + Variation Cost',
            size: standardSize,
            permissions: (row) =>
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'expensesVariationProject',
            type: 'currency',
            title: 'Expenses (cost) + Variation Cost + Project Expenses',
            size: standardSize,
            permissions: (row) =>
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'expensesProject',
            type: 'currency',
            title: 'Expenses (cost) + Project Expenses',
            size: standardSize,
            permissions: (row) =>
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'labourExpense',
            type: 'currency',
            title: 'Labour expense',
            size: standardSize,
            permissions: (row) =>
                canViewProjectStaffPay(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'chargeOut',
            type: 'currency',
            title: 'Charge-out',
            size: standardSize,
            permissions: (row) =>
                canViewProjectStaffChargeOut(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'chargeOutVariation',
            type: 'currency',
            title: 'Charge-out (Variations)',
            size: standardSize,
            permissions: (row) =>
                canViewProjectStaffChargeOut(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'actualVsBudgetedHoursVariation',
            type: 'progressBar',
            title: 'Actual (Variation) / Budgeted Hours',
            size: wideSize,
            aggregationFn: 'progressBar',
        }),
        generateColumnDef({
            key: 'actualProjectVsBudgetedExpenses',
            type: 'progressBar',
            title: 'Actual (Cost + Project) / Budgeted Expenses',
            size: wideSize,
            aggregationFn: 'progressBar',
            permissions: (row) =>
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenseBudgets(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'actualVariationVsBudgetedExpenses',
            type: 'progressBar',
            title: 'Actual (Cost) / Budgeted Expenses',
            size: wideSize,
            aggregationFn: 'progressBar',
            permissions: (row) =>
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenseBudgets(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'actualVariationProjectVsBudgetedExpenses',
            type: 'progressBar',
            title: 'Actual (Cost + Variation + Project) / Budgeted Expenses',
            size: wideSize,
            aggregationFn: 'progressBar',
            permissions: (row) =>
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenseBudgets(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'revenueVsChargeOut',
            type: 'progressBar',
            title: 'Revenue / Charge Out',
            size: wideSize,
            aggregationFn: 'progressBar',
            permissions: (row) =>
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffChargeOut(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'percentRevenueVsFee',
            type: 'percent',
            title: 'Percent Revenue / Fee',
            size: standardSize,
            aggregationFn: 'mean',
            permissions: (row) =>
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'costProjectVsFee',
            type: 'progressBar',
            title: 'Expenses (Cost + Project) / Fee',
            size: wideSize,
            aggregationFn: 'progressBar',
            permissions: (row) =>
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'costVsFee',
            type: 'progressBar',
            title: 'Expense (cost) / Fee',
            size: wideSize,
            aggregationFn: 'progressBar',
            permissions: (row) =>
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'payVsFee',
            type: 'progressBar',
            title: 'Expense (labour) / Fee',
            size: wideSize,
            aggregationFn: 'progressBar',
            permissions: (row) =>
                canViewProjectStaffPay(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'chargeOutVsFee',
            type: 'progressBar',
            title: 'Charge Out / Fee',
            size: wideSize,
            aggregationFn: 'progressBar',
            permissions: (row) =>
                canViewProjectStaffChargeOut(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'chargeOutVariationVsFee',
            type: 'progressBar',
            title: 'Charge Out (Variations) / Fee',
            size: wideSize,
            aggregationFn: 'progressBar',
            permissions: (row) =>
                canViewProjectStaffChargeOut(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'percentOfTotalHours',
            type: 'percent',
            title: 'Percentage of total hours',
            size: standardSize,
        }),
        generateColumnDef({
            key: 'percentOfTotalRevenue',
            type: 'percent',
            title: 'Percentage of total revenue',
            size: standardSize,
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'percentOfTotalExpenses',
            type: 'percent',
            title: 'Percentage of total expenses (cost)',
            size: standardSize,
            permissions: (row) =>
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'percentOfTotalExpensesLabour',
            type: 'percent',
            title: 'Percentage of total expenses (labour)',
            size: standardSize,
            permissions: (row) =>
                canViewProjectStaffPay(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'profitCost',
            type: 'currency',
            title: 'Profit (cost)',
            size: standardSize,
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'profitLabour',
            type: 'currency',
            title: 'Profit (labour)',
            size: standardSize,
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffPay(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'profitFee',
            type: 'currency',
            title: 'Profit (fee)',
            size: standardSize,
            permissions: (row) =>
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'profitFeeVariation',
            type: 'currency',
            title: 'Profit (fee, variation & project expenses)',
            size: standardSize,
            permissions: (row) =>
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'profitCostVariationProject',
            type: 'currency',
            title: 'Profit (cost, variation & project expenses)',
            size: standardSize,
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'profitMarginCost',
            type: 'percent',
            title: 'Profit margin (cost)',
            size: standardSize,
            aggregationFn: 'mean',
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'profitMarginLabour',
            type: 'percent',
            title: 'Profit margin (labour)',
            size: standardSize,
            aggregationFn: 'mean',
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffPay(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'profitMarginFee',
            type: 'percent',
            title: 'Profit margin (fee)',
            size: standardSize,
            permissions: (row) =>
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'profitMarginFeeVariation',
            type: 'percent',
            title: 'Profit margin (fee, variation & project expenses)',
            size: standardSize,
            permissions: (row) =>
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'netMultiplier',
            type: 'number',
            title: 'Net Multiplier',
            size: standardSize,
            aggregationFn: 'mean',
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'markup',
            type: 'percent',
            title: 'Markup (cost + project expenses)',
            size: standardSize,
            aggregationFn: 'mean',
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'markupCost',
            type: 'percent',
            title: 'Markup (cost)',
            size: standardSize,
            aggregationFn: 'mean',
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'markupLabour',
            type: 'percent',
            title: 'Markup (labour)',
            size: standardSize,
            aggregationFn: 'mean',
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffPay(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'revenuePerHour',
            type: 'currency',
            title: 'Revenue per hour',
            size: standardSize,
            aggregationFn: 'mean',
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'revenueVariationPerHour',
            type: 'currency',
            title: 'Revenue per hour (variations)',
            size: standardSize,
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'revenueVariationReimbursementPerHour',
            type: 'currency',
            title: 'Revenue per hour (variations + reimbursements)',
            size: standardSize,
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'completionDate',
            type: 'date',
            title: 'Completion date',
            size: standardSize,
            permissions: (row) =>
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'latestInvoicedPhases',
            type: 'text',
            title: 'Latest Invoiced Phases',
            size: 150,
            permissions: (row) =>
                canViewProjectInvoices(SessionStore.user, row),
        }),
        generateColumnDef({
            key: 'latestRevenueDate',
            type: 'date',
            title: 'Latest Revenue Date',
            size: standardSize,
            permissions: (row) =>
                canViewProjectInvoices(SessionStore.user, row),
        }),
        generateColumnDef({
            key: 'latestTimeEntryDate',
            type: 'date',
            title: 'Latest Time Entry Date',
            size: standardSize,
        }),
        generateColumnDef({
            key: 'latestTimeEntryDateInRange',
            type: 'date',
            title: 'Latest Time Entry Date In Range',
            size: standardSize,
        }),
        generateColumnDef({
            key: 'projectedHours',
            type: 'number',
            title: 'Projected Hours',
            size: standardSize,
            permissions: (row) =>
                canViewStaffAllocations(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'futureHours',
            type: 'number',
            title: 'Future Hours',
            size: standardSize,
            permissions: (row) =>
                canViewStaffAllocations(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'futureLabour',
            type: 'currency',
            title: 'Future Labour Expense',
            size: standardSize,
            permissions: (row) =>
                canViewStaffAllocations(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffPay(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'futureCost',
            type: 'currency',
            title: 'Future Expense',
            size: standardSize,
            permissions: (row) =>
                canViewStaffAllocations(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'futureChargeOut',
            type: 'currency',
            title: 'Future Charge Out',
            size: standardSize,
            permissions: (row) =>
                canViewStaffAllocations(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffChargeOut(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'futureRevenue',
            type: 'currency',
            title: 'Future Revenue',
            size: standardSize,
            permissions: (row) =>
                canViewRevenueTargets(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'projectedRevenue',
            type: 'currency',
            title: 'Projected Revenue',
            size: standardSize,
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewRevenueTargets(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'projectedExpense',
            type: 'currency',
            title: 'Projected Expense (Cost)',
            size: standardSize,
            permissions: (row) =>
                canViewStaffAllocations(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'projectedLabourExpense',
            type: 'currency',
            title: 'Projected Labour Expense',
            size: standardSize,
            permissions: (row) =>
                canViewStaffAllocations(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffPay(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'projectedChargeOut',
            type: 'currency',
            title: 'Projected Charge Out',
            size: standardSize,
            permissions: (row) =>
                canViewStaffAllocations(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffChargeOut(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'projectedProfit',
            type: 'currency',
            title: 'Projected Profit',
            size: standardSize,
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewRevenueTargets(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewStaffAllocations(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'projectedProfitMargin',
            type: 'percent',
            title: 'Projected Profit Margin',
            size: standardSize,
            aggregationFn: 'mean',
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewRevenueTargets(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewStaffAllocations(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'profitVariationProject',
            type: 'currency',
            title: 'Profit (Cost + Variation + Project)',
            size: standardSize,
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'profitMarginVariationProject',
            type: 'percent',
            title: 'Profit Margin (Cost + Variation + Project)',
            size: standardSize,
            aggregationFn: 'mean',
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'markupVariationProject',
            type: 'percent',
            title: 'Markup (Cost + Variation + Project)',
            size: standardSize,
            aggregationFn: 'mean',
            permissions: (row) =>
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'expenseVsRevenueVariationProject',
            type: 'progressBar',
            title: 'Expenses / Revenue (Cost + Variation + Project)',
            size: wideSize,
            aggregationFn: 'progressBar',
            formatter: (v) => Formatter.currency(v, { decimals: 0 }),
            permissions: (row) =>
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'revenueVsExpenseVariationProject',
            type: 'progressBar',
            title: 'Revenue / Expenses (Cost + Variation + Project)',
            size: wideSize,
            aggregationFn: 'progressBar',
            formatter: (v) => Formatter.currency(v, { decimals: 0 }),
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'profitVsRevenueVariationProject',
            type: 'progressBar',
            title: 'Profit / Revenue (Cost + Variation + Project)',
            size: wideSize,
            aggregationFn: 'progressBar',
            formatter: (v) => Formatter.currency(v, { decimals: 0 }),
            permissions: (row) =>
                canViewProjectInvoices(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'profitVsExpenseVariationProject',
            type: 'progressBar',
            title: 'Profit / Expenses (Cost + Variation + Project)',
            size: wideSize,
            aggregationFn: 'progressBar',
            formatter: (v) => Formatter.currency(v, { decimals: 0 }),
            permissions: (row) =>
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'serviceFee',
            type: 'currency',
            title: 'Service Fee',
            size: standardSize,
            permissions: (row) =>
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'serviceProfit',
            type: 'currency',
            title: 'Service Profit',
            size: standardSize,
            permissions: (row) =>
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
        generateColumnDef({
            key: 'serviceProfitMargin',
            type: 'percent',
            title: 'Service Profit Margin',
            size: standardSize,
            aggregationFn: 'mean',
            permissions: (row) =>
                canViewProjectFees(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectStaffCost(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ) &&
                canViewProjectExpenses(
                    SessionStore.user,
                    getCacheItemById('projects', row?.projectId) || row
                ),
        }),
    ]
}
