import { Link } from '@tanstack/react-router'
import { type ColumnDef } from '@tanstack/react-table'
import SessionStore from '@/State/SessionStore'
import {
    canViewCostCentres,
    canViewProjectInvoices,
    canViewRoles,
    canViewRoute,
    canViewStaffAllocations,
    canViewStaffChargeOutRate,
    canViewStaffCostRate,
    canViewStaffOvertimeRate,
    canViewStaffPayRate,
} from '@/State/Permissions/HasPermissions'
import { createGenerateColumnDef } from '@/version2/components/data-table/helpers/generate-column-def'

export function getColumns(
    onHideColumn: (column: string) => void
): ColumnDef<any>[] {
    const generateColumnDef = createGenerateColumnDef({
        onHideColumn,
    })
    const wideSize = 200

    return [
        generateColumnDef({
            key: 'name',
            type: 'text',
            title: 'Name',
            size: wideSize,
            cell: (row) => {
                const value = row.getValue('name');
                return <div><Link to={`/staff/${row.original.id}`}>{value}</Link></div>
            }
        }),
        generateColumnDef({
            key: 'staff',
            type: 'staff',
            title: 'Staff',
            size: wideSize,
        }),
        generateColumnDef({
            key: 'lastName',
            type: 'text',
            title: 'Last Name',
            size: wideSize,
        }),
        generateColumnDef({
            key: 'firstName',
            type: 'text',
            title: 'First Name',
            size: wideSize,
        }),
        generateColumnDef({
            key: 'email',
            type: 'text',
            title: 'Email',
            size: wideSize,
        }),
        generateColumnDef({
            key: 'role',
            type: 'role',
            title: 'Role',
            size: wideSize,
            permissions: () => canViewRoles(SessionStore.user),
        }),
        generateColumnDef({
            key: 'costCentre',
            type: 'costCentre',
            title: 'Cost Centre',
            size: wideSize,
            permissions: () => canViewCostCentres(SessionStore.user),
        }),
        generateColumnDef({
            key: 'permissionName',
            type: 'text',
            title: 'Permission Name',
            size: wideSize,
            permissions: () => canViewRoute(SessionStore.user, 'staff-permissions'),
        }),
        generateColumnDef({
            key: 'isArchived',
            type: 'boolean',
            title: 'Is Archived',
            size: wideSize,
        }),
        generateColumnDef({
            key: 'isActive',
            type: 'boolean',
            title: 'Is Active',
            size: wideSize,
        }),
        generateColumnDef({
            key: 'payRate',
            type: 'number',
            title: 'Pay Rate',
            size: wideSize,
            aggregationFn: 'sum',
            permissions: () => canViewStaffPayRate(SessionStore.user),
        }),
        generateColumnDef({
            key: 'overtimeRate',
            type: 'number',
            title: 'Overtime Rate',
            size: wideSize,
            aggregationFn: 'sum',
            permissions: () => canViewStaffOvertimeRate(SessionStore.user),
        }),
        generateColumnDef({
            key: 'costRate',
            type: 'number',
            title: 'Cost Rate',
            size: wideSize,
            aggregationFn: 'sum',
            permissions: () => canViewStaffCostRate(SessionStore.user),
        }),
        generateColumnDef({
            key: 'chargeOutRate',
            type: 'number',
            title: 'Charge Out Rate',
            size: wideSize,
            aggregationFn: 'sum',
            permissions: () => canViewStaffChargeOutRate(SessionStore.user),
        }),
        generateColumnDef({
            key: 'weeklyAvailability',
            type: 'number',
            title: 'Weekly Availability',
            size: wideSize,
            aggregationFn: 'sum',
        }),
        generateColumnDef({
            key: 'allocatedHours',
            type: 'number',
            title: 'Allocated Hours',
            size: wideSize,
            aggregationFn: 'sum',
            permissions: () => canViewStaffAllocations(SessionStore.user),
        }),
        generateColumnDef({
            key: 'availableHours',
            type: 'number',
            title: 'Available Hours',
            size: wideSize,
            aggregationFn: 'sum',
        }),
        generateColumnDef({
            key: 'recordedHours',
            type: 'number',
            title: 'Recorded Hours',
            size: wideSize,
            aggregationFn: 'sum'
        }),
        generateColumnDef({
            key: 'billableHours',
            type: 'number',
            title: 'Billable Hours',
            size: wideSize,
            aggregationFn: 'sum'
        }),
        generateColumnDef({
            key: 'nonBillableHours',
            type: 'number',
            title: 'Non Billable Hours',
            size: wideSize,
            aggregationFn: 'sum'
        }),
        generateColumnDef({
            key: 'percentBillableHours',
            type: 'percent',
            title: 'Percent Billable Hours',
            size: wideSize,
            aggregationFn: 'sum'
        }),
        generateColumnDef({
            key: 'overtimeHours',
            type: 'number',
            title: 'Overtime Hours',
            size: wideSize,
            aggregationFn: 'sum'
        }),
        generateColumnDef({
            key: 'invoicedHours',
            type: 'number',
            title: 'Invoiced Hours',
            size: wideSize,
            aggregationFn: 'sum'
        }),
        generateColumnDef({
            key: 'pay',
            type: 'currency',
            title: 'Pay',
            size: wideSize,
            aggregationFn: 'sum',
            permissions: () => canViewStaffPayRate(SessionStore.user),
        }),
        generateColumnDef({
            key: 'cost',
            type: 'currency',
            title: 'Cost',
            size: wideSize,
            aggregationFn: 'sum',
            permissions: () => canViewStaffCostRate(SessionStore.user),
        }),
        generateColumnDef({
            key: 'chargeOut',
            type: 'currency',
            title: 'Charge Out',
            size: wideSize,
            aggregationFn: 'sum',
            permissions: () => canViewStaffChargeOutRate(SessionStore.user),
        }),
        generateColumnDef({
            key: 'revenueEarned',
            type: 'currency',
            title: 'Revenue Earned',
            size: wideSize,
            aggregationFn: 'sum',
            permissions: () => canViewStaffChargeOutRate(SessionStore.user) && canViewProjectInvoices(SessionStore.user),
        }),
        generateColumnDef({
            key: 'revenueEarnedPerHour',
            type: 'currency',
            title: 'Revenue Earned Per Hour',
            size: wideSize,
            aggregationFn: 'sum',
            permissions: () => canViewStaffChargeOutRate(SessionStore.user) && canViewProjectInvoices(SessionStore.user),
        }),
        generateColumnDef({
            key: 'projects',
            type: 'project',
            title: 'Projects',
            size: wideSize,
        }),
    ]
}
