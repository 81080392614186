import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'
import { useMemo } from 'react'
import { z } from 'zod'

// Zod schemas
export const OperationalExpensesSchema = z.object({
    id: z.string(),
    name: z.string(),
    value: z.number(),
    startDate: z.date(),
    endDate: z.date(),
    hasRepeat: z.boolean(),
    repeatQuantity: z.number(),
    repeatUnit: z.string(),
    costCentreId: z.string().nullable().optional(),
    deletedAt: z.date().nullable().optional(),
})

export type OperationalExpenses = z.infer<typeof OperationalExpensesSchema>

// Initial state
const initialContext = {
    operationalExpenses: [] as OperationalExpenses[],
    isLoading: false,
    pendingChanges: false,
}

// Create the store
export const operationalExpensesStore = createStore({
    context: initialContext,
    on: {
        setOperationalExpenses: {
            operationalExpenses: (
                _,
                event: { operationalExpenses: OperationalExpenses[] }
            ) => event.operationalExpenses,
        },
        addOperationalExpenses: {
            operationalExpenses: (
                context,
                event: { operationalExpense: OperationalExpenses }
            ) => [...context.operationalExpenses, event.operationalExpense],
            pendingChanges: true,
        },
        updateOperationalExpenses: {
            operationalExpenses: (
                context,
                event: {
                    id: string
                    operationalExpense: Partial<OperationalExpenses>
                }
            ) => {
                const index = context.operationalExpenses.findIndex(
                    (e) => e.id === event.id
                )
                if (index === -1) return context.operationalExpenses

                const updatedExpenses = [...context.operationalExpenses]
                updatedExpenses[index] = {
                    ...updatedExpenses[index],
                    ...event.operationalExpense,
                }
                return updatedExpenses
            },
            pendingChanges: true,
        },
        deleteOperationalExpenses: {
            operationalExpenses: (context, event: { id: string }) => {
                const index = context.operationalExpenses.findIndex(
                    (e) => e.id === event.id
                )
                if (index === -1) return context.operationalExpenses

                const updatedExpenses = [...context.operationalExpenses]
                updatedExpenses[index] = {
                    ...updatedExpenses[index],
                    deletedAt: new Date(),
                }
                return updatedExpenses
            },
            pendingChanges: true,
        },
        setIsLoading: {
            isLoading: (_, event: { isLoading: boolean }) => event.isLoading,
        },
        setPendingChanges: {
            pendingChanges: (_, event: { pending: boolean }) => event.pending,
        },
        reset: {
            operationalExpenses: () => [],
            isLoading: false,
            pendingChanges: false,
        },
    },
})

// Action creators
export const setOperationalExpenses = (
    operationalExpenses: OperationalExpenses[]
) =>
    operationalExpensesStore.send({
        type: 'setOperationalExpenses',
        operationalExpenses,
    })

export const addOperationalExpenses = (
    operationalExpense: OperationalExpenses
) =>
    operationalExpensesStore.send({
        type: 'addOperationalExpenses',
        operationalExpense,
    })

export const updateOperationalExpenses = (
    id: string,
    operationalExpense: Partial<OperationalExpenses>
) =>
    operationalExpensesStore.send({
        type: 'updateOperationalExpenses',
        id,
        operationalExpense,
    })

export const deleteOperationalExpenses = (id: string) =>
    operationalExpensesStore.send({
        type: 'deleteOperationalExpenses',
        id,
    })

export const setIsLoading = (isLoading: boolean) =>
    operationalExpensesStore.send({
        type: 'setIsLoading',
        isLoading,
    })

export const setPendingChanges = (pending: boolean) =>
    operationalExpensesStore.send({
        type: 'setPendingChanges',
        pending,
    })

export const reset = () =>
    operationalExpensesStore.send({
        type: 'reset',
    })

// Selectors
export const useOperationalExpenses = () =>
    useSelector(
        operationalExpensesStore,
        (state) => state.context.operationalExpenses
    )

export const useActiveOperationalExpenses = () => {
    const expenses = useSelector(
        operationalExpensesStore,
        (state) => state.context.operationalExpenses
    )
    return useMemo(() => expenses.filter((e) => !e?.deletedAt), [expenses])
}

export const useIsLoading = () =>
    useSelector(operationalExpensesStore, (state) => state.context.isLoading)

export const usePendingChanges = () =>
    useSelector(
        operationalExpensesStore,
        (state) => state.context.pendingChanges
    )

// Getter for non-reactive access
export const getOperationalExpenses = () =>
    operationalExpensesStore.getSnapshot().context.operationalExpenses
