import { observer } from 'mobx-react'
import React, { useState, useMemo, useEffect } from 'react'
import Modal from '../../Components/Modal'
import {
    PhaseSelector,
    ProjectSelector,
    Selector,
} from '../../Components/Selector'
import RenderOnQueries from '../Layout/RenderOnQueries'
import ResourceScheduleStore from './ResourceScheduleStore'
import { StaffMultiselect } from '../../Components/MultiSelect'
import HeaderButton from '../../Components/ui/HeaderButton'
import StaffCollection from '../../State/Collections/StaffCollection'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import DateValue from '../../Components/Widgets/DateValue'
import Inputs from '../../Components/Inputs'
import { ProjectSidebarStore } from './ResourceScheduleProjectSidebar'
import { format } from 'date-fns'

export default observer(({ report, modalId }) => {
    const [staffs, setStaffs] = useState([])
    const [dateRange, setDateRange] = useState([new Date(), new Date()])
    const [allocationValue, setAllocationValue] = useState(0)
    const [allocationType, setAllocationType] = useState('hours')
    const projects = useMemo(() => {
        return ProjectCollection.projects.filter((p) => {
            const projectFilters = report.filters.projects || []
            const projectStatusFilters = report.filters.projectStatus || []
            const projectOwnerFilters = report.filters.projectOwners || []
            const costCentreFilters = report.filters.costCentres || []
            return (
                (!projectFilters.length || projectFilters.includes(p.id)) &&
                (!projectStatusFilters.length ||
                    projectStatusFilters.includes(p.status)) &&
                (!projectOwnerFilters.length ||
                    projectOwnerFilters.includes(p.ownerId)) &&
                (!costCentreFilters.length ||
                    costCentreFilters.includes(p.costCentreId))
            )
        })
    }, [report.filters])
    const [project, setProject] = useState(projects[0])
    const [phase, setPhase] = useState(null)
    return (
        <Modal
            modalId={modalId}
            heading="Add Resource Allocations"
            onSave={() => {
                ResourceScheduleStore.addStaffs(
                    staffs,
                    project,
                    phase,
                    dateRange,
                    allocationValue,
                    allocationType,
                    (period, newHours, projectId, phaseId, roleId, staffId) => {
                        ProjectSidebarStore.setHoursInPeriod(
                            period,
                            projectId,
                            phaseId || null,
                            roleId || null,
                            staffId || null,
                            newHours,
                            ResourceScheduleStore.selectedPhaseIds,
                            ResourceScheduleStore.selectedStaffIds,
                            ResourceScheduleStore.periodType
                        )
                    }
                )
            }}
            saveLabel="Add Allocations"
            canSave={() => !!staffs.length && !!project && !!phase}
            style={{ minHeight: '44em' }}
        >
            <div style={{ position: 'absolute', width: '44em' }}>
                <div className="flex items-center gap-2 mb-4">
                    <StaffMultiselect
                        className="flex-auto"
                        selectedStaffMembers={staffs}
                        onChange={(s) => setStaffs(s)}
                    />
                    <HeaderButton
                        label="+ Add All Active Staff"
                        variant="secondary"
                        className="flex-none w-44"
                        onClick={() => {
                            setStaffs(StaffCollection.activeStaff)
                        }}
                    />
                </div>
                <ProjectSelector
                    className="mb-4"
                    selectedProject={project}
                    projectOptions={projects}
                    onChange={(pr) => setProject(pr)}
                    variant="secondary"
                />
                {project && (
                    <RenderOnQueries
                        queryIds={[
                            project
                                ? {
                                      id: `phases-${project.id}`,
                                      collection: 'phases',
                                      fields: [
                                          'jobNumber',
                                          'name',
                                          'projectId',
                                          'status',
                                          'startDate',
                                          'endDate',
                                          'isRootPhase',
                                          'position',
                                      ],
                                      filters: [`projectId == "${project.id}"`],
                                  }
                                : null,
                        ].filter((v) => v)}
                    >
                        <PhaseSelection
                            project={project}
                            phase={phase}
                            setPhase={setPhase}
                        />
                    </RenderOnQueries>
                )}
                <div className="flex items-center gap-2 mb-4">
                    <div>
                        From:{' '}
                        <DateValue
                            value={dateRange[0]}
                            onChange={(d) => setDateRange([d, dateRange[1]])}
                        />
                    </div>
                    <div>
                        To:{' '}
                        <DateValue
                            value={dateRange[1]}
                            onChange={(d) => setDateRange([dateRange[0], d])}
                        />
                    </div>
                </div>
                <div className="flex items-center gap-2 mb-4">
                    {allocationType === 'hours' ? (
                        <Inputs.number
                            className="input-base"
                            value={allocationValue}
                            onChange={setAllocationValue}
                            variant="secondary"
                        />
                    ) : (
                        <Inputs.percent
                            className="input-base"
                            value={allocationValue}
                            onChange={setAllocationValue}
                            variant="secondary"
                        />
                    )}
                    <Selector
                        selectedOption={allocationType}
                        onChange={setAllocationType}
                        options={[
                            { label: 'Hours per Period', value: 'hours' },
                            { label: 'Utilisation', value: 'utilisation' },
                        ]}
                        optionLabel={(o) => o.label}
                        variant="secondary"
                        className="w-40"
                    />
                </div>
            </div>
        </Modal>
    )
})

const PhaseSelection = ({ project, phase, setPhase }) => {
    useEffect(() => {
        setPhase(
            project.phases.filter((p) => p.isRootPhase)[0] ||
                project.phases.filter((p) => p.status === 'active')[0]
        )
    }, [project])
    return (
        <PhaseSelector
            className="mb-4"
            selectedPhase={phase}
            phaseOptions={project.phases.filter((p) => p.status !== 'archived')}
            onChange={setPhase}
            variant="secondary"
        />
    )
}
