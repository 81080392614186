import { Column, ColumnDef, Row } from '@tanstack/react-table'
import Formatter from '@/Components/Formatter'
import { FormatDate } from '@/Utils/Localisation/DateFormatter'
import { FormatTime } from '@/Utils/Localisation/TimeFormatter'

export interface SearchParams {
    [key: string]: string | string[] | undefined
}

export interface Option {
    label: string
    value: string
    icon?: React.ComponentType<{ className?: string }>
    withCount?: boolean
}

export interface DataTableFilterField<TData> {
    label: string
    value: keyof TData
    placeholder?: string
    options?: Option[]
    isDefaultFilter?: boolean
    typeFilter?: string
    groupBy?: (item: TData) => string
    sortGroups?: (a: any, b: any) => number
}

export interface DataTableFilterOption<TData> {
    id: string
    label: string
    value: keyof TData
    options: Option[]
    filterValues?: string[] | number[] | '' | [] | undefined
    filterOperator?: string
    isMulti?: boolean
    typeFilter?: string
    groupBy?: (item: TData) => string
    sortGroups?: (a: any, b: any) => number
    isApplyToPhases: boolean
}

export interface Filter {
    column: string
    operator: string
    value?: any
    group?: string
}

interface SortBy {
    column: string
    order: 'asc' | 'desc'
}

interface Options {
    dateRange: string
}

export interface ExportColumn {
    value: string
    label: string
}

export interface TableState {
    columns: string[]
    filters: Filter[]
    dateRange: [string, string]
    sortBy?: Array<{
        column: string
        order: 'asc' | 'desc'
    }>
    groupBy?: string[]
}

export interface OrganisationReport {
    oldId?: string
    id: string
    history?: any[]
    organisationId: string
    name: string
    data: Record<string, string>
    type: string
    columns: string[]
    filters?: Filter[]
    groupBy?: string[]
    sortBy?: SortBy[]
    options: Options
    dateRange: [string, string]
    invoiceDateType: string
}

export interface Fraction {
    numerator: number
    denominator: number
}

export const typeFilterMap: { [key: string]: string } = {
    text: 'text',
    progressBar: 'percent',
    percent: 'percent',
    date: 'date',
    number: 'number',
    project: 'select',
    costCentre: 'select',
    contact: 'select',
    staffMember: 'select',
    status: 'select',
    staffs: 'select',
    role: 'select',
    boolean: 'boolean',
    currency: 'currency',
    time: 'time',
}

export const sortingTextFn = (rowA, rowB, columnId): any => {
    const valueA = rowA.getValue(columnId)
    const valueB = rowB.getValue(columnId)
    const isNumberA = /^\d/.test(valueA)
    const isNumberB = /^\d/.test(valueB)

    if (isNumberA && !isNumberB) return -1
    if (!isNumberA && isNumberB) return 1
    if (!isNumberA && !isNumberB) return 0

    return valueA.localeCompare(valueB)
}

export const sortingProgressFn = (rowA: any, rowB: any, columnId): any => {
    const valueA = rowA.getValue(columnId)
    const valueB = rowB.getValue(columnId)

    const ratioA = valueA.numerator / valueA.denominator
    const ratioB = valueB.numerator / valueB.denominator

    const isUndefinedA = isNaN(ratioA) || valueA.denominator === 0
    const isUndefinedB = isNaN(ratioB) || valueB.denominator === 0

    if (isUndefinedA && !isUndefinedB) return 1
    if (!isUndefinedA && isUndefinedB) return -1
    if (isUndefinedA && isUndefinedB) return 0

    return ratioA - ratioB
}

export const sortingDateFn = (rowA, rowB, columnId) => {
    const dateA = rowA.getValue(columnId)
    const dateB = rowB.getValue(columnId)

    if (!dateA) return 1
    if (!dateB) return -1

    const timeA = new Date(dateA as Date).getTime()
    const timeB = new Date(dateB as Date).getTime()

    return timeA - timeB
}

export const csvFormatter = (
    data: Row<TData>,
    column: Column<TData, unknown>
): string => {
    const value = data.getValue(column?.id)
    switch (column.columnDef.meta?.type) {
        case 'progress':
        case 'progressBar':
            const { numerator, denominator } = value as Fraction
            if (numerator || denominator) {
                return `${Formatter.progress(
                    numerator || 0
                )} / ${Formatter.progress(denominator || 0)}`
            } else {
                return ''
            }
        case 'date':
            return data
                ? FormatDate(new Date(value as string), {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                  })
                : ''
        case 'staffMember':
        case 'project':
            return value as string
        case 'time':
            return data ? FormatTime(new Date(value as string)) : ''
        default:
            return Formatter[column.columnDef.meta?.type](value) ?? value
    }
}

export interface SetColumnsOptions {
    needsRefetch?: boolean
}

export interface SetOrganisationReportOptions {
    needsRefetch?: boolean
}
