import { Button } from './ui/button'
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover'
import { Calendar } from './ui/calendar'
import { DateInput } from './ui/date-input'
import { Label } from './ui/label'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from './ui/select'
import { Switch } from './ui/switch'
import {
    ChevronUpIcon,
    ChevronDownIcon,
    CheckIcon,
    CaretSortIcon,
    CalendarIcon,
} from '@radix-ui/react-icons'
import { cn } from '@/lib/utils'
import { FC, useEffect, useRef, useState } from 'react'
import {
    startOfDay,
    endOfDay,
    startOfWeek,
    endOfWeek,
    startOfMonth,
    endOfMonth,
    startOfQuarter,
    endOfQuarter,
    startOfYear,
    endOfYear,
    sub,
    add,
    getWeek,
    isSameDay,
} from 'date-fns'

export interface DateRangePickerProps {
    /** Click handler for applying the updates from DateRangePicker. */
    onUpdate?: (values: { range: DateRange; rangeCompare?: DateRange }) => void
    /** Initial value for start date */
    initialDateFrom?: Date | string
    /** Initial value for end date */
    initialDateTo?: Date | string
    /** Initial value for start date for compare */
    initialCompareFrom?: Date | string
    /** Initial value for end date for compare */
    initialCompareTo?: Date | string
    /** Alignment of popover */
    align?: 'start' | 'center' | 'end'
    /** Option for locale */
    locale?: string
    /** Option for showing compare feature */
    showCompare?: boolean
}

const formatDate = (date: Date, locale: string = 'en-us'): string => {
    return date.toLocaleDateString(locale, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    })
}

const getDateAdjustedForTimezone = (dateInput: Date | string): Date => {
    if (typeof dateInput === 'string') {
        // Split the date string to get year, month, and day parts
        const parts = dateInput.split('-').map((part) => parseInt(part, 10))
        // Create a new Date object using the local timezone
        // Note: Month is 0-indexed, so subtract 1 from the month part
        const date = new Date(parts[0], parts[1] - 1, parts[2])
        return date
    } else {
        // If dateInput is already a Date object, return it directly
        return dateInput
    }
}

interface DateRange {
    from: Date
    to: Date | undefined
}

interface Preset {
    name: string
    label: string
}

// Define presets
const PRESETS: Preset[] = [
    { name: 'allTime', label: 'All Time' },
    { name: 'today', label: 'Today' },
    { name: 'yesterday', label: 'Yesterday' },
    { name: 'thisWeek', label: 'This Week' },
    { name: 'thisFortnightA', label: 'This Fortnight A' },
    { name: 'thisFortnightB', label: 'This Fortnight B' },
    { name: 'thisMonth', label: 'This Month' },
    { name: 'thisQuarter', label: 'This Quarter' },
    { name: 'thisYear', label: 'This Year' },
    { name: 'lastWeek', label: 'Last Week' },
    { name: 'lastFortnightA', label: 'Last Fortnight A' },
    { name: 'lastFortnightB', label: 'Last Fortnight B' },
    { name: 'lastMonth', label: 'Last Month' },
    { name: 'lastQuarter', label: 'Last Quarter' },
    { name: 'lastYear', label: 'Last Year' },
    { name: 'tomorrow', label: 'Tomorrow' },
    { name: 'inLastWeek', label: 'Last 7 Days' },
    { name: 'inLastFortnight', label: 'Last 14 Days' },
    { name: 'inLastMonth', label: 'Last 30 Days' },
    { name: 'inLastQuarter', label: 'Last 90 Days' },
    { name: 'inLastYear', label: 'Last 365 Days' },
    { name: 'nextWeek', label: 'Next Week' },
    { name: 'nextFortnightA', label: 'Next Fortnight A' },
    { name: 'nextFortnightB', label: 'Next Fortnight B' },
    { name: 'nextMonth', label: 'Next Month' },
    { name: 'nextQuarter', label: 'Next Quarter' },
    { name: 'nextYear', label: 'Next Year' },
    { name: 'inNextWeek', label: 'Next 7 Days' },
    { name: 'inNextFortnight', label: 'Next 14 Days' },
    { name: 'inNextMonth', label: 'Next 30 Days' },
    { name: 'inNextQuarter', label: 'Next 90 Days' },
    { name: 'inNextYear', label: 'Next 365 Days' },
]

/** The DateRangePicker component allows a user to select a range of dates */
export const DateRangePicker: FC<DateRangePickerProps> = ({
    initialDateFrom = startOfDay(new Date()),
    initialDateTo = endOfDay(new Date()),
    initialCompareFrom,
    initialCompareTo,
    onUpdate,
    align = 'end',
    locale = 'en-US',
    showCompare = true,
}): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false)

    const [range, setRange] = useState<DateRange>({
        from: getDateAdjustedForTimezone(initialDateFrom),
        to: initialDateTo
            ? getDateAdjustedForTimezone(initialDateTo)
            : getDateAdjustedForTimezone(initialDateFrom),
    })
    const [rangeCompare, setRangeCompare] = useState<DateRange | undefined>(
        initialCompareFrom
            ? {
                  from: new Date(
                      new Date(initialCompareFrom).setHours(0, 0, 0, 0)
                  ),
                  to: initialCompareTo
                      ? new Date(
                            new Date(initialCompareTo).setHours(0, 0, 0, 0)
                        )
                      : new Date(
                            new Date(initialCompareFrom).setHours(0, 0, 0, 0)
                        ),
              }
            : undefined
    )

    // Refs to store the values of range and rangeCompare when the date picker is opened
    const openedRangeRef = useRef<DateRange | undefined>()
    const openedRangeCompareRef = useRef<DateRange | undefined>()

    const [selectedPreset, setSelectedPreset] = useState<string | undefined>(
        undefined
    )

    const [isSmallScreen, setIsSmallScreen] = useState(
        typeof window !== 'undefined' ? window.innerWidth < 960 : false
    )

    useEffect(() => {
        const handleResize = (): void => {
            setIsSmallScreen(window.innerWidth < 960)
        }

        window.addEventListener('resize', handleResize)

        // Clean up event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const getPresetRange = (presetName: string): DateRange => {
        const preset = PRESETS.find(({ name }) => name === presetName)
        if (!preset) throw new Error(`Unknown date range preset: ${presetName}`)

        const now = new Date()
        let from = new Date()
        let to = new Date()
        switch (preset.name) {
            case 'allTime':
                ;[from, to] = [
                    sub(now, { years: 50 }),
                    // add(now, { years: 50 }),
                    now,
                ].map((d) => new Date(d))
                break
            case 'today':
                ;[from, to] = [startOfDay(now), endOfDay(now)]
                break
            case 'yesterday':
                ;[from, to] = [
                    startOfDay(sub(now, { days: 1 })),
                    endOfDay(sub(now, { days: 1 })),
                ]
                break
            case 'thisWeek':
                ;[from, to] = [
                    startOfWeek(now, { weekStartsOn: 1 }),
                    endOfWeek(now, { weekStartsOn: 1 }),
                ]
                break
            case 'inLastFortnight':
                ;[from, to] = [sub(now, { days: 13 }), now]
                break
            case 'thisFortnightA':
            case 'thisFortnightB':
                const currentWeek = getWeek(now)
                const isEvenWeek = currentWeek % 2 === 0
                const fortnightType =
                    preset.name === 'thisFortnightA' ? 'a' : 'b'
                if (
                    (fortnightType === 'a' && isEvenWeek) ||
                    (fortnightType === 'b' && !isEvenWeek)
                ) {
                    ;[from, to] = [
                        startOfWeek(now, { weekStartsOn: 1 }),
                        endOfWeek(add(now, { weeks: 1 }), { weekStartsOn: 1 }),
                    ]
                } else {
                    ;[from, to] = [
                        startOfWeek(sub(now, { weeks: 1 }), {
                            weekStartsOn: 1,
                        }),
                        endOfWeek(now, { weekStartsOn: 1 }),
                    ]
                }
                break
            case 'thisMonth':
                ;[from, to] = [startOfMonth(now), endOfMonth(now)]
                break
            case 'thisQuarter':
                ;[from, to] = [startOfQuarter(now), endOfQuarter(now)]
                break
            case 'thisYear':
                ;[from, to] = [startOfYear(now), endOfYear(now)]
                break
            case 'lastWeek':
                ;[from, to] = [
                    startOfWeek(sub(now, { weeks: 1 }), { weekStartsOn: 1 }),
                    endOfWeek(sub(now, { weeks: 1 }), { weekStartsOn: 1 }),
                ]
                break
            case 'lastFortnightA':
            case 'lastFortnightB':
                const fortnightTypeLastFortnight =
                    preset.name === 'lastFortnightA' ? 'a' : 'b'
                if (
                    getWeek(sub(now, { weeks: 2 })) %
                    (fortnightTypeLastFortnight === 'a' ? 2 : 1)
                ) {
                    ;[from, to] = [
                        startOfWeek(sub(now, { weeks: 3 }), {
                            weekStartsOn: 1,
                        }),
                        endOfWeek(sub(now, { weeks: 2 }), { weekStartsOn: 1 }),
                    ]
                } else {
                    ;[from, to] = [
                        startOfWeek(sub(now, { weeks: 2 }), {
                            weekStartsOn: 1,
                        }),
                        endOfWeek(sub(now, { weeks: 1 }), { weekStartsOn: 1 }),
                    ]
                }
                break
            case 'lastMonth':
                ;[from, to] = [
                    startOfMonth(sub(now, { months: 1 })),
                    endOfMonth(sub(now, { months: 1 })),
                ]
                break
            case 'lastQuarter':
                ;[from, to] = [
                    startOfQuarter(sub(now, { months: 3 })),
                    endOfQuarter(sub(now, { months: 3 })),
                ]
                break
            case 'lastYear':
                ;[from, to] = [
                    startOfYear(sub(now, { years: 1 })),
                    endOfYear(sub(now, { years: 1 })),
                ]
                break
            case 'tomorrow':
                ;[from, to] = [
                    startOfDay(add(now, { days: 1 })),
                    endOfDay(add(now, { days: 1 })),
                ]
                break
            case 'inLastWeek':
                ;[from, to] = [sub(now, { weeks: 1 }), now]
                break
            case 'inLastMonth':
                ;[from, to] = [sub(now, { months: 1 }), now]
                break
            case 'inLastQuarter':
                ;[from, to] = [sub(now, { months: 3 }), now]
                break
            case 'inLastYear':
                ;[from, to] = [sub(now, { years: 1 }), now]
                break
            case 'nextWeek':
                ;[from, to] = [
                    startOfWeek(add(now, { weeks: 1 }), { weekStartsOn: 1 }),
                    endOfWeek(add(now, { weeks: 1 }), { weekStartsOn: 1 }),
                ]
                break
            case 'nextFortnightA':
            case 'nextFortnightB':
                const fortnightTypeNextFortnight =
                    preset.name === 'nextFortnightA' ? 'a' : 'b'
                if (
                    getWeek(add(now, { weeks: 2 })) %
                    (fortnightTypeNextFortnight === 'a' ? 2 : 1)
                ) {
                    ;[from, to] = [
                        startOfWeek(add(now, { weeks: 1 }), {
                            weekStartsOn: 1,
                        }),
                        endOfWeek(add(now, { weeks: 2 }), { weekStartsOn: 1 }),
                    ]
                } else {
                    ;[from, to] = [
                        startOfWeek(add(now, { weeks: 2 }), {
                            weekStartsOn: 1,
                        }),
                        endOfWeek(add(now, { weeks: 3 }), { weekStartsOn: 1 }),
                    ]
                }
                break
            case 'nextMonth':
                ;[from, to] = [
                    startOfMonth(add(now, { months: 1 })),
                    endOfMonth(add(now, { months: 1 })),
                ]
                break
            case 'nextQuarter':
                ;[from, to] = [
                    startOfQuarter(add(now, { months: 3 })),
                    endOfQuarter(add(now, { months: 3 })),
                ]
                break
            case 'nextYear':
                ;[from, to] = [
                    startOfYear(add(now, { years: 1 })),
                    endOfYear(add(now, { years: 1 })),
                ]
                break
            case 'inNextWeek':
                ;[from, to] = [now, add(now, { weeks: 1 })]
                break
            case 'inNextFortnight':
                //{ name: 'inNextFortnight', label: 'Next 14 Days' },
                break
            case 'inNextMonth':
                ;[from, to] = [now, add(now, { months: 1 })]
                break
            case 'inNextQuarter':
                ;[from, to] = [now, add(now, { months: 3 })]
                break
            case 'inNextYear':
                ;[from, to] = [now, add(now, { years: 1 })]
                break
        }

        return { from, to }
    }

    const setPreset = (preset: string): void => {
        const range = getPresetRange(preset)
        setRange(range)
        if (rangeCompare) {
            const rangeCompare = {
                from: new Date(
                    range.from.getFullYear() - 1,
                    range.from.getMonth(),
                    range.from.getDate()
                ),
                to: range.to
                    ? new Date(
                          range.to.getFullYear() - 1,
                          range.to.getMonth(),
                          range.to.getDate()
                      )
                    : undefined,
            }
            setRangeCompare(rangeCompare)
        }
    }

    const checkPreset = (): void => {
        for (const preset of PRESETS) {
            const presetRange = getPresetRange(preset.name)

            const normalizedRangeFrom = new Date(range.from)
            normalizedRangeFrom.setHours(0, 0, 0, 0)
            const normalizedPresetFrom = new Date(
                presetRange.from.setHours(0, 0, 0, 0)
            )

            const normalizedRangeTo = new Date(range.to ?? 0)
            normalizedRangeTo.setHours(0, 0, 0, 0)
            const normalizedPresetTo = new Date(
                presetRange.to?.setHours(0, 0, 0, 0) ?? 0
            )

            if (
                normalizedRangeFrom.getTime() ===
                    normalizedPresetFrom.getTime() &&
                normalizedRangeTo.getTime() === normalizedPresetTo.getTime()
            ) {
                setSelectedPreset(preset.name)
                return
            }
        }

        setSelectedPreset(undefined)
    }

    const resetValues = (): void => {
        setRange({
            from:
                typeof initialDateFrom === 'string'
                    ? getDateAdjustedForTimezone(initialDateFrom)
                    : initialDateFrom,
            to: initialDateTo
                ? typeof initialDateTo === 'string'
                    ? getDateAdjustedForTimezone(initialDateTo)
                    : initialDateTo
                : typeof initialDateFrom === 'string'
                  ? getDateAdjustedForTimezone(initialDateFrom)
                  : initialDateFrom,
        })
        setRangeCompare(
            initialCompareFrom
                ? {
                      from:
                          typeof initialCompareFrom === 'string'
                              ? getDateAdjustedForTimezone(initialCompareFrom)
                              : initialCompareFrom,
                      to: initialCompareTo
                          ? typeof initialCompareTo === 'string'
                              ? getDateAdjustedForTimezone(initialCompareTo)
                              : initialCompareTo
                          : typeof initialCompareFrom === 'string'
                            ? getDateAdjustedForTimezone(initialCompareFrom)
                            : initialCompareFrom,
                  }
                : undefined
        )
    }

    useEffect(() => {
        checkPreset()
    }, [range])

    const PresetButton = ({
        preset,
        label,
        isSelected,
    }: {
        preset: string
        label: string
        isSelected: boolean
    }): JSX.Element => (
        <Button
            className={cn(isSelected && 'pointer-events-none')}
            variant="ghost"
            onClick={() => {
                setPreset(preset)
            }}
        >
            <>
                <span
                    className={cn('pr-2 opacity-0', isSelected && 'opacity-70')}
                >
                    <CheckIcon width={18} height={18} />
                </span>
                {label}
            </>
        </Button>
    )

    // Helper function to check if two date ranges are equal
    const areRangesEqual = (a?: DateRange, b?: DateRange): boolean => {
        if (!a || !b) return a === b // If either is undefined, return true if both are undefined
        return (
            a.from.getTime() === b.from.getTime() &&
            (!a.to || !b.to || a.to.getTime() === b.to.getTime())
        )
    }

    useEffect(() => {
        if (isOpen) {
            openedRangeRef.current = range
            openedRangeCompareRef.current = rangeCompare
        }
    }, [isOpen])

    return (
        <Popover
            modal={true}
            open={isOpen}
            onOpenChange={(open: boolean) => {
                if (!open) {
                    resetValues()
                }
                setIsOpen(open)
            }}
        >
            <PopoverTrigger asChild>
                <Button
                    size={'sm'}
                    variant="outline"
                    className="w-full justify-between"
                >
                    <div className="text-right">
                        <div className="py-1">
                            <div>
                                {selectedPreset
                                    ? `${PRESETS.find((p) => p.name === selectedPreset)?.label}: `
                                    : ''}
                                {`${formatDate(range.from, locale)}${range.to != null && !isSameDay(range.from, range.to) ? ' - ' + formatDate(range.to, locale) : ''}`}
                            </div>
                        </div>
                        {rangeCompare != null && (
                            <div className="opacity-60 text-xs -mt-1">
                                <>
                                    vs. {formatDate(rangeCompare.from, locale)}
                                    {rangeCompare.to != null
                                        ? ` - ${formatDate(rangeCompare.to, locale)}`
                                        : ''}
                                </>
                            </div>
                        )}
                    </div>
                    <div className="pl-1 opacity-60 -mr-2 scale-125">
                        {isOpen ? (
                            <ChevronUpIcon width={24} />
                        ) : (
                            <ChevronDownIcon width={24} />
                        )}
                    </div>
                </Button>
            </PopoverTrigger>
            <PopoverContent align={align} className="w-auto">
                <div className="flex py-2">
                    <div className="flex">
                        <div className="flex flex-col">
                            <div className="flex flex-col lg:flex-row gap-2 px-3 justify-end items-center lg:items-start pb-4 lg:pb-0">
                                {showCompare && (
                                    <div className="flex items-center space-x-2 pr-4 py-1">
                                        <Switch
                                            defaultChecked={Boolean(
                                                rangeCompare
                                            )}
                                            onCheckedChange={(
                                                checked: boolean
                                            ) => {
                                                if (checked) {
                                                    if (!range.to) {
                                                        setRange({
                                                            from: range.from,
                                                            to: range.from,
                                                        })
                                                    }
                                                    setRangeCompare({
                                                        from: new Date(
                                                            range.from.getFullYear(),
                                                            range.from.getMonth(),
                                                            range.from.getDate() -
                                                                365
                                                        ),
                                                        to: range.to
                                                            ? new Date(
                                                                  range.to.getFullYear() -
                                                                      1,
                                                                  range.to.getMonth(),
                                                                  range.to.getDate()
                                                              )
                                                            : new Date(
                                                                  range.from.getFullYear() -
                                                                      1,
                                                                  range.from.getMonth(),
                                                                  range.from.getDate()
                                                              ),
                                                    })
                                                } else {
                                                    setRangeCompare(undefined)
                                                }
                                            }}
                                            id="compare-mode"
                                        />
                                        <Label htmlFor="compare-mode">
                                            Compare
                                        </Label>
                                    </div>
                                )}
                                <div className="flex flex-col gap-2">
                                    <div className="flex gap-2">
                                        <DateInput
                                            value={range.from}
                                            onChange={(date) => {
                                                const toDate =
                                                    range.to == null ||
                                                    date > range.to
                                                        ? date
                                                        : range.to
                                                setRange((prevRange) => ({
                                                    ...prevRange,
                                                    from: date,
                                                    to: toDate,
                                                }))
                                            }}
                                        />
                                        <div className="py-1">-</div>
                                        <DateInput
                                            value={range.to}
                                            onChange={(date) => {
                                                const fromDate =
                                                    date < range.from
                                                        ? date
                                                        : range.from
                                                setRange((prevRange) => ({
                                                    ...prevRange,
                                                    from: fromDate,
                                                    to: date,
                                                }))
                                            }}
                                        />
                                    </div>
                                    {rangeCompare != null && (
                                        <div className="flex gap-2">
                                            <DateInput
                                                value={rangeCompare?.from}
                                                onChange={(date) => {
                                                    if (rangeCompare) {
                                                        const compareToDate =
                                                            rangeCompare.to ==
                                                                null ||
                                                            date >
                                                                rangeCompare.to
                                                                ? date
                                                                : rangeCompare.to
                                                        setRangeCompare(
                                                            (
                                                                prevRangeCompare
                                                            ) => ({
                                                                ...prevRangeCompare,
                                                                from: date,
                                                                to: compareToDate,
                                                            })
                                                        )
                                                    } else {
                                                        setRangeCompare({
                                                            from: date,
                                                            to: new Date(),
                                                        })
                                                    }
                                                }}
                                            />
                                            <div className="py-1">-</div>
                                            <DateInput
                                                value={rangeCompare?.to}
                                                onChange={(date) => {
                                                    if (
                                                        rangeCompare &&
                                                        rangeCompare.from
                                                    ) {
                                                        const compareFromDate =
                                                            date <
                                                            rangeCompare.from
                                                                ? date
                                                                : rangeCompare.from
                                                        setRangeCompare({
                                                            ...rangeCompare,
                                                            from: compareFromDate,
                                                            to: date,
                                                        })
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {isSmallScreen && (
                                <Select
                                    defaultValue={selectedPreset}
                                    onValueChange={(value) => {
                                        setPreset(value)
                                    }}
                                >
                                    <SelectTrigger className="w-[180px] mx-auto mb-2">
                                        <SelectValue placeholder="Select..." />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {PRESETS.map((preset) => (
                                            <SelectItem
                                                key={preset.name}
                                                value={preset.name}
                                            >
                                                {preset.label}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            )}
                            <div>
                                <Calendar
                                    mode="range"
                                    onSelect={(
                                        value:
                                            | { from?: Date; to?: Date }
                                            | undefined
                                    ) => {
                                        if (value?.from != null) {
                                            setRange({
                                                from: value.from,
                                                to: value?.to,
                                            })
                                        }
                                    }}
                                    selected={range}
                                    numberOfMonths={isSmallScreen ? 1 : 2}
                                    defaultMonth={
                                        new Date(
                                            new Date().setMonth(
                                                new Date().getMonth() -
                                                    (isSmallScreen ? 0 : 1)
                                            )
                                        )
                                    }
                                />
                            </div>
                            <div className="flex justify-end gap-2 py-2 pr-4">
                                <Button
                                    onClick={() => {
                                        setIsOpen(false)
                                        resetValues()
                                    }}
                                    variant="ghost"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => {
                                        setIsOpen(false)
                                        if (
                                            !areRangesEqual(
                                                range,
                                                openedRangeRef.current
                                            ) ||
                                            !areRangesEqual(
                                                rangeCompare,
                                                openedRangeCompareRef.current
                                            )
                                        ) {
                                            onUpdate?.({ range, rangeCompare })
                                        }
                                    }}
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                    </div>
                    {!isSmallScreen && (
                        <div className="h-96 overflow-y-auto">
                            <div className="flex flex-col items-end gap-1 pr-2 pl-6 pb-6">
                                <div className="flex w-full flex-col items-end gap-1 pr-2 pl-6 pb-6">
                                    {PRESETS.map((preset) => (
                                        <PresetButton
                                            key={preset.name}
                                            preset={preset.name}
                                            label={preset.label}
                                            isSelected={
                                                selectedPreset === preset.name
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </PopoverContent>
        </Popover>
    )
}
