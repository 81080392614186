import {
    useState,
    useCallback,
    useRef,
    useEffect,
    useMemo,
    Fragment,
} from 'react'
import { Input } from '@2/components/ui/input'
import { Search } from 'lucide-react'
import {
    Command,
    CommandInput,
    CommandList,
    CommandEmpty,
    CommandItem,
} from '@2/components/ui/command'
import { getFilteredCacheItems, useFilteredCacheItems } from '@2/cache'
import { getLabel } from '@2/utils/get-label'
import Fuse from 'fuse.js'
import { Link } from '@tanstack/react-router'

const generateSubstrings = (str) => {
    const substrings = new Set()
    for (let i = 0; i < str.length - 2; i++) {
        for (let j = i + 3; j <= str.length; j++) {
            substrings.add(str.slice(i, j).toLowerCase())
        }
    }
    return Array.from(substrings)
}

const HighlightExactMatch = ({
    text,
    searchTerm,
}: {
    text: string
    searchTerm: string
}) => {
    if (!searchTerm || !searchTerm.trim()) return <>{text}</>

    const searchSubstrings = useMemo(
        () => generateSubstrings(searchTerm),
        [searchTerm]
    )

    // Split the text into words, preserving spaces
    const words = text.split(/(\s+)/).map((part, index) => ({ part, index }))

    // Find matches
    let highlightIndices = []
    searchSubstrings.forEach((substring) => {
        let startIndex = 0
        while (true) {
            const matchIndex = text.toLowerCase().indexOf(substring, startIndex)
            if (matchIndex === -1) break
            highlightIndices.push([matchIndex, matchIndex + substring.length])
            startIndex = matchIndex + 1
        }
    })

    // Merge overlapping highlights
    highlightIndices.sort((a, b) => a[0] - b[0])
    const mergedIndices = highlightIndices.reduce((acc, curr) => {
        if (acc.length === 0 || curr[0] > acc[acc.length - 1][1]) {
            acc.push(curr)
        } else {
            acc[acc.length - 1][1] = Math.max(acc[acc.length - 1][1], curr[1])
        }
        return acc
    }, [])

    // Apply highlights
    let highlightedPart = []
    let currentIndex = 0
    words.forEach(({ part, index }) => {
        const partStart = text.indexOf(part, currentIndex)
        const partEnd = partStart + part.length
        let highlightedWord = []
        let lastIndex = 0

        mergedIndices.forEach(([start, end]) => {
            if (end <= partStart || start >= partEnd) return
            const hlStart = Math.max(start, partStart) - partStart
            const hlEnd = Math.min(end, partEnd) - partStart
            if (hlStart > lastIndex) {
                highlightedWord.push(part.slice(lastIndex, hlStart))
            }
            highlightedWord.push(
                <strong key={`hl-${index}-${hlStart}`}>
                    {part.slice(hlStart, hlEnd)}
                </strong>
            )
            lastIndex = hlEnd
        })

        if (lastIndex < part.length) {
            highlightedWord.push(part.slice(lastIndex))
        }

        highlightedPart.push(
            <Fragment key={index}>
                {highlightedWord.length > 0 ? highlightedWord : part}
            </Fragment>
        )
        currentIndex = partEnd
    })

    return <>{highlightedPart}</>
}

const getUrl = {
    projects: (item: any) => `/projects/${item.id}`,
    contacts: (item: any) => `/contacts/${item.id}`,
    invoices: (item: any) => `/invoices/${item.id}`,
    organisations: (item: any) => `/organisations/${item.id}`,
    suppliers: (item: any) => `/suppliers/${item.id}`,
    roles: (item: any) => `/staff-roles/${item.id}`,
    costCentres: (item: any) => `/cost-centres/${item.id}`,
    organisationReports: (item: any) => {
        switch (item.type) {
            case 'project':
                return `/projects?report=${item.id}`
            case 'staff':
                return `/staff?report=${item.id}`
            case 'invoice':
                return `/invoices?report=${item.id}`
            case 'timesheet':
                return `/timesheets/report?report=${item.id}`
            case 'time':
                return `/timesheets/report?report=${item.id}`
            case 'expense':
                return `/expenses-reports?report=${item.id}`
        }
    },
    resourceScheduleReports: (item: any) =>
        `/resource-schedule?report=${item.id}`,
    revenueForecastReports: (item: any) =>
        `/revenue-forecast?report=${item.id}`,
    staff: (item: any) => `/staff/${item.id}`,
}

const getModelTypeLabel = {
    projects: (_item: any) => 'Project',
    contacts: (_item: any) => 'Contact',
    invoices: (_item: any) => 'Invoice',
    organisations: (_item: any) => 'Organisation',
    suppliers: (_item: any) => 'Supplier',
    roles: (_item: any) => 'Role',
    costCentres: (_item: any) => 'Cost Centre',
    organisationReports: (item: any) => {
        switch (item.type) {
            case 'project':
                return 'Project Report'
            case 'staff':
                return 'Staff Report'
            case 'invoice':
                return 'Invoice Report'
            case 'timesheet':
                return 'Time Report'
            case 'time':
                return 'Time Report'
            case 'expense':
                return 'Expense Report'
        }
    },
    resourceScheduleReports: (_item: any) => 'Resource Schedule',
    revenueForecastReports: (_item: any) => 'Revenue Forecast',
    staff: (_item: any) => 'Staff',
}

export const SearchBox = () => {
    const [search, setSearch] = useState('')
    const [showResults, setShowResults] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const resultsRef = useRef<HTMLDivElement>(null)

    const searchableModels = [
        'projects',
        'contacts',
        'invoices',
        'organisations',
        'suppliers',
        'roles',
        'costCentres',
        'organisationReports',
        'resourceScheduleReports',
        'revenueForecastReports',
        'staff',
    ]

    const modelMap = useMemo(() => {
        const map = new Map()
        searchableModels.forEach((model) => {
            map.set(model, [model])
            map.set(model.slice(0, -1), [model]) // Add singular form
        })
        map.set('pr', ['projects']) // Add shorthand for projects
        map.set('proj', ['projects']) // Add shorthand for projects
        map.set('cn', ['contacts']) // Add shorthand for contacts
        map.set('con', ['contacts']) // Add shorthand for contacts
        map.set('inv', ['invoices']) // Add shorthand for invoices
        map.set('org', ['organisations']) // Add shorthand for organisations
        map.set('sup', ['suppliers']) // Add shorthand for suppliers
        map.set('rol', ['roles']) // Add shorthand for roles
        map.set('cc', ['costCentres']) // Add shorthand for cost centres
        map.set('rep', [
            'organisationReports',
            'resourceScheduleReports',
            'revenueForecastReports',
        ]) // Add shorthand for organisation reports
        map.set('report', [
            'organisationReports',
            'resourceScheduleReports',
            'revenueForecastReports',
        ]) // Add shorthand for organisation reports
        map.set('st', ['staff']) // Add shorthand for staff
        return map
    }, [searchableModels])

    const allItems = useMemo(() => {
        return searchableModels.flatMap((model) =>
            getFilteredCacheItems(model).map((item: any) => ({
                model,
                item,
                label: getLabel[model](item),
            }))
        )
    }, [searchableModels])

    const fuse = useMemo(
        () =>
            new Fuse(allItems, {
                keys: ['label'],
                threshold: 0.3,
                ignoreLocation: true,
            }),
        [allItems]
    )

    const handleSearch = useCallback((value: string) => {
        setSearch(value)
        setShowResults(value.length > 0)
    }, [])

    const filteredResults = useMemo(() => {
        if (search.length === 0) return []

        const [modelFilter, searchTerm] = search.split(':')

        if (modelMap.has(modelFilter) && searchTerm) {
            // Filter by specific model (plural or singular)
            const modelNames = modelMap.get(modelFilter)
            const modelItems = allItems.filter((item) =>
                modelNames.includes(item.model)
            )
            const modelFuse = new Fuse(modelItems, {
                keys: ['label'],
                threshold: 0.3,
                ignoreLocation: true,
            })
            return modelFuse.search(searchTerm).slice(0, 10)
        } else {
            // Search across all models
            return fuse.search(search).slice(0, 10)
        }
    }, [search, fuse, allItems, modelMap])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                resultsRef.current &&
                !resultsRef.current.contains(event.target as Node) &&
                inputRef.current &&
                !inputRef.current.contains(event.target as Node)
            ) {
                setShowResults(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div className="relative">
            <div className="relative">
                <Search className="z-[1] absolute left-2 top-3 h-4 w-4 text-muted-foreground" />
                <Input
                    ref={inputRef}
                    placeholder="Search all... (e.g., project:test or projects:test)"
                    className="pl-8"
                    value={search}
                    onChange={(e) => handleSearch(e.target.value)}
                    onFocus={() => setShowResults(true)}
                />
            </div>
            {showResults && (
                <div
                    ref={resultsRef}
                    className="absolute z-10 w-full mt-1 bg-background border rounded-md shadow-lg"
                >
                    <Command>
                        <CommandList>
                            {search && search !== '' ? (
                                <CommandEmpty>No results found.</CommandEmpty>
                            ) : null}
                            {filteredResults.map(({ item }) => (
                                <Link to={getUrl[item.model](item.item)}>
                                    <CommandItem
                                        key={`${item.model}-${item.item.id}`}
                                        value={item.item.id}
                                        className="whitespace-pre-wrap cursor-pointer"
                                    >
                                        <HighlightExactMatch
                                            text={item.label}
                                            searchTerm={
                                                search.includes(':')
                                                    ? search.split(':')[1]
                                                    : search
                                            }
                                        />
                                        <span className="ml-2 text-xs text-muted-foreground">
                                            (
                                            {getModelTypeLabel[item.model](
                                                item.item
                                            )}
                                            )
                                        </span>
                                    </CommandItem>
                                </Link>
                            ))}
                        </CommandList>
                    </Command>
                </div>
            )}
        </div>
    )
}
