import { useDataTable } from '@2/components/data-table/hooks/use-data-table'
import { DataTableAdvancedToolbar } from '@2/components/data-table/advanced/data-table-advanced-toolbar'
import { DataTable } from '@2/components/data-table/data-table'
import * as React from 'react'
import { getColumns } from './timesheet-report-columns'
import {
    useReportData,
    useOrganisationReport,
    useIsLoadingReportData,
    usePendingChanges,
    setOrganisationReport,
    setReportData,
    setIsLoadingReportData,
    setColumns,
    setPendingChanges,
} from './time-report-store'
import { trpc } from '@/system/trpc'
import {
    ExportColumn,
    SetColumnsOptions,
    SetOrganisationReportOptions,
    sortingDateFn,
    sortingProgressFn,
    sortingTextFn,
    TableState,
    typeFilterMap,
} from '@/version2/types'
import { TimeReportActions } from './time-report-actions'
import { TimeReportTableFloatingBar } from './time-report-floating-bar'
import { orderColumnDefs } from '@/version2/components/data-table/helpers/order-columns'
import { useMemo } from 'react'

export function TimesheetReportTable() {
    const reportData = useReportData()
    const organisationReport = useOrganisationReport()
    const isLoadingReportData = useIsLoadingReportData()
    const pendingChanges = usePendingChanges()

    const handleStateChange = (
        newState: Partial<TableState>,
        options: SetOrganisationReportOptions
    ) => {
        setOrganisationReport(
            {
                ...organisationReport,
                ...newState,
            },
            options
        )
    }

    const handleColumnsChange = (
        columns: string[],
        options: SetColumnsOptions
    ) => {
        setColumns(columns, options)
    }

    const handleApplyChanges = async () => {
        setIsLoadingReportData(true)
        try {
            const data = await fetchReportData({
                ...organisationReport,
                groupBy: organisationReport.groupBy || ['projectId'],
                sortBy: organisationReport.sortBy || [],
            })
            setReportData(data)
        } finally {
            setIsLoadingReportData(false)
            setPendingChanges(false)
        }
    }

    const columns = useMemo(
        () =>
            orderColumnDefs(
                getColumns((column: string) => {
                    setColumns(
                        organisationReport.columns.filter(
                            (c: any) => c !== column
                        ),
                        {
                            needsRefetch: false,
                        }
                    )
                }),
                organisationReport.columns,
                [],
                []
            ),
        [organisationReport.columns]
    )

    const filterFields: any = columns.map((column: any) => {
        return {
            label: column?.meta?.title,
            value: column.accessorKey,
            typeFilter: typeFilterMap[column?.meta?.type],
            ...column?.meta?.filterOptions,
        }
    })
    const exportColumns: ExportColumn[] = [
        ...organisationReport.groupBy.map((groupByColumn) => ({
            value: groupByColumn,
            label: columns.find(
                (column) => column.accessorKey === groupByColumn
            )?.meta?.title,
        })),
        {
            value: 'timeEntry',
            label: 'Time Entry',
        },
    ]

    const defaultSort = [
        {
            id: 'label',
            desc: false,
        },
    ]

    const { table } = useDataTable({
        data: reportData,
        defaultSort,
        selectable: {
            enabled: true,
            enableMultipleSelection: true,
            enableRowSelection: (row) => !row.original.isLocked,
        },
        groups: {
            enabled: true,
            columns: organisationReport.groupBy,
            leafRowLabel: 'Time Entry',
        },
        columns,
        visibleColumns: ['label', ...organisationReport.columns],
        filterFields,
        enableAdvancedFilter: true,
        sortingFns: {
            sortingTextFn,
            sortingProgressFn,
            sortingDateFn,
        },
    })

    const fetchReportData = async (data: any) => {
        return await trpc.timeReport.getReportsData.query(data)
    }

    return (
        <DataTable
            table={table}
            showTotals={true}
            floatingBar={<TimeReportTableFloatingBar table={table} />}
            loadingReportData={isLoadingReportData}
            hideColumns={organisationReport.groupBy}
        >
            <DataTableAdvancedToolbar
                table={table}
                filterFields={filterFields}
                tableState={{
                    columns: organisationReport.columns,
                    filters: organisationReport.filters ?? [],
                    dateRange: organisationReport.dateRange,
                    sortBy: organisationReport.sortBy,
                    groupBy: organisationReport.groupBy,
                }}
                pendingChanges={pendingChanges}
                onStateChange={handleStateChange}
                onColumnsChange={handleColumnsChange}
                onApplyChanges={handleApplyChanges}
                isHaveGroup={true}
                fixedColumns={['select', 'label']}
                showSearchFieldHeader={false}
                showApplyToPhase={false}
            >
                <TimeReportActions
                    table={table}
                    exportColumns={exportColumns}
                />
            </DataTableAdvancedToolbar>
        </DataTable>
    )
}
