import { createFileRoute } from '@tanstack/react-router'
import RevenueForecastPageHeader from '../../Pages/RevenueForecastPage/RevenueForecastPageHeader'
import RevenueForecastPage from '../../Pages/RevenueForecastPage/RevenueForecastPage'
import PageBody from '../../Pages/Layout/PageBody'
import PageSidebar from '../../Pages/Layout/PageSidebar'
import RevenueForecastSidebar from '../../Pages/RevenueForecastPage/RevenueForecastSidebar'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import SessionStore from '../../State/SessionStore'
import fetchData from '../../Queries/fetchData'
import {
    canViewRoute,
    migratedToV2,
} from '../../State/Permissions/HasPermissions'
import PermissionDenied from '../../Pages/PermissionDenied'
import { qf } from '../../Queries/queryFormatter'
import { setLayoutDefaults } from '@2/layout/layout-store'
import { getCacheItemById } from '@2/cache'
import { CenterPadPageBody } from '@2/layout/page-body'
import { Sidebar } from '@2/layout/sidebar'
export const Route = createFileRoute('/_private/revenue-forecast')({
    beforeLoad: ({ params, search }) => {
        if (!canViewRoute(SessionStore.user, 'revenueForecast', params))
            throw new Error('Permission Denied')
        const report = getCacheItemById('revenueForecastReports', search.report)
        setLayoutDefaults({
            pageTitle: report?.name || 'Revenue Forecast',
            subMenu: 'organisation',
            showSidebar: true,
            sidebarExpanded: false,
        })
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    component: Wrapper,
    pendingComponent: LoadingSpinner,
    loader: async ({ location }) => await loader(location.search),
    loaderDeps: ({ search: { report } }) => ({
        report,
    }),
})

const loader = async ({ report }) => {
    const reportId =
        report || SessionStore.organisation?.defaultRevenueForecastReportId
    const collections = [
        reportId
            ? {
                  collection: 'revenueForecastReports',
                  fields: ['name', 'filters'],
                  filters: [`id == ${qf(reportId)}`],
              }
            : null,
        {
            collection: 'revenueForecastReports',
            fields: ['name'],
        },
        {
            collection: 'overheadExpenses',
            fields: ['name'],
        },
        {
            collection: 'milestones',
            fields: ['label', 'date', 'phaseId', 'projectId'],
        },
    ].filter(Boolean)
    return await Promise.all(collections.map(fetchData))
}

function Wrapper() {
    if (migratedToV2()) {
        return (
            <>
                <CenterPadPageBody>
                    <RevenueForecastPage
                        {...Route.useParams()}
                        {...Route.useSearch()}
                    />
                </CenterPadPageBody>
                <Sidebar>
                    <RevenueForecastSidebar
                        {...Route.useParams()}
                        {...Route.useSearch()}
                    />
                </Sidebar>
            </>
        )
    }
    return (
        <>
            <RevenueForecastPageHeader
                {...Route.useParams()}
                {...Route.useSearch()}
            />
            <PageBody>
                <RevenueForecastPage
                    {...Route.useParams()}
                    {...Route.useSearch()}
                />
            </PageBody>
            <PageSidebar>
                <RevenueForecastSidebar
                    {...Route.useParams()}
                    {...Route.useSearch()}
                />
            </PageSidebar>
        </>
    )
}
