import { trpc } from '@/system/trpc'
import {
    SetColumnsOptions,
    SetOrganisationReportOptions,
    sortingDateFn,
    sortingProgressFn,
    sortingTextFn,
    TableState,
    typeFilterMap,
} from '@/version2/types'
import { DataTableAdvancedToolbar } from '@2/components/data-table/advanced/data-table-advanced-toolbar'
import { DataTable } from '@2/components/data-table/data-table'
import { useDataTable } from '@2/components/data-table/hooks/use-data-table'

import { orderColumnDefs } from '@/version2/components/data-table/helpers/order-columns'
import { getColumns } from './staff-report-columns'
import {
    useOrganisationReport,
    useReportData,
    useIsLoadingReportData,
    setOrganisationReport,
    setReportData,
    setIsLoadingReportData,
    setColumns,
    setPendingChanges,
    usePendingChanges,
} from './staff-report-store'
import { StaffReportActions } from './staff-report-actions'
import { useMemo } from 'react'

export function StaffReportTable() {
    const organisationReport = useOrganisationReport()
    const reportData = useReportData()
    const isLoadingReportData = useIsLoadingReportData()
    const pendingChanges = usePendingChanges()

    const handleStateChange = (
        newState: Partial<TableState>,
        options: SetOrganisationReportOptions
    ) => {
        setOrganisationReport(
            {
                ...organisationReport,
                ...newState,
            },
            options
        )
    }

    const handleColumnsChange = (
        columns: string[],
        options: SetColumnsOptions
    ) => {
        setColumns(columns, options)
    }

    const handleApplyChanges = async () => {
        setIsLoadingReportData(true)
        try {
            const data = await fetchReportData({
                ...organisationReport,
                groupBy: organisationReport.groupBy || [],
                sortBy: organisationReport.sortBy || [],
            })
            setReportData(data)
        } finally {
            setIsLoadingReportData(false)
            setPendingChanges(false)
        }
    }

    const columns = useMemo(
        () =>
            orderColumnDefs(
                getColumns((column: string) => {
                    setColumns(
                        organisationReport.columns.filter(
                            (c: any) => c !== column
                        ),
                        {
                            needsRefetch: false,
                        }
                    )
                }),
                organisationReport.columns,
                [],
                []
            ),
        [organisationReport.columns]
    )

    const filterFields: any = columns.map((column: any) => {
        return {
            label: column?.meta?.title,
            value: column.accessorKey,
            typeFilter: typeFilterMap[column?.meta?.type],
            ...column?.meta?.filterOptions,
        }
    })

    const defaultSort = [
        {
            id: 'name',
            desc: false,
        },
    ]

    const { table } = useDataTable({
        data: reportData,
        defaultSort,
        columns,
        visibleColumns: organisationReport.columns,
        filterFields,
        enableAdvancedFilter: true,
        sortingFns: {
            sortingTextFn,
            sortingProgressFn,
            sortingDateFn,
        },
    })

    const fetchReportData = async (data: any) => {
        return await trpc.staffReport.getReportsData.query(data)
    }

    return (
        <DataTable
            table={table}
            showTotals={true}
            loadingReportData={isLoadingReportData}
        >
            <DataTableAdvancedToolbar
                table={table}
                filterFields={filterFields}
                searchableFields={['name', 'firstName', 'lastName']}
                tableState={{
                    columns: organisationReport.columns,
                    filters: organisationReport.filters ?? [],
                    dateRange: organisationReport.dateRange,
                    sortBy: organisationReport.sortBy,
                    groupBy: organisationReport.groupBy,
                }}
                pendingChanges={pendingChanges}
                onStateChange={handleStateChange}
                onColumnsChange={handleColumnsChange}
                onApplyChanges={handleApplyChanges}
                isLoading={isLoadingReportData}
            >
                <StaffReportActions table={table} />
            </DataTableAdvancedToolbar>
        </DataTable>
    )
}
