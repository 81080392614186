import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'
import { OrganisationReport } from '@/version2/types'

// Types
interface TimeReport {
    budget: number
    chargeOut: number
    cost: number
    date: string | null
    hoursBudget: number
    key: string
    label: string
    notes: string | null
    numMinutes: number
    phaseId: string | null
    project: string
    projectId: string
    projectPhase: string | null
    staffId: string | null
    staffMember: string
    task: string
    taskId: string | null
    timeEntryId: string | null
}

interface TimeReportData extends TimeReport {
    children?: TimeReport[]
}

interface SetOrganisationReportOptions {
    needsRefetch?: boolean
}

interface SetColumnsOptions {
    needsRefetch?: boolean
}

interface SetOrganisationReportOptions {
    needsRefetch?: boolean
}

interface SetColumnsOptions {
    needsRefetch?: boolean
}

// Initial state
const initialContext = {
    reportData: null as TimeReportData[] | null,
    organisationReport: null as OrganisationReport | null,
    isLoadingReportData: false,
    columns: null as string[] | null,
    listProjects: [],
    listPhases: [],
    listTasks: [],
    pendingChanges: false,
}

// Create store
export const timeReportStore = createStore({
    context: initialContext,
    on: {
        setOrganisationReport: {
            organisationReport: (
                _,
                event: {
                    report: OrganisationReport
                    options?: SetOrganisationReportOptions
                }
            ) => event.report,
            pendingChanges: (_, event) => event.options?.needsRefetch ?? true,
        },

        setReportData: {
            reportData: (_, event: { data: TimeReportData[] | [] }) =>
                event.data,
        },

        setIsLoadingReportData: {
            isLoadingReportData: (_, event: { isLoading: boolean }) =>
                event.isLoading,
        },

        setColumns: {
            organisationReport: (
                context,
                event: {
                    columns: string[]
                    options?: SetColumnsOptions
                }
            ) => {
                if (!context.organisationReport) return null
                return {
                    ...context.organisationReport,
                    columns: event.columns,
                }
            },
            pendingChanges: (_, event) => event.options?.needsRefetch ?? true,
        },

        setPendingChanges: {
            pendingChanges: (_, event: { pending: boolean }) => event.pending,
        },
    },
})

// Action creators
export const setOrganisationReport = (
    report: OrganisationReport,
    options?: SetOrganisationReportOptions
) =>
    timeReportStore.send({
        type: 'setOrganisationReport',
        report,
        options,
    })

export const setReportData = (data: TimeReportData[] | []) =>
    timeReportStore.send({
        type: 'setReportData',
        data,
    })

export const setIsLoadingReportData = (isLoading: boolean) =>
    timeReportStore.send({
        type: 'setIsLoadingReportData',
        isLoading,
    })

export const setColumns = (columns: string[], options?: SetColumnsOptions) =>
    timeReportStore.send({
        type: 'setColumns',
        columns,
        options,
    })

export const setPendingChanges = (pending: boolean) =>
    timeReportStore.send({
        type: 'setPendingChanges',
        pending,
    })

// Selectors
export const useReportData = () =>
    useSelector(timeReportStore, (state) => state.context.reportData)

export const useOrganisationReport = () =>
    useSelector(timeReportStore, (state) => state.context.organisationReport)

export const useIsLoadingReportData = () =>
    useSelector(timeReportStore, (state) => state.context.isLoadingReportData)

export const useColumns = () =>
    useSelector(
        timeReportStore,
        (state) => state.context.organisationReport?.columns
    )

export const usePendingChanges = () =>
    useSelector(timeReportStore, (state) => state.context.pendingChanges)

// Direct state access (non-reactive)
export const getReportData = () =>
    timeReportStore.getSnapshot().context.reportData

export const getOrganisationReport = () =>
    timeReportStore.getSnapshot().context.organisationReport
