import { useState } from 'react'
import { toast } from '@vendor/sonner'
import { Button } from '@2/components/ui/button'

export type SaveToastProps = {
    onSave: () => Promise<any>
    successMessage?: string
    errorMessage?: string
    loadingMessage?: string
    confirmationMessage?: string // New prop for confirmation message
    showCancelButton?: boolean
    onSuccess?: (data: any) => void
    onCancel?: () => void
    onRetry?: () => void
    requireConfirmation?: boolean
}

export const useSaveToast = () => {
    const [isLoading, setIsLoading] = useState(false)

    const executeSave = async ({
        onSave,
        successMessage = 'All saved',
        errorMessage = 'Changes have not been saved',
        loadingMessage = 'Saving changes...',
        onSuccess,
        onRetry,
    }: Omit<
        SaveToastProps,
        'requireConfirmation' | 'onCancel' | 'confirmationMessage'
    >) => {
        if (isLoading) return

        setIsLoading(true)

        return toast.promise(onSave(), {
            loading: loadingMessage,
            success: (data) => {
                setIsLoading(false)
                if (onSuccess) onSuccess(data)
                return successMessage
            },
            error: () => {
                setIsLoading(false)
                return (
                    <div className="flex items-center justify-between w-full">
                        <span className="text-muted-foreground">
                            {errorMessage}
                        </span>
                        <Button
                            onClick={() => {
                                toast.dismiss()
                                if (onRetry) {
                                    onRetry()
                                } else {
                                    showSaveToast({
                                        onSave,
                                        successMessage,
                                        errorMessage,
                                        loadingMessage,
                                        onSuccess,
                                        onRetry,
                                    })
                                }
                            }}
                            variant="default"
                            size="sm"
                        >
                            Retry
                        </Button>
                    </div>
                )
            },
        })
    }

    const showSaveToast = async (props: SaveToastProps) => {
        const {
            requireConfirmation,
            onCancel,
            confirmationMessage,
            showCancelButton = true,
            ...saveProps
        } = props

        if (!requireConfirmation) {
            return executeSave(saveProps)
        }

        toast(
            <div className="flex w-full items-center">
                <div className="flex-auto">
                    {confirmationMessage || 'Save changes?'}
                </div>
                <div className="flex-none flex gap-2 justify-end">
                    {showCancelButton && (
                        <Button
                            onClick={() => {
                                toast.dismiss()
                                if (onCancel) onCancel()
                            }}
                            variant="outline"
                            size="sm"
                        >
                            Cancel
                        </Button>
                    )}
                    <Button
                        onClick={() => {
                            toast.dismiss()
                            executeSave(saveProps)
                        }}
                        variant="default"
                        size="sm"
                    >
                        Save
                    </Button>
                </div>
            </div>,
            {
                duration: Infinity,
                dismissible: false,
            }
        )
    }

    return {
        showSaveToast,
        isLoading,
    }
}

export default useSaveToast
