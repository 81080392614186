import { canEditOverheadExpenses } from '@/State/Permissions/HasPermissions'
import SessionStore from '@/State/SessionStore'
import { getCacheItemById } from '@/version2/cache'
import { createGenerateColumnDef } from '@/version2/components/data-table/helpers/generate-column-def'
import { CostCentreSelector, Selector } from '@/version2/components/selector'
import { type ColumnDef } from '@tanstack/react-table'
import { capitalCase } from 'change-case'

export function getColumns(
    onHideColumn: (column: string) => void,
    updateOperationalExpenses: (id: string, data: any) => void,
    deleteOperationalExpenses: (id: string) => void
): ColumnDef<any>[] {
    const generateColumnDef = createGenerateColumnDef({
        onHideColumn,
    })
    const wideSize = 100
    return [
        generateColumnDef({
            key: 'name',
            type: 'text',
            title: 'Name',
            size: wideSize,
            editable: canEditOverheadExpenses(SessionStore.user),
            onChange: (row, val) =>
                updateOperationalExpenses(row.original.id, { name: val }),
        }),
        generateColumnDef({
            key: 'costCentreId',
            type: 'costCentre',
            title: 'Cost Centre',
            size: wideSize,
            editable: true,
            cell: (row) => {
                return (
                    <CostCentreSelector
                        value={getCacheItemById(
                            'costCentres',
                            row.getValue('costCentreId')
                        )}
                        onChange={(costCentre) =>
                            updateOperationalExpenses(row.original.id, {
                                costCentreId: costCentre.id,
                            })
                        }
                        clearable={false}
                    />
                )
            },
        }),
        generateColumnDef({
            key: 'value',
            type: 'currency',
            title: 'Cost',
            size: wideSize,
            editable: canEditOverheadExpenses(SessionStore.user),
            onChange: (row, val) =>
                updateOperationalExpenses(row.original.id, {
                    value: val ? Number(val) : null,
                }),
        }),
        generateColumnDef({
            key: 'startDate',
            type: 'date',
            title: 'Start date',
            size: wideSize,
            editable: canEditOverheadExpenses(SessionStore.user),
            onChange: (row, val) =>
                updateOperationalExpenses(row.original.id, { startDate: val }),
        }),
        generateColumnDef({
            key: 'endDate',
            type: 'date',
            title: 'End date',
            size: wideSize,
            editable: canEditOverheadExpenses(SessionStore.user),
            onChange: (row, val) =>
                updateOperationalExpenses(row.original.id, { endDate: val }),
        }),
        generateColumnDef({
            key: 'hasRepeat',
            type: 'boolean',
            title: 'Repeats',
            size: wideSize,
            editable: canEditOverheadExpenses(SessionStore.user),
            onChange: (row, val) =>
                updateOperationalExpenses(row.original.id, { hasRepeat: val }),
        }),
        generateColumnDef({
            key: 'repeatQuantity',
            type: 'number',
            title: 'Every',
            size: wideSize,
            editable: canEditOverheadExpenses(SessionStore.user),
            onChange: (row, val) =>
                updateOperationalExpenses(row.original.id, {
                    repeatQuantity: val ? Number(val) : null,
                }),
        }),
        generateColumnDef({
            key: 'repeatUnit',
            type: 'text',
            title: 'Period',
            size: wideSize,
            editable: canEditOverheadExpenses(SessionStore.user),
            onChange: (row, val) =>
                updateOperationalExpenses(row.original.id, {
                    repeatQuantity: val,
                }),
            cell: (row) => {
                if (!canEditOverheadExpenses(SessionStore.user))
                    return (
                        <div>
                            {capitalCase(
                                row.getValue('hasRepeat') &&
                                    row.getValue('repeatUnit')
                                    ? row.getValue('repeatUnit')
                                    : ''
                            )}
                        </div>
                    )
                return (
                    <Selector
                        value={row.getValue('repeatUnit')}
                        onChange={(opt) =>
                            updateOperationalExpenses(row.original.id, {
                                repeatUnit: opt,
                            })
                        }
                        options={['days', 'weeks', 'months', 'years']}
                        getLabel={(opt) => capitalCase(opt || '')}
                        clearable={false}
                    />
                )
            },
        }),
        generateColumnDef({
            key: 'deleteButton',
            title: 'Action',
            type: 'button',
            size: wideSize,
            editable: canEditOverheadExpenses(SessionStore.user),
            cell: (row) => {
                return <i className="fa fa-times" />
            },
            onClick: (row) => deleteOperationalExpenses(row.original.id),
        }),
    ]
}
