import cuid from 'cuid'
import { observable, computed, action, makeObservable } from 'mobx'
import _ from 'lodash'
import ContactCollection from '../Collections/ContactCollection'
import InvoiceCollection from '../Collections/InvoiceCollection'
import InvoiceLineItemCollection from '../Collections/InvoiceLineItemCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import Model from './Model'
import bind from 'bind-decorator'

class InvoiceTemplateModel {
    @observable id = cuid()
    @observable templateName = 'Default'

    @observable
    contentStart = ``

    @observable
    contentEnd = `Please deposit funds electronically within 14 days to:
                    CC Architecture
                    BSB: XXX-XXX
                    Account: XXXX XXXX
                    *Please include reference the invoice number in the payment description.`

    @observable footerLeft = `CC Architecture Pty Ltd
                                ABN: XX XXX XXX XXX`

    @observable footerRight = `123 Threshold Lane, Melbourne, 
                                VIC Australia 3000
                                Ph: XX XXXX XXXX
                                lecorb@ccarch.com.au
                                `
    @observable logoUrl = new URL(
        '~/public/coincraft_logo_130.png',
        import.meta.url
    )

    @observable logoSize = 6
    @observable taxName = 'GST'
    @observable documentLabel = 'Tax Invoice'
    @observable columns = ['description', 'quantity', 'unitPrice', 'amount']
    @observable projectCode = true
    @observable projectName = true
    @observable projectAddress = true
    @observable openPerProject = false
    @observable closePerProject = false
    @observable organisation = null
    constructor(org, data) {
        makeObservable(this)
        this.organisation = org
        for (const [key, value] of Object.entries(data)) {
            this.changeProp(key, value)
        }
    }
    @action.bound
    update(data = {}, options, invoice) {
        for (const [key, value] of Object.entries(data)) {
            this.changeProp(key, value)
        }
        this.organisation.updateInvoiceTemplate(this.serialize(), options)
        if (this.openPerProject && invoice?.project) {
            invoice.project.update({ invoiceOpen: data.contentStart || '' })
        }
        if (this.closePerProject && invoice?.project) {
            invoice.project.update({ invoiceClose: data.contentEnd || '' })
        }
    }
    @action.bound
    changeProp(prop, val) {
        this[prop] = val
    }

    @bind
    serialize() {
        return {
            id: this.id,
            templateName: this.templateName,
            contentStart: this.contentStart,
            contentEnd: this.contentEnd,
            footerLeft: this.footerLeft,
            footerRight: this.footerRight,
            logoUrl: this.logoUrl,
            logoSize: this.logoSize,
            taxName: this.taxName,
            documentLabel: this.documentLabel,
            columns: this.columns,
            projectCode: this.projectCode,
            projectName: this.projectName,
            projectAddress: this.projectAddress,
            openPerProject: this.openPerProject,
            closePerProject: this.closePerProject,
        }
    }

    @bind
    getProjectText(invoice) {
        let ctext = []
        if (invoice.project.jobNumber && this.projectCode)
            ctext.push(invoice.project.jobNumber)
        if (invoice.project.name && this.projectName)
            ctext.push(invoice.project.name)
        if (invoice.project.address && this.projectAddress)
            ctext.push(invoice.project.address)
        return ctext.join('\n')
    }

    @bind
    getClientText(invoice) {
        if (!invoice.contact) return ''
        let ctext = []
        if (invoice.contact.firstName && invoice.contact.lastName)
            ctext.push(
                `${invoice.contact.firstName} ${invoice.contact.lastName}`
            )
        if (invoice.contact.organisationName)
            ctext.push(invoice.contact.organisationName)
        if (invoice.contact?.address) ctext.push(invoice.contact?.address)
        return ctext.join('\n')
    }

    @bind
    getOpeningText(invoice) {
        if (this.openPerProject) {
            return invoice.project.invoiceOpen
        } else {
            return this.contentStart
        }
    }

    @bind
    getClosingText(invoice) {
        if (this.closePerProject) {
            return invoice.project.invoiceClose
        } else {
            return this.contentEnd
        }
    }
}

export default InvoiceTemplateModel
