import { ColumnDef } from '@tanstack/react-table'
import { DataTableColumnHeader } from '@2/components/data-table/data-table-column-header'

interface CreateLabelColumnOptions<TData> {
    groupByColumns?: string[]
    columns?: ColumnDef<TData, any>[]
    setIsAlertChangeColumns?: (value: boolean) => void
}

export const createLabelColumn = <TData,>({
    groupByColumns,
    columns,
    setIsAlertChangeColumns,
}: CreateLabelColumnOptions<TData>) => {
    return {
        id: 'label',
        accessorKey: 'label',
        header: ({ column }) => (
            <DataTableColumnHeader
                column={column}
                setIsAlertChangeColumns={setIsAlertChangeColumns}
                title={'Label'}
            />
        ),
        enableHiding: false,
        sortingFn: 'text',
    } as ColumnDef<TData>
}
