import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'
import { z } from 'zod'
import { useMemo } from 'react'

// Zod schemas
export const ContactDetailSchema = z.object({
    id: z.string(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    organisationName: z.string().optional(),
    email: z.string().optional(),
    phone: z.string().optional(),
    address: z.string().optional(),
    notes: z.string().optional(),
})

export type ContactDetail = z.infer<typeof ContactDetailSchema>

// Initial state
const initialContext = {
    contact: null as ContactDetail | null,
    projects: [] as any[],
    invoices: [] as any[],
    isLoading: false,
    pendingChanges: false,
}

type StoreContext = typeof initialContext

// Create the store
export const contactStore = createStore({
    context: initialContext,
    on: {
        setData: {
            contact: (_, event: { contact: ContactDetail }) => event.contact,
            projects: (_, event: { projects: any[] }) => event.projects ?? [],
            invoices: (_, event: { invoices: any[] }) => event.invoices ?? [],
        },
        updateContact: {
            contact: (
                context,
                event: { value: any; prop: keyof ContactDetail }
            ) =>
                context.contact
                    ? {
                          ...context.contact,
                          [event.prop]: event.value,
                      }
                    : null,
            pendingChanges: true,
        },
        setIsLoading: {
            isLoading: (_, event: { isLoading: boolean }) => event.isLoading,
        },
        setPendingChanges: {
            pendingChanges: (_, event: { pending: boolean }) => event.pending,
        },
        reset: {
            contact: null,
            projects: [],
            invoices: [],
            isLoading: false,
            pendingChanges: false,
        },
    },
})

// Action creators
export const setData = (data: {
    contact: ContactDetail
    projects: any[]
    invoices: any[]
}) =>
    contactStore.send({
        type: 'setData',
        ...data,
    })

export const updateContact = (value: any, prop: keyof ContactDetail) =>
    contactStore.send({
        type: 'updateContact',
        value,
        prop,
    })

export const setIsLoading = (isLoading: boolean) =>
    contactStore.send({
        type: 'setIsLoading',
        isLoading,
    })

export const setPendingChanges = (pending: boolean) =>
    contactStore.send({
        type: 'setPendingChanges',
        pending,
    })

export const reset = () =>
    contactStore.send({
        type: 'reset',
    })

// Selector hooks
export const useContact = () =>
    useSelector(contactStore, (state) => state.context.contact)

export const useProjects = () =>
    useSelector(contactStore, (state) => state.context.projects)

export const useInvoices = () =>
    useSelector(contactStore, (state) => state.context.invoices)

export const useIsLoading = () =>
    useSelector(contactStore, (state) => state.context.isLoading)

export const usePendingChanges = () =>
    useSelector(contactStore, (state) => state.context.pendingChanges)

// Getter for non-reactive access
export const getContact = () => contactStore.getSnapshot().context.contact

// Example usage:
/*
// Load contact data
const data = await fetchContactData()
setData(data)

// Update contact field
updateContact('John', 'firstName')

// Reset store
reset()
*/
