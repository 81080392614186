import { ContactForm } from './contact-form'
import { Button } from '@2/components/ui/button'
import LayoutStore from '../../../State/LayoutStore'
import { DeleteContactModal } from './delete-contact-modal'
import { useState } from 'react'
import { canEditContacts } from '../../../State/Permissions/HasPermissions'
import SessionStore from '../../../State/SessionStore'

export const ContactPage = () => {
    const [isOpen, setIsOpen] = useState(false)

    const handleDelete = () => {
        // delete contact
        setIsOpen(false)
    }

    return (
        <div className="flex w-full flex-col items-end">
            {canEditContacts(SessionStore.user) ? (
                <div className="p-8">
                    <Button variant="outline" onClick={() => setIsOpen(true)}>
                        <i className="fa fa-trash mr-1" />
                        Delete contact
                    </Button>
                </div>
            ) : null}

            <ContactForm />
            <DeleteContactModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onSave={handleDelete}
            />
        </div>
    )
}
