import { Button } from '@2/components/ui/button'
import uuid from 'uuid'
import { addHoliday } from './organistion-holiday-store'
import { OrganisationHolidaysTable } from './organistion-holiday-table'

export const OrganisationHolidaysPage = () => {
    const handleAddHoliday = () => {
        addHoliday({
            id: uuid(),
            name: '',
            startDate: new Date(),
            endDate: new Date(),
        })
    }

    return (
        <>
            <div className="p-8">
                <Button variant="outline" onClick={handleAddHoliday}>
                    + Add Holiday
                </Button>
            </div>
            <OrganisationHolidaysTable />
        </>
    )
}
