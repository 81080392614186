import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'
import { OrganisationReport } from '@/version2/types'
import { useMemo } from 'react'

interface StaffReportData {}

interface SetOrganisationReportOptions {
    needsRefetch?: boolean
}

interface SetColumnsOptions {
    needsRefetch?: boolean
}

// Initial state
const initialContext = {
    reportData: null as StaffReportData[] | null,
    organisationReport: null as OrganisationReport | null,
    isLoadingReportData: false,
    columns: null as string[] | null,
    pendingChanges: false,
}

// Create the store
export const staffReportStore = createStore({
    context: initialContext,
    on: {
        setOrganisationReport: {
            organisationReport: (
                _,
                event: {
                    report: OrganisationReport
                    options?: SetOrganisationReportOptions
                }
            ) => event.report,
            pendingChanges: (_, event) => event.options?.needsRefetch ?? true,
        },
        setReportData: {
            reportData: (_, event: { data: StaffReportData[] | [] }) =>
                event.data,
        },
        setIsLoadingReportData: {
            isLoadingReportData: (_, event: { isLoading: boolean }) =>
                event.isLoading,
        },
        setColumns: {
            organisationReport: (
                context,
                event: {
                    columns: string[]
                    options?: SetColumnsOptions
                }
            ) =>
                context.organisationReport
                    ? {
                          ...context.organisationReport,
                          columns: event.columns,
                      }
                    : null,
            pendingChanges: (_, event) => event.options?.needsRefetch ?? true,
        },
        setPendingChanges: {
            pendingChanges: (_, event: { pending: boolean }) => event.pending,
        },
    },
})

// Action creators
export const setOrganisationReport = (
    report: OrganisationReport,
    options: SetOrganisationReportOptions = { needsRefetch: true }
) =>
    staffReportStore.send({
        type: 'setOrganisationReport',
        report,
        options,
    })

export const setReportData = (data: StaffReportData[] | []) =>
    staffReportStore.send({
        type: 'setReportData',
        data,
    })

export const setIsLoadingReportData = (isLoading: boolean) =>
    staffReportStore.send({
        type: 'setIsLoadingReportData',
        isLoading,
    })

export const setColumns = (
    columns: string[],
    options: SetColumnsOptions = { needsRefetch: true }
) =>
    staffReportStore.send({
        type: 'setColumns',
        columns,
        options,
    })

export const setPendingChanges = (pending: boolean) =>
    staffReportStore.send({
        type: 'setPendingChanges',
        pending,
    })

// Selector hooks
export const useReportData = () =>
    useSelector(staffReportStore, (state) => state.context.reportData)

export const useOrganisationReport = () =>
    useSelector(staffReportStore, (state) => state.context.organisationReport)

export const useIsLoadingReportData = () =>
    useSelector(staffReportStore, (state) => state.context.isLoadingReportData)

export const useColumns = () => {
    const report = useOrganisationReport()
    return useMemo(() => report?.columns ?? null, [report])
}

export const usePendingChanges = () =>
    useSelector(staffReportStore, (state) => state.context.pendingChanges)

// Snapshot getter
export const getStaffReportSnapshot = () =>
    staffReportStore.getSnapshot().context
