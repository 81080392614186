import { createFileRoute } from '@tanstack/react-router'
import ExpenseReportsPageHeader from '../../Pages/ExpenseReportsPage/ExpenseReportsPageHeader'
import ExpenseReportsPage from '../../Pages/ExpenseReportsPage/ExpenseReportsPage'
import PageBody from '../../Pages/Layout/PageBody'
import { qf } from '../../Queries/queryFormatter'
import SessionStore from '../../State/SessionStore'
import ExpenseReportStore from '../../Pages/ExpenseReportsPage/ExpenseReportStore'
import fetchData from '../../Queries/fetchData'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import {
    canViewRoute,
    migratedToV2,
} from '../../State/Permissions/HasPermissions'
import PermissionDenied from '../../Pages/PermissionDenied'
import { setLayoutDefaults } from '@2/layout/layout-store'
import { getCacheItemById } from '@2/cache'
import { CenterPadPageBody } from '@2/layout/page-body'

export const Route = createFileRoute('/_private/expense-reports')({
    beforeLoad: ({ params, search }) => {
        if (!canViewRoute(SessionStore.user, 'expenseReports', params))
            throw new Error('Permission Denied')
        const report = getCacheItemById('organisationReports', search.report)
        setLayoutDefaults({
            pageTitle: report?.name || 'Expense Report',
            subMenu: 'expenses',
        })
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    loader: async ({ location }) => await loader(location.search),
    loaderDeps: ({ search: { report } }) => ({
        report,
    }),
    pendingComponent: LoadingSpinner,
    component: Wrapper,
})

const loader = async ({ report }) => {
    const reportId = report || SessionStore.organisation?.defaultExpenseReportId
    if (report) ExpenseReportStore.setReportId(reportId)
    const collections = [
        reportId
            ? {
                  collection: 'reports',
                  fields: [
                      'name',
                      'type',
                      'columns',
                      'filters',
                      'groupBy',
                      'sortBy',
                      'options',
                  ],
                  filters: [`id == ${qf(reportId)}`],
              }
            : null,
        {
            collection: 'reports',
            fields: ['name', 'type'],
        },
        {
            collection: 'contacts',
            fields: ['firstName', 'lastName', 'organisationName'],
        },
    ].filter(Boolean)
    return Promise.all(collections.map(fetchData))
}

function Wrapper() {
    if (migratedToV2()) {
        return (
            <CenterPadPageBody>
                <ExpenseReportsPage />
            </CenterPadPageBody>
        )
    }
    return (
        <>
            <ExpenseReportsPageHeader />
            <PageBody>
                <ExpenseReportsPage />
            </PageBody>
        </>
    )
}
