import { Calendar as CalendarIcon } from 'lucide-react'
import { cn } from '@2/lib/utils'
import { Input } from '@2/components/ui/input'
import { Calendar } from '@2/components/ui/calendar'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@2/components/ui/popover'
import { useEffect, useRef, useState } from 'react'
import { FormatDate } from '@/Utils/Localisation/DateFormatter'
import { ParseDate } from '@/Utils/Localisation/DateParser'

interface DatePickerProps {
    value: Date | undefined
    onChange: (date: Date | undefined) => void
    className?: string
    popoverClassName?: string
}

export function DatePicker({
    value,
    onChange,
    className,
    popoverClassName,
}: DatePickerProps) {
    const [open, setOpen] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const [month, setMonth] = useState(value || new Date())
    const [inputValue, setInputValue] = useState(
        value
            ? FormatDate(value, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
              })
            : ''
    )

    useEffect(() => {
        if (open) {
            // Use a small delay to ensure the input gets focus after the popover opens
            const timeoutId = setTimeout(() => {
                inputRef.current?.focus()
            }, 0)
            return () => clearTimeout(timeoutId)
        }
    }, [open])

    const handleSelect = (date: Date | undefined) => {
        onChange(date)
        setInputValue(
            date
                ? FormatDate(date, {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                  })
                : ''
        )
        inputRef.current?.focus()
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)

        const parsedDate = ParseDate(e.target.value)
        if (!isNaN(parsedDate?.getTime?.())) {
            onChange(parsedDate)
            setMonth(parsedDate)
        }
    }

    const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
        // If the input is already focused, prevent the click from bubbling
        // up to the PopoverTrigger to avoid toggling the calendar
        if (open && document.activeElement === inputRef.current) {
            e.stopPropagation()
        }
    }

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <div className="relative">
                    <Input
                        ref={inputRef}
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        onClick={handleInputClick}
                        placeholder="Pick a date"
                        className={cn(
                            'pl-10',
                            !value && 'text-muted-foreground',
                            className
                        )}
                    />
                    <CalendarIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                </div>
            </PopoverTrigger>
            <PopoverContent className={cn('w-auto p-0', popoverClassName)}>
                <Calendar
                    mode="single"
                    selected={value}
                    onSelect={handleSelect}
                    month={month}
                    onMonthChange={setMonth}
                />
            </PopoverContent>
        </Popover>
    )
}

export default DatePicker
