import { DataTableColumnHeader } from '../data-table-column-header'
import { renderCell } from './render-cell'
import _ from 'lodash'
import { sortingFns } from './sorting-fns'
import { generateFilterOptions } from './filters'
import { Input } from '@2/components/ui/input'

export function generateColumnDef(
    {
        key,
        type,
        title,
        permissions = (row) => true,
        size = 160,
        aggregationFn = null,
        formatter = (v) => v,
        cell = null,
        filterOptions = null,
        editable = false,
        onChange = (v) => {},
        onClick = (v) => {},
        onHideColumn = (column: string) => {},
        className = null,
    } = { key: '', type: '', title: '' }
) {
    return {
        accessorKey: key,
        header: ({ column }) => (
            <DataTableColumnHeader
                column={column}
                title={title}
                onHideColumn={onHideColumn}
            />
        ),
        cell: ({ getValue, row, column }) => {
            return renderCell({
                value: getValue(),
                row,
                column,
                editable,
                onClick,
                onChange,
                cell,
            })
        },
        size: size,
        minSize: size * 0.8,
        maxSize: size * 1.2,
        meta: {
            title,
            type,
            permissions: permissions,
            filterOptions: filterOptions || generateFilterOptions(type),
            formatter: formatter,
            className: className,
        },
        sortingFn: sortingFns[type],
        aggregationFn: aggregationFn,
    }
}

export function createGenerateColumnDef(defaultProps) {
    return (props) => generateColumnDef({ ...defaultProps, ...props })
}
