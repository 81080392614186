// From https://github.com/reduxjs/react-redux/blob/720f0ba79236cdc3e1115f4ef9a7760a21784b48/src/utils/shallowEqual.ts
function is(x, y) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  } else {
    return x !== x && y !== y;
  }
}
function shallowEqual(objA, objB) {
  if (is(objA, objB)) return true;
  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }
  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);
  if (keysA.length !== keysB.length) return false;
  for (let i = 0; i < keysA.length; i++) {
    if (!Object.prototype.hasOwnProperty.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
      return false;
    }
  }
  return true;
}

const symbolObservable = (() => typeof Symbol === 'function' && Symbol.observable || '@@observable')();
function toObserver(nextHandler, errorHandler, completionHandler) {
  const isObserver = typeof nextHandler === 'object';
  const self = isObserver ? nextHandler : undefined;
  return {
    next: (isObserver ? nextHandler.next : nextHandler)?.bind(self),
    error: (isObserver ? nextHandler.error : errorHandler)?.bind(self),
    complete: (isObserver ? nextHandler.complete : completionHandler)?.bind(self)
  };
}
function setter(context, recipe) {
  return recipe(context);
}
const inspectionObservers = new WeakMap();
function createStoreCore(initialContext, transitions, updater) {
  let observers;
  let listeners;
  const initialSnapshot = {
    context: initialContext,
    status: 'active',
    output: undefined,
    error: undefined
  };
  let currentSnapshot = initialSnapshot;
  const emit = ev => {
    if (!listeners) {
      return;
    }
    const type = ev.type;
    const typeListeners = listeners.get(type);
    if (typeListeners) {
      typeListeners.forEach(listener => listener(ev));
    }
  };
  const transition = createStoreTransition(transitions, updater);
  function receive(event) {
    let emitted;
    [currentSnapshot, emitted] = transition(currentSnapshot, event);
    inspectionObservers.get(store)?.forEach(observer => {
      observer.next?.({
        type: '@xstate.snapshot',
        event,
        snapshot: currentSnapshot,
        actorRef: store,
        rootId: store.sessionId
      });
    });
    observers?.forEach(o => o.next?.(currentSnapshot));
    emitted.forEach(emit);
  }
  const store = {
    on(emittedEventType, handler) {
      if (!listeners) {
        listeners = new Map();
      }
      let eventListeners = listeners.get(emittedEventType);
      if (!eventListeners) {
        eventListeners = new Set();
        listeners.set(emittedEventType, eventListeners);
      }
      const wrappedHandler = handler.bind(undefined);
      eventListeners.add(wrappedHandler);
      return {
        unsubscribe() {
          eventListeners.delete(wrappedHandler);
        }
      };
    },
    sessionId: uniqueId(),
    send(event) {
      inspectionObservers.get(store)?.forEach(observer => {
        observer.next?.({
          type: '@xstate.event',
          event,
          sourceRef: undefined,
          actorRef: store,
          rootId: store.sessionId
        });
      });
      receive(event);
    },
    getSnapshot() {
      return currentSnapshot;
    },
    getInitialSnapshot() {
      return initialSnapshot;
    },
    subscribe(observerOrFn) {
      const observer = toObserver(observerOrFn);
      observers ??= new Set();
      observers.add(observer);
      return {
        unsubscribe() {
          return observers?.delete(observer);
        }
      };
    },
    [symbolObservable]() {
      return this;
    },
    inspect: observerOrFn => {
      const observer = toObserver(observerOrFn);
      inspectionObservers.set(store, inspectionObservers.get(store) ?? new Set());
      inspectionObservers.get(store).add(observer);
      observer.next?.({
        type: '@xstate.actor',
        actorRef: store,
        rootId: store.sessionId
      });
      observer.next?.({
        type: '@xstate.snapshot',
        snapshot: initialSnapshot,
        event: {
          type: '@xstate.init'
        },
        actorRef: store,
        rootId: store.sessionId
      });
      return {
        unsubscribe() {
          return inspectionObservers.get(store)?.delete(observer);
        }
      };
    }
  };
  return store;
}

/**
 * Creates a **store** that has its own internal state and can be sent events
 * that update its internal state based on transitions.
 *
 * @example
 *
 * ```ts
 * const store = createStore({
 *   types: {
 *     // ...
 *   },
 *   context: { count: 0 },
 *   on: {
 *     inc: (context, event: { by: number }) => {
 *       return {
 *         count: context.count + event.by
 *       };
 *     }
 *   }
 * });
 *
 * store.subscribe((snapshot) => {
 *   console.log(snapshot);
 * });
 *
 * store.send({ type: 'inc', by: 5 });
 * // Logs { context: { count: 5 }, status: 'active', ... }
 * ```
 */

/**
 * Creates a **store** that has its own internal state and can be sent events
 * that update its internal state based on transitions.
 *
 * @example
 *
 * ```ts
 * const store = createStore(
 *   // Initial context
 *   { count: 0 },
 *   // Transitions
 *   {
 *     inc: (context, event: { by: number }) => {
 *       return {
 *         count: context.count + event.by
 *       };
 *     }
 *   }
 * );
 *
 * store.subscribe((snapshot) => {
 *   console.log(snapshot);
 * });
 *
 * store.send({ type: 'inc', by: 5 });
 * // Logs { context: { count: 5 }, status: 'active', ... }
 * ```
 */

function createStore(initialContextOrObject, transitions) {
  if (transitions === undefined) {
    return createStoreCore(initialContextOrObject.context, initialContextOrObject.on);
  }
  return createStoreCore(initialContextOrObject, transitions);
}

/**
 * Creates a `Store` with a provided producer (such as Immer's `producer(…)` A
 * store has its own internal state and can receive events.
 *
 * @example
 *
 * ```ts
 * import { produce } from 'immer';
 *
 * const store = createStoreWithProducer(produce, {
 *   // Initial context
 *   { count: 0 },
 *   // Transitions
 *   {
 *     on: {
 *       inc: (context, event: { by: number }) => {
 *         context.count += event.by;
 *       }
 *     }
 *   }
 * });
 *
 * store.subscribe((snapshot) => {
 *   console.log(snapshot);
 * });
 *
 * store.send({ type: 'inc', by: 5 });
 * // Logs { context: { count: 5 }, status: 'active', ... }
 * ```
 */

function createStoreWithProducer(producer, initialContextOrConfig, transitions) {
  if (typeof initialContextOrConfig === 'object' && 'context' in initialContextOrConfig && 'on' in initialContextOrConfig) {
    return createStoreCore(initialContextOrConfig.context, initialContextOrConfig.on, producer);
  }
  return createStoreCore(initialContextOrConfig, transitions, producer);
}
/**
 * Creates a store function, which is a function that accepts the current
 * snapshot and an event and returns a new snapshot.
 *
 * @param transitions
 * @param updater
 * @returns
 */
function createStoreTransition(transitions, updater) {
  return (snapshot, event) => {
    let currentContext = snapshot.context;
    const assigner = transitions?.[event.type];
    const emitted = [];
    const enqueue = {
      emit: ev => {
        emitted.push(ev);
      }
    };
    if (!assigner) {
      return [snapshot, emitted];
    }
    if (typeof assigner === 'function') {
      currentContext = updater ? updater(currentContext, draftContext => assigner?.(draftContext, event, enqueue)) : setter(currentContext, draftContext => Object.assign({}, currentContext, assigner?.(draftContext, event,
      // TODO: help me
      enqueue)));
    } else {
      const partialUpdate = {};
      for (const key of Object.keys(assigner)) {
        const propAssignment = assigner[key];
        partialUpdate[key] = typeof propAssignment === 'function' ? propAssignment(currentContext, event, enqueue) : propAssignment;
      }
      currentContext = Object.assign({}, currentContext, partialUpdate);
    }
    return [{
      ...snapshot,
      context: currentContext
    }, emitted];
  };
}

// create a unique 6-char id
function uniqueId() {
  return Math.random().toString(36).slice(6);
}

/**
 * An actor logic creator which creates store [actor
 * logic](https://stately.ai/docs/actors#actor-logic) for use with XState.
 *
 * @param initialContext The initial context for the store, either a function
 *   that returns context based on input, or the context itself
 * @param transitions The transitions object defining how the context updates
 *   due to events
 * @returns An actor logic creator function that creates store actor logic
 */

/**
 * An actor logic creator which creates store [actor
 * logic](https://stately.ai/docs/actors#actor-logic) for use with XState.
 *
 * @param config An object containing the store configuration
 * @param config.context The initial context for the store, either a function
 *   that returns context based on input, or the context itself
 * @param config.on An object defining the transitions for different event types
 * @param config.types Optional object to define custom event types
 * @returns An actor logic creator function that creates store actor logic
 */

function fromStore(initialContextOrObj, transitions) {
  let initialContext;
  let transitionsObj;
  if (typeof initialContextOrObj === 'object' && 'context' in initialContextOrObj) {
    initialContext = initialContextOrObj.context;
    transitionsObj = initialContextOrObj.on;
  } else {
    initialContext = initialContextOrObj;
    transitionsObj = transitions;
  }
  const transition = createStoreTransition(transitionsObj);
  return {
    transition: (snapshot, event, actorScope) => {
      const [nextSnapshot, emittedEvents] = transition(snapshot, event);
      emittedEvents.forEach(actorScope.emit);
      return nextSnapshot;
    },
    getInitialSnapshot: (_, input) => {
      return {
        status: 'active',
        context: typeof initialContext === 'function' ? initialContext(input) : initialContext,
        output: undefined,
        error: undefined
      };
    },
    getPersistedSnapshot: s => s,
    restoreSnapshot: s => s
  };
}

export { createStore, createStoreWithProducer, fromStore, shallowEqual };
