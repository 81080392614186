import { sortingDateFn, sortingTextFn } from '@/version2/types'
import { DataTable } from '@2/components/data-table/data-table'
import { useDataTable } from '@2/components/data-table/hooks/use-data-table'

import { getColumns } from './overhead-expenses-columns'
import {
    deleteOperationalExpenses,
    updateOperationalExpenses,
    useActiveOperationalExpenses,
} from './overhead-expenses-store'
import { useMemo } from 'react'

export function OverheadExpenseTable() {
    const operationalExpenses = useActiveOperationalExpenses()
    const columns = useMemo(
        () =>
            getColumns(
                (column: string) => {},
                updateOperationalExpenses,
                deleteOperationalExpenses
            ),
        []
    )
    const defaultSort = [
        {
            id: 'name',
            desc: false,
        },
    ]

    const { table } = useDataTable({
        data: operationalExpenses,
        defaultSort,
        columns,
        visibleColumns: columns.map((column) => column?.accessorKey),
        sortingFns: {
            sortingTextFn,
            sortingDateFn,
        },
    })

    return <DataTable table={table}></DataTable>
}
