import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import MilestoneCollection from '../../State/Collections/MilestoneCollection'
import { canEditProjectMilestones } from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import Table from '../../Components/Table'
import { PhaseSelector } from '../../Components/Selector'

const milestoneColumns = [
    {
        id: 'date',
        label: 'Date',
        width: 20,
        type: 'date',
        editable: (r) => canEditProjectMilestones(SessionStore.user, r.project),
        value: (r) => r.date,
        onChange: (r) => (v) => r.update({ date: v }),
        style: () => ({
            textAlign: 'left',
        }),
    },
    {
        label: 'Phase',
        width: 20,
        type: 'phase',
        editable: (r) => canEditProjectMilestones(SessionStore.user, r.project),
        value: (p) => p.phase,
        onChange: (r) => (v) => r.update({ phaseId: v.id }),
        component: ({ value, onChange, stores, editable }) => {
            const project = stores.row.rowObject.project
            return editable ? (
                <PhaseSelector
                    options={project.phases}
                    {...{ selectedPhase: value, onChange }}
                    variant="secondary"
                    className="!border-none h-full !rounded-none"
                />
            ) : (
                value?.title || 'No Phase'
            )
        },
    },
    {
        label: 'Milestone',
        width: 60,
        type: 'text',
        editable: (r) => canEditProjectMilestones(SessionStore.user, r.project),
        value: (p) => p.label,
        onChange: (r) => (v) => r.update({ label: v }),
    },
    {
        label: '',
        width: 4,
        type: 'button',
        permissions: (r) =>
            canEditProjectMilestones(SessionStore.user, r.project),
        editable: (r) => canEditProjectMilestones(SessionStore.user, r.project),
        value: (p) => <i className="fa fa-times" style={{ marginRight: 0 }} />,
        onClick: (r) => () => {
            r.update({ deletedAt: new Date() })
        },
    },
]

export default observer(({ id }) => {
    const project = ProjectCollection.projectsById[id]
    useEffect(() => {
        ;(project?.milestones || []).forEach((cli, i) => (cli.position = i))
    }, [project])
    if (!project) return null
    const [rows, setRows] = useState(
        (project?.milestones || []).sort(
            (a, b) => b?.date?.getTime() - a?.date?.getTime()
        )
    )
    useEffect(() => {
        setRows(
            (project?.milestones || []).sort(
                (a, b) => b?.date?.getTime() - a?.date?.getTime()
            )
        )
    }, [project.id])
    return (
        <div>
            {canEditProjectMilestones(SessionStore.user, project) ? (
                <button
                    className="btn btn-default plus-btn border-[#ccc]"
                    style={{ marginBottom: '2em' }}
                    onClick={() => {
                        const m = MilestoneCollection.add(
                            {
                                projectId: project.id,
                                phaseId: project.rootPhaseId,
                                date: new Date(),
                            },
                            { trackUpdates: true }
                        )
                        m.position = project.milestones.length
                        setRows([m, ...rows])
                    }}
                >
                    + Add Milestone
                </button>
            ) : null}
            <Table
                columns={milestoneColumns}
                rows={rows.filter((r) => !r.deletedAt)}
            />
        </div>
    )
})
