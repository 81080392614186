import * as React from 'react'
import { useSearch, useNavigate } from '@tanstack/react-router'
import type { DataTableFilterOption } from '@2/types'
import { TrashIcon } from '@radix-ui/react-icons'
import type { Table } from '@tanstack/react-table'

import { dataTableConfig } from '@2/config/data-table'
import { cn } from '@2/lib/utils'
import { useDebounce } from '@2/hooks/use-debounce'
import { Button } from '@2/components/ui/button'
import { Input } from '@2/components/ui/input'
import { Checkbox } from '@2/components/ui/checkbox'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@2/components/ui/popover'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@2/components/ui/select'
import { DatePicker } from '@2/components/date-picker'

import { DataTableAdvancedFacetedFilter } from '@2/components/data-table/advanced/data-table-advanced-faceted-filter'
import { DateRangePicker } from '@2/components/date-range-picker'
import {
    formatDateISOString,
    formatTimeISOString,
} from '@/version2/utils/format'
import { TimePicker } from '@/Components/ui/time-picker'
import { FormatTime } from '@/Utils/Localisation/TimeFormatter'

interface DataTableFilterItemProps<TData> {
    table: Table<TData>
    selectedOption: DataTableFilterOption<TData>
    selectedOptions: DataTableFilterOption<TData>[]
    onFilterChange: (newOptions: DataTableFilterOption<TData>[]) => void
    defaultOpen: boolean
    showApplyToPhase: boolean
}

export function DataTableFilterItem<TData>({
    table,
    selectedOption,
    selectedOptions,
    onFilterChange,
    defaultOpen,
    showApplyToPhase,
}: DataTableFilterItemProps<TData>) {
    const navigate = useNavigate()
    const search = useSearch({ strict: false })

    const column = table.getColumn(
        selectedOption.value ? String(selectedOption.value) : ''
    )

    const columnSelected = selectedOptions.find(
        (item) => item.value === column?.id
    )

    const filterValues = Array.isArray(columnSelected?.filterValues)
        ? columnSelected?.filterValues
        : [columnSelected?.filterValues]

    const selectedValues = new Set(filterValues)

    const filterOperator = selectedOptions.find(
        (item) => item.value === column?.id
    )?.filterOperator

    const operatorMap = {
        text: dataTableConfig.textComparisonOperators,
        number: dataTableConfig.numberComparisonOperators,
        percent: dataTableConfig.numberComparisonOperators,
        currency: dataTableConfig.numberComparisonOperators,
        date: dataTableConfig.dateComparisonOperators,
        time: dataTableConfig.dateComparisonOperators,
    }

    const operators =
        operatorMap[selectedOption.typeFilter] ||
        dataTableConfig.selectableOperators

    const [value, setValue] = React.useState(filterValues[0] ?? '')
    const [secondValue, setSecondValue] = React.useState(filterValues[1] ?? '')
    const [singleDate, setSingleDate] = React.useState<Date>(
        filterValues[0] ? new Date(filterValues[0]) : new Date()
    )
    const [startDate, setStartDate] = React.useState<Date>(
        filterValues[0] ? new Date(filterValues[0]) : new Date()
    )
    const [endDate, setEndDate] = React.useState<Date>(
        filterValues[1] ? new Date(filterValues[1]) : new Date()
    )
    const debounceValue = useDebounce(value, 500)
    const [open, setOpen] = React.useState(defaultOpen)
    const [selectedOperator, setSelectedOperator] = React.useState(
        operators.find((c) => c.value === filterOperator) ?? operators[0]
    )

    const [applyToPhases, setApplyToPhases] = React.useState(
        columnSelected.isApplyToPhases
    )

    // Create query string
    const createQueryString = React.useCallback(
        (params: Record<string, string | number | null>) => {
            const newSearch = { ...search }

            for (const [key, value] of Object.entries(params)) {
                if (value === null) {
                    delete newSearch[key]
                } else {
                    newSearch[key] = String(value)
                }
            }

            return newSearch
        },
        [search]
    )

    // Update query string
    // React.useEffect(() => {
    //     if (selectedOption.options.length > 0) {
    //         // key=value1.value2.value3~operator
    //         const newSearch = createQueryString({
    //             [String(selectedOption.value)]:
    //                 filterValues.length > 0
    //                     ? `${filterValues.join('.')}~${selectedOperator?.value}`
    //                     : null,
    //         })
    //         navigate({ search: newSearch })
    //     } else {
    //         const newSearch = createQueryString({
    //             [String(selectedOption.value)]:
    //                 debounceValue.length > 0
    //                     ? `${debounceValue}~${selectedOperator?.value}`
    //                     : null,
    //         })
    //         navigate({ search: newSearch })
    //     }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedOption, debounceValue, selectedOperator])

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    size="sm"
                    className={cn(
                        'h-7 gap-0 truncate rounded-full',
                        (selectedValues.size > 0 ||
                            value.toString().length > 0) &&
                            'bg-muted/50'
                    )}
                >
                    <span className="font-medium capitalize">
                        {selectedOption.label}
                    </span>
                    {selectedOption.typeFilter === 'select' ? (
                        selectedValues.size > 0 && (
                            <span className="text-muted-foreground">
                                <span className="text-foreground">: </span>
                                {selectedValues.size > 2
                                    ? `${selectedValues.size} selected`
                                    : selectedOption.options
                                          .filter((item) =>
                                              selectedValues.has(item.value)
                                          )
                                          .map((item) => item.label)
                                          .join(', ')}
                            </span>
                        )
                    ) : selectedOption.typeFilter === 'date' ? (
                        <span className="text-muted-foreground">
                            <span className="text-foreground">: </span>
                            {selectedOperator.value === 'between' ||
                            selectedOperator.value === '!between' ? (
                                <>
                                    {startDate &&
                                        startDate.toLocaleDateString()}
                                    {endDate &&
                                        ` - ${endDate.toLocaleDateString()}`}
                                </>
                            ) : (
                                <>
                                    {singleDate &&
                                        singleDate.toLocaleDateString()}
                                </>
                            )}
                        </span>
                    ) : selectedOption.typeFilter === 'time' ? (
                        <span className="text-muted-foreground">
                            <span className="text-foreground">: </span>
                            {selectedOperator.value === 'between' ||
                            selectedOperator.value === '!between' ? (
                                <>
                                    {startDate && FormatTime(startDate)}
                                    {endDate && ` - ${FormatTime(endDate)}`}
                                </>
                            ) : (
                                <>{singleDate && FormatTime(singleDate)}</>
                            )}
                        </span>
                    ) : (
                        (value.toString().length > 0 ||
                            secondValue.toString().length > 0) && (
                            <span className="text-muted-foreground">
                                <span className="text-foreground">: </span>
                                {value}
                                {((secondValue.toString().length > 0 &&
                                    selectedOperator.value === 'between') ||
                                    selectedOperator.value === '!between') &&
                                    ` - ${secondValue}`}
                                {selectedOption.typeFilter === 'percent' && '%'}
                                {selectedOption.typeFilter === 'currency' &&
                                    '$'}
                            </span>
                        )
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-80 space-y-1.5 p-2" align="start">
                <div className="flex items-center space-x-1 pl-1 pr-0.5 mb-2">
                    <div className="flex flex-1 items-center space-x-1">
                        <div className="text-xs capitalize text-muted-foreground">
                            {selectedOption.label}
                        </div>
                        <Select
                            value={selectedOperator?.value}
                            onValueChange={(value) => {
                                setSelectedOperator(
                                    operators.find((c) => c.value === value)
                                )
                                const currentOptions = selectedOptions
                                const newOptions = currentOptions.map(
                                    (option) =>
                                        option.value === selectedOption.value
                                            ? {
                                                  ...option,
                                                  filterOperator: value,
                                              }
                                            : option
                                )
                                onFilterChange(newOptions)
                            }}
                        >
                            <SelectTrigger className="h-auto w-fit truncate border-none px-2 py-0.5 text-xs hover:bg-muted/50">
                                <SelectValue
                                    placeholder={selectedOperator?.label}
                                />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    {operators.map((item) => (
                                        <SelectItem
                                            key={item.value}
                                            value={item.value}
                                            className="py-1"
                                        >
                                            {item.label}
                                        </SelectItem>
                                    ))}
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                    <Button
                        aria-label="Remove filter"
                        variant="ghost"
                        size="icon"
                        className="size-7 text-muted-foreground"
                        onClick={() => {
                            const currentOptions = selectedOptions
                            const newOptions = currentOptions.filter(
                                (item) => item.value !== selectedOption.value
                            )
                            onFilterChange(newOptions)
                        }}
                    >
                        <TrashIcon className="size-4" aria-hidden="true" />
                    </Button>
                </div>
                {selectedOption.typeFilter === 'date' ? (
                    <>
                        {selectedOperator.value === 'between' ||
                        selectedOperator.value === '!between' ? (
                            <DateRangePicker
                                initialDateFrom={startDate}
                                initialDateTo={endDate}
                                onUpdate={({ range }) => {
                                    setStartDate(range.from)
                                    setEndDate(range.to)
                                    const currentOptions = selectedOptions
                                    const newOptions = currentOptions.map(
                                        (option) => {
                                            if (
                                                option.value ===
                                                selectedOption.value
                                            ) {
                                                return {
                                                    ...option,
                                                    filterValues: [
                                                        formatDateISOString(
                                                            range.from
                                                        ),
                                                        formatDateISOString(
                                                            range.to
                                                        ),
                                                    ],
                                                }
                                            }
                                            return option
                                        }
                                    )
                                    onFilterChange(newOptions)
                                }}
                                align="start"
                                showCompare={false}
                            />
                        ) : (
                            <DatePicker
                                value={singleDate}
                                onChange={(date) => {
                                    setSingleDate(date)
                                    const currentOptions = selectedOptions
                                    const newOptions = currentOptions.map(
                                        (option) =>
                                            option.value ===
                                            selectedOption.value
                                                ? {
                                                      ...option,
                                                      filterValues:
                                                          formatDateISOString(
                                                              date
                                                          ),
                                                  }
                                                : option
                                    )
                                    onFilterChange(newOptions)
                                }}
                            />
                        )}
                    </>
                ) : selectedOption.typeFilter === 'time' ? (
                    <>
                        {selectedOperator.value === 'between' ||
                        selectedOperator.value === '!between' ? (
                            <div>
                                <TimePicker
                                    value={startDate}
                                    onChange={(date) => {
                                        setStartDate(date)
                                        const currentOptions = selectedOptions
                                        const newOptions = currentOptions.map(
                                            (option) => {
                                                if (
                                                    option.value ===
                                                    selectedOption.value
                                                ) {
                                                    return {
                                                        ...option,
                                                        filterValues: [
                                                            formatTimeISOString(
                                                                date
                                                            ),
                                                            formatTimeISOString(
                                                                endDate
                                                            ),
                                                        ],
                                                    }
                                                }
                                                return option
                                            }
                                        )
                                        onFilterChange(newOptions)
                                    }}
                                />
                                <TimePicker
                                    value={endDate}
                                    onChange={(date) => {
                                        setEndDate(date)
                                        const currentOptions = selectedOptions
                                        const newOptions = currentOptions.map(
                                            (option) => {
                                                if (
                                                    option.value ===
                                                    selectedOption.value
                                                ) {
                                                    return {
                                                        ...option,
                                                        filterValues: [
                                                            formatTimeISOString(
                                                                startDate
                                                            ),
                                                            formatTimeISOString(
                                                                date
                                                            ),
                                                        ],
                                                    }
                                                }
                                                return option
                                            }
                                        )
                                        onFilterChange(newOptions)
                                    }}
                                />
                            </div>
                        ) : (
                            <TimePicker
                                value={singleDate}
                                onChange={(date) => {
                                    setSingleDate(date)
                                    const currentOptions = selectedOptions
                                    const newOptions = currentOptions.map(
                                        (option) => {
                                            if (
                                                option.value ===
                                                selectedOption.value
                                            ) {
                                                return {
                                                    ...option,
                                                    filterValues:
                                                        formatTimeISOString(
                                                            date
                                                        ),
                                                }
                                            }
                                            return option
                                        }
                                    )
                                    onFilterChange(newOptions)
                                }}
                            />
                        )}
                    </>
                ) : selectedOption.typeFilter === 'select' ? (
                    column && (
                        <DataTableAdvancedFacetedFilter
                            key={String(selectedOption.value)}
                            column={column}
                            title={selectedOption.label}
                            options={selectedOption.options}
                            groupBy={selectedOption.groupBy}
                            sortGroups={selectedOption.sortGroups}
                            selectedValues={selectedValues}
                            selectedOptions={selectedOptions}
                            onFilterChange={onFilterChange}
                        />
                    )
                ) : (
                    <>
                        {selectedOperator.value === 'between' ||
                        selectedOperator.value === '!between' ? (
                            <div className="flex space-x-2">
                                <Input
                                    suffix={
                                        selectedOption.typeFilter === 'percent'
                                            ? '%'
                                            : selectedOption.typeFilter ===
                                                'currency'
                                              ? '$'
                                              : ''
                                    }
                                    placeholder="From"
                                    className="h-8"
                                    value={value}
                                    onChange={(e) => {
                                        setValue(e.target.value)
                                        const currentOptions = selectedOptions
                                        const newOptions = currentOptions.map(
                                            (option) => {
                                                if (
                                                    option.value ===
                                                    selectedOption.value
                                                ) {
                                                    return {
                                                        ...option,
                                                        filterValues: [
                                                            e.target.value !==
                                                            ''
                                                                ? Number(
                                                                      e.target
                                                                          .value
                                                                  )
                                                                : '',
                                                            secondValue,
                                                        ],
                                                    }
                                                }
                                                return option
                                            }
                                        )
                                        onFilterChange(newOptions)
                                    }}
                                    autoFocus
                                />
                                <Input
                                    suffix={
                                        selectedOption.typeFilter === 'percent'
                                            ? '%'
                                            : selectedOption.typeFilter ===
                                                'currency'
                                              ? '$'
                                              : ''
                                    }
                                    placeholder="To"
                                    className="h-8"
                                    value={secondValue}
                                    onChange={(e) => {
                                        setSecondValue(e.target.value)
                                        const currentOptions = selectedOptions
                                        const newOptions = currentOptions.map(
                                            (option) => {
                                                if (
                                                    option.value ===
                                                    selectedOption.value
                                                ) {
                                                    return {
                                                        ...option,
                                                        filterValues: [
                                                            value,
                                                            e.target.value !==
                                                            ''
                                                                ? Number(
                                                                      e.target
                                                                          .value
                                                                  )
                                                                : '',
                                                        ],
                                                    }
                                                }
                                                return option
                                            }
                                        )
                                        onFilterChange(newOptions)
                                    }}
                                />
                            </div>
                        ) : (
                            <Input
                                suffix={
                                    selectedOption.typeFilter === 'percent'
                                        ? '%'
                                        : selectedOption.typeFilter ===
                                            'currency'
                                          ? '$'
                                          : ''
                                }
                                className="h-8"
                                value={value}
                                onChange={(e) => {
                                    setValue(e.target.value)
                                    const currentOptions = selectedOptions
                                    const newOptions = currentOptions.map(
                                        (option) => {
                                            if (
                                                option.value ===
                                                selectedOption.value
                                            ) {
                                                return {
                                                    ...option,
                                                    filterValues:
                                                        e.target.value,
                                                }
                                            }
                                            return option
                                        }
                                    )
                                    onFilterChange(newOptions)
                                }}
                                autoFocus
                            />
                        )}
                    </>
                )}
                {showApplyToPhase && (
                    <label className="!mt-4 flex items-center gap-x-2">
                        <Checkbox
                            checked={applyToPhases}
                            onCheckedChange={(checked) => {
                                setApplyToPhases(checked === true)
                                const currentOptions = selectedOptions
                                const newOptions = currentOptions.map(
                                    (option) => {
                                        if (
                                            option.value ===
                                            selectedOption.value
                                        ) {
                                            return {
                                                ...option,
                                                isApplyToPhases: checked,
                                            }
                                        }
                                        return option
                                    }
                                )
                                onFilterChange(newOptions)
                            }}
                        />
                        <span>Apply to phases</span>
                    </label>
                )}
            </PopoverContent>
        </Popover>
    )
}
