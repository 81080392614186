import { useSyncExternalStore, useCallback, useRef } from 'react';

function defaultCompare(a, b) {
  return a === b;
}
function useSelectorWithCompare(selector, compare) {
  const previous = useRef();
  return state => {
    const next = selector(state);
    return compare(previous.current, next) ? previous.current : previous.current = next;
  };
}

/**
 * A React hook that subscribes to the `store` and selects a value from the
 * store's snapshot, with an optional compare function.
 *
 * @example
 *
 * ```ts
 * function Component() {
 *   const count = useSelector(store, (s) => s.count);
 *
 *   return <div>{count}</div>;
 * }
 * ```
 *
 * @param store The store, created from `createStore(…)`
 * @param selector A function which takes in the `snapshot` and returns a
 *   selected value
 * @param compare An optional function which compares the selected value to the
 *   previous value
 * @returns The selected value
 */
function useSelector(store, selector, compare = defaultCompare) {
  const selectorWithCompare = useSelectorWithCompare(selector, compare);
  return useSyncExternalStore(useCallback(handleStoreChange => store.subscribe(handleStoreChange).unsubscribe, [store]), () => selectorWithCompare(store.getSnapshot()), () => selectorWithCompare(store.getInitialSnapshot()));
}

export { useSelector };
