import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'
import { z } from 'zod'
import SessionStore, { settingDefaults } from '../../../State/SessionStore'

// Zod schemas
export const AutoUpdateSchema = z.object({
    action: z.string(),
    adjustOnLoad: z.boolean(),
    budget: z.string(),
    start: z.string(),
    end: z.string(),
})

export const SettingSchema = z.object({
    sortPhasesBy: z.string(),
    allowNoPhase: z.boolean(),
    useTasks: z.boolean(),
    allowAfterPhaseEnd: z.boolean(),
    timeEntryAllocations: z.array(z.string()),
    timeEntryStatus: z.array(z.string()),
    timeEntryFlags: z.array(z.string()),
    autoPopulate: z.array(z.string()), // ["budgets", "allocations"]
    updateHoursFromRevenue: z.boolean(),
    updateRevenueFromHours: z.boolean(),
    autoUpdateRevenue: AutoUpdateSchema,
    autoUpdateHours: AutoUpdateSchema,
    reportInvoiceDateType: z.string(),
    savingInvoices: z.array(z.string()), // "lockTime", "automatic"
})

export type SettingData = z.infer<typeof SettingSchema>
export type AutoUpdateData = z.infer<typeof AutoUpdateSchema>

type StoreContext = SettingData & {
    isPendingChanges: boolean
}
// Initial state
const initialContext: StoreContext = {
    isPendingChanges: false,
    ...settingDefaults,
    ...(SessionStore.organisation?.settings || {}),
}

// Create the store
export const settingStore = createStore({
    context: initialContext,
    on: {
        changeSortPhasesBy: {
            sortPhasesBy: (_, event: { value: string }) => event.value,
            isPendingChanges: true,
        },
        changeAllowNoPhase: {
            allowNoPhase: (_, event: { value: boolean }) => event.value,
            isPendingChanges: true,
        },
        changeUseTasks: {
            useTasks: (_, event: { value: boolean }) => event.value,
            isPendingChanges: true,
        },
        changeAllowAfterPhaseEnd: {
            allowAfterPhaseEnd: (_, event: { value: boolean }) => event.value,
            isPendingChanges: true,
        },
        changeTimeEntryAllocations: {
            timeEntryAllocations: (_, event: { value: string[] }) =>
                event.value,
            isPendingChanges: true,
        },
        changeTimeEntryStatus: {
            timeEntryStatus: (_, event: { value: string[] }) => event.value,
            isPendingChanges: true,
        },
        changeTimeEntryFlags: {
            timeEntryFlags: (_, event: { value: string[] }) => event.value,
            isPendingChanges: true,
        },
        changeAutoPopulate: {
            autoPopulate: (_, event: { value: string[] }) => event.value,
            isPendingChanges: true,
        },
        changeUpdateHoursFromRevenue: {
            updateHoursFromRevenue: (_, event: { value: boolean }) =>
                event.value,
            isPendingChanges: true,
        },
        changeUpdateRevenueFromHours: {
            updateRevenueFromHours: (_, event: { value: boolean }) =>
                event.value,
            isPendingChanges: true,
        },
        changeAutoUpdateRevenue: {
            autoUpdateRevenue: (context, event: { value: AutoUpdateData }) => ({
                ...context.autoUpdateRevenue,
                ...event.value,
            }),
            isPendingChanges: true,
        },
        changeAutoUpdateHours: {
            autoUpdateHours: (context, event: { value: AutoUpdateData }) => ({
                ...context.autoUpdateHours,
                ...event.value,
            }),
            isPendingChanges: true,
        },
        changeReportInvoiceDateType: {
            reportInvoiceDateType: (_, event: { value: string }) => event.value,
            isPendingChanges: true,
        },
        changeSavingInvoices: {
            savingInvoices: (_, event: { value: string[] }) => event.value,
            isPendingChanges: true,
        },
        updatePendingChanges: {
            isPendingChanges: (_, event: { value: boolean }) => event.value,
        },
        reset: {
            ...initialContext,
        },
    },
})

// Action creators
const changeSortPhasesBy = (value: string) =>
    settingStore.send({
        type: 'changeSortPhasesBy',
        value,
    })
const changeAllowNoPhase = (value: boolean) =>
    settingStore.send({
        type: 'changeAllowNoPhase',
        value,
    })
const changeUseTasks = (value: boolean) =>
    settingStore.send({
        type: 'changeUseTasks',
        value,
    })
const changeAllowAfterPhaseEnd = (value: boolean) =>
    settingStore.send({
        type: 'changeAllowAfterPhaseEnd',
        value,
    })
const changeTimeEntryAllocations = (value: string[]) =>
    settingStore.send({
        type: 'changeTimeEntryAllocations',
        value,
    })
const changeTimeEntryStatus = (value: string[]) =>
    settingStore.send({
        type: 'changeTimeEntryStatus',
        value,
    })
const changeTimeEntryFlags = (value: string[]) =>
    settingStore.send({
        type: 'changeTimeEntryFlags',
        value,
    })
const changeAutoPopulate = (value: string[]) =>
    settingStore.send({
        type: 'changeAutoPopulate',
        value,
    })
const changeUpdateHoursFromRevenue = (value: boolean) =>
    settingStore.send({
        type: 'changeUpdateHoursFromRevenue',
        value,
    })
const changeUpdateRevenueFromHours = (value: boolean) =>
    settingStore.send({
        type: 'changeUpdateRevenueFromHours',
        value,
    })
const changeAutoUpdateRevenue = (value: AutoUpdateData) =>
    settingStore.send({
        type: 'changeAutoUpdateRevenue',
        value,
    })
const changeAutoUpdateHours = (value: AutoUpdateData) =>
    settingStore.send({
        type: 'changeAutoUpdateHours',
        value,
    })
const changeReportInvoiceDateType = (value: string) =>
    settingStore.send({
        type: 'changeReportInvoiceDateType',
        value,
    })
const changeSavingInvoices = (value: string[]) =>
    settingStore.send({
        type: 'changeSavingInvoices',
        value,
    })
const updatePendingChanges = (value: boolean) =>
    settingStore.send({
        type: 'updatePendingChanges',
        value,
    })
const reset = () =>
    settingStore.send({
        type: 'reset',
    })

export const settingActions = {
    changeSortPhasesBy,
    changeAllowNoPhase,
    changeUseTasks,
    changeAllowAfterPhaseEnd,
    changeTimeEntryAllocations,
    changeTimeEntryStatus,
    changeTimeEntryFlags,
    changeAutoPopulate,
    changeUpdateHoursFromRevenue,
    changeUpdateRevenueFromHours,
    changeAutoUpdateRevenue,
    changeAutoUpdateHours,
    changeReportInvoiceDateType,
    changeSavingInvoices,
    updatePendingChanges,
    reset,
}

// Selector hooks
export const useSetting = () =>
    useSelector(settingStore, (state) => state.context)
export const useSortPhasesBy = () =>
    useSelector(settingStore, (state) => state.context.sortPhasesBy)
export const useAllowNoPhase = () =>
    useSelector(settingStore, (state) => state.context.allowNoPhase)
export const useUseTasks = () =>
    useSelector(settingStore, (state) => state.context.useTasks)
export const useAllowAfterPhaseEnd = () =>
    useSelector(settingStore, (state) => state.context.allowAfterPhaseEnd)
export const useTimeEntryAllocations = () =>
    useSelector(settingStore, (state) => state.context.timeEntryAllocations)
export const useTimeEntryStatus = () =>
    useSelector(settingStore, (state) => state.context.timeEntryStatus)
export const useTimeEntryFlags = () =>
    useSelector(settingStore, (state) => state.context.timeEntryFlags)
export const useAutoPopulate = () =>
    useSelector(settingStore, (state) => state.context.autoPopulate)
export const useUpdateHoursFromRevenue = () =>
    useSelector(settingStore, (state) => state.context.updateHoursFromRevenue)
export const useUpdateRevenueFromHours = () =>
    useSelector(settingStore, (state) => state.context.updateRevenueFromHours)
export const useAutoUpdateRevenue = () =>
    useSelector(settingStore, (state) => state.context.autoUpdateRevenue)
export const useAutoUpdateHours = () =>
    useSelector(settingStore, (state) => state.context.autoUpdateHours)
export const useReportInvoiceDateType = () =>
    useSelector(settingStore, (state) => state.context.reportInvoiceDateType)
export const useSavingInvoices = () =>
    useSelector(settingStore, (state) => state.context.savingInvoices)
export const usePendingChanges = () =>
    useSelector(settingStore, (state) => state.context.isPendingChanges)

// Getter for non-reactive access
