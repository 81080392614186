import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import Table from '../../Components/Table'
import CashflowChart from '../../Components/Widgets/CashflowChart'
import RevenueForecastStore from './RevenueForecastStore'
import { useMediaQuery } from '@react-hook/media-query'
import useResizeObserver from '@react-hook/resize-observer'
import RenderOnQueries from '../Layout/RenderOnQueries'
import LoadingSpinner from '../../Components/LoadingSpinner'
import { RevenueForecastReportPhaseColumns } from '../../reports/RevenueForecast/RevenueForecastReportPhaseColumns'
import { addMonths, format } from 'date-fns'
import FetchStore from '../../Queries/FetchStore'
import { RevenueForecastReportProfitColumns } from '../../reports/RevenueForecast/RevenueForecastReportProfitColumns'
import { RevenueForecastReportStaffColumns } from '../../reports/RevenueForecast/RevenueForecastReportStaffColumns'
import { RevenueForecastReportOverheadColumns } from '../../reports/RevenueForecast/RevenueForecastReportOverheadColumns'
import {
    canViewRevenueForecastOperationalExpenses,
    canViewRevenueForecastStaffExpenses,
    canViewStaffPayRate,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import { FormatCurrency } from '../../Utils/Localisation/CurrencyFormatter'
import { queryClient } from '../../App'
import UpdateReportModal from '../../Components/UpdateReportModal'
import sortPhases from '../../Utils/sortPhases'

const statusOrder = ['active', 'prospective', 'onHold', 'archived']

const useSize = (target) => {
    const [size, setSize] = React.useState()

    React.useLayoutEffect(() => {
        setSize(target.current.getBoundingClientRect())
    }, [target])

    // Where the magic happens
    useResizeObserver(target, (entry) => setSize(entry.contentRect))
    return size
}

const spacerColumn = {
    label: '',
    width: 3,
    type: 'text',
    value: (r) => '',
    onChange: (r) => (v) => null,
}

const ProfitTable = observer(({ store }) => {
    if (
        !canViewRevenueForecastStaffExpenses(SessionStore.user) &&
        !canViewRevenueForecastOperationalExpenses(SessionStore.user)
    )
        return null
    const profitRow =
        store.report.filters.profitDataType === 'margin'
            ? 'profitMargin'
            : 'markup'
    return (
        <>
            <Table
                style={{ margin: '2em 0 0 0' }}
                columns={[
                    spacerColumn,
                    RevenueForecastReportProfitColumns(store).title(),
                    RevenueForecastReportProfitColumns(store).total(
                        null,
                        store
                    ),
                    ...[...Array(12)].map((v, i) => {
                        return RevenueForecastReportProfitColumns(store).profit(
                            addMonths(store.startDate, i),
                            store
                        )
                    }),
                ]}
                rows={['profit']}
            />
            <Table
                columns={[
                    spacerColumn,
                    RevenueForecastReportProfitColumns(store).title(),
                    RevenueForecastReportProfitColumns(store).total(
                        null,
                        store
                    ),
                    ...[...Array(12)].map((v, i) => {
                        return RevenueForecastReportProfitColumns(store)[
                            profitRow
                        ](addMonths(store.startDate, i), store)
                    }),
                ]}
                style={{ margin: '0 0 2em 0' }}
                showHeader={false}
                rows={[profitRow]}
            />
        </>
    )
})

const RevenueTableByStatus = observer(({ store }) => {
    const phaseColumns = RevenueForecastReportPhaseColumns(store)
    return (
        <Table
            tableStore={store.projectTableStore}
            style={{ margin: '2em 0' }}
            columns={[
                {
                    id: 'statusPosition',
                    label: '',
                    width: 3,
                    visible: false,
                    type: 'number',
                    value: (row) => {
                        return statusOrder.indexOf(row.title) + 1
                    },
                },
                phaseColumns.title(null, {}, {}),
                phaseColumns.revenueVsFee(null, {}, {}),
                ...[...Array(12)].map((v, i) => {
                    return phaseColumns.revenue(
                        addMonths(store.startDate, i),
                        {},
                        {}
                    )
                }),
            ]}
            rows={[...store.getRevenueRows(null)].sort(
                (a, b) =>
                    statusOrder.indexOf(a.title) - statusOrder.indexOf(b.title)
            )}
            showTotals={true}
            getChildComponent={(r) => {
                return <RevenueTableByProject status={r.key} store={store} />
            }}
            // onExpand={(row) => {
            //     store.expandRevenueRow(row.status)
            // }}
            // onCollapse={(row) => store.collapseRevenueRow(row.status)}
        />
    )
})

const RevenueTableByProject = observer(({ store, status }) => {
    const level = 1
    const bgColor = 256 - level * 5
    const bgShadow = bgColor - 10
    const phaseColumns = RevenueForecastReportPhaseColumns(store, status)
    return (
        <Table
            columns={[
                phaseColumns.title(
                    null,
                    {},
                    {},
                    {
                        status: status,
                    }
                ),
                phaseColumns.revenueVsFee(
                    null,
                    {},
                    {},
                    {
                        status: status,
                    }
                ),
                ...[...Array(12)].map((v, i) => {
                    return phaseColumns.revenue(
                        addMonths(store.startDate, i),
                        {},
                        {},
                        {
                            status: status,
                        }
                    )
                }),
            ]}
            rows={store
                .getRevenueRows(status)
                .sort((a, b) => a.title?.localeCompare?.(b.title))}
            showHeader={false}
            getChildComponent={(r) => {
                return (
                    <RevenueTableByPhase
                        status={status}
                        projectId={r.key}
                        store={store}
                    />
                )
            }}
            style={{
                boxShadow: 'none',
                background: `linear-gradient(180deg, rgb(${bgShadow},${bgShadow},${bgShadow}) 1px, rgb(${bgColor},${bgColor},${bgColor}) 8px)`,
                fontSize: '1.2rem',
            }}
            // onExpand={(row) =>
            //     store.expandRevenueRow(row.status, row.projectId)
            // }
            // onCollapse={(row) =>
            //     store.expandRevenueRow(row.status, row.projectId)
            // }
            sortBy={store.projectTableStore.sortBy}
        />
    )
})

const RevenueTableByPhase = observer(({ store, status, projectId }) => {
    const level = 2
    const bgColor = 256 - level * 5
    const bgShadow = bgColor - 10
    const phaseColumns = RevenueForecastReportPhaseColumns(
        store,
        [status, projectId].join(',')
    )
    return (
        <Table
            columns={[
                spacerColumn,
                phaseColumns.title(
                    null,
                    {},
                    {},
                    {
                        status: status,
                        projectId: projectId,
                    }
                ),
                phaseColumns.revenueVsFee(
                    null,
                    {},
                    {},
                    {
                        status: status,
                        projectId: projectId,
                    }
                ),
                ...[...Array(12)].map((v, i) => {
                    return phaseColumns.revenue(
                        addMonths(store.startDate, i),
                        {},
                        {},
                        {
                            status: status,
                            projectId: projectId,
                        }
                    )
                }),
                {
                    id: 'startDate',
                    type: 'date',
                    label: 'Start Date',
                    value: (ph) => ph.startDate,
                    visible: false,
                },
            ]}
            rows={store
                .getRevenueRows([status, projectId].join(','))
                .sort(sortPhases)}
            showHeader={false}
            style={{
                boxShadow: 'none',
                background: `linear-gradient(180deg, rgb(${bgShadow},${bgShadow},${bgShadow}) 1px, rgb(${bgColor},${bgColor},${bgColor}) 8px)`,
                fontSize: '1.2rem',
            }}
            sortBy={store.projectTableStore.sortBy}
        />
    )
})

const StaffCostTable = observer(({ store }) => {
    if (
        !canViewRevenueForecastStaffExpenses(SessionStore.user) ||
        !canViewStaffPayRate(SessionStore.user)
    )
        return null
    const staffColumns = RevenueForecastReportStaffColumns(store)
    return (
        <Table
            tableStore={store.staffTableStore}
            style={{ margin: '2em 0 0 0' }}
            columns={[
                spacerColumn,
                staffColumns.title(),
                staffColumns.total(null, store),
                ...[...Array(12)].map((v, i) => {
                    return staffColumns.cost(
                        addMonths(store.startDate, i),
                        store
                    )
                }),
            ]}
            showTotals={true}
            rows={store.getStaffRows()}
            sortBy={[['title', 'asc']]}
        />
    )
})

const OverheadCostTable = observer(({ store }) => {
    if (!canViewRevenueForecastOperationalExpenses(SessionStore.user))
        return null
    const overheadColumns = RevenueForecastReportOverheadColumns(store)
    return (
        <Table
            tableStore={store.expenseTableStore}
            style={{ margin: '2em 0 0 0' }}
            columns={[
                spacerColumn,
                overheadColumns.title(),
                overheadColumns.total(null, store),
                ...[...Array(12)].map((v, i) => {
                    return overheadColumns.cost(
                        addMonths(store.startDate, i),
                        store
                    )
                }),
            ]}
            groupBy={['title']}
            sortBy={[['title', 'asc']]}
            showTotals={true}
            rows={store.getOverheadRows()}
        />
    )
})

export default observer((params) => {
    useEffect(() => {
        RevenueForecastStore.setSearchParams(params)
    }, [params])
    // useEffect(() => {
    //     RevenueForecastStore.reset()
    //     RevenueRowCollection.clear()
    //     ResourceRowCollection.clear()
    //     DataStore.setAutosave(true)
    //     return () => DataStore.setAutosave(false)
    // }, [])
    if (!SessionStore.settings.autoUpdateRevenue.adjustOnLoad) {
        return <ForecastPage />
    } else {
        return (
            <RenderOnQueries
                queryIds={[
                    {
                        id: 'auto-adjust-revenue',
                        path: '/api/v1.5/auto-adjust-revenue',
                        method: 'POST',
                        data: {
                            budgetType:
                                SessionStore.organisation.settings
                                    ?.autoUpdateRevenue?.budget || 'remaining',
                            startDateType:
                                SessionStore.organisation.settings
                                    ?.autoUpdateRevenue?.start || 'now',
                            endDateType:
                                SessionStore.organisation.settings
                                    ?.autoUpdateRevenue?.end || 'endDate',
                        },
                        nowTs: Date.now(),
                    },
                    {
                        id: 'auto-adjust-expenses',
                        path: '/api/v1.5/auto-adjust-expenses',
                        method: 'POST',
                        data: {
                            budgetType:
                                SessionStore.organisation.settings
                                    ?.autoUpdateRevenue?.budget || 'remaining',
                            startDateType:
                                SessionStore.organisation.settings
                                    ?.autoUpdateRevenue?.start || 'now',
                            endDateType:
                                SessionStore.organisation.settings
                                    ?.autoUpdateRevenue?.end || 'endDate',
                        },
                        nowTs: Date.now(),
                    },
                ]}
                loading={<LoadingSpinner />}
            >
                <ForecastPage />
            </RenderOnQueries>
        )
    }
})

const ForecastPage = observer(() => {
    const isPrinting = useMediaQuery('print')
    const DataStore = require('../../State/DataStore').default
    useEffect(() => {
        DataStore.setAutosave(true)
        return () => {
            DataStore.setAutosave(false)
        }
    }, [])
    const [store, setStore] = useState(RevenueForecastStore)
    const target = React.useRef(null)
    const size = useSize(target)
    const widthRatio = size?.width / (store?.projectTableStore?.width * 15)
    return (
        <div
            style={{
                padding: '0 2em',
                position: 'relative',
                // width: '100%',
            }}
        >
            <div ref={target} style={{ width: '100%', display: 'flex' }} />
            <RenderOnQueries
                queryIds={[
                    {
                        id: `revenueTable` + store.report.queryKey,
                        baseURL: process.env.REACT_APP_NODE_SERVER_URL,
                        path: `/revenue-forecast/table`,
                        method: 'POST',
                        staleTime: 0,
                        cacheTime: 0,
                        data: {
                            organisationId: SessionStore.organisationId,
                            userId: SessionStore.user?.id,
                            filters: store.report.filters,
                            level: 0,
                            parentData: {},
                            dateRange: [
                                format(store.startDate, 'yyyy-MM-dd'),
                                format(store.endDate, 'yyyy-MM-dd'),
                            ],
                        },
                    },

                    store.staffRowQuery(),
                    store.overheadRowQuery(),
                ]}
                loading={<LoadingSpinner />}
            >
                <UpdateReportModal report={store.report} />
                <RevenueForecasts
                    store={store}
                    widthRatio={widthRatio}
                    isPrinting={isPrinting}
                />
            </RenderOnQueries>
        </div>
    )
})

const ForecastChart = observer(({ store, widthRatio, isPrinting }) => {
    const months = [...Array(12)].map((v, i) => addMonths(store.startDate, i))
    const staffQueryResults =
        (canViewRevenueForecastStaffExpenses(SessionStore.user) &&
            FetchStore.getResponse(store.makeQueryKey('staff-cost'))?.staff
                ?.staff) ||
        []

    const overheadQueryResults = (
        (canViewRevenueForecastOperationalExpenses(SessionStore.user) &&
            FetchStore.getResponse(store.makeQueryKey('overhead-cost'))
                ?.overheadExpenses?.overheadExpenses) ||
        []
    ).map((e) => e.name)
    const staffCells = months.map((m, i) =>
        RevenueForecastReportStaffColumns(store).cost(m, store)
    )
    const overheadCells = months.map((m, i) =>
        RevenueForecastReportOverheadColumns(store).cost(m, store)
    )
    const revenue = months.map((m) => {
        return store.getRevenueInMonth('', format(m, 'yyyy-MM'), null) || 0
    })
    const prospectiveRevenue = months.map((m) => {
        return store.getProspectiveRevenueInMonth('', format(m, 'yyyy-MM')) || 0
    })
    const expenses = staffCells.map((sc, i) => {
        return (
            (!canViewRevenueForecastStaffExpenses(SessionStore.user) ||
            !canViewStaffPayRate(SessionStore.user)
                ? 0
                : _.sum(
                      staffQueryResults.map((st) => {
                          return sc.value(st)
                      })
                  )) +
            (!canViewRevenueForecastOperationalExpenses(SessionStore.user)
                ? 0
                : _.sum(
                      [...new Set(overheadQueryResults)].map((oh) => {
                          return overheadCells[i].value(oh)
                      })
                  ))
        )
    })
    return (
        <CashflowChart
            revenue={revenue}
            expenses={expenses}
            prospectiveRevenue={prospectiveRevenue}
            totalWidth={(3 + 18 * 2 + 8 * 11.5) * 10}
            graphWidth={8 * 11 * 10}
            height={22 * 10}
            sizeRatio={isPrinting ? widthRatio : 1}
            formatter={(n) => FormatCurrency(n, { decimals: 0, compact: true })}
        />
    )
})

const RevenueForecasts = observer(({ store, widthRatio, isPrinting }) => {
    const queryData = queryClient.getQueryData([
        `revenueTable` + store.report.queryKey,
    ])?.data
    useEffect(() => {
        store.init(queryData)
    }, [store.report.queryKey, queryData])
    return (
        <>
            <ForecastChart
                store={store}
                widthRatio={widthRatio}
                isPrinting={isPrinting}
            />
            <ProfitTable store={store} />
            <RevenueTableByStatus store={store} />
            <StaffCostTable store={store} />
            <OverheadCostTable store={store} />
        </>
    )
})
