import { cn } from '@/lib/utils'

export const CenterPadPageBody = ({ children, className }) => {
    return (
        <div
            className={cn(
                'flex flex-col items-center p-8 flex-auto overflow-auto print:overflow-visible',
                className
            )}
        >
            {children}
            <div className="h-28 w-full print:hidden flex-none"></div>
        </div>
    )
}

export const PageBody = ({ children, className }) => {
    return (
        <div
            className={cn(
                'flex flex-col flex-auto overflow-auto print:overflow-visible',
                className
            )}
        >
            {children}
            <div className="h-28 w-full print:hidden flex-none"></div>
        </div>
    )
}
