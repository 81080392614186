import { observable, computed, action, makeObservable } from 'mobx'
import Collection from './Collection'
import MilestoneModel from '../Models/MilestoneModel'

class MilestoneCollection extends Collection {
    constructor() {
        super({ collection: 'milestones', modelClass: MilestoneModel })
        this.addLookup('milestonesByProjectId', 'manyByKey', {
            key: (t) => t.projectId,
            filter: (t) => !t.deletedAt,
        })
        this.addLookup('milestonesByPhaseId', 'manyByKey', {
            key: (t) => t.phaseId,
            filter: (t) => !t.deletedAt,
        })
        makeObservable(this)
    }
    get milestonesById() {
        return this.modelsById
    }
    get milestones() {
        return this.models
    }
    @action.bound
    addMilestone(data, options) {
        return this.add(data, options)
    }
    @action.bound
    addMilestones(data, options) {
        return this.addMany(data, options)
    }
}

export default new MilestoneCollection()
export const MilestoneCollectionClass = MilestoneCollection
