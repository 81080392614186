import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { Check, Minus } from 'lucide-react'

import { cn } from '@2/lib/utils'
import { Label } from './label'

const Checkbox = React.forwardRef<
    React.ElementRef<typeof CheckboxPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => {
    return (
        <CheckboxPrimitive.Root
            ref={ref}
            className={cn(
                'peer h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground',
                className
            )}
            {...props}
        >
            <CheckboxPrimitive.Indicator
                className={cn('flex items-center justify-center text-current')}
            >
                {props.checked === 'indeterminate' ? (
                    <Minus className="h-4 w-4" />
                ) : (
                    <Check className="h-4 w-4" />
                )}
            </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
    )
})
Checkbox.displayName = CheckboxPrimitive.Root.displayName

const CheckboxWithLabel = ({
    checked,
    onCheckedChange,
    label,
    id,
    className,
}: {
    checked?: boolean
    onCheckedChange?: (value: boolean) => void
    label: string
    id: string
    className?: string
}) => {
    return (
        <div className={cn('flex items-center space-x-2', className)}>
            <Checkbox
                checked={checked}
                onCheckedChange={onCheckedChange}
                id={id}
            />
            <Label
                htmlFor={id}
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
                {label}
            </Label>
        </div>
    )
}

export { Checkbox, CheckboxWithLabel }
