import useSaveToast from '@/version2/components/hooks/save-toast'
import { setLayoutDefaults } from '@2/layout/layout-store'
import { createFileRoute } from '@tanstack/react-router'
import { useCallback, useEffect } from 'react'
import ErrorComponent from '../../Components/ErrorComponent'
import LoadingSpinner from '../../Components/LoadingSpinner'
import PageBody from '../../Pages/Layout/PageBody'
import OverheadExpenseListPage from '../../Pages/OverheadExpenseListPage/OverheadExpenseListPage'
import OverheadExpenseListPageHeader from '../../Pages/OverheadExpenseListPage/OverheadExpenseListPageHeader'
import PermissionDenied from '../../Pages/PermissionDenied'
import fetchData from '../../Queries/fetchData'
import {
    canViewRoute,
    migratedToV2,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import { trpc } from '../../system/trpc'
import { OverHeadExpensePage as OverHeadExpensePageV2 } from '../../version2/pages/overhead-expenses/overhead-expenses-page'
import {
    setIsLoading,
    setOperationalExpenses,
    setPendingChanges,
    usePendingChanges,
} from '../../version2/pages/overhead-expenses/overhead-expenses-store'
import { PageBody as V2PageBody } from '@2/layout/page-body'

export const Route = createFileRoute('/_private/overhead-expenses')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'overheadExpenses', params))
            throw new Error('Permission Denied')
        setLayoutDefaults({
            pageTitle: 'Operational Expenses',
            subMenu: 'organisation',
        })
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    loader: async () => await loader(),
    pendingComponent: LoadingSpinner,
    component: Wrapper,
})

const loader = async () => {
    const collections = [
        {
            collection: 'overheadExpenses',
            fields: [
                'name',
                'costCentreId',
                'value',
                'startDate',
                'endDate',
                'hasRepeat',
                'repeatQuantity',
                'repeatUnit',
            ],
        },
    ].filter(Boolean)
    if (migratedToV2()) {
        setIsLoading(true)
        try {
            const result =
                await trpc.operationalExpense.getOperationalExpenses.query()

            if (result.operationalExpenses) {
                // Parse dates from the API response
                const parsedOperationalExpenses =
                    result.operationalExpenses.map((operationalExpense) => ({
                        ...operationalExpense,
                        value: operationalExpense.value
                            ? Number(operationalExpense.value)
                            : null,
                        startDate: operationalExpense.startDate
                            ? new Date(operationalExpense.startDate)
                            : null,
                        endDate: operationalExpense.endDate
                            ? new Date(operationalExpense.endDate)
                            : null,
                    }))

                setOperationalExpenses(parsedOperationalExpenses)
                setPendingChanges(false)
            }

            return result
        } catch (error) {
            console.error('Error loading overhead expense:', error)
            throw error
        } finally {
            setIsLoading(false)
        }
    }
    return Promise.all(collections.map(fetchData))
}

function Wrapper() {
    const pendingChanges = usePendingChanges()

    const { showSaveToast } = useSaveToast()

    const handleSave = useCallback(async () => {
        const operationalExpenses = getOperationalExpenses()
        const result =
            await trpc.operationalExpense.saveOperationalExpenses.mutate({
                operationalExpenses,
            })
        if (result.success) {
            setPendingChanges(false)
            return result
        }
        throw new Error('Failed to save changes')
    }, [setPendingChanges])

    useEffect(() => {
        if (pendingChanges) {
            showSaveToast({
                onSave: handleSave,
                requireConfirmation: true,
                confirmationMessage: 'Save changes?',
                loadingMessage: 'Saving overhead expense...',
                successMessage: 'Changes saved successfully',
                errorMessage: 'Failed to save changes',
            })
        }
    }, [pendingChanges])
    if (migratedToV2()) {
        return (
            <V2PageBody>
                <OverHeadExpensePageV2 />
            </V2PageBody>
        )
    }
    return (
        <>
            <OverheadExpenseListPageHeader />
            <PageBody>
                <OverheadExpenseListPage />
            </PageBody>
        </>
    )
}
