import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'
import { useMemo } from 'react'
import { z } from 'zod'

// Zod schemas
export const CostCentreSchema = z.object({
    id: z.string(),
    name: z.string().max(100),
    isBillable: z.boolean(),
    deletedAt: z.date().nullable().optional(),
})

export type CostCentre = z.infer<typeof CostCentreSchema>

// Initial state
const initialContext = {
    costCentres: [] as CostCentre[],
    isLoading: false,
    pendingChanges: false,
}

// Create the store
export const costCentrePageStore = createStore({
    context: initialContext,
    on: {
        setCostCentres: {
            costCentres: (_, event: { costCentres: CostCentre[] }) =>
                event.costCentres,
        },
        addCostCentre: {
            costCentres: (context, event: { costCentre: CostCentre }) => [
                ...context.costCentres,
                event.costCentre,
            ],
            pendingChanges: true,
        },
        updateCostCentre: {
            costCentres: (
                context,
                event: { id: string; costCentre: Partial<CostCentre> }
            ) => {
                const index = context.costCentres.findIndex(
                    (c) => c.id === event.id
                )
                if (index === -1) return context.costCentres

                const updatedCostCentres = [...context.costCentres]
                updatedCostCentres[index] = {
                    ...updatedCostCentres[index],
                    ...event.costCentre,
                }
                return updatedCostCentres
            },
            pendingChanges: true,
        },
        deleteCostCentre: {
            costCentres: (context, event: { id: string }) => {
                const index = context.costCentres.findIndex(
                    (c) => c.id === event.id
                )
                if (index === -1) return context.costCentres

                const updatedCostCentres = [...context.costCentres]
                updatedCostCentres[index] = {
                    ...updatedCostCentres[index],
                    deletedAt: new Date(),
                }
                return updatedCostCentres
            },
            pendingChanges: true,
        },
        setIsLoading: {
            isLoading: (_, event: { isLoading: boolean }) => event.isLoading,
        },
        setPendingChanges: {
            pendingChanges: (_, event: { pending: boolean }) => event.pending,
        },
        reset: {
            costCentres: () => [],
            isLoading: false,
            pendingChanges: false,
        },
    },
})

// Action creators
export const setCostCentres = (costCentres: CostCentre[]) =>
    costCentrePageStore.send({
        type: 'setCostCentres',
        costCentres,
    })

export const addCostCentre = (costCentre: CostCentre) =>
    costCentrePageStore.send({
        type: 'addCostCentre',
        costCentre,
    })

export const updateCostCentre = (id: string, costCentre: Partial<CostCentre>) =>
    costCentrePageStore.send({
        type: 'updateCostCentre',
        id,
        costCentre,
    })

export const deleteCostCentre = (id: string) =>
    costCentrePageStore.send({
        type: 'deleteCostCentre',
        id,
    })

export const setIsLoading = (isLoading: boolean) =>
    costCentrePageStore.send({
        type: 'setIsLoading',
        isLoading,
    })

export const setPendingChanges = (pending: boolean) =>
    costCentrePageStore.send({
        type: 'setPendingChanges',
        pending,
    })

export const reset = () =>
    costCentrePageStore.send({
        type: 'reset',
    })

// Selector hooks
export const useCostCentres = () =>
    useSelector(costCentrePageStore, (state) => state.context.costCentres)

export const useActiveCostCentres = () => {
    const costCentres = useSelector(
        costCentrePageStore,
        (state) => state.context.costCentres
    )
    return useMemo(
        () => costCentres.filter((c) => !c?.deletedAt),
        [costCentres]
    )
}

export const useIsLoading = () =>
    useSelector(costCentrePageStore, (state) => state.context.isLoading)

export const usePendingChanges = () =>
    useSelector(costCentrePageStore, (state) => state.context.pendingChanges)

// Getter for non-reactive access
export const getCostCentres = () =>
    costCentrePageStore.getSnapshot().context.costCentres
