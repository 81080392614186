import { Link } from '@tanstack/react-router'
import { type ColumnDef } from '@tanstack/react-table'
import SessionStore from '@/State/SessionStore'
import {
    canViewCostCentres,
    canViewPrimaryContact,
} from '@/State/Permissions/HasPermissions'
import _ from 'lodash'
import { createGenerateColumnDef } from '@/version2/components/data-table/helpers/generate-column-def'

export function getColumns(
    onHideColumn: (column: string) => void
): ColumnDef<any>[] {
    const generateColumnDef = createGenerateColumnDef({
        onHideColumn,
    })

    const standardSize = 160
    const wideSize = 200

    return [
        generateColumnDef({
            key: 'description',
            type: 'text',
            title: 'Invoice #',
            size: 150,
            cell: (row) => {
                const value = row.getValue('description')
                return (
                    <div>
                        <Link to={`/invoices/${row.original.id}`}>{value}</Link>
                    </div>
                )
            },
        }),
        generateColumnDef({
            key: 'issuedOn',
            type: 'date',
            title: 'Issue Date',
            aggregationFn: 'min',
        }),
        generateColumnDef({
            key: 'dueDate',
            type: 'date',
            title: 'Due Date',
            aggregationFn: 'min',
        }),
        generateColumnDef({
            key: 'startDate',
            type: 'date',
            title: 'Work Start Date',
            aggregationFn: 'min',
        }),
        generateColumnDef({
            key: 'endDate',
            type: 'date',
            title: 'Work End Date',
            aggregationFn: 'max',
        }),
        generateColumnDef({
            key: 'createdAt',
            type: 'date',
            title: 'Creation Date',
            aggregationFn: 'min',
        }),
        generateColumnDef({
            key: 'project',
            type: 'project',
            title: 'Project',
            size: wideSize,
        }),
        generateColumnDef({
            key: 'projectTitle',
            type: 'text',
            title: 'Project Title',
            size: wideSize,
        }),
        generateColumnDef({
            key: 'contact',
            type: 'contact',
            title: 'Client',
            size: wideSize,
        }),
        generateColumnDef({
            key: 'projectContact',
            type: 'contact',
            title: 'Project Client',
            size: wideSize,
        }),
        generateColumnDef({
            key: 'projectOwner',
            type: 'staffMember',
            title: 'Project Owner',
            size: wideSize,
        }),
        generateColumnDef({
            key: 'projectPrimaryContact',
            type: 'contact',
            title: 'Project Primary Contact',
            size: wideSize,
            permissions: () => canViewPrimaryContact(),
        }),
        generateColumnDef({
            key: 'costCentre',
            type: 'costCentre',
            title: 'Cost Centre',
            size: 150,
            permissions: (row) => canViewCostCentres(SessionStore.user),
        }),
        ...[
            'amountExTax',
            'amountIncTax',
            'amountTax',
            'agreedFeeExTax',
            'variationExTax',
            'agreedFeeVariationExTax',
            'reimbursementExTax',
            'agreedFeeTax',
            'variationTax',
            'agreedFeeVariationTax',
            'reimbursementTax',
            'agreedFeeIncTax',
            'variationIncTax',
            'agreedFeeVariationIncTax',
            'reimbursementIncTax',
        ].map((key) =>
            generateColumnDef({
                key,
                type: 'currency',
                title: key
                    .replace(/([A-Z])/g, ' $1')
                    .replace(/^./, (str) => str.toUpperCase()),
                aggregationFn: 'sum',
            })
        ),
        generateColumnDef({
            key: 'synced',
            type: 'boolean',
            title: 'Synced',
            filterOptions: {
                options: [
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                ],
            },
        }),
        generateColumnDef({
            key: 'projectStatus',
            type: 'status',
            title: 'Project Status',
            filterOptions: {
                options: [
                    { label: 'Active', value: 'active' },
                    { label: 'On Hold', value: 'onHold' },
                    { label: 'Prospective', value: 'prospective' },
                    { label: 'Archived', value: 'archived' },
                ],
            },
        }),
    ]
}
