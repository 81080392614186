import {
    canViewFlexi,
    canViewRemote,
    usingNewLogin,
} from '../../../State/Permissions/HasPermissions'
import { SelectWithLabel } from '@/version2/components/ui/select'
import { CheckboxWithLabel, Checkbox } from '@/version2/components/ui/checkbox'
import {
    settingActions,
    useAllowNoPhase,
    useSortPhasesBy,
    useUseTasks,
    useAllowAfterPhaseEnd,
    useAutoPopulate,
    useAutoUpdateHours,
    useAutoUpdateRevenue,
    useTimeEntryAllocations,
    useTimeEntryFlags,
    useTimeEntryStatus,
    useUpdateHoursFromRevenue,
    useUpdateRevenueFromHours,
    useReportInvoiceDateType,
    useSavingInvoices,
} from './settings-store'
// import DomainVerification from './DomainVerification'

export function SettingsPageForm() {
    const sortPhasesBy = useSortPhasesBy()
    const allowNoPhase = useAllowNoPhase()
    const useTasks = useUseTasks()
    const allowAfterPhaseEnd = useAllowAfterPhaseEnd()
    const timeEntryAllocations = useTimeEntryAllocations()
    const timeEntryStatus = useTimeEntryStatus()
    const timeEntryFlags = useTimeEntryFlags()
    const autoPopulate = useAutoPopulate()
    const updateHoursFromRevenue = useUpdateHoursFromRevenue()
    const updateRevenueFromHours = useUpdateRevenueFromHours()
    const autoUpdateRevenue = useAutoUpdateRevenue()
    const autoUpdateHours = useAutoUpdateHours()
    const reportInvoiceDateType = useReportInvoiceDateType()
    const savingInvoices = useSavingInvoices()

    return (
        <div className="max-w-[60em] py-[2em] px-[4px]">
            <div className="flex items-center py-[2em]">
                <span className="inline-block">Sort Phases By:</span>
                <div className="mx-[1em] w-[12em]">
                    <SelectWithLabel
                        value={sortPhasesBy}
                        onValueChange={(value) => {
                            settingActions.changeSortPhasesBy(value)
                        }}
                        options={[
                            {
                                key: 'startDate',
                                value: 'startDate',
                                label: 'Start Date',
                            },
                            {
                                key: 'title',
                                value: 'title',
                                label: 'Title',
                            },
                        ]}
                        // defaultValue="title"
                        placeholder="Select..."
                    />
                </div>
            </div>
            <div>
                <CheckboxWithLabel
                    checked={allowNoPhase}
                    onCheckedChange={(value) => {
                        settingActions.changeAllowNoPhase(value)
                    }}
                    id="allowNoPhase"
                    label="Allow time to be logged without a selecting a phase."
                />
            </div>
            <div className="mt-[2em]">
                <CheckboxWithLabel
                    checked={useTasks}
                    onCheckedChange={function (value) {
                        settingActions.changeUseTasks(value)
                    }}
                    label="Allow time to be logged against tasks."
                    id="useTasks"
                />
            </div>
            <div className="mt-[2em]">
                <CheckboxWithLabel
                    checked={allowAfterPhaseEnd}
                    onCheckedChange={(value) => {
                        settingActions.changeAllowAfterPhaseEnd(value)
                    }}
                    label="Allow time to be logged against a phase that has ended."
                    id="allowAfterPhaseEnd"
                />
            </div>
            <div className="mt-[3em]">
                <p>
                    Allow phases with the following statuses to be visible when
                    entering time:
                </p>
            </div>
            <div className="mt-[1em] ml-[2em]">
                <CheckboxWithLabel
                    checked={timeEntryStatus.includes('active')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeTimeEntryStatus([
                                'active',
                                ...timeEntryStatus,
                            ])
                        } else {
                            settingActions.changeTimeEntryStatus(
                                timeEntryStatus.filter((s) => s !== 'active')
                            )
                        }
                    }}
                    label="Active"
                    id="active"
                />
            </div>
            <div className="ml-[2em]">
                <CheckboxWithLabel
                    checked={timeEntryStatus.includes('prospective')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeTimeEntryStatus([
                                'prospective',
                                ...timeEntryStatus,
                            ])
                        } else {
                            settingActions.changeTimeEntryStatus(
                                timeEntryStatus.filter(
                                    (s) => s !== 'prospective'
                                )
                            )
                        }
                    }}
                    label="Prospective"
                    id="prospective"
                />
            </div>
            <div style={{ marginLeft: '2em' }}>
                <CheckboxWithLabel
                    checked={timeEntryStatus.includes('onHold')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeTimeEntryStatus([
                                'onHold',
                                ...timeEntryStatus,
                            ])
                        } else {
                            settingActions.changeTimeEntryStatus(
                                timeEntryStatus.filter((s) => s !== 'onHold')
                            )
                        }
                    }}
                    label="On Hold"
                    id="onHold"
                />
            </div>
            <div style={{ marginLeft: '2em' }}>
                <CheckboxWithLabel
                    checked={timeEntryStatus.includes('archived')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeTimeEntryStatus([
                                'archived',
                                ...timeEntryStatus,
                            ])
                        } else {
                            settingActions.changeTimeEntryStatus(
                                timeEntryStatus.filter((s) => s !== 'archived')
                            )
                        }
                    }}
                    label="Archived"
                    id="archived"
                />
            </div>
            <p className="mt-[3em]">
                Allow staff to flag time entries with the following:
            </p>
            <div className="mt-[1em] ml-[2em]">
                <CheckboxWithLabel
                    checked={timeEntryFlags.includes('variation')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeTimeEntryFlags([
                                'variation',
                                ...timeEntryFlags,
                            ])
                        } else {
                            settingActions.changeTimeEntryFlags(
                                timeEntryFlags.filter((s) => s !== 'variation')
                            )
                        }
                    }}
                    label="Variation"
                    id="variation"
                />
            </div>
            <div style={{ marginLeft: '2em' }}>
                <CheckboxWithLabel
                    checked={timeEntryFlags.includes('billable')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeTimeEntryFlags([
                                'billable',
                                ...timeEntryFlags,
                            ])
                        } else {
                            settingActions.changeTimeEntryFlags(
                                timeEntryFlags.filter((s) => s !== 'billable')
                            )
                        }
                    }}
                    label="Billable"
                    id="billable"
                />
            </div>
            <div style={{ marginLeft: '2em' }}>
                <CheckboxWithLabel
                    checked={timeEntryFlags.includes('overtime')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeTimeEntryFlags([
                                'overtime',
                                ...timeEntryFlags,
                            ])
                        } else {
                            settingActions.changeTimeEntryFlags(
                                timeEntryFlags.filter((s) => s !== 'overtime')
                            )
                        }
                    }}
                    label="Overtime"
                    id="overtime"
                />
            </div>
            {canViewRemote() ? (
                <div style={{ marginLeft: '2em' }}>
                    <CheckboxWithLabel
                        checked={timeEntryFlags.includes('remote')}
                        onCheckedChange={function (value) {
                            if (value) {
                                settingActions.changeTimeEntryFlags([
                                    'remote',
                                    ...timeEntryFlags,
                                ])
                            } else {
                                settingActions.changeTimeEntryFlags(
                                    timeEntryFlags.filter((s) => s !== 'remote')
                                )
                            }
                        }}
                        label="Remote"
                        id="remote"
                    />
                </div>
            ) : null}
            {canViewFlexi() ? (
                <div style={{ marginLeft: '2em' }}>
                    <CheckboxWithLabel
                        checked={timeEntryFlags.includes('flexi')}
                        onCheckedChange={function (value) {
                            if (value) {
                                settingActions.changeTimeEntryFlags([
                                    'flexi',
                                    ...timeEntryFlags,
                                ])
                            } else {
                                settingActions.changeTimeEntryFlags(
                                    timeEntryFlags.filter((s) => s !== 'flexi')
                                )
                            }
                        }}
                        label="Flexi"
                        id="flexi"
                    />
                </div>
            ) : null}
            <p className="mt-[3em]">
                Allow staff to enter time against phases:
            </p>
            <div className="mt-[1em] ml-[2em]">
                <CheckboxWithLabel
                    checked={timeEntryAllocations.includes('noAllocations')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeTimeEntryAllocations([
                                'noAllocations',
                                ...timeEntryAllocations,
                            ])
                        } else {
                            settingActions.changeTimeEntryAllocations(
                                timeEntryAllocations.filter(
                                    (s) => s !== 'noAllocations'
                                )
                            )
                        }
                    }}
                    label="Without budgets or allocations for that staff member."
                    id="noAllocations"
                />
            </div>
            <div className="mt-[1em] ml-[2em]">
                <CheckboxWithLabel
                    checked={timeEntryAllocations.includes('budgets')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeTimeEntryAllocations([
                                'budgets',
                                ...timeEntryAllocations,
                            ])
                        } else {
                            settingActions.changeTimeEntryAllocations(
                                timeEntryAllocations.filter(
                                    (s) => s !== 'budgets'
                                )
                            )
                        }
                    }}
                    label="With budgets for that staff member."
                    id="budgets"
                />
            </div>
            <div className="mt-[1em] ml-[2em]">
                <CheckboxWithLabel
                    checked={timeEntryAllocations.includes('allocations')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeTimeEntryAllocations([
                                'allocations',
                                ...timeEntryAllocations,
                            ])
                        } else {
                            settingActions.changeTimeEntryAllocations(
                                timeEntryAllocations.filter(
                                    (s) => s !== 'allocations'
                                )
                            )
                        }
                    }}
                    label="With monthly allocations for that staff member."
                    id="allocations"
                />
            </div>
            <p className="mt-[3em]">Auto-populate timesheets for phases:</p>
            <div className="mt-[1em] ml-[2em]">
                <CheckboxWithLabel
                    checked={autoPopulate.includes('budgets')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeAutoPopulate([
                                'budgets',
                                ...autoPopulate,
                            ])
                        } else {
                            settingActions.changeAutoPopulate(
                                autoPopulate.filter((s) => s !== 'budgets')
                            )
                        }
                    }}
                    label="With time budgeted for that staff member."
                    id="budgets"
                />
            </div>
            <div className="mt-[1em] ml-[2em]">
                <CheckboxWithLabel
                    checked={autoPopulate.includes('allocations')}
                    onCheckedChange={function (value) {
                        if (value) {
                            settingActions.changeAutoPopulate([
                                'allocations',
                                ...autoPopulate,
                            ])
                        } else {
                            settingActions.changeAutoPopulate(
                                autoPopulate.filter((s) => s !== 'allocations')
                            )
                        }
                    }}
                    label="With monthly allocations assigned to that staff member."
                    id="allocations"
                />
            </div>
            <div className="mt-[2em]">
                <CheckboxWithLabel
                    checked={updateHoursFromRevenue}
                    onCheckedChange={function (value) {
                        settingActions.changeUpdateHoursFromRevenue(value)
                    }}
                    label="Automatically adjust Staff Hours when editing Revenue in Forecasts"
                    id="updateHoursFromRevenue"
                />
                <CheckboxWithLabel
                    checked={updateRevenueFromHours}
                    onCheckedChange={function (value) {
                        settingActions.changeUpdateRevenueFromHours(value)
                    }}
                    label="Automatically adjust Revenue when editing Staff Hours in Forecasts"
                    id="updateRevenueFromHours"
                />
            </div>
            <div className="mt-[4em]">
                <div className="flex items-center mb-[0.5em]">
                    <SelectWithLabel
                        value={autoUpdateRevenue.action}
                        onValueChange={(value) => {
                            settingActions.changeAutoUpdateRevenue({
                                ...autoUpdateRevenue,
                                action: value,
                            })
                        }}
                        options={[
                            {
                                key: 'ask',
                                value: 'ask',
                                label: 'Ask Me to',
                            },
                            {
                                key: 'automatic',
                                value: 'automatic',
                                label: 'Always',
                            },
                            {
                                key: 'never',
                                value: 'never',
                                label: 'Never',
                            },
                        ]}
                        className="flex-row-reverse gap-2"
                        defaultValue="ask"
                        placeholder="Select..."
                        label="Automatically adjust the Revenue Forecast when saving Projects and Invoices."
                    />
                </div>
                <CheckboxWithLabel
                    checked={autoUpdateRevenue.adjustOnLoad}
                    onCheckedChange={function (value) {
                        settingActions.changeAutoUpdateRevenue({
                            ...autoUpdateRevenue,
                            adjustOnLoad: value,
                        })
                    }}
                    label="Automatically adjust Revenue when loading the Revenue Forecast"
                    id="adjustOnLoad"
                    className="my-3"
                />
                <div className="flex items-center border-t border-[#eee] p-[2em]">
                    <span className="inline-block">Distribute</span>
                    <div className="mx-[1em] w-[12em]">
                        <SelectWithLabel
                            value={autoUpdateRevenue.budget}
                            onValueChange={(value) => {
                                settingActions.changeAutoUpdateRevenue({
                                    ...autoUpdateRevenue,
                                    budget: value,
                                })
                            }}
                            options={[
                                {
                                    key: 'total',
                                    value: 'total',
                                    label: 'Total Budget',
                                },
                                {
                                    key: 'remaining',
                                    value: 'remaining',
                                    label: 'Remaining Budget',
                                },
                            ]}
                            // defaultValue="total"
                        />
                    </div>
                    <span className="inline-block">between</span>
                    <div className="mx-[1em] w-[9em]">
                        <SelectWithLabel
                            value={autoUpdateRevenue.start}
                            onValueChange={(value) => {
                                settingActions.changeAutoUpdateRevenue({
                                    ...autoUpdateRevenue,
                                    start: value,
                                })
                            }}
                            options={[
                                {
                                    key: 'startDate',
                                    value: 'startDate',
                                    label: 'Start Date',
                                },
                                {
                                    key: 'now',
                                    value: 'now',
                                    label: 'Current Date',
                                },
                                {
                                    key: 'endDate',
                                    value: 'endDate',
                                    label: 'End Date',
                                },
                            ]}
                            // defaultValue="startDate"
                        />
                    </div>
                    <span className="inline-block">and</span>
                    <div className="mx-[1em] w-[9em]">
                        <SelectWithLabel
                            value={autoUpdateRevenue.end}
                            onValueChange={(value) => {
                                settingActions.changeAutoUpdateRevenue({
                                    ...autoUpdateRevenue,
                                    end: value,
                                })
                            }}
                            options={[
                                {
                                    key: 'startDate',
                                    value: 'startDate',
                                    label: 'Start Date',
                                },
                                {
                                    key: 'endDate',
                                    value: 'endDate',
                                    label: 'End Date',
                                },
                            ]}
                            // defaultValue="startDate"
                        />
                    </div>
                </div>
            </div>
            <div className="mt-[3em]">
                <SelectWithLabel
                    value={autoUpdateHours.action}
                    onValueChange={(value) => {
                        settingActions.changeAutoUpdateHours({
                            ...autoUpdateHours,
                            action: value,
                        })
                    }}
                    options={[
                        {
                            key: 'ask',
                            value: 'ask',
                            label: 'Ask Me to',
                        },
                        {
                            key: 'automatic',
                            value: 'automatic',
                            label: 'Always',
                        },
                        {
                            key: 'never',
                            value: 'never',
                            label: 'Never',
                        },
                    ]}
                    className="flex-row-reverse gap-2"
                    placeholder="Select..."
                    label="Automatically adjust the Resource Schedule when saving Projects and Time entries
                "
                />
                <CheckboxWithLabel
                    checked={autoUpdateHours.adjustOnLoad}
                    onCheckedChange={function (value) {
                        settingActions.changeAutoUpdateHours({
                            ...autoUpdateHours,
                            adjustOnLoad: value,
                        })
                    }}
                    className="my-3"
                    label="Automatically adjust Staff Hours when loading the Resource Schedule"
                    id="adjustOnLoad"
                />
                <div className="flex items-center border-t border-[#eee] p-[2em]">
                    <span className="inline-block">Distribute</span>
                    <div className="mx-[1em] w-[12em]">
                        <SelectWithLabel
                            value={autoUpdateHours.budget}
                            onValueChange={(value) => {
                                settingActions.changeAutoUpdateHours({
                                    ...autoUpdateHours,
                                    budget: value,
                                })
                            }}
                            options={[
                                {
                                    key: 'total',
                                    value: 'total',
                                    label: 'Total Budget',
                                },
                                {
                                    key: 'remaining',
                                    value: 'remaining',
                                    label: 'Remaining Budget',
                                },
                            ]}
                        />
                    </div>
                    <span className="inline-block">between</span>
                    <div className="mx-[1em] w-[9em]">
                        <SelectWithLabel
                            value={autoUpdateHours.start}
                            onValueChange={(value) => {
                                settingActions.changeAutoUpdateHours({
                                    ...autoUpdateHours,
                                    start: value,
                                })
                            }}
                            options={[
                                {
                                    key: 'startDate',
                                    value: 'startDate',
                                    label: 'Start Date',
                                },
                                {
                                    key: 'now',
                                    value: 'now',
                                    label: 'Current Date',
                                },
                                {
                                    key: 'endDate',
                                    value: 'endDate',
                                    label: 'End Date',
                                },
                            ]}
                        />
                    </div>
                    <span className="inline-block">and</span>
                    <div className="mx-[1em] w-[9em]">
                        <SelectWithLabel
                            value={autoUpdateHours.end}
                            onValueChange={(value) => {
                                settingActions.changeAutoUpdateHours({
                                    ...autoUpdateHours,
                                    end: value,
                                })
                            }}
                            options={[
                                {
                                    key: 'startDate',
                                    value: 'startDate',
                                    label: 'Start Date',
                                },
                                {
                                    key: 'endDate',
                                    value: 'endDate',
                                    label: 'End Date',
                                },
                            ]}
                            defaultValue="startDate"
                        />
                    </div>
                </div>
                <div
                    style={{
                        padding: '4em 0em 1em 0em',
                    }}
                    className="flex items-center"
                >
                    <span className="inline-block">
                        Use Invoice Date Type in Reports:
                    </span>
                    <div className="mx-[1em] w-[12em]">
                        <SelectWithLabel
                            value={reportInvoiceDateType}
                            onValueChange={(value) => {
                                settingActions.changeReportInvoiceDateType(
                                    value
                                )
                            }}
                            options={[
                                {
                                    key: 'endDate',
                                    value: 'endDate',
                                    label: 'Work Completion',
                                },
                                {
                                    key: 'issueDate',
                                    value: 'issueDate',
                                    label: 'Issue Date',
                                },
                                {
                                    key: 'dueDate',
                                    value: 'dueDate',
                                    label: 'Due Date',
                                },
                            ]}
                            defaultValue="endDate"
                        />
                    </div>
                </div>
                <div
                    style={{
                        maxWidth: '60em',
                        padding: '0em 0em 2em',
                    }}
                >
                    <p style={{ marginTop: '2em' }}>When saving Invoices:</p>
                    <div
                        style={{
                            marginTop: '1em',
                            marginLeft: '2em',
                        }}
                    >
                        <CheckboxWithLabel
                            checked={savingInvoices.includes('lockTime')}
                            onCheckedChange={function (value) {
                                if (value) {
                                    settingActions.changeSavingInvoices([
                                        'lockTime',
                                        ...savingInvoices,
                                    ])
                                } else {
                                    settingActions.changeSavingInvoices(
                                        savingInvoices.filter(
                                            (s) => s !== 'lockTime'
                                        )
                                    )
                                }
                            }}
                            label="Lock time entries."
                            id="lockTime"
                        />
                    </div>
                </div>
                {usingNewLogin() ? (
                    <div
                        style={{
                            maxWidth: '60em',
                            padding: '0em 0em 2em',
                        }}
                    >
                        <p>
                            Automatically create accounts for users with the
                            following email domains:
                        </p>
                        <div style={{ padding: '1em 2em' }}>
                            {/* <DomainVerification /> */}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}
