import _ from 'lodash'
import { getCache } from '@2/cache'

export const generateFilterOptions = (type) => {
    const cache = getCache()
    switch (type) {
        case 'project':
            return {
                options: _.sortBy(
                    (cache.projects || []).map((project: any) => {
                        return {
                            label: project.jobNumber
                                ? `${project.jobNumber}: ${project.name}`
                                : project.name,
                            value: project.id,
                            status: project.status,
                        }
                    }),
                    (project: any) => _.toLower(project.label)
                ),
                groupBy: (sm) => sm.status,
                sortGroups: (a, b) => {
                    const statusOrder = [
                        'active',
                        'prospective',
                        'onHold',
                        'archived',
                    ]
                    return (
                        statusOrder.indexOf(a.label) -
                        statusOrder.indexOf(b.label)
                    )
                },
            }
        case 'status':
            return {
                options: [
                    { label: 'Active', value: 'active' },
                    { label: 'On Hold', value: 'onHold' },
                    { label: 'Prospective', value: 'prospective' },
                    { label: 'Archived', value: 'archived' },
                ],
            }
        case 'staffMember':
            return {
                options: _.sortBy(
                    (cache.staff || []).map((staff) => {
                        return {
                            label: `${staff.firstName} ${staff.lastName}`,
                            value: staff.id,
                            isArchived: staff.isArchived,
                        }
                    }),
                    (staff: any) => _.toLower(staff.label)
                ),
                groupBy: (sm) => (sm.isArchived ? 'Archived' : 'Active'),
                sortGroups: (a, b) => (a.label === 'Archived' ? 1 : -1),
            }
        case 'costCentre':
            return {
                options: _.sortBy(
                    (cache.costCentres || []).map((costCentre: any) => {
                        return {
                            label: costCentre.name,
                            value: costCentre.id,
                        }
                    }),
                    (costCentre: any) => _.toLower(costCentre.label)
                ),
            }
        case 'contact':
            return {
                options: _.sortBy(
                    (cache.contacts || []).map((contact: any) => {
                        return {
                            label: `(${contact.organisationName})`,
                            value: contact.id,
                        }
                    }),
                    (contact: any) => _.toLower(contact.label)
                ),
            }
        default:
            return {}
    }
}
