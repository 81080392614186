import { Button } from '@2/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
} from '@2/components/ui/dropdown-menu'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogClose,
} from '@2/components/ui/dialog'
import { Input } from '@2/components/ui/input'
import { ExportColumn, OrganisationReport } from '@/version2/types'

interface DataTableActions {
    handleExportToCSV?: (groupId?: string) => void
    handleDialogSubmit?: (dialogContent: string) => Promise<void>
    handleOpenDialog?: (content: string) => void
    handleSetDefault?: () => Promise<void>
    dialogContent?: string
    setDialogContent?: (value: React.SetStateAction<string>) => void
    setNameReport?: (value: React.SetStateAction<string>) => void
    nameReport?: string
    organisationReport?: OrganisationReport
    checkDisableButton?: boolean
    exportColumns?: ExportColumn[]
}

export function DataTableActions({
    handleExportToCSV,
    handleDialogSubmit,
    handleOpenDialog,
    handleSetDefault,
    dialogContent,
    setDialogContent,
    setNameReport,
    nameReport,
    organisationReport,
    checkDisableButton,
    exportColumns,
}: DataTableActions) {
    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button
                        aria-label="Toggle columns"
                        variant="outline"
                        size="sm"
                    >
                        Actions
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                    <DropdownMenuSub>
                        {/* Need to pass list of export selection */}
                        {exportColumns.length > 0 ? (
                            <>
                                <DropdownMenuSubTrigger>Export</DropdownMenuSubTrigger>
                                <DropdownMenuSubContent sideOffset={4}>
                                    {exportColumns.map(({ value, label }) => (
                                        <DropdownMenuItem
                                            key={value}
                                            onClick={() => handleExportToCSV(value)}
                                            className="cursor-pointer"
                                        >
                                            {label}
                                        </DropdownMenuItem>
                                    ))}
                                </DropdownMenuSubContent>
                            </>
                        ) : (
                            <DropdownMenuItem
                                className="cursor-pointer"
                                onClick={() => handleExportToCSV()}
                            >
                                Export
                            </DropdownMenuItem>
                        )}
                    </DropdownMenuSub>
                    <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() => window.print()}
                    >
                        Print
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() => handleDialogSubmit('Save')}
                    >
                        Update report
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        className="cursor-pointer"
                        onClick={() => handleOpenDialog('Save as')}
                    >
                        Save as
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        disabled={!organisationReport?.id}
                        className="cursor-pointer"
                        onClick={handleSetDefault}
                    >
                        Set as default
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        disabled={checkDisableButton}
                        className="cursor-pointer"
                        onClick={() => handleOpenDialog('Rename Report')}
                    >
                        Rename
                    </DropdownMenuItem>
                    <DropdownMenuItem
                        disabled={checkDisableButton}
                        className="cursor-pointer"
                        onClick={() => handleOpenDialog('Delete Report')}
                    >
                        Delete
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>

            <Dialog
                open={!!dialogContent}
                onOpenChange={() => setDialogContent(null)}
            >
                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>{dialogContent}</DialogTitle>
                        <DialogDescription>
                            {dialogContent === 'Update report' &&
                                'Make changes to your report here. Click save when you are done.'}
                            {dialogContent === 'Save as' &&
                                'Please enter new name for this report.'}
                            {dialogContent === 'Set as default' &&
                                'Set this report as the default for future use.'}
                            {dialogContent === 'Rename Report' &&
                                'Please enter new name for this report.'}
                            {dialogContent === 'Delete Report' &&
                                'Are you sure you want to delete this report?'}
                        </DialogDescription>
                    </DialogHeader>
                    {dialogContent && (
                        <div>
                            {(dialogContent === 'Rename Report' ||
                                dialogContent === 'Save as') && (
                                    <Input
                                        required
                                        type="text"
                                        placeholder="Enter new name"
                                        className="w-full"
                                        autoFocus
                                        defaultValue={
                                            dialogContent === 'Save as'
                                                ? ''
                                                : organisationReport.name
                                        }
                                        value={nameReport}
                                        onChange={(e) =>
                                            setNameReport(e.target.value)
                                        }
                                    />
                                )}
                        </div>
                    )}
                    <DialogFooter>
                        <DialogClose asChild>
                            <Button type="button" variant="secondary">
                                Close
                            </Button>
                        </DialogClose>
                        <Button
                            disabled={
                                dialogContent !== 'Delete Report' && !nameReport
                            }
                            onClick={() => handleDialogSubmit(dialogContent)}
                        >
                            {dialogContent === 'Delete Report'
                                ? 'Delete'
                                : 'Save changes'}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}
