import { sortingDateFn, sortingTextFn } from '@/version2/types'
import { DataTable } from '@2/components/data-table/data-table'
import { useDataTable } from '@2/components/data-table/hooks/use-data-table'
import { getColumns } from './organistion-holiday-columns'
import {
    useActiveHolidays,
    updateHoliday,
    deleteHoliday,
} from './organistion-holiday-store'
import { useMemo } from 'react'

export function OrganisationHolidaysTable() {
    const holidays = useActiveHolidays()

    const columns = useMemo(
        () => getColumns((column: string) => {}, updateHoliday, deleteHoliday),
        []
    )

    const defaultSort = [
        {
            id: 'name',
            desc: false,
        },
    ]

    const { table } = useDataTable({
        data: holidays,
        defaultSort,
        columns,
        visibleColumns: columns.map((column) => column?.accessorKey),
        sortingFns: {
            sortingTextFn,
            sortingDateFn,
        },
    })

    return <DataTable table={table} />
}
