import { Button } from '@2/components/ui/button'
import { OverheadExpenseTable } from './overhead-expenses-table'
import { addOperationalExpenses } from './overhead-expenses-store'
import uuid from 'uuid'

export const OverHeadExpensePage = () => {
    const handleAddOverheadExpense = () => {
        addOperationalExpenses({
            id: uuid(),
            name: '',
            startDate: new Date(),
            endDate: new Date(),
            costCentreId: null,
            value: null,
            hasRepeat: false,
            repeatQuantity: null,
            repeatUnit: null,
        })
    }
    return (
        <>
            <div className="p-8">
                <Button variant="outline" onClick={handleAddOverheadExpense}>
                    + Add Overhead Expense
                </Button>
            </div>
            <OverheadExpenseTable />
        </>
    )
}
