import _ from 'lodash'
import React, { useState } from 'react'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import {
    canEditRevenueTargets,
    canViewProjectMilestones,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import { titleCase } from 'title-case'
import { FormatCurrency } from '../../Utils/Localisation/CurrencyFormatter'
import { capitalCase } from 'change-case'
import ProjectForecastsStore from '../../Pages/ProjectPage/forecasts/ProjectForecastsStore'
import { ProjectSidebarStore } from '../../Pages/ResourceSchedulePage/ResourceScheduleProjectSidebar'
import CellComponent from '../../Components/CellComponent'
import MilestoneCollection from '../../State/Collections/MilestoneCollection'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '../../Components/ui/Popover'

const cellStyles = (row, stores, monthStart, isPastMonth) => {
    const inPhaseDateRange =
        row?.startDate &&
        row?.endDate &&
        monthStart >= startOfMonth(new Date(row?.startDate)) &&
        monthStart <= endOfMonth(new Date(row?.endDate))
    const hasRevenue = stores?.cell?.value !== 0
    const isNegative = stores?.cell?.value < 0
    const overBudget = row?.getProgressInMonth?.(monthStart) > 1
    return {
        ...(isPastMonth
            ? {
                  backgroundImage: `url(${new URL(
                      '~/public/diag.png',
                      import.meta.url
                  )})`,
              }
            : {}),
        ...(hasRevenue
            ? !(isNegative || overBudget)
                ? { borderBottom: '5px gold solid' }
                : { borderBottom: '5px #f15a29 solid', color: '#f15a29' }
            : inPhaseDateRange
              ? { borderBottom: '3px #d7d7d7 solid' }
              : {}),
    }
}

export const RevenueForecastReportPhaseColumns = (store, keyString = '') => ({
    title: (
        monthStartDate,
        groupBy = [],
        filters = [],
        { status, projectId } = {}
    ) => ({
        id: 'title',
        label: 'Title',
        type: 'text',
        width: 18,
        editable: (row) => false,
        value: (row, stores) => {
            // labels disappear when using title case for some reason
            // checking it exist first works - maybe some observable thing?
            return row.title
        },
        component: ({ value, group, stores }) => {
            const row = stores.row.rowObject
            if (stores.row.group === 'totals') return 'Total'
            return (
                <div style={{ paddingLeft: `${1 * row.groupLevel}rem` }}>
                    {row.groups?.length === 1
                        ? capitalCase(value)
                        : titleCase(value)}
                </div>
            )
        },
    }),
    revenue: (
        monthStartDate,
        groupBy = [],
        filters = [],
        { status, projectId } = {}
    ) => {
        const monthStart = startOfMonth(monthStartDate)
        const monthEnd = endOfMonth(monthStart)
        const currentMonthStart = startOfMonth(new Date())
        const monthType =
            monthStart.getTime() === currentMonthStart.getTime()
                ? 'current'
                : monthStart.getTime() > currentMonthStart.getTime()
                  ? 'future'
                  : 'past'
        const isPastMonth = monthType === 'past'
        const isFutureMonth = monthType === 'future'
        const isCurrentMonth = monthType === 'current'
        const cellLabel = format(monthStartDate, 'yyyy-MM')
        let queryId = 'revenue'
        if (status) queryId += status
        if (projectId) queryId += projectId
        return {
            id: cellLabel,
            label: format(monthStartDate, 'MMM yy'),
            type: 'currency',
            width: 8,
            value: (row) => {
                return store.getRevenueInMonth(
                    keyString ? `${keyString},${row.key}` : row.key,
                    cellLabel,
                    null
                )
            },
            component: (props) => {
                const row = props.stores.row.rowObject
                return RevenueCellComponent(
                    props,
                    store,
                    format(monthStartDate, 'yyyy-MM'),
                    keyString ? `${keyString},${row.key}` : row.key
                )
            },
            editable: () => {
                return (
                    (isFutureMonth ||
                        isCurrentMonth ||
                        store.report.filters.revenueData === 'projected') &&
                    keyString?.split(',').filter((k) => k !== '').length >= 1 &&
                    canEditRevenueTargets(SessionStore.user)
                )
            },
            style: (row, stores) => {
                return cellStyles(row, stores, monthStart, isPastMonth)
            },
            format: (v) => FormatCurrency(v, { decimals: 0 }),
            aggregate: 'sum',
            onClick: (row) => () => {
                store.selectCell(
                    monthStart,
                    row,
                    keyString ? `${keyString},${row.key}` : row.key
                )
            },
            selected: (row) => {
                return (
                    store.selectedObject === row &&
                    format(store.selectedMonth, 'yyyy-MM') ===
                        format(monthStart, 'yyyy-MM')
                )
            },
            onChange: (r) => (v) => {
                v = v || 0
                store.setEditedRevenueData(
                    keyString ? `${keyString},${r.key}` : r.key,
                    format(monthStartDate, 'yyyy-MM'),
                    v,
                    (period, newRevenue, projectId, phaseId) => {
                        ProjectSidebarStore.setRevenueInPeriod(
                            format(monthStartDate, 'yyyy-MM'),
                            projectId,
                            phaseId || null,
                            newRevenue
                        )
                    }
                )
            },
        }
    },
    csvRevenue: (
        monthStartDate,
        groupBy = [],
        filters = [],
        { status, projectId } = {}
    ) => {
        const monthStart = startOfMonth(monthStartDate)
        const monthLabel = format(monthStart, 'yyyy-MM')
        return {
            id: monthLabel,
            label: format(monthStartDate, 'MMM yy'),
            type: 'text',
            value: (row) => {
                const key = keyString ? `${keyString},${row.key}` : row.key
                let value = FormatCurrency(
                    store.getRevenueInMonth(key, monthLabel, null) || 0,
                    { decimals: 0 }
                )
                if (store.report.filters.revenueData === 'actualsVsProjected') {
                    const actualRevenueInMonth = store.getRevenueInMonth(
                        key,
                        monthLabel,
                        'actuals'
                    )
                    const projectedRevenueInMonth = store.getRevenueInMonth(
                        key,
                        monthLabel,
                        'projected'
                    )
                    value = `${FormatCurrency(actualRevenueInMonth, {
                        decimals: 0,
                    })} / ${FormatCurrency(projectedRevenueInMonth, { decimals: 0 })}`
                }
                return value
            },
        }
    },
    revenueVsFee: (monthStartDate, groupBy = [], filters = []) => {
        const currentMonthStart = startOfMonth(new Date())
        const currentMonthEnd = endOfMonth(currentMonthStart)
        return {
            id: 'revenueVsFee',
            label: (
                <span>
                    Total
                    <span
                        style={{ fontSize: '1.5em', float: 'right' }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <i
                            className={`fa fa-caret-left`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => store.shiftDates(-1)}
                        />
                        <i
                            className={`fa fa-caret-right`}
                            style={{ marginRight: 0, cursor: 'pointer' }}
                            onClick={() => store.shiftDates(1)}
                        />
                    </span>
                </span>
            ),
            type: 'progress',
            width: 18,
            editable: (row) => false,
            format: (v) => FormatCurrency(v, { decimals: 0 }),
            style: (row, stores) => {
                return {
                    ...(stores?.cell?.value?.numerator /
                        stores?.cell?.value?.denominator >
                    1
                        ? { color: '#f15a29' }
                        : {}),
                }
            },
            value: (r) => {
                return {
                    numerator:
                        store.getTotalRevenue(
                            keyString ? `${keyString},${r.key}` : r.key
                        ) || 0,
                    denominator:
                        store.getTotalFee(
                            keyString ? `${keyString},${r.key}` : r.key
                        ) || 0,
                }
            },
        }
    },
})

const RevenueCellComponent = (props, store, month, key = '') => {
    const keys = key.split(',')
    const projectId = keys[1]
    const phaseId = keys[2]
    let value = props.formattedValue
    if (store.report.filters.revenueData === 'actualsVsProjected') {
        const actualRevenueInMonth = store.getRevenueInMonth(
            key,
            month,
            'actuals'
        )
        const projectedRevenueInMonth = store.getRevenueInMonth(
            key,
            month,
            'projected'
        )
        value = `${FormatCurrency(actualRevenueInMonth, {
            decimals: 0,
        })} / ${FormatCurrency(projectedRevenueInMonth, { decimals: 0 })}`
    }
    return (
        <>
            <MilestoneCellComponent
                projectId={projectId}
                phaseId={phaseId}
                month={month}
            />
            <div
                {...{
                    ...props,
                    onChange: null,
                    style: { ...props.style, justifyContent: 'flex-end' },
                }}
            >
                {props.selected && props.editable
                    ? CellComponent['currency']({
                          ...props,
                          key: 'edit',
                      })
                    : CellComponent['text']({
                          ...props,
                          key: 'display',
                          style: {
                              textAlign: 'right',
                              justifyContent: 'flex-end',
                          },
                          value: value,
                          formattedValue: value,
                          editable: false,
                          onChange: null,
                      })}
            </div>
        </>
    )
}

const MilestoneCellComponent = ({ projectId, phaseId, month }) => {
    const [isOpen, setIsOpen] = useState(false)
    if (!canViewProjectMilestones(SessionStore.user)) return null
    const milestones =
        (phaseId
            ? MilestoneCollection.milestonesByPhaseId[phaseId]
            : MilestoneCollection.milestonesByProjectId[projectId]) || []
    const milestonesInMonth = milestones.filter((m) => m.month === month)
    if (milestonesInMonth.length === 0) return null
    return (
        <Popover open={isOpen}>
            <PopoverTrigger
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
            >
                <div className="absolute top-0.5 right-0.5 w-2 h-2 bg-rose-600 rounded-full cursor-pointer border border-white shadow-sm" />
            </PopoverTrigger>
            <PopoverContent
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
            >
                <div className="p-4 text-sm">
                    <h4>Milestones</h4>
                    <ul>
                        {milestonesInMonth.map((m) => (
                            <li key={m.id}>
                                <span className="font-bold">
                                    {format(m.date, 'dd/MM/yy')}
                                </span>{' '}
                                - {m.label}
                            </li>
                        ))}
                    </ul>
                </div>
            </PopoverContent>
        </Popover>
    )
}
