import { observable, computed, action, makeObservable, toJS } from 'mobx'
import React from 'react'
import _ from 'lodash'
import ReportCollection from '../../State/Collections/ReportCollection'
import SessionStore from '../../State/SessionStore'
import DefaultTimeReport from '../../reports/Time/DefaultTimeReport'
import { qf } from '../../Queries/queryFormatter'
import BatchDataStore from '../../State/BatchDataStore'
import { queryClient, router } from '../../App'
import { computedFn } from 'mobx-utils'
import TableStore from '../../Components/TableStore'
import download from 'downloadjs'
import Papa from 'papaparse'
import PhaseCollection from '../../State/Collections/PhaseCollection'
import ProjectCollection from '../../State/Collections/ProjectCollection'
import TaskCollection from '../../State/Collections/TaskCollection'

const supportedAggregates = ['sum', 'avg', 'min', 'max', 'count']

const columnsToFilterFields = {
    project: 'projectId',
    projectPhase: 'phaseTitle',
    staffMember: 'staffId',
    task: 'taskName',
    date: 'date',
    numMinutes: 'numMinutes',
    notes: 'notes',
    costCentre: 'projectCostCentreId',
    staffCostCentre: 'staffCostCentreId',
    projectOwner: 'projectOwnerId',
    projectContact: 'projectContactId',
    projectInvoiceContact: 'projectInvoiceContact',
    projectCode: 'projectCode',
    projectPhaseCode: 'projectPhaseCode',
    staffMemberFirstName: 'staffMemberFirstName',
    staffMemberLastName: 'staffMemberLastName',
    staffRole: 'staffRoleId',
    monthIndex: 'month',
    isBillable: 'isBillable',
    isVariation: 'isVariation',
    beenInvoiced: 'beenInvoiced',
    isOvertime: 'isOvertime',
    isLocked: 'isLocked',
    flexi: 'flexi',
    remote: 'remote',
    fee: 'projectFee',
    remainingFee: 'projectRemainingFee',
    budget: 'projectExpenseBudget',
    hoursBudget: 'projectHoursBudget',
    startMinutes: 'startMinutes',
    endMinutes: 'startMinutes',
    labourExpense: 'pay',
    cost: 'cost',
    chargeOut: 'chargeOut',
    chargeOutRate: 'chargeOutRate',
    hasNotes: 'hasNotes',
}

const columnsToGroupFields = {
    ...columnsToFilterFields,
}

const groupsToModelProps = {
    project: 'project',
    projectPhase: 'phaseTitle',
    staffMember: 'staff',
    staffRole: 'role',
    task: 'task',
}

class TimesheetReportStore {
    rowsLookup = {}
    rowsById = {}
    @observable selectedRows = new Set()
    queryIds = new Set()
    @observable batchSaving = false
    @observable expandAll = false
    @observable expandToLevel = 1
    @observable exportLevel = 10
    @observable tableStoresById = {}
    @observable queryData = []
    tableStore = new TableStore()
    @observable searchParams = {}

    constructor() {
        makeObservable(this)
    }

    @action.bound
    init(queryData) {
        this.queryData = queryData
        this.tableStore = new TableStore()
        this.selectedRows = new Set()
        this.exportLevel = this.report.groupBy.length
    }

    @action.bound
    reset() {
        this.rowsLookup = {}
        this.rowsById = {}
        this.selectedRows = new Set()
        this.queryIds = new Set()
        this.batchSaving = false
        this.expandAll = false
        this.expandToLevel = 1
        this.exportLevel = this.report.groupBy.length
        this.tableStore = new TableStore()
    }

    @action.bound
    setSearchParams(params) {
        this.searchParams = params
    }

    @action.bound
    toggleExpandAll() {
        this.expandAll = !this.expandAll
    }

    @action.bound
    setExpandToLevel(expandToLevel) {
        this.expandToLevel = expandToLevel
    }

    @action.bound
    setExportLevel(exportLevel) {
        this.exportLevel = exportLevel
    }

    @computed
    get report() {
        return (
            ReportCollection.reportsById[this.searchParams?.report] ||
            SessionStore.organisation?.defaultTimeReport
        )
    }

    @computed
    get groupBy() {
        return this.report?.groupBy?.length
            ? this.report.groupBy
            : DefaultTimeReport.groupBy
    }

    @computed
    get sortBy() {
        return this.report?.sortBy?.length
            ? this.report.sortBy
            : DefaultTimeReport.sortBy
    }

    getRowsAtLevel = computedFn((level) => {
        const getRows = (data, currentLevel) => {
            if (currentLevel === level) {
                return data
            }
            if (!data) {
                return []
            }
            return data.flatMap((d) =>
                getRows(d?.children || [], currentLevel + 1)
            )
        }

        return getRows(this.queryData, 0)
    })

    getLeafRows = computedFn((row) => {
        if (!row.children?.length) return [row]
        return row.children.flatMap((c) => this.getLeafRows(c))
    })

    isRowSelected = computedFn((row) => {
        return this.getLeafRows(row).every((r) => this.selectedRows.has(r))
    })

    @action.bound
    selectRow(row) {
        this.getLeafRows(row).forEach((r) => {
            this.selectedRows.add(r)
        })
    }
    @action.bound
    deselectRow(row) {
        this.getLeafRows(row).forEach((r) => {
            this.selectedRows.delete(r)
        })
    }
    @action.bound
    selectAllRows() {
        this.queryData.forEach((row) => this.selectRow(row))
    }
    @action.bound
    deselectAllRows() {
        this.selectedRows.clear()
    }
    @action.bound
    batchSaveSelectedRows(data) {
        this.batchSaving = true
        const selectedRowIds = [...this.selectedRows].map((r) => r.timeEntryId)
        BatchDataStore.addData({
            collection: 'timeEntries',
            filters: [[`id in (${qf(selectedRowIds)})`]],
            data: data,
        })
        BatchDataStore.startSave(() => {
            // queryClient.invalidateQueries([this.report.queryKey])
            this.editSelectedRowAndAncestors(selectedRowIds, data)
            this.batchSaving = false
        })
        this.selectedRows.clear()
        this.rowsLookup = {}
    }
    @action.bound
    editSelectedRowAndAncestors(selectedRows, data) {
        const selectedRowIds = new Set(selectedRows)
        const editRow = (row) => {
            let newRow
            const leafRows = this.getLeafRows(row)
            const shouldEdit = leafRows.every((r) =>
                selectedRowIds.has(r.timeEntryId)
            )
            const checkChildren =
                shouldEdit ||
                leafRows.some((r) => selectedRowIds.has(r.timeEntryId))
            if (shouldEdit) {
                if (data.projectId) {
                    const project =
                        ProjectCollection.projectsById[data.projectId]
                    data.project = project.title
                    if (row.label === row.project) {
                        data.label = project.title
                    }
                }
                if (data.phaseId) {
                    const phase = PhaseCollection.phasesById[data.phaseId]
                    data.projectPhase = phase.title
                    if (row.label === row.projectPhase) {
                        data.label = phase.title
                    }
                }
                if (data.taskId) {
                    const task = TaskCollection.tasksById[data.taskId]
                    data.task = task.name
                    if (row.label === row.task) {
                        data.label = task.name
                    }
                }
                newRow = {
                    ...row,
                    ...data,
                }
            } else {
                newRow = row
            }
            if (checkChildren && row.children?.length) {
                newRow.children = row.children.map((c) => editRow(c))
            }
            return newRow
        }
        this.queryData.map((row) => editRow(row))
    }

    async downloadCSV() {
        const rows = this.getRowsAtLevel(this.exportLevel)
        const tableStore = new TableStore()
        tableStore.update({
            columns: this.report.tableColumns,
            rows: rows,
        })
        download(
            Papa.unparse(tableStore.getCsvData()),
            `${this.report.name}.csv`,
            'text/csv'
        )
    }
}
export default new TimesheetReportStore()
