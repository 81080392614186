// hooks/use-table-selection.ts
import { Row, Table, RowSelectionState } from '@tanstack/react-table'

interface UseTableSelectionOptions {
    // Allow customizing selection behavior
    enableRowSelection?: (row: Row<any>) => boolean
    enableMultipleSelection?: boolean
}

export const useTableSelection = (options: UseTableSelectionOptions = {}) => {
    const { enableRowSelection = () => true, enableMultipleSelection = true } =
        options

    const getSubRowSelectionState = (
        row: Row<any>,
        selection: RowSelectionState
    ): 'none' | 'some' | 'all' => {
        const subRows = row.getLeafRows()
        if (!subRows.length) return 'none'

        const selectedCount = subRows.filter(
            (subRow) => selection[subRow.id]
        ).length
        if (selectedCount === 0) return 'none'
        if (selectedCount === subRows.length) return 'all'
        return 'some'
    }

    const handleRowSelection = (
        row: Row<any>,
        table: Table<any>,
        checked: boolean
    ) => {
        table.setRowSelection((old) => {
            const newSelection = { ...old }

            // Handle single selection mode
            if (!enableMultipleSelection) {
                Object.keys(newSelection).forEach((key) => {
                    delete newSelection[key]
                })
            }

            // Set the current row
            if (enableRowSelection(row)) {
                newSelection[row.id] = checked
            }

            // Handle child rows
            row.getLeafRows().forEach((subRow) => {
                if (enableRowSelection(subRow)) {
                    newSelection[subRow.id] = checked
                }
            })

            // Handle parent rows
            let currentRow = row.getParentRow()
            while (currentRow) {
                const subState = getSubRowSelectionState(
                    currentRow,
                    newSelection
                )
                newSelection[currentRow.id] = subState === 'all'
                currentRow = currentRow.getParentRow()
            }

            return newSelection
        })
    }

    return {
        getSubRowSelectionState,
        handleRowSelection,
    }
}
