import { DataTableAdvancedToolbar } from '@2/components/data-table/advanced/data-table-advanced-toolbar'
import { DataTable } from '@2/components/data-table/data-table'
import { useDataTable } from '@2/components/data-table/hooks/use-data-table'

import { trpc } from '@/system/trpc'
import { generateColumnDef } from '@/version2/components/data-table/helpers/generate-column-def'
import { orderColumnDefs } from '@/version2/components/data-table/helpers/order-columns'
import {
    ExportColumn,
    SetColumnsOptions,
    SetOrganisationReportOptions,
    sortingDateFn,
    sortingProgressFn,
    sortingTextFn,
    TableState,
    typeFilterMap,
} from '@/version2/types'
import { Link } from '@tanstack/react-router'
import { ProjectReportActions } from './project-report-actions'
import { getColumns } from './project-report-columns'
import {
    useOrganisationReport,
    useReportData,
    useIsLoadingReportData,
    usePendingChanges,
    setOrganisationReport,
    setReportData,
    setIsLoadingReportData,
    setColumns,
    setPendingChanges,
} from './project-report-store'
import { useMemo } from 'react'

export function ProjectReportTable() {
    const organisationReport = useOrganisationReport()
    const reportData = useReportData()
    const isLoadingReportData = useIsLoadingReportData()
    const pendingChanges = usePendingChanges()

    const handleStateChange = (
        newState: Partial<TableState>,
        options: SetOrganisationReportOptions
    ) => {
        setOrganisationReport(
            {
                ...organisationReport,
                ...newState,
            },
            options
        )
    }

    const handleColumnsChange = (
        columns: string[],
        options: SetColumnsOptions
    ) => {
        setColumns(columns, options)
    }

    const handleApplyChanges = async () => {
        setIsLoadingReportData(true)
        try {
            const data = await fetchReportData({
                ...organisationReport,
                groupBy: organisationReport.groupBy || ['projectId'],
                sortBy: organisationReport.sortBy || [],
            })
            setReportData(data)
        } finally {
            setIsLoadingReportData(false)
            setPendingChanges(false)
        }
    }
    const columns = useMemo(
        () =>
            orderColumnDefs(
                getColumns((column: string) => {
                    setColumns(
                        organisationReport.columns.filter(
                            (c: any) => c !== column
                        ),
                        {
                            needsRefetch: false,
                        }
                    )
                }),
                organisationReport.columns,
                [],
                []
            ),
        [organisationReport.columns]
    )
    const filterFields: any = columns.map((column: any) => {
        return {
            label: column?.meta?.title,
            value: column.accessorKey,
            typeFilter: typeFilterMap[column?.meta?.type],
            ...column?.meta?.filterOptions,
        }
    })

    const { table } = useDataTable({
        data: reportData,
        columns: columns,
        visibleColumns: organisationReport.columns,
        filterFields,
        enableAdvancedFilter: true,
        sortingFns: {
            sortingTextFn,
            sortingProgressFn,
            sortingDateFn,
        },
        groups: {
            enabled: true,
            columns: ['projectId'],
            leafRowLabel: 'Phase',
            labelColumn: generateColumnDef({
                key: 'name',
                type: 'text',
                title: 'Name',
                size: 260,
                cell: (row) => {
                    const value = row.getValue('name')
                    return (
                        <div
                            style={{
                                paddingLeft: `${1 * row.original.groupLevel}rem`,
                                width: '100%',
                            }}
                        >
                            {row.original.children?.length ? (
                                <Link
                                    to={`/projects/${row.original.projectId}`}
                                >
                                    {value}
                                </Link>
                            ) : (
                                value
                            )}
                        </div>
                    )
                },
            }),
        },
    })

    const fetchReportData = async (data: any) => {
        return await trpc.projectReport.getReportsData.query(data)
    }

    const exportColumns: ExportColumn[] = [
        {
            label: 'Project',
            value: 'project',
        },
        {
            label: 'Phase',
            value: 'phase',
        },
    ]

    return (
        <DataTable
            table={table}
            showTotals={true}
            loadingReportData={isLoadingReportData}
        >
            <DataTableAdvancedToolbar
                table={table}
                filterFields={filterFields}
                searchableFields={['project']}
                tableState={{
                    columns: organisationReport.columns,
                    filters: organisationReport.filters ?? [],
                    dateRange: organisationReport.dateRange,
                    sortBy: organisationReport.sortBy,
                    groupBy: organisationReport.groupBy,
                }}
                pendingChanges={pendingChanges}
                onStateChange={handleStateChange}
                onColumnsChange={handleColumnsChange}
                onApplyChanges={handleApplyChanges}
                isLoading={isLoadingReportData}
                groupByColumns={['projectId']}
            >
                <ProjectReportActions
                    table={table}
                    exportColumns={exportColumns}
                />
            </DataTableAdvancedToolbar>
        </DataTable>
    )
}
