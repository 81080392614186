import cuid from 'cuid'
import { parse } from 'date-fns'
import { observable, computed, action, makeObservable } from 'mobx'
import DailyAllocationCollection from '../Collections/DailyAllocationCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import ProjectExpenseCollection from '../Collections/ProjectExpenseCollection'
import ProjectRateCollection from '../Collections/ProjectRateCollection'
import TaskCollection from '../Collections/TaskCollection'
import TimeEntryCollection from '../Collections/TimeEntryCollection'
import Model from './Model'
import { addUnitToDate, newDate, unitsBetween } from '@ryki/datemath'
import PhaseCollection from '../Collections/PhaseCollection'
import _ from 'lodash'
import BudgetCollection from '../Collections/BudgetCollection'
import InvoiceLineItemAggregateCollection from '../Aggregates/InvoiceLineItemAggregateCollection'
import InvoiceLineItemCollection from '../Collections/InvoiceLineItemCollection'
import TimeEntryAggregateCollection from '../Aggregates/TimeEntryAggregateCollection'
import ProjectNoteCollection from '../Collections/ProjectNoteCollection'
import RevenueTargetCollection from '../Collections/RevenueTargetCollection'
import ProjectExpenseAllocationCollection from '../Collections/ProjectExpenseAllocationCollection'
import ChangeLogCollection from '../Collections/ChangeLogCollection'
import bind from 'bind-decorator'
import getRateInDateRange from '../../Utils/getRateInDateRange'
import getRateAtDate from '../../Utils/getRateAtDate'
import { get } from 'underscore'
import MonthlyAllocationCollection from '../Collections/MonthlyAllocationCollection'
import WeeklyAllocationCollection from '../Collections/WeeklyAllocationCollection'

class PhaseModel extends Model {
    @observable jobNumber = null
    @observable name = null
    @observable projectId = null
    @observable startDate = null
    @observable endDate = null
    @observable fee = 0
    @observable expenseBudget = 0
    @observable hoursBudget = 0
    @observable status = null
    @observable percentLikelihood = null
    @observable feeLinked = null
    @observable expenseBudgetLinked = null
    @observable hoursBudgetLinked = null
    @observable durationUnit = 'weeks'
    @observable phaseData = null
    @observable previousBilled = null
    @observable isRootPhase = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = PhaseCollection
        this.init(data, options)
    }
    @computed
    get project() {
        return ProjectCollection.modelsById[this.projectId]
    }

    @computed
    get allocations() {
        return this.monthlyAllocations
    }

    @computed
    get monthlyAllocations() {
        return MonthlyAllocationCollection.allocationsByPhaseId[this.id] || []
    }

    @computed
    get weeklyAllocations() {
        return WeeklyAllocationCollection.allocationsByPhaseId[this.id] || []
    }

    @computed
    get allocatedStaff() {
        return this.allocations.map((a) => a.staff)
    }

    @computed
    get dailyAllocations() {
        return (
            DailyAllocationCollection.dailyAllocationsByPhaseId[this.id] || []
        )
    }
    @computed
    get title() {
        return this.jobNumber && this.name
            ? `${this.jobNumber}: ${this.name}`
            : this.name || this.jobNumber
    }

    @computed
    get expenses() {
        return ProjectExpenseCollection.expensesByPhaseId[this.id] || []
    }

    @computed
    get expenseAllocations() {
        return (
            ProjectExpenseAllocationCollection.expenseAllocationsByPhaseId[
                this.id
            ] || []
        )
    }

    @computed
    get rates() {
        return ProjectRateCollection.ratesByPhaseId[this.id] || []
    }

    @computed
    get tasks() {
        return (TaskCollection.tasksByPhaseId[this.id] || []).sort((a, b) => {
            // First, sort by createdAt.
            const createdAtComparison =
                new Date(a.initiatedAt) - new Date(b.initiatedAt)
            if (createdAtComparison !== 0) {
                return createdAtComparison
            }

            // If createdAt is the same, sort by name.
            // Tasks without names go last.
            if (a.name && b.name) {
                return a.name.localeCompare(b.name)
            }
            if (a.name) {
                return -1 // a has a name and comes before b
            }
            if (b.name) {
                return 1 // b has a name and comes before a
            }

            // If both don't have a name, keep their order unchanged.
            return 0
        })
    }
    @computed
    get budgets() {
        return BudgetCollection.budgetsByPhaseId[this.id] || []
    }
    @computed
    get hoursFromBudgets() {
        return _.sum(this.budgets.map((b) => b.hours))
    }
    @computed
    get costFromBudgets() {
        return _.sum(this.budgets.map((b) => b.cost))
    }
    @computed
    get budgetedStaff() {
        return this.budgets.map((b) => b.staff)
    }
    @computed
    get budgetedRoles() {
        return [
            ...new Set(
                [
                    ...this.budgets.map((b) => b.staff?.role),
                    ...this.budgets.map((b) => b.role),
                ].filter((r) => r)
            ),
        ]
    }
    @computed
    get duration() {
        return unitsBetween(this.startDate, this.endDate, this.durationUnit)
    }

    @computed
    get invoiceLineItems() {
        return InvoiceLineItemCollection.lineItemsByPhaseId[this.id] || []
    }

    @computed
    get timeEntries() {
        return TimeEntryCollection.timeEntriesByPhaseId[this.id] || []
    }

    @bind
    getAggregateInvoiceLineItems(group) {
        return (
            InvoiceLineItemAggregateCollection.getGroupCollection(group)
                .lineItemsByPhaseId[this.id] || []
        )
    }

    @bind
    getAggregateTimeEntries(group) {
        return (
            TimeEntryAggregateCollection.getGroupCollection(group)
                .timeEntriesByPhaseId[this.id] || []
        )
    }

    @computed
    get revenueTargets() {
        return RevenueTargetCollection.revenueTargetsByPhaseId[this.id] || []
    }

    @computed
    get costCentre() {
        return this.project?.costCentre
    }

    @computed
    get contact() {
        return this.project?.contact
    }

    @computed
    get primaryContact() {
        return this.project?.primaryContact
    }

    @computed
    get owner() {
        return this.project?.owner
    }

    @computed
    get notes() {
        return ProjectNoteCollection.notesByPhaseId[this.id]
    }

    @computed
    get changeLog() {
        return ChangeLogCollection.changeLogItemsByPhaseId[this.id] || []
    }

    @action.bound
    updateDuration(duration, options) {
        const newEndDate = addUnitToDate(
            this.startDate,
            duration.unit,
            duration.value
        )
        this.update({ endDate: newEndDate }, options)
    }
    @computed
    get hasDates() {
        return this.startDate && this.endDate
    }
    @computed
    get isCurrent() {
        return (
            (!this.startDate && this.endDate >= new Date()) ||
            (!this.endDate && this.startDate <= new Date()) ||
            (this.endDate >= new Date() && this.startDate <= new Date())
        )
    }
    @computed
    get hasEnded() {
        return this.hasDates && !this.isCurrent
    }
    @action.bound
    updateBudgets(field) {
        const budgets = this.budgets.filter((b) => b.resource)
        const expenses = this.expenses.filter((e) => !e.billable)
        if (field === 'fee' && this.feeLinked && budgets.length) {
            const existingBudgetFee = _.sum(budgets.map((b) => b.chargeOut))
            if (!existingBudgetFee) {
                budgets.forEach((b) => {
                    b.update({ hours: 1 })
                })
            }
            const ratio = this.fee / _.sum(budgets.map((b) => b.chargeOut))
            budgets.forEach((b) => {
                b.update({
                    hours: isFinite(b.hours * ratio) ? b.hours * ratio : 0,
                })
            })
        } else if (
            field === 'expenseBudget' &&
            this.expenseBudgetLinked &&
            (budgets.length || expenses.length)
        ) {
            const existingBudget = _.sum(budgets.map((b) => b.cost))
            if (!existingBudget) {
                budgets.forEach((b) => {
                    b.update({ hours: 1 })
                })
            }
            const ratio =
                (this.expenseBudget - _.sum(expenses.map((e) => e.cost))) /
                _.sum(budgets.map((b) => b.cost))
            budgets.forEach((b) => {
                b.update({
                    hours: isFinite(b.hours * ratio) ? b.hours * ratio : 0,
                })
            })
        } else if (
            field === 'hoursBudget' &&
            this.hoursBudgetLinked &&
            budgets.length
        ) {
            const existingBudget = _.sum(budgets.map((b) => b.hours))
            if (!existingBudget) {
                budgets.forEach((b) => {
                    b.update({ hours: 1 })
                })
            }
            const ratio = this.hoursBudget / _.sum(budgets.map((b) => b.hours))
            budgets.forEach((b) => {
                b.update({
                    hours: isFinite(b.hours * ratio) ? b.hours * ratio : 0,
                })
            })
        }
        this.updateFromBudgets()
    }
    @action.bound
    updateFromBudgets() {
        const budgets = this.budgets.filter((b) => b.resource)
        const expenses = this.expenses.filter((e) => !e.billable)
        if (this.feeLinked && budgets.length) {
            this.update({ fee: _.sum(budgets.map((b) => b.chargeOut)) })
        }
        if (this.expenseBudgetLinked && (budgets.length || expenses.length)) {
            this.update({
                expenseBudget:
                    _.sum(budgets.map((b) => b.cost)) +
                    _.sum(expenses.map((e) => e.cost)),
            })
        }
        if (this.hoursBudgetLinked && budgets.length) {
            this.update({
                hoursBudget: _.sum(budgets.map((b) => b.hours)),
            })
        }
    }

    @computed
    get feeAdjustedByLikelihood() {
        if (
            ['prospective', 'onHold'].includes(this.status) &&
            this.percentLikelihood
        ) {
            return this.fee * (this.percentLikelihood / 100)
        } else {
            return this.fee
        }
    }
    @computed
    get defaultTask() {
        return this.tasks.find((t) => t.isDefault) || this.tasks?.[0]
    }

    @computed
    get costRate() {
        return _.mean(this.budgets.map((b) => b.costRate))
    }

    @computed
    get chargeOutRate() {
        return _.mean(this.budgets.map((b) => b.chargeOutRate))
    }

    getRateAtDate(rateType, date) {
        return _.mean(
            this.budgets.map((b) => {
                return (
                    getRateAtDate(
                        this.rates.filter(
                            (r) =>
                                r.staff === b.staff ||
                                (!r.staff && r.role === b.role)
                        ),
                        rateType,
                        date
                    ) ||
                    b.staff?.getRateAtDate(rateType, date) ||
                    b.role?.getRateAtDate(rateType, date) ||
                    0
                )
            })
        )
    }
}

export default PhaseModel
