import { sortingDateFn, sortingTextFn } from '@/version2/types'
import { DataTable } from '@2/components/data-table/data-table'
import { useDataTable } from '@2/components/data-table/hooks/use-data-table'

import {
    useActiveCostCentres,
    updateCostCentre,
    deleteCostCentre,
} from './cost-centre-store'
import { getColumns } from './cost-centre-columns'
import { useMemo } from 'react'

export function CostCentreTable() {
    const costCentres = useActiveCostCentres()
    const columns = useMemo(
        () =>
            getColumns(
                (column: string) => {},
                updateCostCentre,
                deleteCostCentre
            ),
        [updateCostCentre, deleteCostCentre]
    )
    const defaultSort = [
        {
            id: 'name',
            desc: false,
        },
    ]

    const { table } = useDataTable({
        data: costCentres,
        defaultSort,
        columns,
        visibleColumns: columns.map((column) => column?.accessorKey),
        sortingFns: {
            sortingTextFn,
            sortingDateFn,
        },
    })

    return <DataTable table={table}></DataTable>
}
