import React, { useState } from 'react'
import { observer } from 'mobx-react'
import ResourceScheduleReportHeader from '../../Components/Reports/ResourceScheduleReportHeader'
import ResourceScheduleReportCollection from '../../State/Collections/ResourceScheduleReportCollection'
import SessionStore from '../../State/SessionStore'
import PageHeader from '../Layout/PageHeader'
import ResourceScheduleStore from './ResourceScheduleStore'
import { canEditStaffAllocations } from '../../State/Permissions/HasPermissions'
import { makeRequest } from '../../Queries/makeRequest'
import { queryClient } from '../../App'
import MonthlyResourceCellCollection from '../../State/Collections/MonthlyResourceCellCollection'
import DataStore from '../../State/DataStore'
import HeaderButton from '../../Components/ui/HeaderButton'
import { Selector } from '../../Components/Selector'

export default observer(() => {
    const [store, setStore] = useState(ResourceScheduleStore)
    return (
        <PageHeader
            heading={
                <div>
                    <div>Resource Schedule</div>
                </div>
            }
            extraContent={
                <ResourceScheduleReportHeader
                    store={store}
                    report={store.report}
                    reportOptions={ResourceScheduleReportCollection.resourceSchedules.filter(
                        (r) => !r.deletedAt
                    )}
                    baseUrl={'/projects'}
                />
            }
            rightButtons={
                <div className="flex items-center gap-2">
                    {/*canEditStaffAllocations(SessionStore.user) ? (
                        <HeaderButton
                            label="Auto Adjust"
                            icon={<i className="fa fa-magic" />}
                            onClick={async () => {
                                await DataStore.startSave()
                                MonthlyResourceCellCollection.clear()
                                await makeRequest({
                                    path:
                                        process.env.REACT_APP_NODE_SERVER_URL +
                                        '/auto-adjust/resources',
                                    method: 'POST',
                                    data: {
                                        organisationId:
                                            SessionStore.organisation.id,
                                        userId: SessionStore.user?.id,
                                        budgetType:
                                            SessionStore.organisation.settings
                                                ?.autoUpdateHours?.budget ||
                                            'remaining',
                                        startDateType:
                                            SessionStore.organisation.settings
                                                ?.autoUpdateHours?.start ||
                                            'now',
                                        endDateType:
                                            SessionStore.organisation.settings
                                                ?.autoUpdateHours?.end ||
                                            'endDate',
                                        nowTs: Date.now(),
                                    },
                                })
                                queryClient.refetchQueries({
                                    predicate: (query) => {
                                        if (
                                            typeof query.queryKey[0] !==
                                            'string'
                                        ) {
                                            return false
                                        }
                                        return query.queryKey[0]?.startsWith(
                                            'rc'
                                        )
                                    },
                                })
                            }}
                        />
                    ) : null*/}
                    <HeaderButton
                        label={store.expandAll ? 'Collapse All' : 'Expand All'}
                        className="export-button page-header__button"
                        onClick={() => store.setExpandAll(!store.expandAll)}
                        icon={
                            <i
                                className={`fa fa-${store.expandAll ? 'minus' : 'plus'}`}
                            />
                        }
                    />
                    <div className="export-button-dropdown inline-flex items-center dont-print gap-2">
                        <div className="flex items-center">
                            <HeaderButton
                                label="Export"
                                icon={<i className="fa fa-table" />}
                                onClick={() => {
                                    store.downloadCSV()
                                }}
                                className="border-r-0 rounded-tr-none rounded-br-none"
                            />
                            <Selector
                                className=" border! border-white bg-transparent hover:bg-white/25 transition-colors rounded-[6px] rounded-tl-none rounded-bl-none"
                                style={{
                                    maxWidth: '8em',
                                    margin: 0,
                                    fontSize: '1em',
                                    background: 'transparent',
                                }}
                                value={store.exportGroup}
                                options={store.report.filters.groups}
                                optionLabel={function (group) {
                                    return (
                                        <div className="capitalize">
                                            {group}
                                        </div>
                                    )
                                }}
                                onChange={(group) =>
                                    store.setExportGroup(group)
                                }
                            />
                        </div>
                    </div>
                    <HeaderButton
                        label="Print"
                        icon={<i className="fa fa-print" />}
                        onClick={() => window.print()}
                    />
                </div>
            }
        />
    )
})
