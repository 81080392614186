import { observable, computed, action, makeObservable } from 'mobx'
import PhaseCollection from '../Collections/PhaseCollection'
import StaffCollection from '../Collections/StaffCollection'
import Model from './Model'
import ProjectCollection from '../Collections/ProjectCollection'
import RoleCollection from '../Collections/RoleCollection'
import BudgetCollection from '../Collections/BudgetCollection'
import {
    avgSpanSet,
    spanSetBetweenSpan,
    spanSetFromPointSet,
} from '../../Spanner'
import combineRates from '../../Utils/combineRates'
import getCombinedRateInDateRange from '../../Utils/getCombinedRateInDateRange'

class BudgetModel extends Model {
    @observable staffId = null
    @observable roleId = null
    @observable projectId = null
    @observable phaseId = null
    @observable hours = null

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = BudgetCollection
        this.init(data, options)
    }

    @computed
    get resource() {
        return this.staff || this.role
    }

    @computed
    get staff() {
        return StaffCollection.staffsById[this.staffId]
    }

    @computed
    get phase() {
        return PhaseCollection.phasesById[this.phaseId]
    }

    @computed
    get project() {
        return ProjectCollection.projectsById[this.projectId]
    }

    @computed
    get role() {
        return RoleCollection.rolesById[this.roleId]
    }

    @computed
    get costRate() {
        if (!this.resource) return 0
        return getCombinedRateInDateRange(
            {
                project: this.project,
                phase: this.phase,
                staff: this.staff,
                role: this.role,
            },
            'cost',
            [this.startDate, this.endDate]
        )
    }

    @computed
    get chargeOutRate() {
        if (!this.resource) return 0
        return getCombinedRateInDateRange(
            {
                project: this.project,
                phase: this.phase,
                staff: this.staff,
                role: this.role,
            },
            'chargeOut',
            [this.startDate, this.endDate]
        )
    }

    @computed
    get cost() {
        return this.hours * this.costRate
    }

    @computed
    get chargeOut() {
        return this.hours * this.chargeOutRate
    }

    @action.bound
    rateValueToHours(value, rateType) {
        const rateName =
            rateType === 'availability'
                ? 'weeklyAvailability'
                : rateType + 'Rate'
        const val = value / this[rateName]
        this.update({ hours: isFinite(val) ? val : 0 })
    }

    @computed
    get rates() {
        return this.resource?.rates.sort((a, b) => a.date - b.date)
    }

    @computed
    get startDate() {
        return this.phase?.startDate
    }

    @computed
    get endDate() {
        return this.phase?.endDate
    }
}

export default BudgetModel
