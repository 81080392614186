import { useState } from 'react'
import { Settings, LayoutDashboard, LineChart, FileText } from 'lucide-react'
import { useFilteredCacheItems } from '@2/cache'
import {
    SubNavigationFooter,
    SubNavigationHeading,
    SubNavigationSidebar,
} from '../components/sidebar'
import { SubNavigationBody } from '../components/sidebar'
import { ExpandableList } from '../components/expandable-list'
import { SideNavButton } from '../components/navigation-button'

export const InvoicesSubNav = () => {
    const [expandedList, setExpandedList] = useState('reports')
    const invoiceReports = useFilteredCacheItems(
        'organisationReports',
        (r) => r.type === 'invoice'
    )

    const invoices = useFilteredCacheItems('invoices')

    const lists = [
        {
            id: 'reports',
            title: 'Invoice Reports',
            icon: LineChart,
            rootRoute: '/invoices',
            items: (invoiceReports || [])
                .map((r) => ({
                    label: r.name,
                    value: r.id,
                    route: `/invoices?report=${r.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
        {
            id: 'invoices',
            title: 'Invoices',
            icon: FileText,
            items: invoices
                .map((i) => ({
                    label: i.ref,
                    value: i.id,
                    route: `/invoices/${i.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
    ]

    return (
        <SubNavigationSidebar>
            <SubNavigationHeading>Invoices</SubNavigationHeading>
            <SubNavigationBody>
                {lists.map((list) => (
                    <ExpandableList
                        key={list.id}
                        title={list.title}
                        rootRoute={list.rootRoute}
                        items={list.items}
                        isExpanded={expandedList === list.id}
                        icon={list.icon}
                        onToggle={() =>
                            setExpandedList(
                                expandedList === list.id ? null : list.id
                            )
                        }
                    />
                ))}
            </SubNavigationBody>
            <SubNavigationFooter>
                <SideNavButton
                    icon={LayoutDashboard}
                    label="Invoice Templates"
                    onClick={() => null}
                />
                <SideNavButton
                    icon={Settings}
                    label="Invoice Settings"
                    onClick={() => null}
                />
            </SubNavigationFooter>
        </SubNavigationSidebar>
    )
}
