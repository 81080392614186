import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'
import { useMemo } from 'react'
import { Fraction, OrganisationReport } from '@/version2/types'

// Types
interface Project {
    projectId: string
    title: string
    startDate: string
    isRootPhase: boolean | null
    name: string
    actualVsBudgetedHours: Fraction
    actualVsBudgetedExpenses: Fraction
    revenueVsFee: Fraction
    profit: number
    profitMargin: number | null
    status: string
    hoursBudgeted: number
    recordedHoursAllTime: number
    expensesAllTime: number
    expenseBudget: number
    revenueAllTime: number
    fee: number
    revenue: number
    expensesProject: number
    expenses: number
    projectExpenses: number
}

export interface ProjectReportData extends Project {
    children: Project[]
    key: string
    label: string
}

interface SetOrganisationReportOptions {
    needsRefetch?: boolean
}

interface SetColumnsOptions {
    needsRefetch?: boolean
}

interface SetOrganisationReportOptions {
    needsRefetch?: boolean
}

interface SetColumnsOptions {
    needsRefetch?: boolean
}

// Initial state
const initialContext = {
    reportData: [] as ProjectReportData[],
    organisationReport: null as OrganisationReport | null,
    isLoadingReportData: false,
    columns: [] as string[],
    pendingChanges: false,
}

// Create store
export const projectReportStore = createStore({
    context: initialContext,
    on: {
        setOrganisationReport: {
            organisationReport: (
                _,
                event: {
                    report: OrganisationReport
                    options?: SetOrganisationReportOptions
                }
            ) => event.report,
            pendingChanges: (_, event) => event.options?.needsRefetch ?? true,
        },

        setReportData: {
            reportData: (_, event: { data: ProjectReportData[] }) => event.data,
        },

        setIsLoadingReportData: {
            isLoadingReportData: (_, event: { isLoading: boolean }) =>
                event.isLoading,
        },

        setColumns: {
            organisationReport: (
                context,
                event: {
                    columns: string[]
                    options?: SetColumnsOptions
                }
            ) => {
                if (!context.organisationReport) return null
                return {
                    ...context.organisationReport,
                    columns: event.columns,
                }
            },
            pendingChanges: (_, event) => event.options?.needsRefetch ?? true,
        },

        setPendingChanges: {
            pendingChanges: (_, event: { pending: boolean }) => event.pending,
        },
    },
})

// Selector hooks
export const useReportData = () =>
    useSelector(projectReportStore, (state) => state.context.reportData)

export const useOrganisationReport = () =>
    useSelector(projectReportStore, (state) => state.context.organisationReport)

export const useIsLoadingReportData = () =>
    useSelector(
        projectReportStore,
        (state) => state.context.isLoadingReportData
    )

export const useColumns = () => {
    const report = useOrganisationReport()
    return useMemo(() => report?.columns ?? [], [report])
}

export const usePendingChanges = () =>
    useSelector(projectReportStore, (state) => state.context.pendingChanges)

// Action creators
export const setOrganisationReport = (
    report: OrganisationReport,
    options?: SetOrganisationReportOptions
) =>
    projectReportStore.send({
        type: 'setOrganisationReport',
        report,
        options,
    })

export const setReportData = (data: ProjectReportData[]) =>
    projectReportStore.send({
        type: 'setReportData',
        data,
    })

export const setIsLoadingReportData = (isLoading: boolean) =>
    projectReportStore.send({
        type: 'setIsLoadingReportData',
        isLoading,
    })

export const setColumns = (columns: string[], options?: SetColumnsOptions) =>
    projectReportStore.send({
        type: 'setColumns',
        columns,
        options,
    })

export const setPendingChanges = (pending: boolean) =>
    projectReportStore.send({
        type: 'setPendingChanges',
        pending,
    })
