import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'
import { OrganisationReport } from '@/version2/types'
import { useMemo } from 'react'

interface InvoiceReportData {
    amountExTax: number
    amountIncTax: number
    amountTax: number
    costCentre: string | null
    description: string | null
    id: string
    issuedOn: string
    project: string
    projectId: string
    synced: boolean | null
}

interface SetOrganisationReportOptions {
    needsRefetch?: boolean
}

interface SetColumnsOptions {
    needsRefetch?: boolean
}

// Initial state
const initialContext = {
    reportData: null as InvoiceReportData[] | null,
    organisationReport: null as OrganisationReport | null,
    isLoadingReportData: false,
    columns: null as string[] | null,
    pendingChanges: false,
}

// Create the store
export const invoiceReportStore = createStore({
    context: initialContext,
    on: {
        setOrganisationReport: {
            organisationReport: (
                _,
                event: {
                    report: OrganisationReport
                    options?: SetOrganisationReportOptions
                }
            ) => event.report,
            pendingChanges: (_, event) => event.options?.needsRefetch ?? true,
        },
        setReportData: {
            reportData: (_, event: { data: InvoiceReportData[] | [] }) =>
                event.data,
        },
        setIsLoadingReportData: {
            isLoadingReportData: (_, event: { isLoading: boolean }) =>
                event.isLoading,
        },
        setColumns: {
            organisationReport: (
                context,
                event: {
                    columns: string[]
                    options?: SetColumnsOptions
                }
            ) =>
                context.organisationReport
                    ? {
                          ...context.organisationReport,
                          columns: event.columns,
                      }
                    : null,
            pendingChanges: (_, event) => event.options?.needsRefetch ?? true,
        },
        setPendingChanges: {
            pendingChanges: (_, event: { pending: boolean }) => event.pending,
        },
    },
})

// Action creators
export const setOrganisationReport = (
    report: OrganisationReport,
    options: SetOrganisationReportOptions = { needsRefetch: true }
) =>
    invoiceReportStore.send({
        type: 'setOrganisationReport',
        report,
        options,
    })

export const setReportData = (data: InvoiceReportData[] | []) =>
    invoiceReportStore.send({
        type: 'setReportData',
        data,
    })

export const setIsLoadingReportData = (isLoading: boolean) =>
    invoiceReportStore.send({
        type: 'setIsLoadingReportData',
        isLoading,
    })

export const setColumns = (
    columns: string[],
    options: SetColumnsOptions = { needsRefetch: true }
) =>
    invoiceReportStore.send({
        type: 'setColumns',
        columns,
        options,
    })

export const setPendingChanges = (pending: boolean) =>
    invoiceReportStore.send({
        type: 'setPendingChanges',
        pending,
    })

// Selectors
export const useReportData = () =>
    useSelector(invoiceReportStore, (state) => state.context.reportData)

export const useOrganisationReport = () =>
    useSelector(invoiceReportStore, (state) => state.context.organisationReport)

export const useIsLoadingReportData = () =>
    useSelector(
        invoiceReportStore,
        (state) => state.context.isLoadingReportData
    )

export const useColumns = () => {
    const organisationReport = useOrganisationReport()
    return useMemo(
        () => organisationReport?.columns ?? null,
        [organisationReport]
    )
}

export const usePendingChanges = () =>
    useSelector(invoiceReportStore, (state) => state.context.pendingChanges)

// Getter functions for non-reactive access
export const getReportData = () =>
    invoiceReportStore.getSnapshot().context.reportData

export const getOrganisationReport = () =>
    invoiceReportStore.getSnapshot().context.organisationReport

export const getColumns = () =>
    invoiceReportStore.getSnapshot().context.organisationReport?.columns ?? null
