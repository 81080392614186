import useSaveToast from '@/version2/components/hooks/save-toast'
import { setLayoutDefaults } from '@2/layout/layout-store'
import { createFileRoute } from '@tanstack/react-router'
import { useCallback, useEffect } from 'react'
import ErrorComponent from '../../Components/ErrorComponent'
import LoadingSpinner from '../../Components/LoadingSpinner'
import CostCentreListPage from '../../Pages/CostCentreListPage/CostCentreListPage'
import CostCentreListPageHeader from '../../Pages/CostCentreListPage/CostCentreListPageHeader'
import PageBody from '../../Pages/Layout/PageBody'
import fetchData from '../../Queries/fetchData'
import PermissionDenied from '../../Pages/PermissionDenied'
import {
    canViewRoute,
    migratedToV2,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import { trpc } from '../../system/trpc'
import {
    setIsLoading,
    setCostCentres,
    usePendingChanges,
    setPendingChanges,
    getCostCentres,
} from '../../version2/pages/cost-centre/cost-centre-store'
import { CostCentrePage as CostCentrePageV2 } from '../../version2/pages/cost-centre/cost-centre-page'
import { CenterPadPageBody } from '@2/layout/page-body'

export const Route = createFileRoute('/_private/cost-centres/')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'costCentres', params))
            throw new Error('Permission Denied')
        setLayoutDefaults({
            pageTitle: 'Cost Centres',
            subMenu: 'organisation',
        })
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    loader: async () => await loader(),
    pendingComponent: LoadingSpinner,
    component: Wrapper,
})

const loader = async () => {
    const collections = [
        {
            collection: 'costCentres',
            fields: ['name', 'isBillable'],
        },
    ].filter(Boolean)
    return Promise.all(collections.map(fetchData))
}

function Wrapper() {
    if (migratedToV2()) {
        return (
            <CenterPadPageBody>
                <CostCentreListPage />
            </CenterPadPageBody>
        )
    }
    return (
        <>
            <CostCentreListPageHeader />
            <PageBody>
                <CostCentreListPage />
            </PageBody>
        </>
    )
}
