// import _ from 'lodash'
import { Modal } from '@2/components/modal'
import { useContact } from './contact-store'

export const DeleteContactModal = ({ isOpen, setIsOpen, onSave }) => {
    const contact = useContact()

    return (
        <Modal
            canSave={() => true}
            isLoading={false}
            isOpenDialog={isOpen}
            setIsOpenDialog={setIsOpen}
            styleSaveButton={{
                border: '1px #ef4444 solid',
                backgroundColor: '#ef4444',
                color: 'white',
            }}
            heading="Delete Contact"
            saveLabel={'Delete Contact'}
            onSave={onSave}
        >
            <p style={{ margin: '1em' }}>
                {/* Are you sure you wish to delete <em>{contact?.label}</em>? */}
                Are you sure you wish to delete{' '}
                <strong>{`${contact?.firstName ?? ''} ${contact?.lastName ?? ''}`}</strong>
                ?
            </p>
        </Modal>
    )
}
