import { type ColumnDef } from '@tanstack/react-table'
import { createGenerateColumnDef } from '@/version2/components/data-table/helpers/generate-column-def'
import SessionStore from '@/State/SessionStore'
import { canEditHolidays } from '@/State/Permissions/HasPermissions'

export function getColumns(
    onHideColumn: (column: string) => void,
    updateHoliday: (id: string, data: any) => void,
    deleteHoliday: (id: string) => void
): ColumnDef<any>[] {
    const generateColumnDef = createGenerateColumnDef({
        onHideColumn,
    })
    const wideSize = 100
    return [
        generateColumnDef({
            key: 'name',
            type: 'text',
            title: 'Name',
            size: wideSize,
            editable: canEditHolidays(SessionStore.user),
            onChange: (row, val) =>
                updateHoliday(row.original.id, { name: val }),
        }),
        generateColumnDef({
            key: 'startDate',
            type: 'date',
            title: 'Start date',
            size: wideSize,
            editable: canEditHolidays(SessionStore.user),
            onChange: (row, val) =>
                updateHoliday(row.original.id, { startDate: val }),
        }),
        generateColumnDef({
            key: 'endDate',
            type: 'date',
            title: 'End date',
            size: wideSize,
            editable: canEditHolidays(SessionStore.user),
            onChange: (row, val) =>
                updateHoliday(row.original.id, { endDate: val }),
        }),
        generateColumnDef({
            key: 'deleteButton',
            title: 'Action',
            type: 'button',
            editable: canEditHolidays(SessionStore.user),
            permissions: () => canEditHolidays(SessionStore.user),
            cell: (row) => {
                return <i className="fa fa-times" />
            },
            onClick: (row) => deleteHoliday(row.original.id),
        }),
    ]
}
