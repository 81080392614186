import { Button } from '@/version2/components/ui/button'
import {
    Tooltip,
    TooltipTrigger,
    TooltipContent,
    TooltipProvider,
} from '@/version2/components/ui/tooltip'
import { Plus } from 'lucide-react'

export const SubNavigationSidebar = ({ children }) => {
    return (
        <nav className="flex flex-col w-64 h-full border-l border-border bg-background  transition-all duration-300">
            <div className="flex flex-col h-full p-4 overflow-y-auto overflow-x-hidden relative">
                <div className="flex flex-col h-full">{children}</div>
            </div>
        </nav>
    )
}

export const SubNavigationHeading = ({ children }) => {
    return (
        <div className="flex justify-between items-center pt-1 pb-4 flex-none">
            <h4 className="m-0">{children}</h4>
            {/* <TooltipProvider>
                <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                        <Button
                            variant="outline"
                            size="icon"
                            className="h-6 w-6"
                            onClick={() => {
                                return null
                            }}
                        >
                            <Plus className="h-4 w-4" />
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>Create Project</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider> */}
        </div>
    )
}

export const SubNavigationBody = ({ children }) => {
    return (
        <div className="flex-auto border-t border-border py-4">{children}</div>
    )
}

export const SubNavigationFooter = ({ children }) => {
    return (
        <div className="flex-none border-t border-border pt-4">{children}</div>
    )
}
