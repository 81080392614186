import { observer } from 'mobx-react'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import RevenueForecastReportHeader from '../../Components/Reports/RevenueForecastReportHeader'
import RevenueForecastReportCollection from '../../State/Collections/RevenueForecastReportCollection'
import SessionStore from '../../State/SessionStore'
import PageHeader from '../Layout/PageHeader'
import RevenueForecastStore from './RevenueForecastStore'
import { canEditRevenueTargets } from '../../State/Permissions/HasPermissions'
import { makeRequest } from '../../Queries/makeRequest'
import { queryClient } from '../../App'
import MonthlyRevenueCellCollection from '../../State/Collections/MonthlyRevenueCellCollection'
import DataStore from '../../State/DataStore'
import HeaderButton from '../../Components/ui/HeaderButton'
import { Selector } from '../../Components/Selector'

export default observer(({ label }) => {
    const [store, setStore] = useState(RevenueForecastStore)
    return (
        <PageHeader
            heading={
                <div>
                    <div>Revenue Forecast</div>
                </div>
            }
            extraContent={
                <RevenueForecastReportHeader
                    store={store}
                    report={store.report}
                    reportOptions={RevenueForecastReportCollection.revenueForecastReports.filter(
                        (r) => !r.deletedAt
                    )}
                    baseUrl={'/projects'}
                />
            }
            rightButtons={
                <div className="flex gap-2 items-center">
                    {/*canEditRevenueTargets(SessionStore.user) ? (
                        <HeaderButton
                            label="Auto Adjust"
                            icon={<i className="fa fa-magic" />}
                            onClick={async () => {
                                await DataStore.startSave()
                                MonthlyRevenueCellCollection.clear()
                                await makeRequest({
                                    path:
                                        process.env.REACT_APP_NODE_SERVER_URL +
                                        '/auto-adjust/revenue',
                                    method: 'POST',
                                    data: {
                                        organisationId:
                                            SessionStore.organisation.id,
                                        userId: SessionStore.user?.id,
                                        budgetType:
                                            SessionStore.organisation.settings
                                                ?.autoUpdateRevenue?.budget ||
                                            'remaining',
                                        startDateType:
                                            SessionStore.organisation.settings
                                                ?.autoUpdateRevenue?.start ||
                                            'now',
                                        endDateType:
                                            SessionStore.organisation.settings
                                                ?.autoUpdateRevenue?.end ||
                                            'endDate',
                                        nowTs: Date.now(),
                                    },
                                })
                                await makeRequest({
                                    path:
                                        process.env.REACT_APP_NODE_SERVER_URL +
                                        '/auto-adjust/expenses',
                                    method: 'POST',
                                    data: {
                                        organisationId:
                                            SessionStore.organisation.id,
                                        userId: SessionStore.user?.id,
                                        budgetType:
                                            SessionStore.organisation.settings
                                                ?.autoUpdateRevenue?.budget ||
                                            'remaining',
                                        startDateType:
                                            SessionStore.organisation.settings
                                                ?.autoUpdateRevenue?.start ||
                                            'now',
                                        endDateType:
                                            SessionStore.organisation.settings
                                                ?.autoUpdateRevenue?.end ||
                                            'endDate',
                                        nowTs: Date.now(),
                                    },
                                })
                                queryClient.refetchQueries({
                                    predicate: (query) => {
                                        if (
                                            typeof query.queryKey[0] !==
                                            'string'
                                        ) {
                                            return false
                                        }
                                        return (
                                            query.queryKey[0]?.startsWith(
                                                'rv'
                                            ) ||
                                            query.queryKey[0]?.startsWith('exp')
                                        )
                                    },
                                })
                            }}
                        />
                    ) : null*/}
                    <div className="export-button-dropdown inline-flex items-center dont-print gap-2">
                        <div className="flex items-center">
                            <HeaderButton
                                label="Export"
                                icon={<i className="fa fa-table" />}
                                onClick={() => {
                                    store.downloadCSV()
                                }}
                                className="border-r-0 rounded-tr-none rounded-br-none"
                            />
                            <Selector
                                className=" border! border-white bg-transparent hover:bg-white/25 transition-colors rounded-[6px] rounded-tl-none rounded-bl-none"
                                style={{
                                    maxWidth: '8em',
                                    margin: 0,
                                    fontSize: '1em',
                                    background: 'transparent',
                                }}
                                value={store.exportGroup}
                                options={['Projects', 'Phases']}
                                optionLabel={function (group) {
                                    return <div>{group}</div>
                                }}
                                onChange={(group) =>
                                    store.setExportGroup(group)
                                }
                            />
                        </div>
                    </div>

                    <HeaderButton
                        label="Print"
                        icon={<i className="fa fa-print" />}
                        onClick={() => window.print()}
                    />
                </div>
            }
        />
    )
})
