import React, { useState } from 'react'
import { Button } from '@2/components/ui/button'
import { Avatar, AvatarFallback, AvatarImage } from '@2/components/ui/avatar'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@2/components/ui/dropdown-menu'
import { ScrollArea } from '@2/components/ui/scroll-area'
import {
    MoreVertical,
    PencilRulerIcon,
    BuildingIcon,
    BookUserIcon,
    UsersIcon,
    ClockIcon,
    TruckIcon,
    ReceiptTextIcon,
    Settings,
    LandmarkIcon,
    HelpCircleIcon,
} from 'lucide-react'
import { cn } from '@2/lib/utils'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { NavButton } from './components/navigation-button'
import { ProjectsSubNav } from './sub-navs/project-sub-nav'
import { OrgSubNav } from './sub-navs/org-sub-nav'
import { ClientsSubNav } from './sub-navs/clients-sub-nav'
import { SuppliersSubNav } from './sub-navs/suppliers-sub-nav'
import { InvoicesSubNav } from './sub-navs/invoices-sub-nav'
import { ExpensesSubNav } from './sub-navs/expenses-sub-nav'
import { TimeSubNav } from './sub-navs/time-sub-nav'
import { ResourcesSubNav } from './sub-navs/resources-sub-nav'
import { useSubMenu, useShowSubMenu, setSubMenu } from '@2/layout/layout-store'
import { useNavigate } from '@tanstack/react-router'
import { useClerk } from '@clerk/clerk-react'
const subMenuLookup = {
    organisation: <OrgSubNav />,
    projects: <ProjectsSubNav />,
    clients: <ClientsSubNav />,
    suppliers: <SuppliersSubNav />,
    invoices: <InvoicesSubNav />,
    expenses: <ExpensesSubNav />,
    time: <TimeSubNav />,
    resources: <ResourcesSubNav />,
}

export const NavigationSidebar = () => {
    const subMenu = useSubMenu()
    const showSubMenu = useShowSubMenu()
    const [hovering, setHovering] = useState(false)
    const [parent, enableAnimations] = useAutoAnimate(/* optional config */)
    return (
        <div
            ref={parent}
            className="flex h-screen"
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => {
                setHovering(false)
            }}
        >
            <MainNavigationSidebar setSubMenu={setSubMenu} subMenu={subMenu} />
            {(showSubMenu || hovering) && subMenu
                ? subMenuLookup[subMenu]
                : null}
        </div>
    )
}

export const MainNavigationSidebar = ({ subMenu, setSubMenu }) => {
    const navigate = useNavigate()
    const { signOut } = useClerk()
    const isExpanded = false
    return (
        <nav
            className={cn(
                'relative flex flex-col h-full bg-background transition-all duration-300',
                isExpanded ? 'w-60' : 'w-16'
            )}
        >
            <div className="flex grow flex-col gap-y-4 overflow-hidden p-4">
                <div
                    className={cn(
                        'flex items-center',
                        isExpanded
                            ? 'justify-start space-x-2'
                            : 'justify-center'
                    )}
                >
                    <div className="w-7 h-7 bg-yellow-400 rounded-full flex-shrink-0"></div>
                    {isExpanded && (
                        <span className="text-lg font-semibold">coincraft</span>
                    )}
                </div>

                <ScrollArea className="flex-1 -mx-4 px-4">
                    <div className="space-y-1 py-4 border-t border-border">
                        <NavButton
                            active={subMenu === 'organisation'}
                            isExpanded={isExpanded}
                            icon={BuildingIcon}
                            label="Organisation"
                            onClick={() => {
                                setSubMenu('organisation')
                                navigate({
                                    to: '/revenue-forecast',
                                })
                            }}
                        />
                        <NavButton
                            active={subMenu === 'projects'}
                            isExpanded={isExpanded}
                            icon={PencilRulerIcon}
                            label="Projects"
                            onClick={() => {
                                setSubMenu('projects')
                                navigate({
                                    to: '/projects',
                                })
                            }}
                        />
                        <NavButton
                            active={subMenu === 'resources'}
                            isExpanded={isExpanded}
                            icon={UsersIcon}
                            label="Resources"
                            onClick={() => {
                                setSubMenu('resources')
                                navigate({
                                    to: '/staff',
                                })
                            }}
                        />
                        <NavButton
                            active={subMenu === 'clients'}
                            isExpanded={isExpanded}
                            icon={BookUserIcon}
                            label="Clients"
                            onClick={() => {
                                setSubMenu('clients')
                                navigate({
                                    to: '/contacts',
                                })
                            }}
                        />
                        <NavButton
                            active={subMenu === 'suppliers'}
                            isExpanded={isExpanded}
                            icon={TruckIcon}
                            label="Suppliers"
                            onClick={() => {
                                setSubMenu('suppliers')
                                navigate({
                                    to: '/suppliers',
                                })
                            }}
                        />
                    </div>
                    <div className="space-y-1 border-t border-b border-border py-4">
                        <NavButton
                            active={subMenu === 'invoices'}
                            isExpanded={isExpanded}
                            icon={LandmarkIcon}
                            label="Invoicing"
                            onClick={() => {
                                setSubMenu('invoices')
                                navigate({
                                    to: '/invoices',
                                })
                            }}
                        />
                        <NavButton
                            active={subMenu === 'time'}
                            isExpanded={isExpanded}
                            icon={ClockIcon}
                            label="Time"
                            onClick={() => {
                                setSubMenu('time')
                                navigate({
                                    to: '/timesheets?tab=weekly',
                                })
                            }}
                        />
                        <NavButton
                            active={subMenu === 'expenses'}
                            isExpanded={isExpanded}
                            icon={ReceiptTextIcon}
                            label="Expenses"
                            onClick={() => {
                                setSubMenu('expenses')
                                navigate({
                                    to: '/track-expenses',
                                })
                            }}
                        />
                    </div>
                </ScrollArea>
                <div className="space-y-1 border-b border-t border-border py-4">
                    <NavButton
                        isExpanded={isExpanded}
                        icon={HelpCircleIcon}
                        label="Help"
                        onClick={() =>
                            window
                                .open('https://help.coincraft.co', '_blank')
                                .focus()
                        }
                    />
                    <NavButton
                        isExpanded={isExpanded}
                        icon={Settings}
                        label="Settings"
                        onClick={() => {
                            setSubMenu(null)
                            navigate({
                                to: '/settings',
                            })
                        }}
                    />
                </div>

                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button
                            variant="ghost"
                            className={cn(
                                'w-full justify-between text-foreground hover:bg-accent hover:text-accent-foreground mb-2',
                                !isExpanded && 'justify-center p-2'
                            )}
                        >
                            <div className="flex items-center gap-2">
                                <Avatar className="h-8 w-8">
                                    <AvatarFallback className="bg-primary text-primary-foreground">
                                        LC
                                    </AvatarFallback>
                                </Avatar>
                                {isExpanded && <span>Le Corbusier</span>}
                            </div>
                            {isExpanded && <MoreVertical className="h-4 w-4" />}
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="w-[200px]">
                        <DropdownMenuItem>Profile</DropdownMenuItem>
                        <DropdownMenuItem
                            onClick={() => {
                                setSubMenu(null)
                                navigate({
                                    to: '/billing',
                                })
                            }}
                        >
                            Billing
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            onClick={() => {
                                signOut()
                            }}
                        >
                            Logout
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </nav>
    )
}
