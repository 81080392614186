import { useState } from 'react'
import {
    Settings,
    LayoutDashboard,
    LineChart,
    SquareKanban,
    Clock,
    Watch,
} from 'lucide-react'
import { useFilteredCacheItems } from '@2/cache'
import {
    SubNavigationFooter,
    SubNavigationHeading,
    SubNavigationSidebar,
} from '../components/sidebar'
import { SubNavigationBody } from '../components/sidebar'
import { ExpandableList } from '../components/expandable-list'
import { SideNavButton } from '../components/navigation-button'
import { useNavigate } from '@tanstack/react-router'

export const TimeSubNav = () => {
    const navigate = useNavigate()
    const [expandedList, setExpandedList] = useState('reports')
    const timeReports = useFilteredCacheItems(
        'organisationReports',
        (r) => r.type === 'timesheet'
    )

    const lists = [
        {
            id: 'reports',
            title: 'Time Reports',
            icon: LineChart,
            rootRoute: '/timesheets/report',
            items: (timeReports || [])
                .map((r) => ({
                    label: r.name,
                    value: r.id,
                    route: `/timesheets/report?report=${r.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
    ]

    return (
        <SubNavigationSidebar>
            <SubNavigationHeading>Time</SubNavigationHeading>
            <SubNavigationBody>
                <SideNavButton
                    icon={Clock}
                    label="Weekly Timesheets"
                    onClick={() => {
                        navigate({ to: '/timesheets?tab=weekly' })
                    }}
                />
                <SideNavButton
                    icon={Watch}
                    label="Daily Timesheets"
                    onClick={() => {
                        navigate({ to: '/timesheets?tab=daily' })
                    }}
                />
                <SideNavButton
                    icon={SquareKanban}
                    label="Time Entry Calendar"
                    onClick={() => {
                        navigate({ to: '/timesheets/calendar' })
                    }}
                />
                {lists.map((list) => (
                    <ExpandableList
                        key={list.id}
                        title={list.title}
                        rootRoute={list.rootRoute}
                        items={list.items}
                        isExpanded={expandedList === list.id}
                        icon={list.icon}
                        onToggle={() =>
                            setExpandedList(
                                expandedList === list.id ? null : list.id
                            )
                        }
                    />
                ))}
            </SubNavigationBody>
            <SubNavigationFooter>Timer here...</SubNavigationFooter>
        </SubNavigationSidebar>
    )
}
