import Formatter from '@/Components/Formatter'
import CellComponent from '@/Components/CellComponent'
import ProgressBar from '@/Components/Widgets/ProgresBar2'
import { Input } from '@2/components/ui/input'
import { DatePicker } from '@2/components/date-picker'
import { Button } from '../../ui/button'
import { Checkbox } from '../../ui/checkbox'
export function renderCell({
    value,
    row,
    column,
    cell,
    editable = false,
    onClick,
    onChange,
}: {
    value: any
    row: any
    column: any
    cell: (row: any) => any
    editable: boolean
    onClick: (row: any) => void
    onChange: (row: any, value: any) => void
}) {
    const { type, permissions } = column.columnDef?.meta
    if (permissions !== undefined && !permissions(row)) {
        value = null
    }
    if (cell && type !== 'button') {
        return cell(row)
    }
    switch (type) {
        case 'currency':
            if (editable) {
                return (
                    <Input
                        type={type}
                        value={value}
                        onChange={(e) => onChange(row, e.target.value)}
                    />
                )
            }
            return CellComponent.currency({
                value,
                formattedValue: Formatter.currency(value),
            })
        case 'percent':
            if (editable) {
                return (
                    <Input
                        type={type}
                        value={value}
                        onChange={(e) => onChange(row, e.target.value)}
                    />
                )
            }
            return CellComponent.percent({
                value,
                formattedValue: Formatter.percent(value),
            })
        case 'date': {
            if (editable) {
                return (
                    <DatePicker
                        value={value}
                        onChange={(val) => onChange(row, val)}
                    />
                )
            }
            return Formatter.date(value ? new Date(value) : null)
        }
        case 'number': {
            if (editable) {
                return (
                    <Input
                        type={type}
                        value={value}
                        onChange={(e) => onChange(row, e.target.value)}
                    />
                )
            }
            return CellComponent.number({
                value,
                formattedValue: Formatter.number(value),
            })
        }
        case 'status': {
            return Formatter.status(value)
        }
        case 'boolean': {
            if (editable) {
                return (
                    <Checkbox
                        checked={value}
                        onCheckedChange={(checked) => onChange(row, !!checked)}
                    />
                )
            }
            return Formatter.boolean(value)
        }
        case 'progressBar':
            const formatter =
                column.columnDef?.meta?.formatter || Formatter.number
            return (
                <ProgressBar
                    width="100%"
                    height="1.5em"
                    numerator={value?.numerator}
                    denominator={value?.denominator}
                    formatNumber={formatter}
                    radius="4"
                />
            )
        case 'button':
            return (
                <Button
                    onClick={() => onClick(row)}
                    disabled={!editable}
                    variant="outline"
                >
                    {cell(row)}
                </Button>
            )
        default:
            if (editable) {
                return (
                    <Input
                        type={type}
                        value={value}
                        onChange={(e) => onChange(row, e.target.value)}
                        onClick={() => onClick(row)}
                    />
                )
            }
            return value
    }
}
