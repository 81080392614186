import { createFileRoute } from '@tanstack/react-router'
import ContactListPageHeader from '../../Pages/ContactListPage/ContactListPageHeader'
import ContactListPage from '../../Pages/ContactListPage/ContactListPage'
import PageBody from '../../Pages/Layout/PageBody'
import fetchData from '../../Queries/fetchData'
import LoadingSpinner from '../../Components/LoadingSpinner'
import ErrorComponent from '../../Components/ErrorComponent'
import {
    canViewRoute,
    migratedToV2,
} from '../../State/Permissions/HasPermissions'
import SessionStore from '../../State/SessionStore'
import PermissionDenied from '../../Pages/PermissionDenied'
import { setLayoutDefaults } from '@2/layout/layout-store'
import { CenterPadPageBody } from '@2/layout/page-body'

export const Route = createFileRoute('/_private/contacts/')({
    beforeLoad: ({ params }) => {
        if (!canViewRoute(SessionStore.user, 'contacts', params))
            throw new Error('Permission Denied')
        setLayoutDefaults({
            pageTitle: 'Clients',
            subMenu: 'clients',
        })
    },
    errorComponent: ({ error, reset }) => {
        if (error.message === 'Permission Denied') return <PermissionDenied />
        return <ErrorComponent error={error} reset={reset} />
    },
    loader: async () => await loader(),
    pendingComponent: LoadingSpinner,
    component: Wrapper,
})

const loader = async () => {
    const collections = [
        {
            collection: 'contacts',
            fields: [
                'firstName',
                'lastName',
                'organisationName',
                'address',
                'email',
                'phone',
                'accountingSystemId',
                'accountingSystemContactId',
            ],
        },
    ].filter(Boolean)
    return Promise.all(collections.map(fetchData))
}
function Wrapper() {
    if (migratedToV2()) {
        return (
            <CenterPadPageBody>
                <ContactListPage />
            </CenterPadPageBody>
        )
    }
    return (
        <>
            <ContactListPageHeader />
            <PageBody>
                <ContactListPage />
            </PageBody>
        </>
    )
}
