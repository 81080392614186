import cuid from 'cuid'
import { observable, computed, action, makeObservable } from 'mobx'
import PhaseCollection from '../Collections/PhaseCollection'
import ProjectCollection from '../Collections/ProjectCollection'
import MilestoneCollection from '../Collections/MilestoneCollection'
import Model from './Model'
import { format } from 'date-fns'

class MilestoneModel extends Model {
    @observable projectId = null
    @observable phaseId = null

    @observable date = new Date()
    @observable label = ''

    constructor(data, options) {
        super()
        makeObservable(this)
        this.collection = MilestoneCollection
        this.init(data, options)
    }
    @computed
    get project() {
        return ProjectCollection.modelsById[this.projectId]
    }
    @computed
    get phase() {
        return PhaseCollection.modelsById[this.phaseId]
    }
    @computed
    get month() {
        return this.date ? format(this.date, 'yyyy-MM') : null
    }
    @computed
    get week() {
        return this.date ? format(this.date, 'RRRR-II') : null
    }
}

export default MilestoneModel
