import React from 'react'
import { NavigationSidebar } from '@2/layout/navigation-sidebar'
import { TopBar } from '@2/layout/topbar'
import { useAutoAnimate } from '@formkit/auto-animate/react'

export const Layout = ({ children }) => {
    const [parent, enableAnimations] = useAutoAnimate(/* optional config */)
    return (
        <>
            <div className="flex overflow-hidden bg-background h-screen print:overflow-visible">
                <div className="flex-none print:hidden">
                    <NavigationSidebar />
                </div>
                <div className="flex-auto flex flex-col overflow-hidden border-l border-border print:overflow-visible">
                    <div className="flex-none w-full">
                        <TopBar />
                    </div>
                    <div
                        ref={parent}
                        className="flex-auto w-full overflow-hidden flex"
                    >
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}
