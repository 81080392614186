import { ReactNode } from 'react'
import { useSidebarExpanded, useShowSidebar } from './layout-store'
import { cn } from '@/lib/utils'
import { useAutoAnimate } from '@formkit/auto-animate/react'

export const Sidebar = ({ children }: { children: ReactNode }) => {
    const [parent, enableAnimations] = useAutoAnimate(/* optional config */)
    const sidebarExpanded = useSidebarExpanded()
    const showSidebar = useShowSidebar()
    if (!showSidebar) return null
    return (
        <div
            ref={parent}
            className={cn(
                'flex-none flex flex-col overflow-auto print:overflow-visible border-l border-border transition-all duration-500',
                sidebarExpanded
                    ? 'translate-x-0 opacity-100 w-80'
                    : 'translate-x-full w-0 opacity-0'
            )}
        >
            {children}
            <div className="h-28 w-full print:hidden flex-none"></div>
        </div>
    )
}
