import { addDays, differenceInDays, format, parse } from 'date-fns'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { Switch, Input, InputNumber } from 'antd'
import { columnDefs } from './PdfTableColumnDefs'
import InvoiceCollection from '../../State/Collections/InvoiceCollection'
import SessionStore from '../../State/SessionStore'
import { canEditProjectInvoices } from '../../State/Permissions/HasPermissions'
import { MultiSelect } from '../../Components/MultiSelect'

const { TextArea } = Input

export default observer(({ id }) => {
    const invoice = InvoiceCollection.invoicesById[id]
    const template = SessionStore.organisation.defaultInvoiceTemplate
    if (!invoice || !canEditProjectInvoices(SessionStore.user, invoice.project))
        return null
    return (
        <div style={{ padding: '1em' }}>
            <h3 style={{ margin: '0.5em 0 0 0' }}>Logo</h3>
            <div style={{ textAlign: 'center' }}>
                <img
                    style={{ height: '5em' }}
                    alt="logo"
                    src={template.logoUrl}
                />
            </div>
            <div className="flex items-center mt-1 text-sm">
                <div
                    className="py-0 px-[0.5em] font-bold text-sm"
                    style={{
                        flex: '0 0 auto',
                    }}
                >
                    Logo URL
                </div>
                <div className="flex-1 py-0 px-[0.25em] !text-sm">
                    <Input
                        value={template.logoUrl || ''}
                        onChange={(e) =>
                            template.update({
                                logoUrl: e.target.value,
                            })
                        }
                    />
                </div>
            </div>
            <div className="flex items-center mt-1 text-sm">
                <div className="font-bold py-0 px-[0.5em]">Logo Size</div>
                <div className="flex-1 py-0 px-[0.25em]">
                    <InputNumber
                        value={template.logoSize}
                        onChange={(v) => {
                            template.update({
                                logoSize: parseFloat(v),
                            })
                        }}
                        min={1}
                        max={20}
                    />
                </div>
            </div>
            <div className="flex items-center mt-12 text-sm">
                <div
                    className="text-sm font-bold px-[0.5em]"
                    style={{
                        flex: '0 0 auto',
                    }}
                >
                    Document Label
                </div>
                <div style={{ flex: '1 1 auto', padding: '0 0.25em' }}>
                    <Input
                        value={template.documentLabel || ''}
                        onChange={(e) =>
                            template.update({
                                documentLabel: e.target.value,
                            })
                        }
                    />
                </div>
            </div>
            <h3 style={{ margin: '1.5em 0 0 0' }}>Header</h3>
            <div className="flex items-center mt-1 text-sm">
                <div className="px-[0.5em] py-0">
                    <Switch
                        checked={template.projectCode}
                        onChange={(projectCode) => {
                            template.update({
                                projectCode,
                            })
                        }}
                    />
                </div>
                <div className="flex-1 py-0 px-[0.25em] text-sm">
                    Display Project Code
                </div>
            </div>
            <div className="flex items-center mt-1 text-sm">
                <div
                    style={{
                        flex: '0 0 auto',
                        padding: '0 0.5em',
                    }}
                >
                    <Switch
                        checked={template.projectName}
                        onChange={(projectName) => {
                            template.update({
                                projectName,
                            })
                        }}
                    />
                </div>
                <div className="flex-1 py-0 px-[0.25em] text-sm">
                    Display Project Name
                </div>
            </div>
            <div className="flex items-center mt-1 text-sm">
                <div
                    className="py-0 px-[0.5em]"
                    style={{
                        flex: '0 0 auto',
                    }}
                >
                    <Switch
                        checked={template.projectAddress}
                        onChange={(projectAddress) => {
                            template.update({
                                projectAddress,
                            })
                        }}
                    />
                </div>
                <div className="flex-1 py-0 px-[0.25em] text-sm">
                    Display Project Address
                </div>
            </div>
            {template.projectAddress && (
                <>
                    <div className="mt-[1.5em] font-bold text-sm">
                        Project Address
                    </div>
                    <div
                        style={{
                            fontSize: '1.2rem',
                            padding: '0 0.75em 0 0',
                        }}
                    >
                        <TextArea
                            value={invoice.project.address}
                            onChange={(e) => {
                                invoice.project.update({
                                    address: e.target.value,
                                })
                            }}
                            placeholder="Edit Project Address..."
                            autoSize={{ minRows: 3, maxRows: 4 }}
                            allowClear
                        />
                    </div>
                </>
            )}
            {invoice.contact && (
                <>
                    <div className="mt-[1em] font-bold text-sm">
                        Client Address
                    </div>
                    <div className="text-sm pr-[0.75em]">
                        <TextArea
                            value={invoice.contact.address}
                            onChange={(e) => {
                                invoice.contact.update({
                                    address: e.target.value,
                                })
                            }}
                            placeholder="Edit client address..."
                            autoSize={{ minRows: 3, maxRows: 4 }}
                            allowClear
                        />
                    </div>
                </>
            )}
            <h3 style={{ margin: '1.5em 0 0 0' }}>Content</h3>
            <div className='mt-[1em] font-bold text-sm"'>Opening Statement</div>
            <div className="text-sm pr-[0.75em]">
                <TextArea
                    value={template.getOpeningText(invoice)}
                    onChange={(e) => {
                        template.update(
                            {
                                contentStart: e.target.value,
                            },
                            null,
                            invoice
                        )
                    }}
                    placeholder="Enter some notes..."
                    autoSize={{ minRows: 5, maxRows: 10 }}
                    allowClear
                />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '0.5rem',
                        fontSize: '1.2rem',
                    }}
                >
                    <div
                        style={{
                            flex: '0 0 auto',
                            padding: '0 0.5em',
                        }}
                    >
                        <Switch
                            size="small"
                            checked={template.openPerProject}
                            onChange={(openPerProject) => {
                                template.update({
                                    openPerProject,
                                })
                            }}
                        />
                    </div>
                    <div className="flex-1 py-0 px-[0.25em] text-sm">
                        Unique to Each Project
                    </div>
                </div>
            </div>
            <div className="mt-[2em] font-bold text-sm">Table Columns</div>
            <div
                style={{
                    fontSize: '1.2rem',
                    padding: '0 0.75em 0 0',
                }}
            >
                <MultiSelect
                    selectedOptions={template.columns
                        .filter((ck) => !['amount'].includes(ck))
                        .map((ck) => columnDefs[ck])}
                    onChange={(v) =>
                        template.update({
                            columns: [...v.map((v) => v.key), 'amount'],
                        })
                    }
                    options={Object.values(columnDefs).filter(
                        (v) => v.key !== 'amount'
                    )}
                    optionLabel={(o) => o.label}
                    optionValue={(o) => o}
                    searchMatcher={(c, text) =>
                        c?.label.toLowerCase().match(text?.toLowerCase())
                    }
                    styles={{
                        multiValueLabel: (provided, state) => ({
                            ...provided,
                            fontSize: '12px',
                        }),
                    }}
                />
            </div>
            <div className="flex items-center mt-12 text-sm">
                <div
                    className="text-sm font-bold px-[0.5em]"
                    style={{
                        flex: '0 0 auto',
                    }}
                >
                    Tax Label
                </div>
                <div style={{ flex: '1 1 auto', padding: '0 0.25em' }}>
                    <Input
                        value={template.taxName || ''}
                        onChange={(e) =>
                            template.update({
                                taxName: e.target.value,
                            })
                        }
                    />
                </div>
            </div>
            <div className="mt-[2em] font-bold text-sm">Closing Statement</div>
            <div className="text-sm pr-[0.75em]">
                <TextArea
                    value={template.getClosingText(invoice)}
                    onChange={(e) => {
                        template.update(
                            {
                                contentEnd: e.target.value,
                            },
                            null,
                            invoice
                        )
                    }}
                    placeholder="Enter some notes..."
                    autoSize={{ minRows: 5, maxRows: 10 }}
                    allowClear
                />
                <div className="flex items-center mt-2 text-sm">
                    <div
                        className="px-[0.5em]"
                        style={{
                            flex: '0 0 auto',
                        }}
                    >
                        <Switch
                            size="small"
                            checked={template.closePerProject}
                            onChange={(closePerProject) => {
                                template.update({
                                    closePerProject,
                                })
                            }}
                        />
                    </div>
                    <div className="text-sm px-[0.25em] flex-1">
                        Unique to Each Project
                    </div>
                </div>
            </div>
            <h3 style={{ margin: '1.5em 0 0 0' }}>Footer</h3>
            <div className="text-sm font-bold mt-[1em]">Left</div>
            <div
                style={{
                    fontSize: '12px',
                    padding: '0 0.75em 0 0',
                }}
            >
                <TextArea
                    value={template.footerLeft}
                    onChange={(e) => {
                        template.update({
                            footerLeft: e.target.value,
                        })
                    }}
                    style={{ fontSize: '12px' }}
                    placeholder="Enter some notes..."
                    autoSize={{ minRows: 4, maxRows: 6 }}
                    allowClear
                />
            </div>
            <div className="text-sm font-bold mt-[1em]">Right</div>
            <div
                style={{
                    fontSize: '12px',
                    padding: '0 0.75em 0 0',
                }}
            >
                <TextArea
                    value={template.footerRight}
                    onChange={(e) => {
                        template.update({
                            footerRight: e.target.value,
                        })
                    }}
                    style={{ fontSize: '12px' }}
                    placeholder="Enter some notes..."
                    autoSize={{ minRows: 4, maxRows: 6 }}
                    allowClear
                />
            </div>
        </div>
    )
})
