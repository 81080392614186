import { Button } from '@2/components/ui/button'
import uuid from 'uuid'
import { addCostCentre } from './cost-centre-store'
import { CostCentreTable } from './cost-centre-table'

export const CostCentrePage = () => {
    const handleAddCostCentre = () => {
        addCostCentre({
            id: uuid(),
            name: '',
            isBillable: false,
        })
    }
    return (
        <>
            <div className="p-8">
                <Button variant="outline" onClick={handleAddCostCentre}>
                    + Add Cost Centre
                </Button>
            </div>
            <CostCentreTable />
        </>
    )
}
