// columns/select-column.tsx
import { ColumnDef, Row } from '@tanstack/react-table'
import { Checkbox } from '@2/components/ui/checkbox'
import { useTableSelection } from '@2/components/data-table/hooks/use-table-selection'

interface SelectableColumnOptions<TData> {
    enableRowSelection?: (row: Row<TData>) => boolean
    enableMultipleSelection?: boolean
}

export const createSelectableColumn = <TData,>(
    options: SelectableColumnOptions<TData> = {}
): ColumnDef<TData> => {
    const { getSubRowSelectionState, handleRowSelection } =
        useTableSelection(options)

    return {
        id: 'select',
        accessorKey: 'select',
        header: ({ table }) => {
            if (!options.enableMultipleSelection) return null

            return (
                <div className="flex justify-center">
                    <Checkbox
                        checked={
                            table.getIsAllRowsSelected()
                                ? true
                                : table.getIsSomeRowsSelected()
                                  ? 'indeterminate'
                                  : false
                        }
                        onCheckedChange={(checked) => {
                            table.toggleAllRowsSelected(!!checked)
                        }}
                        aria-label="Select all"
                    />
                </div>
            )
        },
        cell: ({ row, table }) => {
            if (!options.enableRowSelection?.(row)) {
                return null
            }

            const subRowState = row.getLeafRows().length
                ? getSubRowSelectionState(row, table.getState().rowSelection)
                : 'none'

            return (
                <div className="flex justify-center">
                    <Checkbox
                        checked={
                            row.getIsSelected()
                                ? true
                                : subRowState === 'some'
                                  ? 'indeterminate'
                                  : false
                        }
                        onCheckedChange={(checked) => {
                            handleRowSelection(row, table, !!checked)
                        }}
                        aria-label="Select row"
                    />
                </div>
            )
        },
        enableSorting: false,
        enableHiding: false,
        size: 40,
        minSize: 34,
    }
}
