import * as React from 'react'
import { type Table } from '@tanstack/react-table'
import { PlusIcon, InfoCircledIcon } from '@radix-ui/react-icons'
import isEqual from 'lodash/isEqual'

import { cn } from '@2/lib/utils'
import { Button } from '@2/components/ui/button'
import { Input } from '@2/components/ui/input'
import { DateRangePicker } from '@2/components/date-range-picker'
import { DataTableFilterCombobox } from '@2/components/data-table/advanced/data-table-filter-combobox'
import { DataTableGroupOptions } from '@2/components/data-table/data-table-view-options'
import { DataTableViewOptions } from '@2/components/data-table/data-table-view-options'
import { DataTableFilterItem } from '@2/components/data-table/advanced/data-table-filter-item'
import { DataTableMultiFilter } from '@2/components/data-table/advanced/data-table-multi-filter'
import { TableState, Filter } from '@2/types'
import { subYears } from 'date-fns'

export interface DataTableFilterField<TData> {
    label: string
    value: keyof TData
    placeholder?: string
    options?: Array<{ label: string; value: string }>
    isDefaultFilter?: boolean
    typeFilter?: string
    groupBy?: (item: TData) => string
    sortGroups?: (a: any, b: any) => number
}

export interface DataTableFilterOption<TData> {
    id: string
    label: string
    value: keyof TData
    options: Array<{ label: string; value: string }>
    filterValues?: any[] | string | null
    filterOperator?: string
    isMulti?: boolean
    typeFilter?: string
    groupBy?: (item: TData) => string
    sortGroups?: (a: any, b: any) => number
    isApplyToPhases: boolean
}

interface DataTableAdvancedToolbarProps<TData> {
    table: Table<TData>
    filterFields?: DataTableFilterField<TData>[]
    children?: React.ReactNode
    className?: string
    tableState: TableState
    onStateChange: (newState: Partial<TableState>) => void
    onApplyChanges: () => Promise<void>
    onColumnsChange?: (columns: string[]) => void
    isLoading?: boolean
    groupByColumns?: string[]
    fixedColumns?: string[]
    showSearchFieldHeader?: boolean
    showApplyToPhase?: boolean
    isHaveGroup?: boolean
    pendingChanges: boolean
    searchableFields?: (keyof TData)[]
}

export function DataTableAdvancedToolbar<TData>({
    table,
    filterFields = [],
    tableState,
    onStateChange,
    onApplyChanges,
    onColumnsChange,
    isLoading = false,
    children,
    className,
    groupByColumns = [],
    fixedColumns = [],
    showSearchFieldHeader = true,
    showApplyToPhase = true,
    isHaveGroup = false,
    pendingChanges = false,
    searchableFields = [] as (keyof TData)[],
    ...props
}: DataTableAdvancedToolbarProps<TData>) {
    const [globalFilter, setGlobalFilter] = React.useState('')
    const [error, setError] = React.useState<string | null>(null)

    // Convert dates for display
    const displayDates = tableState?.dateRange
        ? tableState.dateRange.map((date) => {
              if (typeof date === 'number' && date === -Infinity) {
                  return subYears(new Date(), 50)
              }
              if (typeof date === 'number' && date === Infinity) {
                  return new Date()
              }
              return date ? new Date(date) : new Date()
          })
        : ([new Date(), new Date()] as [Date, Date])

    const handleApplyChanges = async () => {
        try {
            setError(null)
            await onApplyChanges()
        } catch (err) {
            setError('Failed to update report. Please try again.')
            console.error(err)
        }
    }

    const handleSearch = (value: string) => {
        setGlobalFilter(value)
        table.setGlobalFilter(value)
    }

    // Custom global filter function
    const globalFilterFn = React.useCallback(
        (row: any, columnId: string, filterValue: string) => {
            const searchValue = String(filterValue).toLowerCase()

            return searchableFields.some((field) => {
                const value = row.getValue(field)
                return (
                    value && String(value).toLowerCase().includes(searchValue)
                )
            })
        },
        [searchableFields]
    )

    React.useEffect(() => {
        if (table) {
            table.options.globalFilterFn = globalFilterFn
        }
    }, [table, globalFilterFn])

    return (
        <div
            className={cn(
                'flex w-full flex-col space-y-2.5 overflow-auto p-8 print:hidden',
                className
            )}
            {...props}
        >
            {/* Top Bar: Date Range and Actions */}
            <div className="flex items-center justify-between gap-2">
                <div className="min-w-[300px]">
                    <DateRangePicker
                        onUpdate={({ range }) => {
                            onStateChange({
                                dateRange: [range.from, range.to].map((date) =>
                                    date.toISOString()
                                ),
                            })
                        }}
                        initialDateFrom={displayDates[0]}
                        initialDateTo={displayDates[1]}
                        align="start"
                        locale="en-GB"
                        showCompare={false}
                    />
                </div>

                <div className="flex items-center gap-2">
                    {showSearchFieldHeader && (
                        <Input
                            placeholder="Filter by name"
                            value={globalFilter ?? ''}
                            onChange={(event) =>
                                handleSearch(event.target.value)
                            }
                            className="h-8 w-40 lg:w-64"
                        />
                    )}

                    {isHaveGroup && (
                        <DataTableGroupOptions
                            table={table}
                            onStateChange={onStateChange}
                            tableState={tableState}
                        />
                    )}

                    <DataTableViewOptions
                        table={table}
                        onColumnsChange={onColumnsChange}
                        fixedColumns={fixedColumns}
                        tableState={tableState}
                    />

                    {children}
                </div>
            </div>

            {/* Filters Section */}
            <div className="flex items-center gap-2 flex-wrap">
                {convertFiltersToOptions(tableState?.filters, filterFields)
                    // .filter((option) => !option.isMulti)
                    .map((option) => (
                        <DataTableFilterItem
                            key={String(option.value)}
                            table={table}
                            selectedOption={option}
                            selectedOptions={convertFiltersToOptions(
                                tableState?.filters,
                                filterFields
                            )}
                            onFilterChange={(newOptions) => {
                                onStateChange({
                                    filters:
                                        convertOptionsToFilters(newOptions),
                                })
                            }}
                            showApplyToPhase={showApplyToPhase}
                        />
                    ))}

                {/* {convertFiltersToOptions(tableState.filters, filterFields).some(
                    (option) => option.isMulti
                ) && (
                    <DataTableMultiFilter
                        table={table}
                        allOptions={filterFields}
                        options={convertFiltersToOptions(
                            tableState.filters,
                            filterFields
                        ).filter((option) => option.isMulti)}
                        setSelectedOptions={(newOptions) => {
                            onStateChange({
                                filters: convertOptionsToFilters(newOptions),
                            })
                        }}
                    />
                )} */}

                <DataTableFilterCombobox
                    options={filterFields}
                    selectedOptions={convertFiltersToOptions(
                        tableState?.filters,
                        filterFields
                    )}
                    onFilterChange={(newOptions) => {
                        onStateChange({
                            filters: convertOptionsToFilters(newOptions),
                        })
                    }}
                >
                    <Button
                        variant="outline"
                        size="sm"
                        className="h-7 rounded-full print:hidden"
                    >
                        <PlusIcon className="mr-2 size-4 opacity-50" />
                        Add filter
                    </Button>
                </DataTableFilterCombobox>
            </div>

            {/* Changes Alert */}
            {(pendingChanges || error) && (
                <div
                    className={cn(
                        'flex items-center justify-between rounded-md px-4 py-2 border',
                        error ? 'border-red-500' : 'border-gray-300'
                    )}
                >
                    <div className="flex items-center">
                        <InfoCircledIcon className="mr-2 size-4" />
                        <span>{error || 'Changes pending'}</span>
                    </div>
                    <Button
                        onClick={handleApplyChanges}
                        className={cn('bg-[#facc15] rounded-md px-4 py-1')}
                        disabled={isLoading}
                    >
                        {isLoading
                            ? 'Loading...'
                            : error
                              ? 'Try again'
                              : 'Apply changes'}
                    </Button>
                </div>
            )}
        </div>
    )
}

// Helper functions
function convertFiltersToOptions<TData>(
    filters: Filter[],
    filterFields: DataTableFilterField<TData>[]
): DataTableFilterOption<TData>[] {
    if (!filters) return []
    return filters.map((filter) => ({
        id: crypto.randomUUID(),
        label:
            filterFields.find((f) => f.value === filter.column)?.label ??
            String(filter.column),
        value: filter.column as keyof TData,
        options:
            filterFields.find((f) => f.value === filter.column)?.options ?? [],
        filterValues: filter.value,
        filterOperator: filter.operator,
        typeFilter: filterFields.find((f) => f.value === filter.column)
            ?.typeFilter,
        isApplyToPhases: filter.group === 'phase',
    }))
}

function convertOptionsToFilters<TData>(
    options: DataTableFilterOption<TData>[]
): Filter[] {
    if (!options) return []
    return options.map((option) => ({
        column: String(option.value),
        operator:
            option.filterOperator ??
            (option.typeFilter === 'select' ? 'in' : '='),
        value: option.filterValues,
        group: option.isApplyToPhases ? 'phase' : 'project',
    }))
}
