import { createStore } from '@xstate/store'
import { useSelector } from '@xstate/store/react'
import { useMemo } from 'react'
import { z } from 'zod'

// Zod schemas
export const OrganisationHolidaySchema = z.object({
    id: z.string(),
    name: z.string().max(200),
    startDate: z.date(),
    endDate: z.date(),
    deletedAt: z.date().nullable().optional(),
})

export type OrganisationHoliday = z.infer<typeof OrganisationHolidaySchema>

// Initial state
const initialContext = {
    holidays: [] as OrganisationHoliday[],
    isLoading: false,
    pendingChanges: false,
}

// Create the store
export const holidaysPageStore = createStore({
    context: initialContext,
    on: {
        setHolidays: {
            holidays: (_, event: { holidays: OrganisationHoliday[] }) =>
                event.holidays,
        },
        addHoliday: {
            holidays: (context, event: { holiday: OrganisationHoliday }) => [
                ...context.holidays,
                event.holiday,
            ],
            pendingChanges: true,
        },
        updateHoliday: {
            holidays: (
                context,
                event: { id: string; holiday: Partial<OrganisationHoliday> }
            ) => {
                const index = context.holidays.findIndex(
                    (h) => h.id === event.id
                )
                if (index === -1) return context.holidays

                const updatedHolidays = [...context.holidays]
                updatedHolidays[index] = {
                    ...updatedHolidays[index],
                    ...event.holiday,
                }
                return updatedHolidays
            },
            pendingChanges: true,
        },
        deleteHoliday: {
            holidays: (context, event: { id: string }) => {
                const index = context.holidays.findIndex(
                    (h) => h.id === event.id
                )
                if (index === -1) return context.holidays

                const updatedHolidays = [...context.holidays]
                updatedHolidays[index] = {
                    ...updatedHolidays[index],
                    deletedAt: new Date(),
                }
                return updatedHolidays
            },
            pendingChanges: true,
        },
        setIsLoading: {
            isLoading: (_, event: { isLoading: boolean }) => event.isLoading,
        },
        setPendingChanges: {
            pendingChanges: (_, event: { pending: boolean }) => event.pending,
        },
        reset: {
            holidays: () => [],
            isLoading: false,
            pendingChanges: false,
        },
    },
})

// Selector hooks
export const useHolidays = () =>
    useSelector(holidaysPageStore, (state) => state.context.holidays)
export const useActiveHolidays = () => {
    const holidays = useSelector(
        holidaysPageStore,
        (state) => state.context.holidays
    )
    return useMemo(() => holidays.filter((h) => !h?.deletedAt), [holidays])
}
export const useIsLoading = () =>
    useSelector(holidaysPageStore, (state) => state.context.isLoading)
export const usePendingChanges = () =>
    useSelector(holidaysPageStore, (state) => state.context.pendingChanges)
export const addHoliday = (holiday: OrganisationHoliday) =>
    holidaysPageStore.send({
        type: 'addHoliday',
        holiday,
    })
export const updateHoliday = (
    id: string,
    holiday: Partial<OrganisationHoliday>
) =>
    holidaysPageStore.send({
        type: 'updateHoliday',
        id,
        holiday,
    })
export const deleteHoliday = (id: string) =>
    holidaysPageStore.send({
        type: 'deleteHoliday',
        id,
    })
export const setHolidays = (holidays: OrganisationHoliday[]) =>
    holidaysPageStore.send({
        type: 'setHolidays',
        holidays,
    })
export const setIsLoading = (isLoading: boolean) =>
    holidaysPageStore.send({
        type: 'setIsLoading',
        isLoading,
    })
export const setPendingChanges = (pending: boolean) =>
    holidaysPageStore.send({
        type: 'setPendingChanges',
        pending,
    })
export const getHolidays = () =>
    holidaysPageStore.getSnapshot().context.holidays
