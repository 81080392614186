import * as React from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { DayPicker } from 'react-day-picker'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@2/components/ui/select'
import { Button } from '@2/components/ui/button'

import { cn } from '@2/lib/utils'
import { buttonVariants } from '@2/components/ui/button'

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({
    className,
    classNames,
    showOutsideDays = true,
    ...props
}: CalendarProps) {
    let [month, setMonth] = React.useState<Date>(new Date())
    month = props.month || month
    setMonth = props.onMonthChange || setMonth

    // Create arrays for months and years
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    const years = Array.from(
        { length: 16 },
        (_, i) => new Date().getFullYear() - 10 + i
    )

    // Handlers for month and year selection
    const handleMonthSelect = (value: string) => {
        const newMonth = new Date(month.setMonth(months.indexOf(value)))
        setMonth(newMonth)
    }

    const handleYearSelect = (value: string) => {
        const newDate = new Date(month.setFullYear(parseInt(value)))
        setMonth(newDate)
    }

    const handlePreviousMonth = () => {
        const previousMonth = new Date(month)
        previousMonth.setMonth(previousMonth.getMonth() - 1)
        setMonth(previousMonth)
    }

    const handleNextMonth = () => {
        const nextMonth = new Date(month)
        nextMonth.setMonth(nextMonth.getMonth() + 1)
        setMonth(nextMonth)
    }

    return (
        <DayPicker
            showOutsideDays={showOutsideDays}
            className={cn('p-3', className)}
            month={month}
            onMonthChange={setMonth}
            classNames={{
                months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
                month: 'space-y-4',
                caption: 'flex justify-center pt-1 relative items-center',
                caption_label: 'text-sm font-medium flex space-x-2',
                nav: 'space-x-1 flex items-center',
                nav_button: cn(
                    buttonVariants({ variant: 'outline' }),
                    'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'
                ),
                nav_button_previous: 'absolute left-1',
                nav_button_next: 'absolute right-1',
                table: 'w-full border-collapse space-y-1',
                head_row: 'flex justify-between px-4',
                head_cell:
                    'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
                row: 'flex w-full justify-between mt-2 px-4',
                cell: 'h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
                day: cn(
                    buttonVariants({ variant: 'ghost' }),
                    'h-9 w-9 p-0 font-normal aria-selected:opacity-100'
                ),
                day_range_end: 'day-range-end',
                day_selected:
                    'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
                day_today: 'bg-accent text-accent-foreground',
                day_outside:
                    'day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
                day_disabled: 'text-muted-foreground opacity-50',
                day_range_middle:
                    'aria-selected:bg-accent aria-selected:text-accent-foreground',
                day_hidden: 'invisible',
                ...classNames,
            }}
            components={{
                Caption: ({ displayMonth }) => (
                    <div className="flex justify-center space-x-2 items-center">
                        <Button
                            variant="ghost"
                            className="h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
                            onClick={handlePreviousMonth}
                        >
                            <ChevronLeft className="h-4 w-4" />
                        </Button>
                        <div className="relative">
                            <Select
                                value={months[displayMonth.getMonth()]}
                                onValueChange={handleMonthSelect}
                            >
                                <SelectTrigger className="w-[120px] h-8">
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent
                                    ref={(ref) => {
                                        if (ref) {
                                            ref.style.zIndex = '9999999999'
                                        }
                                    }}
                                >
                                    {months.map((month) => (
                                        <SelectItem key={month} value={month}>
                                            {month}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="relative">
                            <Select
                                value={displayMonth.getFullYear().toString()}
                                onValueChange={handleYearSelect}
                            >
                                <SelectTrigger className="w-[90px] h-8">
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent
                                    ref={(ref) => {
                                        if (ref) {
                                            ref.style.zIndex = '9999999999'
                                        }
                                    }}
                                >
                                    {years.map((year) => (
                                        <SelectItem
                                            key={year}
                                            value={year.toString()}
                                        >
                                            {year}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>

                        <Button
                            variant="ghost"
                            className="h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
                            onClick={handleNextMonth}
                        >
                            <ChevronRight className="h-4 w-4" />
                        </Button>
                    </div>
                ),
            }}
            {...props}
        />
    )
}
Calendar.displayName = 'Calendar'

export { Calendar }
