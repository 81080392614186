import { useState } from 'react'
import {
    Settings,
    LayoutDashboard,
    LineChart,
    Users,
    UserMinus,
    HardHat,
    Archive,
    Shield,
} from 'lucide-react'
import { useFilteredCacheItems } from '@2/cache'
import {
    SubNavigationFooter,
    SubNavigationHeading,
    SubNavigationSidebar,
} from '../components/sidebar'
import { SubNavigationBody } from '../components/sidebar'
import { ExpandableList } from '../components/expandable-list'
import { SideNavButton } from '../components/navigation-button'
import { useNavigate } from '@tanstack/react-router'

const getProjectTitle = (project) =>
    project.jobNumber ? `${project.jobNumber}: ${project.name}` : project.name

export const ResourcesSubNav = () => {
    const navigate = useNavigate()
    const [expandedList, setExpandedList] = useState('reports')
    const staffReports = useFilteredCacheItems(
        'organisationReports',
        (r) => r.type === 'staffMember'
    )
    const activeStaff = useFilteredCacheItems('staff', (s) => !s.isArchived)
    const archivedStaff = useFilteredCacheItems('staff', (s) => s.isArchived)
    const activeRoles = useFilteredCacheItems('roles', (r) => !r.isArchived)
    const archivedRoles = useFilteredCacheItems('roles', (r) => r.isArchived)

    const lists = [
        {
            id: 'reports',
            title: 'Staff Reports',
            icon: LineChart,
            rootRoute: '/staff',
            items: (staffReports || [])
                .map((r) => ({
                    label: r.name,
                    value: r.id,
                    route: `/staff?report=${r.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
        {
            id: 'activeStaff',
            title: 'Active Staff',
            icon: Users,
            items: activeStaff
                .map((s) => ({
                    label: `${s.firstName} ${s.lastName}`,
                    value: s.id,
                    route: `/staff/${s.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
        {
            id: 'archivedStaff',
            title: 'Archived Staff',
            icon: Archive,
            items: archivedStaff
                .map((s) => ({
                    label: `${s.firstName} ${s.lastName}`,
                    value: s.id,
                    route: `/staff/${s.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
        {
            id: 'activeRoles',
            title: 'Active Roles',
            icon: HardHat,
            items: activeRoles
                .map((r) => ({
                    label: r.name,
                    value: r.id,
                    route: `/staff-roles/${r.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
        {
            id: 'archivedRoles',
            title: 'Archived Roles',
            icon: Archive,
            items: archivedRoles
                .map((r) => ({
                    label: r.name,
                    value: r.id,
                    route: `/staff-roles/${r.id}`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        },
    ]

    return (
        <SubNavigationSidebar>
            <SubNavigationHeading>Resources</SubNavigationHeading>
            <SubNavigationBody>
                {lists.map((list) => (
                    <ExpandableList
                        key={list.id}
                        title={list.title}
                        items={list.items}
                        icon={list.icon}
                        rootRoute={list.rootRoute}
                        isExpanded={expandedList === list.id}
                        onToggle={() =>
                            setExpandedList(
                                expandedList === list.id ? null : list.id
                            )
                        }
                    />
                ))}
            </SubNavigationBody>
            <SubNavigationFooter>
                <SideNavButton
                    icon={Shield}
                    label="Permissions"
                    onClick={() => {
                        navigate({
                            to: '/permissions',
                        })
                    }}
                />
            </SubNavigationFooter>
        </SubNavigationSidebar>
    )
}
