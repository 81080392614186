import { ColumnDef } from '@tanstack/react-table'

export function orderColumnDefs(
    columns: ColumnDef<any, any>[],
    selectedColumnIds: string[],
    groupedColumnIds: string[],
    fixedColumnIds: string[]
) {
    return columns.sort((a, b) => {
        // Get priority for each column (lower number = higher priority)
        const getPriority = (columnId: string): number => {
            if (fixedColumnIds.includes(columnId)) return 1
            if (groupedColumnIds.includes(columnId)) return 2
            if (selectedColumnIds.includes(columnId)) return 3
            return 4
        }

        // Get index within its category array (-1 if not found)
        const getOrderIndex = (columnId: string): number => {
            if (fixedColumnIds.includes(columnId)) {
                return fixedColumnIds.indexOf(columnId)
            }
            if (groupedColumnIds.includes(columnId)) {
                return groupedColumnIds.indexOf(columnId)
            }
            if (selectedColumnIds.includes(columnId)) {
                return selectedColumnIds.indexOf(columnId)
            }
            return -1
        }

        const priorityA = getPriority(a.accessorKey || a.id)
        const priorityB = getPriority(b.accessorKey || b.id)

        // If priorities are different, sort by priority
        if (priorityA !== priorityB) {
            return priorityA - priorityB
        }

        // If same priority, sort by order within their category
        const orderA = getOrderIndex(a.accessorKey || a.id)
        const orderB = getOrderIndex(b.accessorKey || b.id)

        // Only compare order indices if both columns are in the same category array
        if (orderA !== -1 && orderB !== -1) {
            return orderA - orderB
        }

        // Keep original order for columns not in any category array
        return 0
    })
}
