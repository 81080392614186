import { createGenerateColumnDef } from '@/version2/components/data-table/helpers/generate-column-def'
import { type ColumnDef } from '@tanstack/react-table'

export function getColumns(
    onHideColumn: (column: string) => void,
    updateCostCentre: (id: string, data: any) => void,
    deleteCostCentre: (id: string) => void
): ColumnDef<any>[] {
    const generateColumnDef = createGenerateColumnDef({
        onHideColumn,
    })
    const wideSize = 100
    return [
        generateColumnDef({
            key: 'name',
            type: 'text',
            title: 'Name',
            size: wideSize,
            editable: true,
            onChange: (row, val) =>
                updateCostCentre(row.original.id, { name: val }),
        }),
        generateColumnDef({
            key: 'isBillable',
            title: 'Billable',
            type: 'boolean',
            size: wideSize,
            editable: true,
            onChange: (row, val) =>
                updateCostCentre(row.original.id, { isBillable: val }),
        }),
        generateColumnDef({
            key: 'deleteButton',
            title: 'Action',
            type: 'button',
            size: wideSize,
            editable: true,
            cell: (row) => {
                return <i className="fa fa-times" />
            },
            onClick: (row) => deleteCostCentre(row.original.id),
        }),
    ]
}
