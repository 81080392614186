import * as React from 'react'
import type { Table, Column } from '@tanstack/react-table'
import {
    MixerHorizontalIcon,
    CheckIcon,
    MagnifyingGlassIcon,
} from '@radix-ui/react-icons'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import { Button } from '@2/components/ui/button'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@2/components/ui/popover'
import { Input } from '@/version2/components/ui/input'
import { cn } from '@/lib/utils'
import {
    TableState,
    SetColumnsOptions,
    SetOrganisationReportOptions,
} from '@/version2/types'

interface DataTableViewOptionsProps<TData> {
    table: Table<TData>
    onColumnsChange: (columns: string[], options: SetColumnsOptions) => void // For updating column order/visibility
    onStateChange?: (
        newState: Partial<TableState>,
        options: SetOrganisationReportOptions
    ) => void
    tableState: TableState
    fixedColumns?: string[]
}

export const DataTableViewOptions = React.forwardRef(
    function DataTableViewOptions<TData>({
        table,
        onColumnsChange,
        tableState,
        fixedColumns = [],
    }: DataTableViewOptionsProps<TData>) {
        const [searchQuery, setSearchQuery] = React.useState('')
        const [open, setOpen] = React.useState(false)
        const columns = table
            .getAllColumns()
            .filter(
                (column) =>
                    typeof column.accessorFn !== 'undefined' &&
                    column.getCanHide() &&
                    (column.columnDef?.meta as any)?.title
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
            )

        const sortedColumns = [...columns].sort((a, b) => {
            if (a.getIsVisible() === b.getIsVisible()) {
                return 0
            }
            return a.getIsVisible() ? -1 : 1
        })

        const filteredColumns = sortedColumns.filter((column) =>
            (column.columnDef?.meta as any)?.title
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
        )

        const onDragEnd = (result: any) => {
            if (!result.destination) {
                return
            }

            const newColumns = Array.from(filteredColumns)
            const [reorderedItem] = newColumns.splice(result.source.index, 1)
            newColumns.splice(result.destination.index, 0, reorderedItem)
            onColumnsChange(
                newColumns
                    .filter((col) => col.getIsVisible())
                    .map((col) => col.id),
                { needsRefetch: false }
            )
        }

        const renderClone = (provided: any, snapshot: any, rubric: any) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <div
                    className={cn(
                        'flex items-center relative py-1.5',
                        snapshot.isDragging && 'bg-accent'
                    )}
                >
                    <CheckIcon className={cn('mr-2')} />
                    <span className="truncate">
                        {
                            (
                                filteredColumns[rubric.source.index].columnDef
                                    ?.meta as any
                            ).title
                        }
                    </span>
                </div>
            </div>
        )

        const ColumnItem = React.memo(
            ({
                column,
                index,
                isLastVisible,
            }: {
                column: Column<TData, unknown>
                index: number
                isLastVisible: boolean
            }) => {
                const selected = tableState.columns.includes(column.id)
                return (
                    <Draggable
                        draggableId={column.id}
                        index={index}
                        isDragDisabled={!column.getIsVisible()}
                    >
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <li
                                    key={column.id}
                                    onClick={() => {
                                        column.toggleVisibility(!selected)
                                        if (!selected) {
                                            onColumnsChange([
                                                ...tableState.columns,
                                                column.id,
                                            ])
                                        } else {
                                            onColumnsChange(
                                                tableState.columns.filter(
                                                    (id) => id !== column.id
                                                )
                                            )
                                        }
                                    }}
                                    className={cn(
                                        'flex items-center relative cursor-pointer py-1.5',
                                        selected &&
                                            isLastVisible &&
                                            'border-b border-border mb-2'
                                    )}
                                >
                                    {selected && (
                                        <CheckIcon className={cn('mr-2')} />
                                    )}
                                    <span
                                        className={cn(
                                            'truncate',
                                            selected ? '' : 'ml-7'
                                        )}
                                    >
                                        {(column.columnDef?.meta as any)?.title}
                                    </span>
                                </li>
                            </div>
                        )}
                    </Draggable>
                )
            }
        )

        return (
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        aria-label="Toggle columns"
                        variant="outline"
                        size="sm"
                        className="ml-auto hidden h-8 lg:flex"
                    >
                        <MixerHorizontalIcon className="mr-2 size-4" />
                        Select columns
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[20rem] p-0" align="end">
                    <div className="pl-2">
                        <Input
                            placeholder="Search columns..."
                            value={searchQuery}
                            className="bg-transparent focus-visible:outline-none focus-visible:ring-0 border-none pl-6 pr-2 py-1 h-8"
                            onChange={(e) => setSearchQuery(e.target.value)}
                            prefix={<MagnifyingGlassIcon />}
                        />
                        <ul className="max-h-[400px] overflow-y-auto overflow-x-hidden">
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable
                                    droppableId="column-list"
                                    renderClone={renderClone}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className={
                                                snapshot.isDraggingOver
                                                    ? 'pointer-events-none'
                                                    : ''
                                            }
                                        >
                                            {filteredColumns.map(
                                                (column, index) => (
                                                    <ColumnItem
                                                        key={`${column.id}-${column.getIsVisible()}-${index}`}
                                                        column={column}
                                                        index={index}
                                                        isLastVisible={
                                                            index ===
                                                            filteredColumns.findIndex(
                                                                (col) =>
                                                                    !col.getIsVisible()
                                                            ) -
                                                                1
                                                        }
                                                    />
                                                )
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </ul>
                    </div>
                </PopoverContent>
            </Popover>
        )
    }
)

export const DataTableGroupOptions = React.forwardRef(
    function DataTableGroupOptions<TData>({
        table,
        onStateChange,
        tableState,
    }: DataTableViewOptionsProps<TData>) {
        // Check logic again
        const [searchQuery, setSearchQuery] = React.useState('')
        const [open, setOpen] = React.useState(false)
        const listGroups = tableState.groupBy
        const columns = table
            .getAllColumns()
            .filter(
                (column) =>
                    typeof column.accessorFn !== 'undefined' &&
                    column.getCanHide() &&
                    (column.columnDef?.meta as any)?.title
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
            )

        const sortedColumns = React.useMemo(() => {
            return [...columns].sort((a, b) => {
                const aIndex = listGroups.indexOf(a.id)
                const bIndex = listGroups.indexOf(b.id)
                if (aIndex === -1 && bIndex === -1) {
                    return 0
                }
                if (aIndex === -1) {
                    return 1
                }
                if (bIndex === -1) {
                    return -1
                }
                return aIndex - bIndex
            })
        }, [tableState.groupBy])

        const filteredColumns = React.useMemo(() => {
            return sortedColumns.filter((column) =>
                (column.columnDef?.meta as any)?.title
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
            )
        }, [sortedColumns, searchQuery])

        const onDragEnd = (result: any) => {
            if (!result.destination) return

            const newColumns = Array.from(filteredColumns)
            const [reorderedItem] = newColumns.splice(result.source.index, 1)
            newColumns.splice(result.destination.index, 0, reorderedItem)

            const groupColumns = newColumns
                .filter((columnId) => listGroups.includes(columnId.id))
                .map((item) => item.id)

            onStateChange({
                groupBy: groupColumns,
            })
        }

        const renderClone = (provided: any, snapshot: any, rubric: any) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <div
                    className={cn(
                        'flex items-center relative py-1.5',
                        snapshot.isDragging && 'bg-accent'
                    )}
                >
                    <CheckIcon className={cn('mr-2')} />
                    <span className="truncate">
                        {
                            (
                                filteredColumns[rubric.source.index].columnDef
                                    ?.meta as any
                            ).title
                        }
                    </span>
                </div>
            </div>
        )

        const ColumnItem = React.memo(
            ({
                column,
                index,
                isLastGroupItem,
            }: {
                column: Column<TData, unknown>
                index: number
                isLastGroupItem: boolean
            }) => {
                return (
                    <Draggable
                        draggableId={column.id}
                        index={index}
                        isDragDisabled={!listGroups.includes(column.id)}
                    >
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <li
                                    key={column.id}
                                    onClick={() => {
                                        const newListGroups =
                                            listGroups.includes(column.id)
                                                ? listGroups.filter(
                                                      (id) => id !== column.id
                                                  )
                                                : [...listGroups, column.id]

                                        onStateChange({
                                            groupBy: newListGroups,
                                        })
                                    }}
                                    className={cn(
                                        'flex items-center relative cursor-pointer py-1.5',
                                        listGroups.includes(column.id) &&
                                            isLastGroupItem &&
                                            'border-b border-border mb-2'
                                    )}
                                >
                                    {listGroups.includes(column.id) && (
                                        <CheckIcon className={cn('mr-2')} />
                                    )}
                                    <span
                                        className={cn(
                                            'truncate',
                                            listGroups.includes(column.id)
                                                ? ''
                                                : 'ml-7'
                                        )}
                                    >
                                        {(column.columnDef?.meta as any)?.title}
                                    </span>
                                </li>
                            </div>
                        )}
                    </Draggable>
                )
            }
        )

        return (
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        aria-label="Toggle columns"
                        variant="outline"
                        size="sm"
                        className="ml-auto hidden h-8 lg:flex"
                    >
                        <MixerHorizontalIcon className="mr-2 size-4" />
                        Select groups
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[20rem] p-0" align="end">
                    <div className="pl-2">
                        <Input
                            placeholder="Search columns..."
                            value={searchQuery}
                            className="bg-transparent focus-visible:outline-none focus-visible:ring-0 border-none pl-6 pr-2 py-1 h-8"
                            onChange={(e) => setSearchQuery(e.target.value)}
                            prefix={<MagnifyingGlassIcon />}
                        />
                        <ul className="max-h-[400px] overflow-y-auto overflow-x-hidden">
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable
                                    droppableId="column-list"
                                    renderClone={renderClone}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className={
                                                snapshot.isDraggingOver
                                                    ? 'pointer-events-none'
                                                    : ''
                                            }
                                        >
                                            {filteredColumns.map(
                                                (column, index) => (
                                                    <ColumnItem
                                                        key={`${column.id}-${listGroups.includes(column.id)}-${index}`}
                                                        column={column}
                                                        index={index}
                                                        isLastGroupItem={
                                                            index ===
                                                            filteredColumns.findIndex(
                                                                (col) =>
                                                                    !listGroups.includes(
                                                                        col.id
                                                                    )
                                                            ) -
                                                                1
                                                        }
                                                    />
                                                )
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </ul>
                    </div>
                </PopoverContent>
            </Popover>
        )
    }
)
